// external components
import Slider from "@material-ui/core/Slider";
import FlipIcon from "@mui/icons-material/Flip";
import { IconButton, Tooltip } from "@mui/material";
import { memo, useEffect, useReducer, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { toast } from "react-hot-toast";

// internal components
import { EditorContextApi } from "../../../../../pages/ResumeEditor/EditorContextApi";
import {
	ControlCollapse,
	DeleteDialog,
	Description,
	FirstCol,
	SecondCol,
	SectionTitle,
	ToolTips
} from "../common";

const PersonalDetails = ({ contents }) => {
	// personalDetails contents
	const {
		personal_details_subtitle,
		job_title,
		upload_photo,
		edit_photo,
		delete_photo,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		personal_city,
		personal_area,
		personal_zip,
		same_address,
		permanent_address,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		edit_additional_details,
		hidden_additional_details,
		rename,
		revert,
		job_title_tooltip,
		nid_tooltip,
		driving_license_tooltip,
		date_of_birth_tooltip
	} = contents.editor;

	// editor contextApi
	const { getResume, setResume, updateResumeInfo, delay, userId } =
		EditorContextApi();

	// assign
	const _id = getResume.personal_details[0]._id;
	const template = getResume.template;
	const counter = getResume.counter;

	// for delete image
	const [deleteImg, setDeleteImg] = useState("");

	// for title and collapse controls start
	const initialController = {
		border: false,
		editTitle: false,
		collapse: true,
		moreEdit: false,
		beforeUploadImg: false
	};

	const controlHandler = (state, action) => {
		switch (action.type) {
			case "open-border":
				return {
					...state,
					border: true
				};

			case "open-editor":
				return {
					...state,
					editTitle: true
				};

			case "close-border-editor":
				return {
					...state,
					border: false,
					editTitle: false
				};

			case "open-collapse":
				return {
					...state,
					collapse: true
				};

			case "close-collapse":
				return {
					...state,
					collapse: false
				};

			case "open-moreEdit":
				return {
					...state,
					moreEdit: true
				};

			case "close-moreEdit":
				return {
					...state,
					moreEdit: false
				};

			case "open-before-upload-img":
				return {
					...state,
					beforeUploadImg: true
				};

			case "close-before-upload-img":
				return {
					...state,
					beforeUploadImg: false
				};

			default:
				return state;
		}
	};

	const [control, dispatchControl] = useReducer(
		controlHandler,
		initialController
	);
	// for title and collapse controls end

	// getting & updating personal_details all values start
	const [inputValues, setInputValues] = useState(getResume.personal_details[0]);

	const [latestUpdate, setLatestUpdate] = useState({});

	useEffect(() => {
		if (Object.keys(latestUpdate)[0]) {
			const timeoutId = setTimeout(() => {
				updateResumeInfo({ ...latestUpdate, _id });
			}, delay);

			return () => {
				clearTimeout(timeoutId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestUpdate]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		if (name === "phone") {
			setInputValues((prevValues) => ({
				...prevValues,
				[name]: value.replace(/[^0-9+]/g, "")
			}));

			setLatestUpdate({
				[name]: value.replace(/[^0-9+]/g, "") || null
			});
		} else {
			setInputValues((prevValues) => ({
				...prevValues,
				[name]: value
			}));

			setLatestUpdate({
				[name]: value || null
			});
		}
	};
	// getting & updating personal_details all values end

	// for handle img change and restrictions start
	const [isImgValid, setIsImgValid] = useState({});

	// generateAlertMessage
	const generateAlertMsg = ({ dimension, size }) => {
		if (dimension && size) {
			return "Maximum dimension of photo should be 800px X 800px and size not more than 3 MB.";
		} else if (dimension) {
			return "Maximum dimension of photo should be 800px X 800px.";
		} else if (size) {
			return "File size not more than 3 MB.";
		}
	};

	// for reset onChange
	const fileInputRef = useRef(null);

	// for image onChange handler
	const handleImageChange = (event) => {
		const imageFile = event.target.files[0];

		if (imageFile) {
			setIsImgValid({});

			const objectUrl = URL.createObjectURL(imageFile);

			setImage(objectUrl);

			const image = new Image();
			image.src = objectUrl;
			image.onload = () => {
				// Check image dimensions
				if (image.width > 800 || image.height > 800) {
					setIsImgValid((prev) => ({
						...prev,
						dimension: "Image dimensions must not exceed 800x800 pixels."
					}));
				}

				// Check image size
				const maxSizeInBytes = 3 * 1024 * 1024; // 3 MB
				if (imageFile.size > maxSizeInBytes) {
					setIsImgValid((prev) => ({
						...prev,
						size: "Image size must not exceed 3 MB."
					}));
				}
			};

			// for reset onChange
			fileInputRef.current.value = "";
		}
	};
	// for handle img change and restrictions end

	//for cropping image start
	const cropperRef = useRef();

	const [zoom, setZoom] = useState(1);
	const [image, setImage] = useState(null);

	// for data-url-to-blob start
	const convertBlobUrlToBlob = async (blobUrl) => {
		const response = await fetch(blobUrl);
		const blob = await response.blob();
		return blob;
	};
	// for data-url-to-blob end

	// for define cropped img dimension start
	const [dimension, setDimension] = useState({});

	useEffect(() => {
		if (template) {
			if (template === "Account Manager" || template === "Simple General") {
				setDimension({ width: 172, height: 180 });
			} else if (template === "Financial Analyst") {
				setDimension({ width: 70, height: 70 });
			} else if (template === "Art Director") {
				setDimension({ width: 130, height: 130 });
			} else {
				setDimension({ width: 172, height: 180 });
			}
		}
	}, [template]);
	// for define cropped img dimension end

	// for close beforeImgUpload & image-cropper start
	const myRef = useRef();

	const handleClickOutside = (e) => {
		if (!myRef.current?.contains(e.target) && image) {
			setImage(null);
			setIsFlipped(false);
			setIsImgValid({});
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [image]);

	const beforeImgUploadRef = useRef();

	const handleClickOutsideBeforeImgUpload = (e) => {
		if (
			!beforeImgUploadRef.current?.contains(e.target) &&
			control.beforeUploadImg
		) {
			dispatchControl({ type: "close-before-upload-img" });
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideBeforeImgUpload);
		return () =>
			document.removeEventListener(
				"mousedown",
				handleClickOutsideBeforeImgUpload
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [control.beforeUploadImg]);
	// for close beforeImgUpload & image-cropper end
	//for cropping image end

	// for uploadingCroppedImage start

	// for flipping img
	const [isFlipped, setIsFlipped] = useState(false);

	const generateFlipping = (canvasScaled) => {
		// Flip the canvas horizontally
		const canvasFlipped = document.createElement("canvas");
		canvasFlipped.width = canvasScaled.width;
		canvasFlipped.height = canvasScaled.height;
		const ctxFlipped = canvasFlipped.getContext("2d");
		ctxFlipped.translate(canvasScaled.width, 0);
		ctxFlipped.scale(-1, 1);
		ctxFlipped.drawImage(canvasScaled, 0, 0);

		return canvasFlipped.toDataURL();
	};

	const uploadingCroppedImage = async () => {
		try {
			const formData = new FormData();

			// for getting croppedImg
			const canvasScaled = cropperRef.current.getImage();
			const finalCroppedImg = isFlipped
				? generateFlipping(canvasScaled)
				: canvasScaled.toDataURL();

			// url to convert blob
			const originalBlob = await convertBlobUrlToBlob(image);
			const croppedBlob = await convertBlobUrlToBlob(finalCroppedImg);

			// get time
			const time = Date.now();

			formData.append(
				"files",
				originalBlob,
				`${userId}-${counter}-${time}.png`
			);
			formData.append(
				"files",
				croppedBlob,
				`${userId}-${counter}-${time}-cropped.png`
			);

			const response = await fetch(
				`/resume/upload-profile-img?user=${userId}&counter=${counter}&_id=${_id}`,
				{
					method: "POST",
					body: formData
				}
			);

			const result = await response.json();

			if (response.status === 200) {
				setImage(null);
				setIsFlipped(false);
				setIsImgValid({});
				setInputValues((prevValues) => ({
					...prevValues,
					original_profile_img: `${userId}-${counter}-${time}.png`,
					cropped_profile_img: `${userId}-${counter}-${time}-cropped.png`
				}));

				setResume((prev) => ({
					...prev,
					personal_details: [
						{
							...prev.personal_details[0],
							original_profile_img: `${userId}-${counter}-${time}.png`,
							cropped_profile_img: `${userId}-${counter}-${time}-cropped.png`
						},
						...prev.personal_details.slice(1)
					]
				}));
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};
	// for uploadingCroppedImage end

	// for removing profile-img for cv start
	const removeProfileImg = async () => {
		try {
			const response = await fetch(
				`/resume/remove-profile-img?user=${userId}&counter=${counter}&_id=${_id}`
			);

			const result = await response.json();

			if (response.status === 200) {
				setInputValues((prevValues) => ({
					...prevValues,
					original_profile_img: "",
					cropped_profile_img: ""
				}));

				setResume((prev) => ({
					...prev,
					personal_details: [
						{
							...prev.personal_details[0],
							original_profile_img: "",
							cropped_profile_img: ""
						},
						...prev.personal_details.slice(1)
					]
				}));

				setDeleteImg("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};

	// delete item handler
	useEffect(() => {
		if (deleteImg?.conform) {
			removeProfileImg();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteImg?.conform]);
	// for removing profile-img for cv end

	// for tooltip states controls start
	const initialTooltip = {
		job_title_tooltip: false,
		nationality_tooltip: false,
		driving_tooltip: false,
		dob_tooltip: false
	};

	const reducer = (state, action) => {
		switch (action.type) {
			case "OPEN_TOOLTIP":
				return {
					...state,
					[action.tooltipId]: true
				};
			case "CLOSE_TOOLTIP":
				return {
					...state,
					[action.tooltipId]: false
				};
			default:
				return state;
		}
	};

	const [tooltipStates, dispatchToolTip] = useReducer(reducer, initialTooltip);
	// for tooltip states controls end

	return (
		<>
			<div className="section-container" id="personal-details">
				{/* label start  */}
				<div className="label" id={control.collapse ? "active" : ""}>
					<SectionTitle
						title="Personal Details"
						name="personal_details_title"
						control={control}
						dispatchControl={dispatchControl}
						_id={_id}
						value={inputValues.personal_details_title}
						setInputValues={setInputValues}
						updateResumeInfo={updateResumeInfo}
						rename={rename}
						revert={revert}
					/>

					<ControlCollapse
						collapse={control.collapse}
						dispatchControl={dispatchControl}
					/>
				</div>
				{/* label end  */}

				{/* content start  */}
				<div
					className={
						control.collapse && control.moreEdit
							? "content-container active personal-details"
							: "content-container personal-details"
					}
					id={control.collapse ? "active" : ""}
				>
					{/* description  */}
					<div style={{ marginBottom: "10px" }}>
						<Description content={personal_details_subtitle} />
					</div>

					{/* job title & profile-img start  */}
					<div className="row-container">
						<div className="first-col">
							<div className="sub-label">
								<label htmlFor="job-title">
									{job_title} <span id="important">*</span>
								</label>

								<div className="edit-icon">
									<ToolTips
										title={job_title_tooltip}
										name={"job_title_tooltip"}
										condition={tooltipStates.job_title_tooltip}
										dispatch={dispatchToolTip}
									/>
								</div>
							</div>

							<div className="input-field">
								<input
									id="job-title"
									placeholder="e.g. Teacher"
									name="job_title"
									value={inputValues.job_title}
									onChange={handleInputChange}
									autoFocus={inputValues.job_title ? false : true}
								/>
							</div>
						</div>

						<div className="take-profile-img">
							<div className="sub-label">
								<label
									onClick={() =>
										dispatchControl({ type: "open-before-upload-img" })
									}
								>
									<div className="img-container">
										{inputValues.cropped_profile_img ? (
											<img
												src={`/assets/profile-img/${inputValues.cropped_profile_img}`}
												alt="preview-profile-img"
												className="img-fluid"
											/>
										) : (
											<i className="fa-solid fa-user"></i>
										)}
									</div>
								</label>

								{inputValues.original_profile_img ? (
									<div className="profile-modify">
										<span
											onClick={() =>
												setImage(
													`/assets/profile-img/${inputValues.original_profile_img}`
												)
											}
											id="edit-photo"
										>
											<i className="fa-solid fa-pencil"></i> {edit_photo}
										</span>

										<span
											onClick={() =>
												getResume.do_not_ask_again
													? removeProfileImg()
													: setDeleteImg({ _id })
											}
											id="delete-photo"
										>
											<i className="fa-solid fa-trash-can"></i> {delete_photo}
										</span>
									</div>
								) : (
									<label
										onClick={() =>
											dispatchControl({ type: "open-before-upload-img" })
										}
										id="upload-photo"
									>
										<span>{upload_photo}</span>
									</label>
								)}

								<input
									style={{ display: "none" }}
									ref={fileInputRef}
									type="file"
									id="resume-img"
									accept="image/png, image/jpg, image/jpeg"
									onChange={handleImageChange}
								/>
							</div>

							{/* before-img-upload dialog start  */}
							{control.beforeUploadImg && (
								<div className="before-upload-img-container cropping-profile-container">
									<div
										className="before-upload-img-wrapper cropping-profile-wrapper"
										ref={beforeImgUploadRef}
									>
										<label
											className="primary-btn-styles"
											htmlFor="resume-img"
											style={{ cursor: "pointer" }}
										>
											Choose Image
										</label>

										<div id="info" style={{ width: "fit-content" }}>
											Maximum dimension of photo should be 800px X 800px <br />
											and size not more than 3 MB.
										</div>

										{/* close  */}
										<div
											className="cross-icon"
											onClick={() =>
												dispatchControl({ type: "close-before-upload-img" })
											}
										>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												version="1.1"
												xmlns="http://www.w3.org/2000/svg"
												className="sc-cbZa-DL enTcjE"
											>
												<path d="M10.5857864,12 L3.79289322,5.20710678 L5.20710678,3.79289322 L12,10.5857864 L18.7928932,3.79289322 L20.2071068,5.20710678 L13.4142136,12 L20.2071068,18.7928932 L18.7928932,20.2071068 L12,13.4142136 L5.20710678,20.2071068 L3.79289322,18.7928932 L10.5857864,12 Z"></path>
											</svg>
										</div>
									</div>
								</div>
							)}
							{/* before-img-upload dialog end  */}

							{image && (
								<div className="cropping-profile-container">
									<div className="cropping-profile-wrapper" ref={myRef}>
										{/* cropper-wrapper start  */}
										<div className="cropper-wrapper">
											<div id="top-layer"></div>
											<div id="bottom-layer"></div>
											<div id="left-layer"></div>
											<div id="right-layer"></div>
											<AvatarEditor
												ref={cropperRef}
												image={image}
												scale={zoom}
												width={dimension?.width ? dimension.width : 240}
												height={dimension?.height ? dimension.height : 240}
												borderRadius={3}
												color={[14, 19, 30, 0.7]}
												border={200}
												style={{ transform: isFlipped ? "scaleX(-1)" : "none" }}
											/>
										</div>
										{/* cropper-wrapper end  */}

										{/* zoom-img-wrapper start  */}
										<div className="zoom-img-wrapper">
											<div id="zoom-text">Zoom</div>
											<div className="slider-container">
												<div id="slide-range">2x</div>
												<Slider
													sx={{
														'& input[type="range"]': {
															WebkitAppearance: "slider-vertical"
														}
													}}
													orientation="vertical"
													value={zoom}
													min={1}
													max={2}
													step={0.01}
													aria-labelledby="Zoom"
													onChange={(e, zoom) => setZoom(zoom)}
												/>
												<div id="slide-range">1x</div>
											</div>

											{/* for flip img   */}

											<Tooltip title="Flip" placement="right">
												<IconButton
													className="flip-button"
													onClick={() => setIsFlipped(!isFlipped)}
												>
													<FlipIcon />
												</IconButton>
											</Tooltip>
										</div>
										{/* zoom-img-wrapper end */}

										{/* crop-btn-container start  */}
										<div className="crop-btn-container">
											<div id="upload-new">
												<img
													src="/assets/icons/upload-icon.svg"
													alt="upload-icon"
												/>

												<span>
													<label htmlFor="resume-img">Upload&nbsp;New</label>
												</span>
											</div>

											{/* alert message start  */}
											{Object.keys(isImgValid).length !== 0 && (
												<div className="alert-msg">
													<i className="fa-solid fa-triangle-exclamation"></i>
													{generateAlertMsg(isImgValid)}
												</div>
											)}
											{/* alert message end */}

											<button
												onClick={uploadingCroppedImage}
												className="primary-btn-styles"
												disabled={
													Object.keys(isImgValid).length === 0 ? false : true
												}
												id={
													Object.keys(isImgValid).length === 0
														? ""
														: "disable-btn"
												}
											>
												Save&nbsp;Changes
											</button>
										</div>
										{/* crop-btn-container end  */}

										{/* cross-icon start  */}
										<div
											className="cross-icon"
											onClick={() => {
												setImage(null);
												setIsFlipped(false);
												setIsImgValid({});
											}}
										>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												version="1.1"
												xmlns="http://www.w3.org/2000/svg"
												className="sc-cbZa-DL enTcjE"
											>
												<path d="M10.5857864,12 L3.79289322,5.20710678 L5.20710678,3.79289322 L12,10.5857864 L18.7928932,3.79289322 L20.2071068,5.20710678 L13.4142136,12 L20.2071068,18.7928932 L18.7928932,20.2071068 L12,13.4142136 L5.20710678,20.2071068 L3.79289322,18.7928932 L10.5857864,12 Z"></path>
											</svg>
										</div>
										{/* cross-icon end  */}
									</div>
								</div>
							)}
						</div>
					</div>
					{/* job title & profile-img end  */}

					{/* first-name & last-name start  */}
					<div className="row-container">
						<FirstCol
							content={first_name}
							name={"first_name"}
							haveIm={true}
							value={inputValues.first_name}
							onChange={handleInputChange}
						/>

						<SecondCol
							content={last_name}
							name={"last_name"}
							haveIm={true}
							value={inputValues.last_name}
							onChange={handleInputChange}
						/>
					</div>
					{/* first-name & last-name end  */}

					{/* email & phone start  */}
					<div className="row-container">
						<FirstCol
							content={email}
							name={"email"}
							haveIm={true}
							value={inputValues.email}
							onChange={handleInputChange}
						/>

						<SecondCol
							content={phone}
							name={"phone"}
							haveIm={true}
							value={inputValues.phone}
							onChange={handleInputChange}
						/>
					</div>
					{/* email & phone end  */}

					{/* country & nationality start  */}
					<div className="row-container">
						<FirstCol
							content={country}
							name={"country"}
							value={inputValues.country}
							onChange={handleInputChange}
						/>

						<div className="second-col">
							<div className="sub-label">
								<label htmlFor="nationality">{nationality}</label>

								<div className="edit-icon">
									<ToolTips
										title={nid_tooltip}
										name={"nationality_tooltip"}
										condition={tooltipStates.nationality_tooltip}
										dispatch={dispatchToolTip}
									/>
								</div>
							</div>

							<div className="input-field">
								<input
									id="nationality"
									name="nationality"
									value={inputValues.nationality}
									onChange={handleInputChange}
								/>
							</div>
						</div>
					</div>
					{/* country & nationality end  */}

					{/* present-address & city-division start  */}
					<div className="row-container">
						<FirstCol
							content={present_address}
							name={"present_address"}
							value={inputValues.present_address}
							onChange={handleInputChange}
						/>

						<SecondCol
							content={personal_city}
							name={"present_city"}
							value={inputValues.present_city}
							onChange={handleInputChange}
						/>
					</div>
					{/* present-address & city-division end  */}

					{/* state/area & zip-code start  */}
					<div className="row-container">
						<FirstCol
							content={personal_area}
							name={"present_area"}
							value={inputValues.present_area}
							onChange={handleInputChange}
						/>

						<SecondCol
							content={personal_zip}
							name={"present_zip"}
							value={inputValues.present_zip}
							onChange={handleInputChange}
						/>
					</div>
					{/* state/area & zip-code end  */}

					{/* permanent-address start  */}
					<div
						className="row-container-permanent"
						id={!inputValues.same_address ? "active" : ""}
					>
						{/* permanent-address & permanent-city-division start  */}
						<div className="row-container">
							<div className="first-col">
								<div
									className="sub-label"
									style={{ justifyContent: "flex-start", opacity: "1" }}
								>
									<label htmlFor="permanent-address">
										{permanent_address} &nbsp; &nbsp;
									</label>
									<div
										id="hide-refer-toggle"
										style={{
											padding: 0,
											margin: 0
										}}
									>
										<input
											type="checkbox"
											checked={inputValues.same_address ? true : false}
											onClick={() => {
												updateResumeInfo({
													same_address: !inputValues.same_address,
													_id
												});

												setInputValues((prevValues) => ({
													...prevValues,
													same_address: !inputValues.same_address
												}));
											}}
											readOnly
										/>

										<span style={{ fontSize: "12.5px" }}>{same_address}</span>
									</div>
								</div>

								<div className="input-field">
									<input
										id="permanent-address"
										name="permanent_address"
										value={inputValues.permanent_address}
										onChange={handleInputChange}
									/>
								</div>
							</div>

							<div className="second-col">
								<div
									className="sub-label"
									style={{
										visibility: inputValues.same_address ? "hidden" : "visible"
									}}
								>
									<label htmlFor="permanent-city"> {personal_city}</label>
								</div>

								<div className="input-field">
									<input
										id="permanent-city"
										name="permanent_city"
										value={inputValues.permanent_city}
										onChange={handleInputChange}
									/>
								</div>
							</div>
						</div>
						{/* permanent-address & permanent-city-division end  */}

						{/* permanent-state/area & permanent-zip-code start  */}
						<div className="row-container">
							<FirstCol
								content={personal_area}
								name={"permanent_area"}
								value={inputValues.permanent_area}
								onChange={handleInputChange}
							/>

							<SecondCol
								content={personal_zip}
								name={"permanent_zip"}
								value={inputValues.permanent_zip}
								onChange={handleInputChange}
							/>
						</div>
						{/* permanent-state/area & permanent-zip-code end  */}
					</div>
					{/* permanent-address end  */}

					{/* Driving License & nid/passport start  */}
					<div className="row-container">
						<div className="first-col">
							<div className="sub-label">
								<label htmlFor="driving-license">{driving_license}</label>

								<div className="edit-icon">
									<ToolTips
										title={driving_license_tooltip}
										name={"driving_tooltip"}
										condition={tooltipStates.driving_tooltip}
										dispatch={dispatchToolTip}
									/>
								</div>
							</div>

							<div className="input-field">
								<input
									id="driving-license"
									name="driving_license"
									value={inputValues.driving_license}
									onChange={handleInputChange}
								/>
							</div>
						</div>

						<SecondCol
							content={nid}
							name={"nid"}
							value={inputValues.nid}
							onChange={handleInputChange}
						/>
					</div>
					{/* Driving License & nid/passport end  */}

					{/* place of dob & dob start  */}
					<div className="row-container">
						<FirstCol
							content={place_of_birth}
							name={"place_of_birth"}
							value={inputValues.place_of_birth}
							onChange={handleInputChange}
						/>

						<div className="second-col">
							<div className="sub-label">
								<label htmlFor="dob">{date_of_birth}</label>

								<div className="edit-icon">
									<ToolTips
										title={date_of_birth_tooltip}
										name={"dob_tooltip"}
										condition={tooltipStates.dob_tooltip}
										dispatch={dispatchToolTip}
									/>
								</div>
							</div>

							<div className="input-field">
								<input
									id="dob"
									name="date_of_birth"
									value={inputValues.date_of_birth}
									onChange={handleInputChange}
								/>
							</div>
						</div>
					</div>
					{/* place of dob & dob end */}
				</div>
				{/* content end */}

				{/* edit-hide toggle start  */}
				{control.collapse && (
					<div className="edit-hide-wrapper">
						{!control.moreEdit ? (
							<span
								id="edit-hide-t"
								onClick={() => dispatchControl({ type: "open-moreEdit" })}
							>
								{edit_additional_details}{" "}
								<i className="fa-solid fa-chevron-down"></i>
							</span>
						) : (
							<span
								id="edit-hide-t"
								onClick={() => dispatchControl({ type: "close-moreEdit" })}
							>
								{hidden_additional_details}{" "}
								<i className="fa-solid fa-chevron-up" id="active"></i>
							</span>
						)}
					</div>
				)}
				{/* edit-hide toggle end  */}

				{/* delete modal  */}
				{deleteImg && (
					<DeleteDialog
						contents={contents}
						deleteItem={deleteImg}
						setDeleteItem={setDeleteImg}
					/>
				)}
			</div>
		</>
	);
};

export default memo(PersonalDetails);
