/* eslint-disable import/no-webpack-loader-syntax */
// internal components
import GraphicWebDesignerStyle from "!!raw-loader!./GraphicWebDesigner.css";
import { RichTextParser, SectionTitle, SkillsLevel, Time } from "../Components";
import {
	checkVariables,
	divideAndAddBrTag,
	fetchingResumeData,
	generateIconWhite,
	isArrayEmpty,
	objHasAnyValue,
	removeEmptyObjects
} from "../Components/Utils";

const GraphicWebDesigner = ({ getResume }) => {
	// fetching resume's all data
	const {
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		professional_summary_title,
		rich_text_content,
		employment_history_title,
		employment_history_items,
		education_title,
		education_items,
		project_title,
		project_items,
		web_link_items,
		skills_title,
		skills_items,
		displaying_level,
		languages_title,
		languages_items,
		hobbies_title,
		hobbies_description,
		activity_title,
		activity_items,
		internship_title,
		internship_items,
		courses_title,
		courses_items,
		references_title,
		references_hide,
		references_items,
		customArr,
		disclaimer_title,
		disclaimer_description
	} = fetchingResumeData(getResume);

	//  professional_summary
	const professional_summary = rich_text_content ? (
		<div className="section">
			<div className="section-title-wrapper">
				<SectionTitle
					className="title"
					sectionName={professional_summary_title}
					equalTo="Professional Summary"
					initialName="About"
				/>
				<div className="line"></div>
			</div>

			<RichTextParser className="item__first" rich_text={rich_text_content} />
		</div>
	) : (
		""
	);

	//  employment_history
	const employmentHistoryItemsArr = employment_history_items.filter((obj) =>
		objHasAnyValue(obj)
	);

	const employmentHistoryLastItem = employmentHistoryItemsArr.length - 1;

	const employment_history = !isArrayEmpty(employmentHistoryItemsArr) ? (
		<div className="section">
			<div className="section-title-wrapper">
				<SectionTitle
					className="title"
					sectionName={employment_history_title}
					equalTo="EmploymenT History"
					initialName="Work Experience"
				/>
				<div className="line"></div>
			</div>

			{employmentHistoryItemsArr.map((item, index) => {
				const checkTime = checkVariables(item.start_date, item.end_date);

				return (
					<div
						className={index === 0 ? "item item__first" : "item"}
						key={index}
					>
						<div className="item_left-section">
							{item.employment_city && (
								<div className="item__header">{item.employment_city}</div>
							)}

							{checkTime && (
								<div className="item__sub_header when-time">
									<Time start={item.start_date} end={item.end_date} />
								</div>
							)}
						</div>

						<div
							className="item-contents"
							id={index === employmentHistoryLastItem ? "last" : ""}
						>
							<div className="item-dot-inside"></div>

							<div className="item__header">{item.employment_job_title}</div>

							<div className="item__sub_header">{item.employer}</div>

							{item.employment_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.employment_rich_text_content}
								/>
							)}
						</div>
					</div>
				);
			})}
		</div>
	) : (
		""
	);

	// projects
	const projectsItemsArr = project_items.filter((obj) => objHasAnyValue(obj));

	const projectsLastItem = projectsItemsArr.length - 1;

	const projects = !isArrayEmpty(projectsItemsArr) ? (
		<div className="section">
			<div className="section-title-wrapper">
				<SectionTitle
					className="title"
					sectionName={project_title}
					equalTo="Projects/Portfolio"
					initialName="Projects"
				/>
				<div className="line"></div>
			</div>

			{projectsItemsArr.map((item, index) => {
				return (
					<div
						className={index === 0 ? "item item__first" : "item"}
						key={index}
					>
						<div className="item_left-section">
							{item.project_duration && (
								<div className="item__header">{item.project_duration}</div>
							)}

							{!isArrayEmpty(item.project_links) && (
								<div className="item__sub_header when-time when-project-links">
									{removeEmptyObjects(item.project_links, [
										"label",
										"link"
									]).map((label, index) => {
										return (
											<div key={index}>
												<a
													href={label.link}
													target="_blank"
													rel="noopener noreferrer"
												>
													{label.label}
												</a>
												{index !== item.project_links.length - 1 && " | "}
											</div>
										);
									})}
								</div>
							)}
						</div>

						<div
							className="item-contents"
							id={index === projectsLastItem ? "last" : ""}
						>
							<div className="item-dot-inside"></div>

							<div className="item__header">{item.project_name}</div>

							{checkVariables(item.technologies) && (
								<div className="item__sub_header when-technologies">
									<strong>Technologies:&nbsp;</strong>
									{item.technologies}
								</div>
							)}

							{item.pro_description && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.pro_description}
								/>
							)}
						</div>
					</div>
				);
			})}
		</div>
	) : (
		""
	);

	// education
	const education = !isArrayEmpty(education_items) ? (
		<div className="section">
			<div className="title">{education_title}</div>

			{education_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.edu_start_date,
						item.edu_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">{item.degree}</div>

							{item.school && (
								<div className="item__sub_header_italic">{item.school}</div>
							)}

							{checkVariables(item.edu_city, checkTime) && (
								<div className="item__sub_header">
									{item.edu_city}

									{item.edu_city && checkTime && " / "}

									{checkTime && (
										<Time
											start={item.edu_start_date}
											end={item.edu_end_date}
											fromWhere={"education"}
										/>
									)}
								</div>
							)}

							{item.edu_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.edu_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// internship
	const internship = !isArrayEmpty(internship_items) ? (
		<div className="section">
			<div className="title">{internship_title}</div>

			{internship_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.intern_start_date,
						item.intern_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">{item.intern_job}</div>

							{item.intern_employer && (
								<div className="item__sub_header_italic">
									{item.intern_employer}
								</div>
							)}

							{checkVariables(item.intern_city, checkTime) && (
								<div className="item__sub_header">
									{item.intern_city}

									{item.intern_city && checkTime && " / "}

									{checkTime && (
										<Time
											start={item.intern_start_date}
											end={item.intern_end_date}
										/>
									)}
								</div>
							)}

							{item.intern_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.intern_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// courses
	const courses = !isArrayEmpty(courses_items) ? (
		<div className="section">
			<div className="title">{courses_title}</div>

			{courses_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.course_start_date,
						item.course_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">{item.course_name}</div>

							{item.course_institution && (
								<div className="item__sub_header_italic">
									{item.course_institution}
								</div>
							)}

							{checkTime && (
								<div className="item__sub_header">
									{checkTime && (
										<Time
											start={item.course_start_date}
											end={item.course_end_date}
										/>
									)}
								</div>
							)}

							{item.course_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.course_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// extra curricular activities
	const activitiesArr = activity_items.filter((obj) => objHasAnyValue(obj));

	const activitiesLastItem = activitiesArr.length - 1;

	const activities = !isArrayEmpty(activitiesArr) ? (
		<div className="section">
			<div className="section-title-wrapper">
				<div className="title">{activity_title}</div>
				<div className="line"></div>
			</div>

			{activitiesArr.map((item, index) => {
				const checkTime = checkVariables(
					item.activity_start_date,
					item.activity_end_date
				);

				return (
					<div
						className={index === 0 ? "item item__first" : "item"}
						key={index}
					>
						<div className="item_left-section">
							{item.activity_city && (
								<div className="item__header">{item.activity_city}</div>
							)}

							{checkTime && (
								<div className="item__sub_header when-time">
									<Time
										start={item.activity_start_date}
										end={item.activity_end_date}
									/>
								</div>
							)}
						</div>

						<div
							className="item-contents"
							id={index === activitiesLastItem ? "last" : ""}
						>
							<div className="item-dot-inside"></div>

							<div className="item__header">{item.activity_function}</div>

							<div className="item__sub_header">{item.activity_employer}</div>

							{item.activity_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.activity_rich_text_content}
								/>
							)}
						</div>
					</div>
				);
			})}
		</div>
	) : (
		""
	);

	// references
	const references = !isArrayEmpty(references_items) ? (
		<div className="section">
			<div className="title">{references_title}</div>

			{references_hide && (
				<div className="item__first">
					<div className="item__sub_header when-disclaimer">
						Reference available upon request
					</div>
				</div>
			)}

			{!references_hide &&
				references_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item__header">{item.references_name}</div>

								{item.references_company && (
									<div className="item__sub_header_italic">
										{item.references_company}
									</div>
								)}

								{item.references_phone && (
									<div className="item__sub_header">
										{item.references_phone}
									</div>
								)}

								{item.references_email && (
									<div className="item__sub_header">
										{item.references_email}
									</div>
								)}
							</div>
						);
					})}
		</div>
	) : (
		""
	);

	// custom
	const custom = (title, itemsArr, lastIndex) =>
		!isArrayEmpty(itemsArr) ? (
			<div className="section">
				<div className="section-title-wrapper">
					<div className="title">{title}</div>
					<div className="line"></div>
				</div>

				{itemsArr.map((item, index) => {
					const checkTime = checkVariables(
						item.custom_start_date,
						item.custom_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item_left-section">
								{item.custom_city && (
									<div className="item__header">{item.custom_city}</div>
								)}

								{checkTime && (
									<div className="item__sub_header when-time">
										<Time
											start={item.custom_start_date}
											end={item.custom_end_date}
										/>
									</div>
								)}
							</div>

							<div
								className="item-contents"
								id={index === lastIndex ? "last" : ""}
							>
								<div className="item-dot-inside"></div>

								<div className="item__header">{item.custom_activity}</div>

								{item.custom_rich_text_content && (
									<RichTextParser
										className={"rich_editor__container"}
										rich_text={item.custom_rich_text_content}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		) : (
			""
		);

	//  personal_details
	const personal_details = (
		<div className="personal-details-wrapper">
			<div className="personal-details-section">
				{/* present_address start */}
				{checkVariables(
					present_address,
					present_city,
					present_area,
					present_zip,
					permanent_address,
					permanent_city,
					permanent_area,
					permanent_zip,
					same_address
				) && (
					<div className="personal__sub-header">
						<div className="content">
							{/* present address  */}
							{checkVariables(
								present_address,
								present_city,
								present_area,
								present_zip
							) && (
								<>
									<b>Present Address:</b> &nbsp;
									{present_address}
									{present_address && present_city && ", "}
									{present_city}
									{present_area && `, ${present_area}`}
									{present_zip && `, ${present_zip}`}
									{!checkVariables(
										permanent_address,
										permanent_city,
										permanent_area,
										permanent_zip
									) &&
										country &&
										`, ${country}`}
									&nbsp;&nbsp;
								</>
							)}

							{/* permanent_address */}
							{checkVariables(
								permanent_address,
								permanent_city,
								permanent_area,
								permanent_zip,
								same_address
							) && (
								<>
									{same_address ? (
										<>
											<b>Permanent Address:</b> &nbsp; Same as above
										</>
									) : (
										<>
											<b>Permanent Address:</b> &nbsp;
											{permanent_address}
											{permanent_address && permanent_city && ", "}
											{permanent_city}
											{permanent_area && `, ${permanent_area}`}
											{permanent_zip && `, ${permanent_zip}`}
											{country && `, ${country}`}
										</>
									)}
								</>
							)}
						</div>
					</div>
				)}
				{/* present_address end */}
			</div>

			<div className="personal-details-section">
				{nationality && (
					<div className="personal__sub-header">
						<div className="content">
							<b>Nationality:</b> &nbsp; {nationality}
							{checkVariables(
								nid,
								driving_license,
								date_of_birth,
								place_of_birth
							) && ", "}
						</div>
					</div>
				)}

				{nid && (
					<div className="personal__sub-header">
						<div className="content">
							<b>{isNaN(nid) ? "Passport" : "NID"}:</b> &nbsp; {nid}
							{checkVariables(driving_license, date_of_birth, place_of_birth) &&
								", "}
						</div>
					</div>
				)}

				{driving_license && (
					<div className="personal__sub-header">
						<div className="content">
							<b>Driving License:</b> &nbsp; {driving_license}
							{checkVariables(date_of_birth, place_of_birth) && ", "}
						</div>
					</div>
				)}

				{place_of_birth && (
					<div className="personal__sub-header">
						<div className="content">
							<b>Place Of Birth:</b> &nbsp; {place_of_birth}
							{checkVariables(place_of_birth) && ", "}
						</div>
					</div>
				)}

				{date_of_birth && (
					<div className="personal__sub-header">
						<div className="content">
							<b>Date Of Birth:</b> &nbsp; {date_of_birth}
						</div>
					</div>
				)}
			</div>
		</div>
	);

	// skills
	const skills = !isArrayEmpty(skills_items) ? (
		<div className="section">
			<div className="section-title-wrapper">
				<div className="title">{skills_title}</div>
				<div className="line"></div>
			</div>

			<div className="skill-container">
				{skills_items.map((value, index) => {
					return (
						<div className="skill-wrapper" key={index}>
							<SkillsLevel
								title_class="skill_title"
								level_class="skill_level"
								bg_color="#58595B"
								skill={value.skill}
								skill_level={value.skill_level}
								displaying_level={displaying_level}
							/>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// languages
	const languages = !isArrayEmpty(languages_items) ? (
		<div className="section">
			<div className="section-title-wrapper">
				<div className="title">{languages_title}</div>
				<div className="line"></div>
			</div>

			<div className="skill-container">
				{languages_items.map((value, index) => {
					return (
						<div className="skill-wrapper" key={index}>
							<SkillsLevel
								title_class="skill_title"
								level_class="skill_level"
								bg_color="#58595B"
								skill={value.languages_name}
								skill_level={value.languages_level}
							/>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	//  hobbies
	const hobbies = hobbies_description ? (
		<div className="section">
			<div className="title">{hobbies_title}</div>

			<div className="hobbies-description">
				{hobbies_description.split(",").map((value, index) => {
					return (
						<div
							key={index}
							className="item__sub_header refer-item-mt when-hobbies"
						>
							<div className="bullet"></div>

							{value}
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// web_links
	const web_links = !isArrayEmpty(web_link_items) ? (
		<div className="section">
			<div className="title">Follow Me</div>

			<div className="web__links_wrapper">
				{!isArrayEmpty(web_link_items) &&
					removeEmptyObjects(web_link_items, ["link_label", "link_url"]).map(
						(label, index) => {
							return (
								<div key={index} className="link__wrapper">
									<img
										src={`/assets/icons/${generateIconWhite(label.link_url)}`}
										alt="icon"
										className="social-icon"
									/>

									<a
										href={label.link_url}
										target="_blank"
										rel="noopener noreferrer"
										className="link__url"
									>
										{label.link_label}
									</a>
								</div>
							);
						}
					)}
			</div>
		</div>
	) : (
		""
	);

	//  disclaimer
	const disclaimer = disclaimer_description ? (
		<div className="section">
			<div className="title">{disclaimer_title}</div>

			<div className="item__first">
				<div className="item__sub_header when-disclaimer">
					{disclaimer_description}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// for displaying additional left section start
	const additionalSectionLeft = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Courses") {
			storeAdditionalSection.push(courses);
		} else if (value.key === "Internship") {
			storeAdditionalSection.push(internship);
		}

		return storeAdditionalSection;
	});
	// for displaying additional left section end

	// for displaying additional right-section start
	const additionalSectionRight = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Extra-curricular Activities") {
			storeAdditionalSection.push(activities);
		} else if (value.key === "Custom") {
			customArr.map((item) => {
				if (item.cus_section_id === value._id) {
					const filteredItems = item.custom_items.filter((obj) =>
						objHasAnyValue(obj)
					);
					if (filteredItems.length > 0) {
						storeAdditionalSection.push(
							custom(
								item.custom_title,
								filteredItems,
								filteredItems[filteredItems.length - 1]
							)
						);
					}
				}

				return storeAdditionalSection;
			});
		}

		return storeAdditionalSection;
	});
	// for displaying additional right-section end

	// left-section array
	const leftSection = [
		education,
		...additionalSectionLeft,
		hobbies,
		web_links,
		references,
		disclaimer
	];

	// right-section array
	const rightSection = [
		professional_summary,
		employment_history,
		projects,
		skills,
		languages,
		...additionalSectionRight
	];

	return (
		<>
			<style>{GraphicWebDesignerStyle}</style>

			<div className="container">
				<div className="body">
					{/* body__left start  */}
					<div className="body__left">
						<div className="body__left_bg"></div>

						{/* name, job-title  start */}
						<div className="identity">
							<div className="name-job-title-wrapper">
								{checkVariables(first_name, last_name) && (
									<div className="name">
										{first_name} &nbsp;
										{last_name}
									</div>
								)}

								{job_title && <div className="job-title">{job_title}</div>}
							</div>

							<div className="phone-email-wrapper">
								{/* phone start  */}
								{phone && (
									<div className="personal__sub-header">
										<img
											src="/assets/icons/phone-black.png"
											alt="icon"
											className="icon"
										/>

										<div className="content">
											<a
												href={`tel:${phone}`}
												target="_blank"
												rel="noopener noreferrer"
												className="anchor"
											>
												{phone}
											</a>
										</div>
									</div>
								)}
								{/* phone end  */}

								{/* email start  */}
								{email && (
									<div className="personal__sub-header">
										<img
											src="/assets/icons/email-fill-black.png"
											alt="icon"
											className="icon"
										/>

										<div className="content">
											<a
												href={`mailto:${email}`}
												target="_blank"
												rel="noopener noreferrer"
												dangerouslySetInnerHTML={{
													__html: divideAndAddBrTag(email, 100)
												}}
												className="anchor"
											></a>
										</div>
									</div>
								)}
								{/* email end  */}
							</div>
						</div>
						{/* name, job-title  end */}

						<div className="img-container">
							<img
								src={`/assets/profile-img/${
									cropped_profile_img
										? cropped_profile_img
										: "default-profile.png"
								}`}
								alt="profile-img"
								className="profile-img"
							/>
						</div>

						{leftSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div key={index} id={index === 0 ? "left-first-section" : ""}>
									{value}
								</div>
							))}
					</div>
					{/* body__left end  */}

					{/* body__right start  */}
					<div className="body__right">
						{personal_details}

						{rightSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div key={index}>{value}</div>
							))}
					</div>
					{/* body__right end  */}
				</div>
			</div>
		</>
	);
};

export default GraphicWebDesigner;
