// external components
import { useEffect, useRef, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

// internal components
import Footer from "./common/Footer/Footer";
import Navbar from "./common/Navbar/Navbar";
import ShareSocial from "./common/ShareSocial/ShareSocial";
import { getCurrentUser } from "./common/function";
import Logout from "./components/Logout";
import AboutUs from "./pages/AboutUs/AboutUs";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AdminLogin from "./pages/AdminLogin/AdminLogin";
import ChooseTemplate from "./pages/ChooseTemplate/ChooseTemplate";
import ContactUs from "./pages/ContactUs/ContactUs";
import CoverEditor from "./pages/CoverEditor/CoverEditor";
import FAQ from "./pages/FAQ/FAQ";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import Payment from "./pages/Payment/Payment";
import PaymentFailed from "./pages/PaymentFailed";
import PaymentSuccess from "./pages/PaymentSuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy/RefundPolicy";
import ResumeEditor from "./pages/ResumeEditor/ResumeEditor";
import ResumeTemplates from "./pages/ResumeTemplates/ResumeTemplates";
import Share from "./pages/Share/Share";
import SignUp from "./pages/SignUp/SignUp";
import Subscription from "./pages/Subscription/Subscription";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import UserProfile from "./pages/UserProfile/UserProfile";
import { GetContextApi } from "./storage/ContextApi";
import "./styles/_index.scss";

const App = () => {
	// initialization aos
	useEffect(() => {
		AOS.init();
	}, []);

	// for setting current-user & contents
	const { currentUser, setCurrentUser, contents, freeTemplateName } =
		GetContextApi();

	// for loading until fetching isn't complete
	const [isLoading, setIsLoading] = useState(true);

	// for fetching current-user from server start

	useEffect(() => {
		if (!currentUser) {
			(async () => {
				const result = await getCurrentUser();

				setCurrentUser(result);
				setIsLoading(false);
			})();
		} else {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for fetching current-user from server end

	// define viewport for mobile device start
	let vh = window.innerHeight * 0.01;

	document.documentElement.style.setProperty("--vh", `${vh}px`);

	window.addEventListener("DOMContentLoaded", () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	});
	// define viewport for mobile device end

	// for getting current-router pathname
	const routePath = useLocation().pathname.substring(1) || "home";

	// for getting route-query
	const searchParams = new URLSearchParams(useLocation().search);
	const getQuery = searchParams.get("selectedTab");

	// Scroll to top if path changes
	useEffect(() => {
		scroll.scrollToTop({
			duration: 0,
			smooth: true
		});
	}, [routePath, getQuery]);

	// for getting all subscription packages start
	const [allPackages, setAllPackages] = useState([]);

	const getAllPackage = async () => {
		try {
			const response = await fetch(`/package/all-packages`);

			const result = await response.json();

			if (response.status === 200) {
				setAllPackages(result.filter((value) => value.State === "Publish"));
			} else if (result.error) {
				return null;
			}
		} catch (error) {
			return null;
		}
	};

	useEffect(() => {
		const delay = routePath === "subscription" ? 0 : 1000;

		setTimeout(() => {
			getAllPackage();
		}, delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for getting all subscription packages end

	// for getting all templates start
	const [allTemplates, setAllTemplates] = useState([]);
	const freeResumeTemplateId = useRef();

	const getAllTemplates = async () => {
		try {
			const response = await fetch(`/template/all-templates`);

			const result = await response.json();

			if (response.status === 200) {
				setAllTemplates(result);

				freeResumeTemplateId.current = result.filter(
					(template) => template.name === freeTemplateName
				)[0]._id;
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2000,
					theme: "colored"
				});
			}
		} catch (error) {
			return null;
		}
	};

	useEffect(() => {
		const delay = routePath === "resume-templates" ? 0 : 1000;

		setTimeout(() => {
			getAllTemplates();
		}, delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for getting all templates end

	return (
		<div
			style={{
				fontFamily:
					contents.title === "bangla"
						? "solaimanLipi, sans-serif"
						: "Roboto, sans-serif"
			}}
		>
			{/* for users start  */}
			{!isLoading && !routePath.includes("admin") && (
				<>
					{/* navbar  */}
					{![
						`resume/${routePath.split("/")[1]}/create-resume`,
						`resume/${routePath.split("/")[1]}/edit`,
						"log-out",
						"subscription",
						"subscription/payment",
						`resume/share/${routePath.split("/")[2]}`
					].includes(routePath) && <Navbar fromWhere={routePath} />}

					{/* social-share  */}
					{![
						`resume/${routePath.split("/")[1]}/create-resume`,
						`resume/${routePath.split("/")[1]}/edit`,
						`resume/share/${routePath.split("/")[2]}`,
						"log-in",
						"sign-up"
					].includes(routePath) && <ShareSocial />}

					<Routes>
						{/* home-page  */}
						<Route path="/" element={<Home allTemplates={allTemplates} />} />

						{/* login router start  */}
						<Route path="log-in" element={<Login />} />
						<Route path="login" element={<Navigate to="/log-in" />} />
						{/* login router end  */}

						{/* sign-up router start  */}
						<Route path="sign-up" element={<SignUp />} />
						<Route path="signup" element={<Navigate to="/sign-up" />} />
						<Route path="register" element={<Navigate to="/sign-up" />} />
						<Route path="registration" element={<Navigate to="/sign-up" />} />
						{/* sign-up router end  */}

						{/* contact-us page  */}
						<Route
							path="contact-us"
							element={<ContactUs getQuery={getQuery} />}
						/>

						{/* about-us page  */}
						<Route path="about-us" element={<AboutUs />} />

						{/* privacy policy page  */}
						<Route path="privacy-policy" element={<PrivacyPolicy />} />

						{/* refund policy page  */}
						<Route path="refund-policy" element={<RefundPolicy />} />

						{/* faq page  */}
						<Route path="faq" element={<FAQ />} />

						{/* user-profile page  */}
						<Route path="user-profile" element={<UserProfile />} />

						{/* terms and conditions page  */}
						<Route path="terms-and-conditions" element={<TermsConditions />} />

						{/* user-dashboard page  */}
						<Route
							path="user-dashboard"
							element={
								<UserDashboard
									freeResumeTemplateId={freeResumeTemplateId.current}
								/>
							}
						/>

						{/* for account sittings */}
						<Route path="account-settings" element={<AccountSettings />} />

						{/* for resume editor page  */}
						<Route
							path="resume/:counter/create-resume"
							element={<ResumeEditor />}
						/>

						{/* for resume edit page  */}
						<Route
							path="resume/:counter/edit"
							element={<ChooseTemplate allTemplates={allTemplates} />}
						/>

						{/* for cover-letter editor page  */}
						<Route
							path="cover-letter/:counter/editor"
							element={<CoverEditor />}
						/>

						{/* for share-resume  */}
						<Route path="resume/share/:_id" element={<Share />} />

						{/* for subscription page  */}
						<Route
							path="subscription"
							element={<Subscription allPackages={allPackages} />}
						/>

						{/* for subscription's payment page  */}
						<Route
							path="subscription/payment"
							element={<Payment allPackages={allPackages} />}
						/>

						{/* for successfully payment */}
						<Route path="thank-you" element={<PaymentSuccess />} />

						{/* for successfully payment */}
						<Route path="payment-failed" element={<PaymentFailed />} />

						{/* for resume-templates page */}
						<Route
							path="resume-templates"
							element={<ResumeTemplates allTemplates={allTemplates} />}
						/>

						{/* for log-out  */}
						<Route path="/log-out" element={<Logout />} />

						{/* for 404 page  */}
						<Route path="*" element={<NotFoundPage fromWhere={routePath} />} />
					</Routes>

					{/* footer  */}
					{![
						"log-in",
						"sign-up",
						"log-out",
						"thank-you",
						`resume/${routePath.split("/")[1]}/create-resume`,
						`resume/${routePath.split("/")[1]}/edit`,
						`resume/share/${routePath.split("/")[2]}`
					].includes(routePath) && <Footer getQuery={getQuery} />}
				</>
			)}
			{/* for users end  */}
			{/* for admin start */}
			{routePath.includes("admin") && (
				<Routes>
					{/* admin log-in page  */}
					<Route path="admin/log-in" element={<AdminLogin />} />
					<Route path="admin" element={<Navigate to="/admin/log-in" />} />
					<Route path="admin/login" element={<Navigate to="/admin/log-in" />} />

					{/* admin dashboard page  */}
					<Route path="admin/dashboard" element={<AdminDashboard />} />

					{/* for 404 page  */}
					<Route path="*" element={<NotFoundPage fromWhere={routePath} />} />
				</Routes>
			)}
			{/* for admin end */}

			<Toaster
				toastOptions={{
					style: {
						color: "white",
						borderRadius: "48px",
						paddingLeft: "18px"
					},
					success: {
						style: {
							background: "green"
						}
					},
					error: {
						style: {
							background: "#f82049"
						}
					}
				}}
				containerStyle={{
					top: 10
				}}
			/>
		</div>
	);
};

export default App;
