// external components

// internal components
import AdminLoginFields from "../../components/AdminLoginFields/AdminLoginFields";

const AdminLogin = () => {
	return (
		<>
			<div className="row m-0 login-container">
				<div className="col p-0 login-wrapper"></div>

				<AdminLoginFields />
			</div>
		</>
	);
};

export default AdminLogin;
