// external components
import { useEffect, useRef, useState } from "react";

// internal components
import "./TabsDropdown.css";

const TabsDropdown = ({ selectedTab, setSelectedTab, contents }) => {
	const [tabsDrop, setTabsDrop] = useState("");

	// for border animation
	const [isActiveInput, setIsActiveInput] = useState(false);

	// for close dropdown when outside clicked start
	const myRef = useRef();

	const handleClickOutside = (e) => {
		if (!myRef.current?.contains(e.target) && tabsDrop) {
			setTabsDrop(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabsDrop]);
	// for close dropdown when outside clicked end

	// contactUs contents
	const {
		feedback,
		billing,
		pricing,
		page_errors,
		cancel_subscription,
		other
	} = contents.contact_us;

	return (
		<>
			<div
				className={
					tabsDrop
						? "tabs-dropdown-container active"
						: "tabs-dropdown-container"
				}
				onClick={() => setTabsDrop(!tabsDrop)}
				ref={myRef}
			>
				<input
					type="text"
					id="Level"
					placeholder="Select method"
					value={selectedTab}
					className={isActiveInput ? "focused" : ""}
					onFocus={() => setIsActiveInput(true)}
					onBlur={() => {
						setIsActiveInput(false);
					}}
					readOnly
				/>

				<div className="option" ref={myRef}>
					<div onClick={() => setSelectedTab(feedback)}>{feedback}</div>

					<div onClick={() => setSelectedTab(billing)}>{billing}</div>

					<div onClick={() => setSelectedTab(pricing)}>{pricing}</div>

					<div onClick={() => setSelectedTab(page_errors)}>{page_errors}</div>

					<div onClick={() => setSelectedTab(cancel_subscription)}>
						{cancel_subscription}
					</div>

					<div onClick={() => setSelectedTab(other)}>{other}</div>
				</div>
			</div>
		</>
	);
};

export default TabsDropdown;
