// external components
import { useEffect, useState } from "react";

// internal components
import { DeleteDialog } from "../../common";
import {
	Courses,
	CustomSection,
	Disclaimer,
	ExtraActivities,
	Internship,
	Languages,
	References
} from "../index";
import "./AddSection.css";

const AddSection = ({
	addSecArr,
	setAddSecArr,
	contents,
	allPhrasesRef,
	updateResumeInfo,
	setResume,
	getResume
}) => {
	// initial assign
	useEffect(() => {
		if (addSecArr.length > 0) {
			const selectComponent = (key, _id) => {
				if (key === "Courses") {
					return (
						<Courses
							contents={contents}
							sectionId={_id}
							setRemoveSection={setRemoveSection}
						/>
					);
				} else if (key === "Extra-curricular Activities") {
					return (
						<ExtraActivities
							contents={contents}
							sectionId={_id}
							setRemoveSection={setRemoveSection}
						/>
					);
				} else if (key === "Internship") {
					return (
						<Internship
							contents={contents}
							sectionId={_id}
							setRemoveSection={setRemoveSection}
							allPhrasesRef={allPhrasesRef}
						/>
					);
				} else if (key === "Languages") {
					return (
						<Languages
							contents={contents}
							sectionId={_id}
							setRemoveSection={setRemoveSection}
						/>
					);
				} else if (key === "References") {
					return (
						<References
							contents={contents}
							sectionId={_id}
							setRemoveSection={setRemoveSection}
						/>
					);
				} else if (key === "Custom") {
					return (
						<CustomSection
							contents={contents}
							sectionId={_id}
							setRemoveSection={setRemoveSection}
						/>
					);
				} else if (key === "Disclaimer") {
					return (
						<Disclaimer
							contents={contents}
							sectionId={_id}
							setRemoveSection={setRemoveSection}
						/>
					);
				}
			};

			let additionalSectionArr = [];

			addSecArr.map((value) =>
				additionalSectionArr.push({
					key: value.key,
					component: selectComponent(value.key, value._id)
				})
			);

			setAddSecArr(additionalSectionArr);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// adding additional section handler
	const addHandler = (key) => {
		(async () => {
			const getAdditionalSection = await updateResumeInfo({
				add_additional_section: key
			});

			if (getAdditionalSection) {
				// assign
				const addSection = getAdditionalSection.section;
				const specificItemArr = getAdditionalSection.itemArr;

				if (key === "Courses") {
					setResume((prev) => ({
						...prev,
						additional_section: [...prev.additional_section, addSection],
						courses: {
							...prev.courses,
							courses_items: specificItemArr
						}
					}));

					setAddSecArr((prev) => [
						...prev,
						{
							key,
							component: (
								<Courses
									contents={contents}
									sectionId={addSection._id}
									setRemoveSection={setRemoveSection}
								/>
							)
						}
					]);
				} else if (key === "Extra-curricular Activities") {
					setResume((prev) => ({
						...prev,
						additional_section: [...prev.additional_section, addSection],
						activity: {
							...prev.activity,
							activity_items: specificItemArr
						}
					}));

					setAddSecArr((prev) => [
						...prev,
						{
							key,
							component: (
								<ExtraActivities
									contents={contents}
									sectionId={addSection._id}
									setRemoveSection={setRemoveSection}
								/>
							)
						}
					]);
				} else if (key === "Internship") {
					setResume((prev) => ({
						...prev,
						additional_section: [...prev.additional_section, addSection],
						internship: {
							...prev.internship,
							internship_items: specificItemArr
						}
					}));

					setAddSecArr((prev) => [
						...prev,
						{
							key,
							component: (
								<Internship
									contents={contents}
									sectionId={addSection._id}
									setRemoveSection={setRemoveSection}
									allPhrasesRef={allPhrasesRef}
								/>
							)
						}
					]);
				} else if (key === "Languages") {
					setResume((prev) => ({
						...prev,
						additional_section: [...prev.additional_section, addSection],
						languages: {
							...prev.languages,
							languages_items: specificItemArr
						}
					}));

					setAddSecArr((prev) => [
						...prev,
						{
							key,
							component: (
								<Languages
									contents={contents}
									sectionId={addSection._id}
									setRemoveSection={setRemoveSection}
								/>
							)
						}
					]);
				} else if (key === "References") {
					setResume((prev) => ({
						...prev,
						additional_section: [...prev.additional_section, addSection],
						references: {
							...prev.references,
							references_items: specificItemArr
						}
					}));

					setAddSecArr((prev) => [
						...prev,
						{
							key,
							component: (
								<References
									contents={contents}
									sectionId={addSection._id}
									setRemoveSection={setRemoveSection}
								/>
							)
						}
					]);
				} else if (key === "Custom") {
					setResume((prev) => ({
						...prev,
						additional_section: [...prev.additional_section, addSection],
						custom: [...prev.custom, specificItemArr]
					}));

					setAddSecArr((prev) => [
						...prev,
						{
							key,
							component: (
								<CustomSection
									contents={contents}
									sectionId={addSection._id}
									setRemoveSection={setRemoveSection}
								/>
							)
						}
					]);
				} else if (key === "Disclaimer") {
					setResume((prev) => ({
						...prev,
						additional_section: [...prev.additional_section, addSection],
						disclaimer: specificItemArr
					}));

					setAddSecArr((prev) => [
						...prev,
						{
							key,
							component: (
								<Disclaimer
									contents={contents}
									sectionId={addSection._id}
									setRemoveSection={setRemoveSection}
								/>
							)
						}
					]);
				}
			}
		})();
	};

	// removing additional section handler
	const [removeSection, setRemoveSection] = useState("");

	useEffect(() => {
		if (
			removeSection.sectionId &&
			(removeSection?.conform || getResume.do_not_ask_again)
		) {
			updateResumeInfo({
				remove_additional_section: {
					key: removeSection.key,
					sectionId: removeSection.sectionId
				}
			});

			setAddSecArr((prev) =>
				prev.filter(
					(section) =>
						section.component.props.sectionId !== removeSection.sectionId
				)
			);

			if (removeSection.key === "Courses") {
				setResume((prev) => {
					const updatedAdditionalSection = prev.additional_section.filter(
						(section) => section._id !== removeSection.sectionId
					);

					return {
						...prev,
						additional_section: updatedAdditionalSection,
						courses: {
							...prev.courses,
							courses_items: []
						}
					};
				});
			} else if (removeSection.key === "Extra-curricular Activities") {
				setResume((prev) => {
					const updatedAdditionalSection = prev.additional_section.filter(
						(section) => section._id !== removeSection.sectionId
					);

					return {
						...prev,
						additional_section: updatedAdditionalSection,
						activity: {
							...prev.activity,
							activity_items: []
						}
					};
				});
			} else if (removeSection.key === "Internship") {
				setResume((prev) => {
					const updatedAdditionalSection = prev.additional_section.filter(
						(section) => section._id !== removeSection.sectionId
					);

					return {
						...prev,
						additional_section: updatedAdditionalSection,
						internship: {
							...prev.internship,
							internship_items: []
						}
					};
				});
			} else if (removeSection.key === "Languages") {
				setResume((prev) => {
					const updatedAdditionalSection = prev.additional_section.filter(
						(section) => section._id !== removeSection.sectionId
					);

					return {
						...prev,
						additional_section: updatedAdditionalSection,
						languages: {
							...prev.languages,
							languages_items: []
						}
					};
				});
			} else if (removeSection.key === "References") {
				setResume((prev) => {
					const updatedAdditionalSection = prev.additional_section.filter(
						(section) => section._id !== removeSection.sectionId
					);

					return {
						...prev,
						additional_section: updatedAdditionalSection,
						references: {
							...prev.references,
							references_items: []
						}
					};
				});
			} else if (removeSection.key === "Custom") {
				setResume((prev) => {
					const updatedAdditionalSection = prev.additional_section.filter(
						(section) => section._id !== removeSection.sectionId
					);

					const updatedCustom = prev.custom.filter(
						(customItem) =>
							customItem.cus_section_id !== removeSection.sectionId
					);

					return {
						...prev,
						additional_section: updatedAdditionalSection,
						custom: updatedCustom
					};
				});
			} else if (removeSection.key === "Disclaimer") {
				setResume((prev) => {
					const updatedAdditionalSection = prev.additional_section.filter(
						(section) => section._id !== removeSection.sectionId
					);

					return {
						...prev,
						additional_section: updatedAdditionalSection,
						disclaimer: [{ ...prev, disclaimer_description: "" }]
					};
				});
			}
			setRemoveSection("");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removeSection?.sectionId, removeSection?.conform]);

	return (
		<>
			<div className="add-section-container">
				<h2>Add Section</h2>

				<div className="add-section-wrapper">
					<div className="item-wrapper" onClick={() => addHandler("Custom")}>
						<img src="/assets/icons/custom.svg" alt="icon" />
						<label htmlFor="custom-section">Custom Section</label>
					</div>

					<div
						className="item-wrapper"
						onClick={() =>
							!addSecArr.some((obj) => obj.key === "Courses") &&
							addHandler("Courses")
						}
						id={
							addSecArr.some((obj) => obj.key === "Courses") ? "selected" : ""
						}
					>
						<img src="/assets/icons/courses.svg" alt="icon" />
						<label htmlFor="Courses">Courses</label>
					</div>

					<div
						className="item-wrapper"
						onClick={() =>
							!addSecArr.some(
								(obj) => obj.key === "Extra-curricular Activities"
							) && addHandler("Extra-curricular Activities")
						}
						id={
							addSecArr.some((obj) => obj.key === "Extra-curricular Activities")
								? "selected"
								: ""
						}
					>
						<img src="/assets/icons/extra.svg" alt="icon" />
						<label htmlFor="extra">Extra-curricular Activities</label>
					</div>

					<div
						className="item-wrapper"
						onClick={() =>
							!addSecArr.some((obj) => obj.key === "Internship") &&
							addHandler("Internship")
						}
						id={
							addSecArr.some((obj) => obj.key === "Internship")
								? "selected"
								: ""
						}
					>
						<img src="/assets/icons/internship.svg" alt="icon" />
						<label htmlFor="Internship">Internship</label>
					</div>

					<div className="item-wrapper" id="selected">
						<img src="/assets/icons/hobbies.svg" alt="icon" />
						<label htmlFor="hobbies">Hobbies</label>
					</div>

					<div
						className="item-wrapper"
						onClick={() =>
							!addSecArr.some((obj) => obj.key === "Languages") &&
							addHandler("Languages")
						}
						id={
							addSecArr.some((obj) => obj.key === "Languages") ? "selected" : ""
						}
					>
						<img src="/assets/icons/languages.svg" alt="icon" />
						<label htmlFor="Languages">Languages</label>
					</div>

					<div
						className="item-wrapper"
						onClick={() =>
							!addSecArr.some((obj) => obj.key === "References") &&
							addHandler("References")
						}
						id={
							addSecArr.some((obj) => obj.key === "References")
								? "selected"
								: ""
						}
					>
						<img src="/assets/icons/references.svg" alt="icon" />
						<label htmlFor="References">References</label>
					</div>

					<div
						className="item-wrapper"
						onClick={() =>
							!addSecArr.some((obj) => obj.key === "Disclaimer") &&
							addHandler("Disclaimer")
						}
						id={
							addSecArr.some((obj) => obj.key === "Disclaimer")
								? "selected"
								: ""
						}
					>
						<img src="/assets/icons/disclaimer.png" alt="icon" />
						<label htmlFor="Disclaimer">Disclaimer</label>
					</div>
				</div>

				{/* delete modal  */}
				{!getResume.do_not_ask_again && removeSection && (
					<DeleteDialog
						contents={contents}
						deleteItem={removeSection}
						setDeleteItem={setRemoveSection}
					/>
				)}
			</div>
		</>
	);
};

export default AddSection;
