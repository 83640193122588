// external components
import { arrayMoveImmutable } from "array-move";
import { memo, useEffect, useReducer, useState } from "react";
import { AnimatedList } from "react-animated-list";

// internal components
import { EditorContextApi } from "../../../../../pages/ResumeEditor/EditorContextApi";
import {
	AddMore,
	ControlCollapse,
	DeleteDialog,
	Description,
	SectionTitle
} from "../common";
import {
	SortableContainer,
	SortableItem,
	updateBeforeSortStart
} from "../common/drag&drop";
import { addOneMoreItem, updateItems } from "../common/function";
import { SkillItem } from "../SectionItems";

const Skills = ({ contents }) => {
	// skills contents
	const {
		skills_subtitle,
		is_show_skill_level,
		add_skill,
		add_more_skill,
		rename,
		revert
	} = contents.editor;

	// editor contextApi
	const { updateResumeInfo, getResume, setResume, delay, userId } =
		EditorContextApi();

	// assign
	const _id = getResume.skills._id;
	const counter = getResume.counter;

	// for delete specific items
	const [deleteItem, setDeleteItem] = useState("");

	// for title and collapse controls start
	const initialController = {
		border: false,
		editTitle: false,
		collapse: true
	};

	const controlHandler = (state, action) => {
		switch (action.type) {
			case "open-border":
				return {
					...state,
					border: true
				};

			case "open-editor":
				return {
					...state,
					editTitle: true
				};

			case "close-border-editor":
				return {
					...state,
					border: false,
					editTitle: false
				};

			case "open-collapse":
				return {
					...state,
					collapse: true
				};

			case "close-collapse":
				return {
					...state,
					collapse: false
				};

			default:
				return state;
		}
	};

	const [control, dispatchControl] = useReducer(
		controlHandler,
		initialController
	);
	// for title and collapse controls end

	// assign skills's all values start
	const [inputValues, setInputValues] = useState({
		skills_title: getResume.skills.skills_title,
		displaying_level: getResume.skills.displaying_level
	});

	const [skillItems, setSkillItems] = useState([]);
	useEffect(() => {
		if (getResume?.skills?.skills_items.length > 0) {
			let getSkillItemArray = [];

			getResume?.skills?.skills_items.map((value) =>
				getSkillItemArray.push(
					<SkillItem
						itemValues={value}
						handleSetActive={handleSetActive}
						contents={contents}
						setDeleteItem={setDeleteItem}
						updateResumeInfo={updateResumeInfo}
						delay={delay}
					/>
				)
			);

			setSkillItems(getSkillItemArray);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// assign skills's all values end

	// handle activate id start
	const [activeId, setActiveId] = useState(-1);

	const handleSetActive = (id) => {
		setActiveId((prevActiveId) => (prevActiveId === id ? -1 : id));
	};
	// handle activate id end

	// handle when dragEnd
	const onSortEnd = async ({ oldIndex, newIndex }) => {
		setSkillItems(arrayMoveImmutable(skillItems, oldIndex, newIndex));

		const arr = arrayMoveImmutable(
			getResume?.skills?.skills_items,
			oldIndex,
			newIndex
		);

		setResume((prev) => ({
			...prev,
			skills: {
				...prev.skills,
				skills_items: arr
			}
		}));

		updateItems(arr, userId, counter, "skills", "skills_items");
	};

	// delete item handler
	useEffect(() => {
		if (deleteItem._id && (deleteItem?.conform || getResume.do_not_ask_again)) {
			setSkillItems((prev) =>
				prev.filter((item) => item.props.itemValues._id !== deleteItem._id)
			);

			const arr = getResume.skills.skills_items.filter(
				(item) => item._id !== deleteItem._id
			);

			setResume((prev) => ({
				...prev,
				skills: {
					...prev.skills,
					skills_items: arr
				}
			}));

			updateItems(arr, userId, counter, "skills", "skills_items");

			setDeleteItem("");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteItem?._id, deleteItem?.conform]);

	// define some predefine skills and randomly sort them start
	const predefineSkills = [
		"Ability to work well under pressure",
		"Adaptability",
		"Attention to detail",
		"Creativity",
		"Decision making",
		"Strong work ethic",
		"Time management",

		"Ability to speak another language",
		"Copywriting",
		"Data analysis",
		"Event planning",
		"Food preparation",
		"Graphic design",
		"Search engine optimization (SEO)",
		"Typing speed",

		"Editing",
		"Interpersonal skills",
		"Negotiation",
		"Public speaking",
		"Teamwork",
		"Technical writing",
		"Training",
		"Verbal communication",
		"Written communication",

		"E-mail management",
		"Microsoft Office",
		"Research",
		"Social media",
		"Systems administration",
		"Web or graphic design",
		"Windows and Mac OS",

		"Attentive listening",
		"Diplomacy",
		"Marketing",
		"Negotiation",
		"Patience",
		"Sales",
		"Troubleshooting",

		"Conflict resolution",
		"Delegation",
		"Problem-solving",
		"Mentorship",
		"Motivation",
		"Team building",
		"Team management",

		"Active listening",
		"Creativity",
		"Decision making",
		"Research",
		"Team building"
	];

	const [predefineSkillsArr, setPredefineSkillsArr] = useState(predefineSkills);

	// for getting selected pre-define skill
	const [selectedSkill, setSelectedSkill] = useState("");

	const skillsSortRandomly = (a, b) => {
		return 0.5 - Math.random();
	};

	useEffect(() => {
		if (selectedSkill) {
			setTimeout(() => {
				setPredefineSkillsArr(
					predefineSkillsArr
						.filter((value) => value !== selectedSkill)
						.sort(skillsSortRandomly)
				);
			}, 2000);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSkill]);
	// define some predefine skills and randomly sort them end

	return (
		<div className="section-container" id="skills">
			{/* label start  */}
			<div className="label" id={control.collapse ? "active" : ""}>
				<SectionTitle
					title="Skills"
					name="skills_title"
					control={control}
					dispatchControl={dispatchControl}
					_id={_id}
					value={inputValues.skills_title}
					setInputValues={setInputValues}
					updateResumeInfo={updateResumeInfo}
					rename={rename}
					revert={revert}
					drag={true}
				/>

				<ControlCollapse
					collapse={control.collapse}
					dispatchControl={dispatchControl}
				/>
			</div>
			{/* label end  */}

			{/* content start  */}
			<div
				className="content-container remove-margin"
				id={control.collapse ? "active" : ""}
			>
				{/* description  */}
				<Description content={skills_subtitle} />

				{/* for-hide reference toggle start  */}
				<div id="hide-refer-toggle" className="add-margin">
					<input
						type="checkbox"
						checked={inputValues.displaying_level ? true : false}
						onClick={() => {
							updateResumeInfo({
								displaying_level: !inputValues.displaying_level,
								_id
							});

							setInputValues((prev) => ({
								...prev,
								displaying_level: !prev.displaying_level
							}));
						}}
						readOnly
					/>
					<span>{is_show_skill_level}</span>
				</div>
				{/* for-hide reference toggle end */}

				{/* for displaying predefine arr start  */}
				<div className="predefine-skills">
					<AnimatedList animation={"fade"}>
						{predefineSkillsArr.map((value, index) => {
							return (
								<span
									key={index}
									onClick={() => {
										setSelectedSkill(value);
										addOneMoreItem(
											userId,
											counter,
											"skills",
											"skills_items",
											{
												skill: value,
												skill_level: "Expert"
											},
											{ activeId, setActiveId, handleSetActive },
											setSkillItems,
											{
												itemComponent: SkillItem,
												contents,
												updateResumeInfo,
												setDeleteItem,
												delay
											},
											setResume
										);
									}}
									id={value === selectedSkill ? "active" : ""}
								>
									{value}&nbsp;{" "}
									{value === selectedSkill ? (
										<i className="fa-solid fa-check"></i>
									) : (
										<i className="fa-solid fa-plus"></i>
									)}
								</span>
							);
						})}
					</AnimatedList>
				</div>
				{/* for displaying predefine arr end */}

				{/* for displaying skillItems's array start  */}
				{skillItems.length > 0 && (
					<SortableContainer
						onSortEnd={onSortEnd}
						useDragHandle
						lockAxis="y"
						lockToContainerEdges={true}
						updateBeforeSortStart={() => updateBeforeSortStart(setActiveId)}
					>
						{skillItems.map((item, index) => (
							<SortableItem
								key={item.props.itemValues._id}
								index={index}
								value={item}
								className="display-section-item"
								id={activeId === item.props.itemValues._id ? "active" : ""}
							/>
						))}
					</SortableContainer>
				)}
				{/* for displaying skillItems's array end */}

				{/* add-more  */}
				<AddMore
					userId={userId}
					counter={counter}
					section={"skills"}
					item={"skills_items"}
					newArr={{
						skill: "",
						skill_level: "Expert"
					}}
					arrCom={skillItems}
					setArrCom={setSkillItems}
					itemCom={{
						itemComponent: SkillItem,
						contents,
						updateResumeInfo,
						setDeleteItem,
						delay
					}}
					setResume={setResume}
					controlActiveId={{ activeId, setActiveId, handleSetActive }}
					initialContent={add_skill}
					moreContent={add_more_skill}
				/>
			</div>
			{/* content end */}

			{/* delete modal  */}
			{!getResume.do_not_ask_again && deleteItem && (
				<DeleteDialog
					contents={contents}
					deleteItem={deleteItem}
					setDeleteItem={setDeleteItem}
				/>
			)}
		</div>
	);
};

export default memo(Skills);
