// external components
import { Alert, AlertTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

// internal components
import "./AdminPhrasesContents.css";
import Category from "./Category/Category";
import Phrases from "./Phrases/Phrases";

const AdminPhrasesContents = () => {
	// for getting selected tab
	const [selectedTab, setSelectedTab] = useState("Category");

	// loading until fetching is not complete
	const [isLoading, setIsLoading] = useState(false);

	// for getting all categories start
	const [categories, setCategories] = useState([]);

	// for updating categories list
	const [isUpdateCategory, setIsUpdateCategory] = useState("");

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const response = await fetch(`/category`);

				const result = await response.json();

				if (response.status === 200) {
					setCategories(result);

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		})();
	}, [isUpdateCategory]);
	// for getting all categories end

	// for getting all phrases start
	const [phrases, setPhrases] = useState([]);

	// for updating phrases list
	const [isUpdatePhrases, setIsUpdatePhrases] = useState("");

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const response = await fetch(`/phrases/all-phrases`);

				const result = await response.json();

				if (response.status === 200) {
					setPhrases(result);

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		})();
	}, [isUpdatePhrases]);
	// for getting all phrases end

	return (
		<>
			<div className="admin-phrases-contents">
				<div className="header-section">
					{/* header-section_left start  */}
					<div className="header-section_left">
						<div className="title">Category&nbsp;&&nbsp;Phrases</div>

						<div className="tab-container">
							<button
								id={selectedTab === "Category" ? "active" : ""}
								onClick={() => setSelectedTab("Category")}
							>
								Category
							</button>
							<button
								id={selectedTab === "Phrases" ? "active" : ""}
								onClick={() => setSelectedTab("Phrases")}
							>
								Phrases
							</button>

							<div
								className="for-border"
								id={selectedTab === "Category" ? "for-category" : "for-phrases"}
							></div>
						</div>
					</div>
					{/* header-section_left end  */}

					{/* header-section_right start  */}
					<Alert severity="warning">
						<AlertTitle>Example</AlertTitle>
						Category :&nbsp;&nbsp;Web Designer; Web Developer; Android; <br />
						Phrase :&nbsp;&nbsp;sentence. next sentence. next sentence.
					</Alert>
					{/* header-section_right end */}
				</div>

				{/* content section start  */}
				{selectedTab === "Category" && (
					<Category
						categories={categories}
						isLoading={isLoading}
						setIsUpdateCategory={setIsUpdateCategory}
					/>
				)}
				{selectedTab === "Phrases" && (
					<Phrases
						isLoading={isLoading}
						phrases={phrases}
						setIsUpdatePhrases={setIsUpdatePhrases}
						categories={categories}
					/>
				)}
				{/* content section end */}
			</div>
		</>
	);
};

export default AdminPhrasesContents;
