// external components
import { memo, useEffect, useReducer, useState } from "react";

// internal components
import { EditorContextApi } from "../../../../../pages/ResumeEditor/EditorContextApi";
import { ControlCollapse, SectionTitle } from "../common";

const Disclaimer = ({ contents, sectionId, setRemoveSection }) => {
	// disclaimer content
	const { rename, revert, deleteText } = contents.editor;

	// editor contextApi
	const { updateResumeInfo, getResume, delay } = EditorContextApi();

	// assign
	const _id = getResume.disclaimer[0]._id;

	// for title and collapse controls start
	const initialController = {
		border: false,
		editTitle: false,
		collapse: true
	};

	const controlHandler = (state, action) => {
		switch (action.type) {
			case "open-border":
				return {
					...state,
					border: true
				};

			case "open-editor":
				return {
					...state,
					editTitle: true
				};

			case "close-border-editor":
				return {
					...state,
					border: false,
					editTitle: false
				};

			case "open-collapse":
				return {
					...state,
					collapse: true
				};

			case "close-collapse":
				return {
					...state,
					collapse: false
				};

			default:
				return state;
		}
	};

	const [control, dispatchControl] = useReducer(
		controlHandler,
		initialController
	);
	// for title and collapse controls end

	// assign & updating disclaimer's all values start
	const [inputValues, setInputValues] = useState(getResume.disclaimer[0]);

	const [latestUpdate, setLatestUpdate] = useState({});

	useEffect(() => {
		if (Object.keys(latestUpdate)[0]) {
			const timeoutId = setTimeout(() => {
				updateResumeInfo({ ...latestUpdate, _id });
			}, delay);

			return () => {
				clearTimeout(timeoutId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestUpdate]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setInputValues((prevValues) => ({
			...prevValues,
			[name]: value
		}));

		setLatestUpdate({
			[name]: value || null
		});
	};
	// assign & updating  disclaimer's all values end

	return (
		<div className="section-container">
			{/* label start  */}
			<div className="label" id={control.collapse ? "active" : ""}>
				<SectionTitle
					title="Disclaimer"
					name="disclaimer_title"
					control={control}
					dispatchControl={dispatchControl}
					_id={_id}
					value={inputValues.disclaimer_title}
					setInputValues={setInputValues}
					updateResumeInfo={updateResumeInfo}
					rename={rename}
					revert={revert}
					drag={true}
					whenAdditionalSection={{
						deleteText,
						sectionId,
						setRemoveSection,
						key: "Disclaimer"
					}}
				/>

				<ControlCollapse
					collapse={control.collapse}
					dispatchControl={dispatchControl}
				/>
			</div>
			{/* label end  */}

			{/* content start  */}
			<div
				className="content-container remove-margin hobbies"
				id={control.collapse ? "active" : ""}
			>
				{/* description  */}
				{/* <Description content={disclaimer_subtitle} /> */}

				{/* description-box start  */}
				<div className="input-field description-box">
					<textarea
						name="disclaimer_description"
						placeholder="e.g. Skiing, Skydiving, Painting, ..."
						rows="4"
						onChange={handleInputChange}
						value={inputValues.disclaimer_description}
					></textarea>
				</div>
				{/* description-box end */}
			</div>
			{/* content end */}
		</div>
	);
};

export default memo(Disclaimer);
