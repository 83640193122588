// external components

// internal components
import "./_AuthLeft.scss";

const AuthLeft = ({ contents }) => {
	// authLeft contents
	const { title, section_1, section_2, section_3 } = contents.auth_left;

	return (
		<>
			<div className="auth_left">
				<h4
					className="auth_left__title"
					dangerouslySetInnerHTML={{ __html: title }}
				></h4>

				{/* first-section  */}
				<div className="auth_left__section" id="first">
					<img
						src="/assets/icons/save-time.png"
						alt="icon"
						className="img-fluid auth_left__section_img"
					/>
					<span
						className="auth_left__section_des"
						dangerouslySetInnerHTML={{ __html: section_1 }}
					></span>
				</div>

				{/* second-section  */}
				<div className="auth_left__section" id="second">
					<img
						src="/assets/icons/achieve.png"
						alt="icon"
						className="img-fluid auth_left__section_img"
					/>
					<span
						className="auth_left__section_des"
						dangerouslySetInnerHTML={{ __html: section_2 }}
					></span>
				</div>

				{/* third-section  */}
				<div className="auth_left__section" id="third">
					<img
						src="/assets/icons/target.png"
						alt="icon"
						className="img-fluid auth_left__section_img"
					/>
					<span
						className="auth_left__section_des"
						dangerouslySetInnerHTML={{ __html: section_3 }}
					></span>
				</div>
			</div>
		</>
	);
};

export default AuthLeft;
