// external components
import { NavLink } from "react-router-dom";

// internal components
import "./_style.scss";

const PageHeader = ({ pageName, link, whichLanguage }) => {
	return (
		<>
			<div className="page-main-header-container">
				<div className="container">
					<div className="row page-header-container">
						<div className="col-11 col-sm-12 page-header-wrapper">
							<h2 className="header-name">{pageName}</h2>
							<p className="header-path">
								<NavLink to={"/"}>
									{whichLanguage === "bangla" ? "হোম" : "Home"}
								</NavLink>{" "}
								&nbsp;{" > "}&nbsp; {link}{" "}
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PageHeader;
