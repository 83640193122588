// external components

// internal components
import { PaymentMethods } from "../../common";
import ClientFeedback from "../../components/ClientFeedback/ClientFeedback";
import Features from "../../components/Features/Features";
import Landing from "../../components/Landing/Landing";
import Procedure from "../../components/Procedure/Procedure";
import SelectTemplate from "../../components/SelectTemplate/SelectTemplate";

const Home = ({ allTemplates }) => {
	return (
		<>
			<div className="home-container">
				<Landing />
				<SelectTemplate allTemplates={allTemplates} />
				<Features />
				<Procedure />
				<ClientFeedback />
				<PaymentMethods />
			</div>
		</>
	);
};

export default Home;
