// external components
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import {
	LoginSocialFacebook,
	LoginSocialGoogle,
	LoginSocialLinkedin
} from "reactjs-social-login";

// internal components
import {
	AgreeCheckbox,
	AuthInfoMsg,
	AuthLeft,
	PasswordRequired,
	ShapeAnimation,
	SubFooter
} from "../../common";
import { isValidateEmail } from "../../common/function";
import { GetContextApi } from "../../storage/ContextApi";
import "./Login.css";

const Login = () => {
	// for set currentUser and contents & userCountry
	const { currentUser, setCurrentUser, contents, userCountry } =
		GetContextApi();

	// login contents
	const {
		loginBox_title,

		recover_password,
		setup_account,
		setup_account_password,

		forgetPassDes,

		emailAddress,
		email_placeholder,
		email_error_msg,

		passwordTxt,

		new_pass_placeholder,
		enter_pass_placeholder,

		keepMeSign,
		forgetPass,

		loginBtn,
		loggingBtn,

		submit,
		submitting,

		loginSocialNet,

		dontHaveAct,
		dontHaveActLabel
	} = contents.log_in;

	// authInfoMsg contents
	const {
		heading_re_pass,
		description_re_pass,

		heading_unAuth_email,
		description_unAuth_email,

		or_unAuth_email,

		or,

		resend_btn,
		sending_btn
	} = contents.auth_info_msg;

	// for redirect sign-up page
	const Navigate = useNavigate();

	// if currentUser have then redirect to user-dashboard
	useEffect(() => {
		if (currentUser) {
			Navigate("/user-dashboard");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// for getting query from url
	const [searchParams] = useSearchParams();

	const [myParam, setMyParam] = useState(searchParams.get("key"));

	// for loading until didn't submit on server
	const [isLoading, setIsLoading] = useState(false);

	// for getting input-fields values
	const [getEmail, setEmail] = useState("");
	const [getPassword, setPassword] = useState("");
	const [getConformPassword, setConformPassword] = useState("");

	// for password eye toggle
	const [eyeTP, setEyeTP] = useState(false);
	const [eyeTCP, setEyeTCP] = useState(false);

	// for keep me loggedIn
	const [keepLogin, setKeepLogin] = useState(false);

	// for checking button clicked for submit or not
	const [btnClicked, setBtnClicked] = useState(false);

	// checkbox for term and policy
	const [isCheckTerm, setIsCheckTerm] = useState(false);
	const [isSendUpdate, setIsSendUpdate] = useState(false);

	// for displaying message
	const [isDisplayMass, setIsDisplayMass] = useState("");

	// login form submit on server start
	const submitHandler = async () => {
		if (getPassword && isValidateEmail(getEmail)) {
			setIsLoading(true);

			try {
				const userObj = {
					email: getEmail,
					password: getPassword,
					keepLogin
				};

				const response = await fetch("/user/log-in", {
					method: "POST",
					body: JSON.stringify(userObj),
					headers: {
						"Content-Type": "application/json"
					}
				});

				const result = await response.json();

				if (response.status === 200) {
					setCurrentUser(result.currentUser);
					Navigate("/user-dashboard");

					setIsLoading(false);
					setPassword("");
					setEyeTP(false);
					setBtnClicked(false);

					setEmail("");
				} else if (response.status === 203) {
					setIsDisplayMass("invalid-email");
					setIsLoading(false);
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						theme: "dark",
						autoClose: 2500
					});
					setIsLoading(false);
				} else {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 2500
					});
					setIsLoading(false);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
				setIsLoading(false);
			}
		}
	};
	// login form submit on server end

	// forget password start
	const [forgetPassT, setForgetPassT] = useState(false);

	const forgetPasswordHandler = async () => {
		if (isValidateEmail(getEmail)) {
			setIsLoading(true);

			try {
				const response = await fetch("/user/forget-password", {
					method: "POST",
					body: JSON.stringify({ email: getEmail }),
					headers: {
						"Content-Type": "application/json"
					}
				});

				const result = await response.json();

				if (response.status === 200) {
					toast.success(result.message, {
						position: "top-right",
						theme: "colored",
						autoClose: 2000
					});

					setIsDisplayMass("reset-password");
					setIsLoading(false);
					setBtnClicked(false);
				} else if (response.status === 400) {
					toast.error(result.error, {
						position: "top-right",
						theme: "dark",
						autoClose: 2500
					});
					setIsLoading(false);
				} else {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 2500
					});
					setIsLoading(false);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
				setIsLoading(false);
			}
		}
	};
	// for forget password end

	// for sending email again for forget password start
	const resendEmailHandler = async () => {
		setIsLoading(true);
		try {
			const response = await fetch("/user/forget-password/resend-email", {
				method: "POST",
				body: JSON.stringify({ email: getEmail }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});

				setIsLoading(false);
			} else if (response.status === 202) {
				toast.error(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 1500
				});

				setIsDisplayMass("");

				setIsLoading(false);
				setEmail("");
			} else if (response.status === 400) {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setIsLoading(false);
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setIsLoading(false);
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
			setIsLoading(false);
		}
	};
	// for sending email again for forget password end

	// for sending email again start
	const resendEmailHandlerForVerification = async () => {
		setIsLoading(true);
		try {
			const response = await fetch("/user/resend-email", {
				method: "POST",
				body: JSON.stringify({ email: getEmail }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});

				setIsLoading(false);
			} else if (response.status === 202) {
				toast.error(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setIsLoading(false);
				setEmail("");

				setIsDisplayMass("");
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setIsLoading(false);
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
			setIsLoading(false);
		}
	};
	// for sending email again end

	// for renew password start

	// for password's required fields checking start
	const [passwordRequired, setPasswordRequired] = useState(false);

	// renewPassword handler start
	const renewPassword = async () => {
		if (!(getPassword?.length >= 8 && passwordRequired)) {
			toast.error("Password requirement didn't achieved.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark"
			});
		} else {
			if (getPassword === getConformPassword) {
				setIsLoading(true);
				try {
					const response = await fetch("/user/renew-password", {
						method: "POST",
						body: JSON.stringify({
							email_token: myParam,
							password: getPassword
						}),
						headers: {
							"Content-Type": "application/json"
						}
					});

					const result = await response.json();

					if (response.status === 200) {
						toast.success(result.message, {
							position: "top-right",
							theme: "colored",
							autoClose: 3000
						});

						setMyParam("");
						setForgetPassT(false);
						setIsLoading(false);
						setPassword("");
						setConformPassword("");
						setBtnClicked(false);

						Navigate("/log-in");
					} else if (response.status === 202) {
						toast.error(result.message, {
							position: "top-right",
							theme: "colored",
							autoClose: 2500
						});

						setIsLoading(false);

						setPassword("");
						setConformPassword("");

						setMyParam("");
						setForgetPassT(true);
						setIsDisplayMass("");
					} else if (response.status === 400) {
						toast.error(result.error, {
							position: "top-right",
							theme: "colored",
							autoClose: 2500
						});

						setIsLoading(false);

						setPassword("");
						setConformPassword("");

						setMyParam("");
						setForgetPassT(true);
						setIsDisplayMass("");
					} else {
						toast.error(result.error, {
							position: "top-right",
							theme: "colored",
							autoClose: 2500
						});
						setIsLoading(false);

						setPassword("");
						setConformPassword("");

						setMyParam("");
						setForgetPassT(true);
						setIsDisplayMass("");
					}
				} catch (error) {
					toast.error(error.message, {
						position: "top-right",
						theme: "colored",
						autoClose: 3000
					});
					setIsLoading(false);

					setPassword("");
					setConformPassword("");

					setMyParam("");
					setForgetPassT(true);
					setIsDisplayMass("");
				}
			} else {
				toast.error("Password didn't match.", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark"
				});
			}
		}
	};
	// renewPassword handler end

	// for login with socials start

	// set email & password when social login
	const [socialPassword, setSocialPassword] = useState("");

	// socialPasswordHandler start
	const socialPasswordHandler = async () => {
		if (!(getPassword?.length >= 8 && passwordRequired)) {
			toast.error("Password requirement didn't achieved.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark"
			});
		} else {
			if (getPassword === getConformPassword) {
				if (isCheckTerm) {
					setIsLoading(true);
					try {
						const response = await fetch("/user/setup-account", {
							method: "PUT",
							body: JSON.stringify({
								email: getEmail,
								password: getPassword,
								_id: socialPassword._id
							}),
							headers: {
								"Content-Type": "application/json"
							}
						});

						const result = await response.json();

						if (response.status === 200) {
							setCurrentUser(result.currentUser);
							Navigate("/user-dashboard");

							setSocialPassword("");
							setIsLoading(false);
							setPassword("");
							setConformPassword("");
							setBtnClicked(false);
						} else if (response.status === 202) {
							toast.error(result.message, {
								position: "top-right",
								theme: "dark",
								autoClose: 2000
							});

							setTimeout(() => {
								setCurrentUser(result.currentUser);
								Navigate("/user-dashboard");
							}, 2500);

							setSocialPassword("");
							setIsLoading(false);
							setPassword("");
							setConformPassword("");
							setBtnClicked(false);
						} else if (response.status === 400) {
							toast.error(result.error, {
								position: "top-right",
								theme: "colored",
								autoClose: 2500
							});

							setIsLoading(false);
						} else {
							toast.error(result.error, {
								position: "top-right",
								theme: "colored",
								autoClose: 2500
							});
							setIsLoading(false);
						}
					} catch (error) {
						toast.error(error.message, {
							position: "top-right",
							theme: "colored",
							autoClose: 3000
						});
						setIsLoading(false);
					}
				}
			} else {
				toast.error("Password didn't match.", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark"
				});
			}
		}
	};
	// socialPasswordHandler end

	// loginWithSocialHandler start
	const loginWithSocialHandler = async (response) => {
		setIsLoading(true);

		const name =
			response.provider === "linkedin"
				? response.data.localizedFirstName +
				  " " +
				  response.data.localizedLastName
				: response.data.name;

		const profile_img =
			response.provider === "facebook"
				? response.data.picture.data.url
				: response.provider === "linkedin"
				? ""
				: response.data.picture;

		const provider = response.provider;

		// for get linkedin email
		let getEmail = "";

		if (response.provider === "linkedin") {
			const responseLinkedin = await fetch("/user/get-linkedin-email", {
				method: "POST",
				body: JSON.stringify({ access_token: response.data.access_token }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const resultLinkedin = await responseLinkedin.json();

			if (responseLinkedin.status === 200) {
				getEmail = resultLinkedin;
			} else {
				getEmail = "";
			}
		}

		const email =
			response.provider === "linkedin" ? getEmail : response.data.email;

		try {
			const userObj = {
				name,
				email,
				profile_img,
				provider
			};

			const response = await fetch("/user/login-with-socials", {
				method: "POST",
				body: JSON.stringify(userObj),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				setCurrentUser(result);
				setIsLoading(false);
				result.password
					? Navigate("/user-dashboard")
					: setSocialPassword({ provider, email, _id: result._id });
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setIsLoading(false);
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
			setIsLoading(false);
		}
	};
	// loginWithSocialHandler end
	// for login with socials end

	return (
		<>
			<div
				className="login-main-container-user"
				id={userCountry !== "BD" ? "when-foreigner-viewport" : ""}
			>
				<div className="container">
					<div className="row login-container-user">
						<div className="col-11 col-sm-12 login-wrapper-user">
							{/* when-recover-password start */}
							{myParam && (
								<div className="recover-password">
									<img
										src="/assets/images/recover-password-bg.png"
										alt="recover-password"
										className="img-fluid"
									/>
								</div>
							)}
							{/* when-recover-password end */}

							{/* login-left start  */}
							{!myParam && <AuthLeft contents={contents} />}
							{/* login-left end  */}

							{/* login-right start  */}
							<div className="login-right">
								{!isDisplayMass ? (
									<div
										className="log-in-form"
										id={myParam ? "when-recover-password" : ""}
									>
										<h4 id="title">
											{myParam || forgetPassT
												? recover_password
												: socialPassword
												? !socialPassword.email
													? setup_account
													: setup_account_password
												: loginBox_title}
										</h4>

										{forgetPassT && !myParam && !socialPassword && (
											<div className="forget-pass-description">
												{forgetPassDes}
											</div>
										)}

										<div className="input-fields">
											{!myParam && (
												<div className="field">
													{!socialPassword.email &&
														btnClicked &&
														!isValidateEmail(getEmail) && (
															<p id="error-msg">{email_error_msg}</p>
														)}

													<label htmlFor="email">{emailAddress}</label>

													<input
														type="email"
														placeholder={email_placeholder}
														id={
															btnClicked && !getEmail && !socialPassword?.email
																? "not-fill"
																: "email"
														}
														onChange={(e) => setEmail(e.target.value)}
														value={
															socialPassword?.email
																? socialPassword.email
																: getEmail
														}
														readOnly={socialPassword?.email ? true : false}
													/>
												</div>
											)}

											{/* password start  */}
											{!forgetPassT && (
												<div className="field" id="when-password">
													<label htmlFor="password">{passwordTxt}</label>

													<input
														type={eyeTP ? "text" : "password"}
														placeholder={
															myParam || socialPassword
																? new_pass_placeholder
																: enter_pass_placeholder
														}
														id={
															btnClicked && !getPassword
																? "not-fill"
																: "password"
														}
														onChange={(e) => setPassword(e.target.value)}
														value={getPassword}
													/>

													{!forgetPassT && !myParam && !socialPassword && (
														<div className="option-container">
															<div className="checked">
																<input
																	type="checkbox"
																	id="checked"
																	value="true"
																	onClick={() => setKeepLogin(!keepLogin)}
																	readOnly
																/>
																<label
																	htmlFor="checked"
																	className={keepLogin ? "checkedBox" : ""}
																>
																	<p>{keepMeSign}</p>
																</label>
															</div>

															<p onClick={() => setForgetPassT(true)}>
																{forgetPass}
															</p>
														</div>
													)}

													{getPassword &&
														(eyeTP ? (
															<i
																className="fa-solid fa-eye"
																onClick={() => setEyeTP(!eyeTP)}
															></i>
														) : (
															<i
																className="fa-solid fa-eye-slash"
																onClick={() => setEyeTP(!eyeTP)}
															></i>
														))}

													{(myParam || socialPassword) && getPassword && (
														<div style={{ marginTop: "20px" }}>
															<PasswordRequired
																getPassword={getPassword}
																passwordRequired={passwordRequired}
																setPasswordRequired={setPasswordRequired}
																contents={contents}
															/>
														</div>
													)}
												</div>
											)}
											{/* password end  */}

											{/* conform password start  */}
											{(myParam || socialPassword) && (
												<div
													className="field conform-password"
													id="when-password"
												>
													{btnClicked && !getConformPassword && (
														<p id="error-msg">Conform Password is required *</p>
													)}

													<label htmlFor="conform-password">
														Conform Password*
													</label>

													<input
														type={eyeTCP ? "text" : "password"}
														placeholder="Conform Password . . ."
														id={
															btnClicked && !getConformPassword
																? "not-fill"
																: "conform-password"
														}
														onChange={(e) => setConformPassword(e.target.value)}
														value={getConformPassword}
													/>

													{getConformPassword &&
														(eyeTCP ? (
															<i
																className="fa-solid fa-eye"
																onClick={() => setEyeTCP(!eyeTCP)}
															></i>
														) : (
															<i
																className="fa-solid fa-eye-slash"
																onClick={() => setEyeTCP(!eyeTCP)}
															></i>
														))}
												</div>
											)}
											{/* conform password end  */}

											{/* agree checkbox start  */}
											{socialPassword && (
												<div className="agreeCheckbox-container">
													<AgreeCheckbox
														isCheckTerm={isCheckTerm}
														setIsCheckTerm={setIsCheckTerm}
														btnClicked={btnClicked}
														isSendUpdate={isSendUpdate}
														setIsSendUpdate={setIsSendUpdate}
														contents={contents}
													/>
												</div>
											)}
											{/* agree checkbox end */}

											{!forgetPassT ? (
												myParam ? (
													<button
														onClick={() => {
															setBtnClicked(true);
															renewPassword();
														}}
													>
														{isLoading ? submitting : submit}
													</button>
												) : !socialPassword ? (
													<button
														onClick={() => {
															setBtnClicked(true);
															submitHandler();
														}}
													>
														{isLoading ? loggingBtn : loginBtn}
													</button>
												) : (
													<button
														onClick={() => {
															setBtnClicked(true);
															socialPassword?.email
																? socialPasswordHandler()
																: isValidateEmail(getEmail) &&
																  socialPasswordHandler();
														}}
													>
														{isLoading ? submitting : submit}
													</button>
												)
											) : (
												<button
													onClick={() => {
														setBtnClicked(true);
														forgetPasswordHandler();
													}}
													id={forgetPassT ? "forgetPBtn" : ""}
												>
													{isLoading ? submitting : submit}
												</button>
											)}
										</div>

										{!forgetPassT && !myParam && !socialPassword && (
											<div id="or-with-login">{loginSocialNet}</div>
										)}

										{!forgetPassT && !myParam && !socialPassword && (
											<div className="login-with-social">
												<LoginSocialFacebook
													appId={process.env.REACT_APP_APPID}
													onResolve={loginWithSocialHandler}
													onReject={(error) => {
														toast.error("Login with facebook failed!", {
															position: "top-right",
															theme: "colored",
															autoClose: 2500
														});
													}}
													className="social-icon"
												>
													<i className="fa-brands fa-facebook-f"></i>
												</LoginSocialFacebook>

												<LoginSocialGoogle
													client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
													discoveryDocs="claims_supported"
													access_type="offline"
													scope="profile email"
													onResolve={loginWithSocialHandler}
													onReject={(error) => {
														toast.error("Login with google failed!", {
															position: "top-right",
															theme: "colored",
															autoClose: 2500
														});
													}}
													className="social-icon"
												>
													<i className="fa-brands fa-google-plus-g"></i>
												</LoginSocialGoogle>

												<LoginSocialLinkedin
													client_id={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
													client_secret={
														process.env.REACT_APP_LINKEDIN_SECRET_KEY
													}
													redirect_uri={`${process.env.REACT_APP_DOMAIN}/user-dashboard`}
													onResolve={loginWithSocialHandler}
													scope="r_liteprofile r_emailaddress"
													onReject={(error) => {
														toast.error("Login with linkedin failed!", {
															position: "top-right",
															theme: "colored",
															autoClose: 2500
														});
													}}
													className="social-icon"
												>
													<i className="fa-brands fa-linkedin-in"></i>
												</LoginSocialLinkedin>
											</div>
										)}

										{!socialPassword && !myParam && (
											<p id="have-not-account">
												{dontHaveAct}{" "}
												<NavLink to={"/sign-up"}>{dontHaveActLabel}</NavLink>
											</p>
										)}

										{forgetPassT && (
											<p id="or-login">
												or,&nbsp;{" "}
												<span onClick={() => setForgetPassT(false)}>
													{loginBtn}
												</span>{" "}
											</p>
										)}
									</div>
								) : (
									<AuthInfoMsg
										heading={
											isDisplayMass === "invalid-email"
												? heading_unAuth_email
												: heading_re_pass
										}
										description={
											isDisplayMass === "invalid-email"
												? description_unAuth_email
												: description_re_pass
										}
										or={
											isDisplayMass === "invalid-email" ? or_unAuth_email : or
										}
										resend_btn={resend_btn}
										sending_btn={sending_btn}
										isLoading={isLoading}
										resendEmailHandler={
											isDisplayMass === "invalid-email"
												? resendEmailHandlerForVerification
												: resendEmailHandler
										}
									/>
								)}
							</div>
							{/* login-right end  */}
						</div>
					</div>
				</div>

				<SubFooter contents={contents} />

				{/* shapes animation component   */}
				<ShapeAnimation />
			</div>
		</>
	);
};

export default Login;
