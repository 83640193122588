// external components

// internal components
import PageHeader from "../../common/PageHeader";
import { GetContextApi } from "../../storage/ContextApi";
import "./AboutUs.css";

const AboutUs = () => {
	// aboutUs contents
	const { contents } = GetContextApi();

	const { title, description } = contents.about_us;

	return (
		<>
			<PageHeader
				pageName={contents.title === "bangla" ? "আমাদের সম্পর্কে" : "ABOUT US"}
				link={contents.title === "bangla" ? "আমাদের সম্পর্কে" : "About-Us"}
				whichLanguage={contents.title}
			/>

			{/* contents-section start  */}
			<div className="container">
				<div className="about-us-container row">
					<div className="about-us-wrapper col-11 col-sm-12">
						{/* title  */}
						<h4 id="top-title">{title}</h4>

						{description.map((item, index) => (
							<div
								className="content"
								key={index}
								dangerouslySetInnerHTML={{ __html: item }}
							></div>
						))}
					</div>
				</div>
			</div>
			{/* contents-section start  */}
		</>
	);
};

export default AboutUs;
