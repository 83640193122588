/* eslint-disable import/no-webpack-loader-syntax */
// internal components
import ArtDirectorStyle from "!!raw-loader!./ArtDirector.css";
import {
	RichTextParser,
	SectionTitle,
	SkillsLevelVerticalLine,
	Time
} from "../Components";
import {
	checkVariables,
	divideAndAddBrTag,
	fetchingResumeData,
	isArrayEmpty,
	objHasAnyValue,
	removeEmptyObjects
} from "../Components/Utils";

const ArtDirector = ({ getResume }) => {
	// fetching resume's all data
	const {
		personal_details_title,
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		professional_summary_title,
		rich_text_content,
		employment_history_title,
		employment_history_items,
		education_title,
		education_items,
		project_title,
		project_items,
		web_link_items,
		skills_title,
		skills_items,
		displaying_level,
		languages_title,
		languages_items,
		hobbies_title,
		hobbies_description,
		activity_title,
		activity_items,
		internship_title,
		internship_items,
		courses_title,
		courses_items,
		references_title,
		references_hide,
		references_items,
		customArr,
		disclaimer_title,
		disclaimer_description
	} = fetchingResumeData(getResume);

	//  professional_summary
	const professional_summary = rich_text_content ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>

				<SectionTitle
					className="title"
					sectionName={professional_summary_title}
					equalTo="Professional Summary"
					initialName="Profile"
				/>

				<div className="contents-wrapper">
					{rich_text_content && (
						<RichTextParser
							className={"rich__editor_wrapper"}
							rich_text={rich_text_content}
						/>
					)}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// web_links
	const web_links = checkVariables(web_link_items, phone, email) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>

				<div className="title">Contact</div>

				<div className="web__links_wrapper contents-wrapper">
					{!isArrayEmpty(web_link_items) &&
						removeEmptyObjects(web_link_items, ["link_label", "link_url"]).map(
							(label, index) => {
								return (
									<div key={index} className="link__wrapper">
										<img
											src={`/assets/icons/${
												(label.link_url.includes("youtube") &&
													"account-youtube.png") ||
												(label.link_url.includes("twitter") &&
													"account-twitter.png") ||
												(label.link_url.includes("linkedin") &&
													"account-linkedin.png") ||
												(label.link_url.includes("instagram") &&
													"account-instagram.png") ||
												(label.link_url.includes("facebook") &&
													"account-facebook.png") ||
												"internet.png"
											}`}
											alt="icon"
											className="link__img"
										/>

										<a
											href={label.link_url}
											target="_blank"
											rel="noopener noreferrer"
											className="link__url"
										>
											{label.link_label}
										</a>
									</div>
								);
							}
						)}

					{phone && (
						<div className="link__wrapper">
							<img
								src={"/assets/icons/account-phone.png"}
								alt="icon"
								className="link__img"
							/>

							<a
								href={`tel:${phone}`}
								target="_blank"
								rel="noopener noreferrer"
								className="link__url"
							>
								{phone}
							</a>
						</div>
					)}

					{email && (
						<div className="link__wrapper">
							<img
								src={"/assets/icons/account-email.png"}
								alt="icon"
								className="link__img"
							/>

							<a
								href={`mailto:${email}`}
								target="_blank"
								rel="noopener noreferrer"
								className="link__url"
								dangerouslySetInnerHTML={{ __html: divideAndAddBrTag(email) }}
							></a>
						</div>
					)}
				</div>
			</div>
		</div>
	) : (
		""
	);

	//  employment_history
	const employment_history = !isArrayEmpty(employment_history_items) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>

				<SectionTitle
					className="title"
					sectionName={employment_history_title}
					equalTo="EmploymenT History"
					initialName="Work Experience"
				/>

				<div className="contents-wrapper">
					{employment_history_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div
									className={index === 0 ? "item item__first" : "item"}
									key={index}
								>
									<div className="item-dot"></div>

									<div className="item-content">
										<div className="item__header space-between">
											{item.employment_job_title}

											{checkVariables(item.start_date, item.end_date) && (
												<div className="time">
													{<Time start={item.start_date} end={item.end_date} />}
												</div>
											)}
										</div>

										<div className="item__sub_header">
											{item.employer}

											{item.employment_city && `, ${item.employment_city}`}
										</div>

										{item.employment_rich_text_content && (
											<RichTextParser
												className={"rich__editor_wrapper"}
												rich_text={item.employment_rich_text_content}
											/>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// projects
	const projects = !isArrayEmpty(project_items) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>

				<SectionTitle
					className="title"
					sectionName={project_title}
					equalTo="Projects/Portfolio"
					initialName="Projects"
				/>

				<div className="contents-wrapper">
					{project_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div
									className={index === 0 ? "item item__first" : "item"}
									key={index}
								>
									<div className="item-dot"></div>

									<div className="item-content">
										<div className="item__header project-header">
											<div>
												{item.project_name}

												{item.project_duration &&
													` ( ${item.project_duration} )`}
											</div>

											<div className="project_links">
												{!isArrayEmpty(item.project_links) &&
													removeEmptyObjects(item.project_links, [
														"label",
														"link"
													]).map((label, index) => {
														return (
															<div
																key={index}
																className={
																	index === 0 ? "label label__first" : "label"
																}
															>
																<a
																	href={label.link}
																	target="_blank"
																	rel="noopener noreferrer"
																	className="anchor"
																>
																	{label.label}
																</a>
															</div>
														);
													})}
											</div>
										</div>

										{checkVariables(item.technologies) && (
											<div className="item__sub_header">
												<strong>Technologies&nbsp;:&nbsp;</strong>
												{item.technologies}
											</div>
										)}

										{item.pro_description && (
											<RichTextParser
												className={"rich__editor_wrapper"}
												rich_text={item.pro_description}
											/>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// education
	const education = !isArrayEmpty(education_items) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>
				<div className="title">{education_title}</div>

				<div className="contents-wrapper">
					{education_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div
									className={index === 0 ? "item item__first" : "item"}
									key={index}
								>
									<div className="item__header">
										{item.degree}
										{item.school && `, ${item.school}`}

										{item.edu_city && `, ${item.edu_city}`}
									</div>

									{checkVariables(item.edu_start_date, item.edu_end_date) && (
										<div className="item__time">
											<Time
												start={item.edu_start_date}
												end={item.edu_end_date}
												fromWhere={"education"}
											/>
										</div>
									)}

									{item.edu_rich_text_content && (
										<RichTextParser
											className={"rich__editor_wrapper"}
											rich_text={item.edu_rich_text_content}
										/>
									)}
								</div>
							);
						})}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// internship
	const internship = !isArrayEmpty(internship_items) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>

				<div className="title">{internship_title}</div>

				<div className="contents-wrapper">
					{internship_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div
									className={index === 0 ? "item item__first" : "item"}
									key={index}
								>
									<div className="item-dot"></div>

									<div className="item-content">
										<div className="item__header space-between">
											{item.intern_job}

											{checkVariables(
												item.intern_start_date,
												item.intern_end_date
											) && (
												<div className="time">
													{
														<Time
															start={item.intern_start_date}
															end={item.intern_end_date}
														/>
													}
												</div>
											)}
										</div>

										<div className="item__sub_header">
											{item.intern_employer}

											{item.intern_city && `, ${item.intern_city}`}
										</div>

										{item.intern_rich_text_content && (
											<RichTextParser
												className={"rich__editor_wrapper"}
												rich_text={item.intern_rich_text_content}
											/>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// courses
	const courses = !isArrayEmpty(courses_items) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>
				<div className="title">{courses_title}</div>

				<div className="contents-wrapper">
					{courses_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div
									className={index === 0 ? "item item__first" : "item"}
									key={index}
								>
									<div className="item__header">
										{item.course_name}
										{item.course_institution && `, ${item.course_institution}`}
									</div>

									{checkVariables(
										item.course_start_date,
										item.course_end_date
									) && (
										<div className="item__time">
											<Time
												start={item.course_start_date}
												end={item.course_end_date}
												fromWhere={"education"}
											/>
										</div>
									)}

									{item.course_rich_text_content && (
										<RichTextParser
											className={"rich__editor_wrapper"}
											rich_text={item.course_rich_text_content}
										/>
									)}
								</div>
							);
						})}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// extra curricular activities
	const activities = !isArrayEmpty(activity_items) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>

				<div className="title">{activity_title}</div>

				<div className="contents-wrapper">
					{activity_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div
									className={index === 0 ? "item item__first" : "item"}
									key={index}
								>
									<div className="item-dot"></div>

									<div className="item-content">
										<div className="item__header space-between">
											{item.activity_function}

											{checkVariables(
												item.activity_start_date,
												item.activity_end_date
											) && (
												<div className="time">
													{
														<Time
															start={item.activity_start_date}
															end={item.activity_end_date}
														/>
													}
												</div>
											)}
										</div>

										<div className="item__sub_header">
											{item.activity_employer}

											{item.activity_city && `, ${item.activity_city}`}
										</div>

										{item.activity_rich_text_content && (
											<RichTextParser
												className={"rich__editor_wrapper"}
												rich_text={item.activity_rich_text_content}
											/>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// references
	const references = !isArrayEmpty(references_items) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>
				<div className="title">{references_title}</div>

				{references_hide && (
					<div className="contents-wrapper">
						<div className="item__header">Reference available upon request</div>
					</div>
				)}

				{!references_hide && (
					<div className="contents-wrapper">
						{references_items
							.filter((obj) => objHasAnyValue(obj))
							.map((item, index) => {
								return (
									<div
										className={index === 0 ? "item item__first" : "item"}
										key={index}
									>
										<div className="item__header">
											{item.references_name}
											{item.references_company &&
												`, ${item.references_company}`}
										</div>

										{checkVariables(
											item.references_phone,
											item.references_email
										) && (
											<div style={{ fontSize: "10px" }}>
												{item.references_phone}
												{item.references_phone && <br />}
												{item.references_email}
											</div>
										)}
									</div>
								);
							})}
					</div>
				)}
			</div>
		</div>
	) : (
		""
	);

	// custom
	const custom = (specificCustom) =>
		!isArrayEmpty(specificCustom.custom_items) ? (
			<div className="section">
				<div className="section-wrapper">
					<div className="dot"></div>
					<div className="title">{specificCustom.custom_title}</div>

					<div className="contents-wrapper">
						{specificCustom.custom_items
							.filter((obj) => objHasAnyValue(obj))
							.map((item, index) => {
								return (
									<div
										className={index === 0 ? "item item__first" : "item"}
										key={index}
									>
										<div className="item__header">
											{item.custom_activity}

											{item.custom_city && `, ${item.custom_city}`}
										</div>

										{checkVariables(
											item.custom_start_date,
											item.custom_end_date
										) && (
											<div className="item__time">
												<Time
													start={item.custom_start_date}
													end={item.custom_end_date}
													fromWhere={"education"}
												/>
											</div>
										)}

										{item.custom_rich_text_content && (
											<RichTextParser
												className={"rich__editor_wrapper"}
												rich_text={item.custom_rich_text_content}
											/>
										)}
									</div>
								);
							})}
					</div>
				</div>
			</div>
		) : (
			""
		);

	//  personal_details
	const personal_details = checkVariables(
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth
	) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>
				<div className="title">{personal_details_title}</div>

				<div className="contents-wrapper">
					{/* present_address start */}
					{checkVariables(
						present_address,
						present_city,
						present_area,
						present_zip
					) && (
						<div className="personal__sub-header">
							<div className="header">Present Address</div>

							<div className="content">
								{present_address}

								{present_city && `, ${present_city}`}

								{present_area && `, ${present_area}`}

								{present_zip && `, ${present_zip}`}

								{!checkVariables(
									permanent_address,
									permanent_city,
									permanent_area,
									permanent_zip
								) &&
									country &&
									`, ${country}`}
							</div>
						</div>
					)}
					{/* present_address end */}

					{/* permanent_address start */}
					{checkVariables(
						permanent_address,
						permanent_city,
						permanent_area,
						permanent_zip,
						same_address
					) && (
						<div className="personal__sub-header">
							<div className="header">Permanent Address</div>

							{same_address ? (
								<div className="content">Same as above</div>
							) : (
								<div className="content">
									{permanent_address}
									{permanent_city && `, ${permanent_city}`}

									{permanent_area && `, ${permanent_area}`}

									{permanent_zip && `, ${permanent_zip}`}

									{country && `, ${country}`}
								</div>
							)}
						</div>
					)}
					{/* permanent_address end */}

					{nationality && (
						<div className="personal__sub-header">
							<div className="header">Nationality</div>

							<div className="content">{nationality}</div>
						</div>
					)}

					{nid && (
						<div className="personal__sub-header">
							<div className="header">{isNaN(nid) ? "Passport" : "NID"}</div>

							<div className="content">{nid}</div>
						</div>
					)}

					{place_of_birth && (
						<div className="personal__sub-header">
							<div className="header">Place Of Birth</div>

							<div className="content">{place_of_birth}</div>
						</div>
					)}

					{date_of_birth && (
						<div className="personal__sub-header">
							<div className="header">Date Of Birth</div>

							<div className="content">{date_of_birth}</div>
						</div>
					)}

					{driving_license && (
						<div className="personal__sub-header">
							<div className="header">Driving License</div>

							<div className="content">{driving_license}</div>
						</div>
					)}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// skills
	const skills = !isArrayEmpty(skills_items) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>
				<div className="title">{skills_title}</div>

				<div className=" contents-wrapper">
					{skills_items.map((value, index) => {
						return (
							<div className="skill-wrapper" key={index}>
								<SkillsLevelVerticalLine
									skill={value.skill}
									skill_level={value.skill_level}
									bg_color="#566470"
									displaying_level={displaying_level}
									title_class="skill__title"
									level_class="skill__level"
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// languages
	const languages = !isArrayEmpty(languages_items) ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>

				<div className="title">{languages_title}</div>

				<div className="contents-wrapper">
					{languages_items.map((value, index) => {
						return (
							<div className="skill-wrapper" key={index}>
								<SkillsLevelVerticalLine
									skill={value.languages_name}
									skill_level={value.languages_level}
									bg_color="#566470"
									title_class="skill__title"
									level_class="skill__level"
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	) : (
		""
	);

	//  hobbies
	const hobbies = hobbies_description ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>

				<div className="title">{hobbies_title}</div>

				<div className="contents-wrapper">
					<pre className="hobbies-description">{hobbies_description}</pre>
				</div>
			</div>
		</div>
	) : (
		""
	);

	//  disclaimer
	const disclaimer = disclaimer_description ? (
		<div className="section">
			<div className="section-wrapper">
				<div className="dot"></div>

				<div className="title">{disclaimer_title}</div>

				<div className="contents-wrapper">
					<div className="hobbies-description">{disclaimer_description}</div>
				</div>
			</div>
		</div>
	) : (
		""
	);

	// for displaying additional section start
	const additionalSection = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Courses") {
			storeAdditionalSection.push(courses);
		} else if (value.key === "Extra-curricular Activities") {
			storeAdditionalSection.push(activities);
		} else if (value.key === "Internship") {
			storeAdditionalSection.push(internship);
		} else if (value.key === "Custom") {
			customArr.map(
				(item) =>
					item.cus_section_id === value._id &&
					storeAdditionalSection.push(custom(item))
			);
		}

		return storeAdditionalSection;
	});
	// for displaying additional section end

	// left-section array
	const leftSection = [
		professional_summary,
		web_links,
		personal_details,
		skills,
		languages,
		hobbies,
		references
	];

	// right-section array
	const rightSection = [
		education,
		employment_history,
		projects,
		...additionalSection,
		disclaimer
	];

	return (
		<>
			<style>{ArtDirectorStyle}</style>

			<div className="container">
				{/* top_header start  */}
				<div className="top_header">
					<div className="top_header__left">
						<div className="img-container">
							<img
								src={`/assets/profile-img/${
									cropped_profile_img
										? cropped_profile_img
										: "default-profile.png"
								}`}
								alt="profile-img"
								className="profile-img"
							/>
						</div>
					</div>

					<div className="top_header__right">
						{checkVariables(first_name, last_name) && (
							<div className="name">
								{first_name && first_name} &nbsp; {last_name && last_name}
							</div>
						)}

						{job_title && <div className="job-title">{job_title}</div>}
					</div>
				</div>
				{/* top_header end  */}

				<div className="body">
					{/* body__left start  */}
					<div className="body__left">
						{leftSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div
									key={index}
									className={index === 0 ? "wrapper wrapper_first " : "wrapper"}
								>
									{value}
								</div>
							))}

						{/* edit start  */}
						{}
						{/* edit end */}
					</div>
					{/* body__left end  */}

					{/* body__right start  */}
					<div className="body__right">
						{rightSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div
									key={index}
									className={index === 0 ? "wrapper wrapper_first " : "wrapper"}
								>
									{value}
								</div>
							))}

						{}
					</div>
					{/* body__right end  */}
				</div>
			</div>
		</>
	);
};

export default ArtDirector;
