// external components
import { NavLink } from "react-router-dom";

// internal components
import "./NotFoundPage.css";

const NotFoundPage = ({ fromWhere }) => {
	return (
		<>
			<div className="container-fluid p-0">
				<div
					className={
						fromWhere.includes("admin")
							? "not-found-page-admin"
							: "not-found-page"
					}
				>
					<div id="not-found-wrapper">
						<h4>Page Not Found.</h4>

						<div id="not-found-gif"></div>
						<h4>
							<NavLink
								to={fromWhere.includes("admin") ? "/admin/log-in" : "/"}
								id="error-navlink"
							>
								<span className="hover-link">
									{fromWhere.includes("admin")
										? "Go Back To Admin Login"
										: "Go Back To Home Page"}
								</span>
							</NavLink>
						</h4>
					</div>
				</div>
			</div>
		</>
	);
};

export default NotFoundPage;
