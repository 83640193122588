// external components
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from "react-sortable-hoc";

// internal components
import DragIcon from "./DragIcon";

const DragHandle = sortableHandle(() => <DragIcon className="for-item-drag" />);

const SortableItem = sortableElement(({ value, className, id }) => (
	<div className={className} id={id}>
		<DragHandle />
		{value}
	</div>
));

const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});

const updateBeforeSortStart = async (setState) => {
	setState(-1);

	const hasActive = document.querySelector(".display-section-item#active");

	if (Boolean(hasActive)) {
		await new Promise((resolve) => setTimeout(resolve, 300));
		return "return after a delay of 300 milliseconds";
	} else {
		return "no delay";
	}
};

export { SortableContainer, SortableItem, updateBeforeSortStart };
