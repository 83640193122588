// external components
import { convertFromHTML } from "draft-convert";
import { EditorState } from "draft-js";
import { memo, useReducer, useRef, useState } from "react";

// internal components
import { EditorContextApi } from "../../../../../pages/ResumeEditor/EditorContextApi";
import {
	ControlCollapse,
	Description,
	EditorTips,
	PhrasesPreview,
	PreWritten,
	RichEditor,
	SectionTitle
} from "../common";

const ProfessionalSummary = ({ contents, allPhrasesRef }) => {
	// professional_summary contents
	const { professional_summary_subtitle, rename, revert } = contents.editor;

	// editor contextApi
	const { getResume, updateResumeInfo, delay } = EditorContextApi();

	// getting _id
	const _id = getResume.professional_summary[0]._id;

	// define maximum words & counter
	const maxWords = 80;
	const [wordCount, setWordCount] = useState(0);

	// for pre-written phrases toggle
	const [phrasesT, setPhrasesT] = useState(false);
	const phrasesComponentRef = useRef();
	const phrasesBtnRef = useRef();

	// getting search value
	const [searchValue, setSearchValue] = useState("");

	// for title and collapse controls start
	const initialController = {
		border: false,
		editTitle: false,
		collapse: true,
		editor: false
	};

	const controlHandler = (state, action) => {
		switch (action.type) {
			case "open-border":
				return {
					...state,
					border: true
				};

			case "open-editor":
				return {
					...state,
					editTitle: true
				};

			case "close-border-editor":
				return {
					...state,
					border: false,
					editTitle: false
				};

			case "open-collapse":
				return {
					...state,
					collapse: true
				};

			case "close-collapse":
				return {
					...state,
					collapse: false
				};

			case "focused-rich-editor":
				return {
					...state,
					editor: true
				};

			case "blur-rich-editor":
				return {
					...state,
					editor: false
				};

			default:
				return state;
		}
	};

	const [control, dispatchControl] = useReducer(
		controlHandler,
		initialController
	);
	// for title and collapse controls end

	// getting professional_summary
	const { professional_summary_title, rich_text_content } =
		getResume.professional_summary[0];

	const [inputValues, setInputValues] = useState({
		professional_summary_title,
		rich_text_content: rich_text_content
			? EditorState.createWithContent(convertFromHTML(rich_text_content))
			: EditorState.createEmpty()
	});

	return (
		<>
			<div className="section-container" id="professional-summary">
				{/* label start  */}
				<div className="label" id={control.collapse ? "active" : ""}>
					<SectionTitle
						title="Professional Summary"
						name="professional_summary_title"
						control={control}
						dispatchControl={dispatchControl}
						_id={_id}
						value={inputValues.professional_summary_title}
						setInputValues={setInputValues}
						updateResumeInfo={updateResumeInfo}
						rename={rename}
						revert={revert}
					/>

					<ControlCollapse
						collapse={control.collapse}
						dispatchControl={dispatchControl}
					/>
				</div>
				{/* label end  */}

				{/* content start  */}
				<div
					className="content-container remove-margin professionalSummary"
					id={control.collapse ? "active" : ""}
				>
					{/* description  */}
					<Description content={professional_summary_subtitle} />

					{/* rich-editor start  */}
					<div className="input-field rich-editor">
						<RichEditor
							name="rich_text_content"
							editorState={inputValues.rich_text_content}
							setInputValues={setInputValues}
							updateResumeInfo={updateResumeInfo}
							_id={_id}
							maxWords={maxWords}
							wordCount={wordCount}
							setWordCount={setWordCount}
							placeholder="e.g. Passionate science teacher with 8+ years of experience and a track record of ..."
							control={control.editor}
							dispatchControl={dispatchControl}
							delay={delay}
						/>

						{/* PreWritten text  */}
						<PreWritten
							phrasesT={phrasesT}
							setPhrasesT={setPhrasesT}
							phrasesBtnRef={phrasesBtnRef}
							contents={contents}
						/>

						{/* phrases preview  */}
						<PhrasesPreview
							name="rich_text_content"
							phrasesT={phrasesT}
							setPhrasesT={setPhrasesT}
							phrasesComponentRef={phrasesComponentRef}
							phrasesBtnRef={phrasesBtnRef}
							searchValue={searchValue}
							setSearchValue={setSearchValue}
							editorState={inputValues.rich_text_content}
							setInputValues={setInputValues}
							maxWords={maxWords}
							allPhrasesRef={allPhrasesRef}
						/>
					</div>

					{/* tips   */}
					<EditorTips
						contents={contents}
						maxWords={maxWords}
						wordCount={wordCount}
					/>
					{/* rich-editor end */}
				</div>
				{/* content end */}
			</div>
		</>
	);
};

export default memo(ProfessionalSummary);
