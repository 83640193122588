// external components

// internal components
import { DatePickerCom, DropDownCom, InputField } from "../../common";

const PersonalDetails = ({ userProfile, setUserProfile, handleOnChange }) => {
	const personalDetailsFields = [
		{
			type: "text",
			name: "first_name",
			haveIm: true
		},
		{
			type: "text",
			name: "last_name"
		},
		{
			type: "text",
			name: "father_s_name"
		},
		{
			type: "text",
			name: "mother_s_name"
		},
		{
			type: "dropdown",
			name: "gender",
			placeholder: "Select your gender",
			haveIm: true,
			optionArr: ["Male", "Female", "Other"]
		},
		{
			type: "date",
			name: "date_of_birth",
			haveIm: true
		}
	];

	return (
		<>
			{personalDetailsFields.map(
				(item, index) =>
					(["text", "number"].includes(item.type) && (
						<InputField
							type={item.type}
							name={item.name}
							value={userProfile[item.name]}
							onChange={handleOnChange}
							labelName={item?.label}
							placeholder={item?.placeholder}
							haveIm={item?.haveIm}
							key={index}
						/>
					)) ||
					(item.type === "date" && (
						<DatePickerCom
							name={item.name}
							value={userProfile[item.name]}
							onChange={handleOnChange}
							labelName={item?.label}
							placeholder={item?.placeholder}
							haveIm={item?.haveIm}
							key={index}
						/>
					)) ||
					(item.type === "dropdown" && (
						<DropDownCom
							name={item.name}
							value={userProfile[item.name]}
							setValue={setUserProfile}
							optionArr={item.optionArr}
							labelName={item?.label}
							placeholder={item?.placeholder}
							haveIm={item?.haveIm}
							key={index}
						/>
					))
			)}
		</>
	);
};

export default PersonalDetails;
