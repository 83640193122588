// external components
import moment from "moment";

// internal components
import "./AdminDashboardContents.css";
import TableSkeleton from "./TableSkeleton";

const AdminDashboardContents = ({ isLoading, allUsers, allResumes }) => {
	const gettingPaidUser = allUsers.filter(
		(user) => user.package["Package Name"] !== "Free Plan"
	);

	return (
		<>
			<div className="admin-dashboard-contents">
				{/* statistic start  */}
				<div className="statistic">
					<div className="statistic-section" id="registered">
						<span className="title">Registered &nbsp;Users</span>
						<div className="icon-number-wrapper">
							<span className="number">{allUsers.length}</span>
							<span className="icon" id="registered-icon">
								<i className="fa-solid fa-list-check"></i>
							</span>
						</div>
					</div>

					<div className="statistic-section" id="resume">
						<span className="title">Created &nbsp;Resumes</span>

						<div className="icon-number-wrapper">
							<span className="number">{allResumes.length}</span>

							<span className="icon" id="resume-icon">
								<i className="fa-solid fa-circle-check"></i>
							</span>
						</div>
					</div>

					<div className="statistic-section" id="cover-letter">
						<div className="title">Created &nbsp;Cover &nbsp;Letters</div>

						<div className="icon-number-wrapper">
							<span className="number">{0}</span>

							<span className="icon" id="cover-letter-icon">
								<i className="fa-solid fa-circle-check"></i>
							</span>
						</div>
					</div>

					<div className="statistic-section" id="subscription">
						<span className="title">Paid &nbsp;Subscription</span>

						<div className="icon-number-wrapper">
							<span className="number">{gettingPaidUser.length}</span>

							<span className="icon" id="subscription-icon">
								<i className="fa-solid fa-dollar-sign"></i>
							</span>
						</div>
					</div>
				</div>
				{/* statistic end  */}

				{/* all-users list start  */}
				<div className="all-users-list">
					<div className="header">
						<h5>All Users</h5>

						<button type="button" className="btn">
							<i className="fa-regular fa-envelope"></i> Bulk Emails
						</button>
					</div>
					{isLoading ? (
						<TableSkeleton />
					) : (
						<div className="table-container">
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col">#</th>

										<th scope="col">Profile Image</th>
										<th scope="col" style={{ width: "20%" }}>
											Name
										</th>

										<th scope="col" style={{ width: "30%" }}>
											Email
										</th>

										<th scope="col" style={{ width: "15%" }}>
											Status
										</th>

										<th scope="col" style={{ width: "13%" }}>
											Registered Date
										</th>

										<th scope="col">Action</th>
									</tr>
								</thead>
								<tbody>
									{allUsers.length > 0 &&
										allUsers
											.map((value, index) => {
												return (
													<tr key={index}>
														<td id="id">
															<span>{index + 1}</span>
														</td>

														<td>
															<div className="img-container">
																<img
																	src={`/assets/profile-img/${
																		value.profile_img
																			? value.profile_img
																			: "default-profile.png"
																	}`}
																	alt=""
																/>
															</div>
														</td>

														<td>
															<input readOnly value={value?.name} />
														</td>

														<td>
															<input readOnly value={value?.email} />
														</td>

														<td>
															<input readOnly value={value?.status} />
														</td>

														<td>
															<input
																readOnly
																value={moment(value?.createdAt).format(
																	"MMMM DD, YYYY"
																)}
															/>
														</td>

														<td>
															<div className="btn-container">
																<button className="btn">
																	<a
																		href={`mailto:${value?.email}`}
																		id="send-email"
																	>
																		Send Email
																	</a>
																</button>
															</div>
														</td>
													</tr>
												);
											})
											.reverse()}
								</tbody>
							</table>
						</div>
					)}
				</div>
				{/* all-users list end  */}
			</div>
		</>
	);
};

export default AdminDashboardContents;
