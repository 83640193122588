// external components
import { useEffect, useRef, useState } from "react";

// internal components

const DropDownCom = ({
	name,
	placeholder,
	value,
	setValue,
	optionArr,
	labelName,
	haveIm
}) => {
	const [dropdownToggle, setDropdownToggle] = useState(false);

	// for close dropdown when outside clicked start
	const myRef = useRef();

	const handleClickOutside = (e) => {
		if (!myRef.current?.contains(e.target) && dropdownToggle) {
			setDropdownToggle(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dropdownToggle]);
	// for close dropdown when outside clicked end

	return (
		<>
			<div className="input-field">
				<label htmlFor={name} className="label">
					{labelName ? labelName : name.replace(/_/g, " ")}
					{haveIm && <span id="important">*</span>}
				</label>

				<div
					className={
						dropdownToggle ? "dropdown-container active" : "dropdown-container"
					}
					onClick={() => setDropdownToggle(!dropdownToggle)}
					ref={myRef}
				>
					<input
						type="text"
						id={name}
						className="input"
						name={name}
						placeholder={placeholder ? placeholder : "Select an option"}
						value={value}
						readOnly
					/>

					<div className="options-wrapper" ref={myRef}>
						{optionArr.map((value, index) => {
							return (
								<div
									onClick={() =>
										setValue((prev) => ({
											...prev,
											[name]: value
										}))
									}
									key={index}
									className="item"
								>
									{value}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default DropDownCom;
