// external components
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";

// internal components
import "./_AdminContent.scss";

const AdminContent = () => {
	const [selectedTab, setSelectedTab] = useState(0);

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	return (
		<>
			<Box
				sx={{
					padding: "10px 40px 0"
				}}
			>
				<Paper
					elevation={24}
					sx={{ borderRadius: "16px", border: "1px solid red" }}
				>
					{/* header start  */}
					<Box
						sx={{
							paddingTop: "15px"
						}}
					>
						<Typography
							variant="h6"
							sx={{
								wordSpacing: "5px",
								paddingLeft: "20px",
								borderLeft: "5px solid #f30a5c",
								alignSelf: "center",
								opacity: 0.9,
								lineHeight: 1
							}}
						>
							Content
						</Typography>

						<Tabs value={selectedTab} onChange={handleChange}>
							<Tab label="Home" />
							<Tab label="Resume" />
							<Tab label="About Us" />
							<Tab label="Contact Us" />
							<Tab label="Log In" />
							<Tab label="Sign Up" />
							<Tab label="FAQ" />
							<Tab label="Term & Condition" />
							<Tab label="Privacy Policy" />
							<Tab label="Refund Policy" />
							<Tab label="User Dashboard" />
							<Tab label="Account Sittings" />
							<Tab label="Subscription" />
							<Tab label="Payment" />
						</Tabs>
					</Box>
					{/* header end  */}
				</Paper>
			</Box>
		</>
	);
};

export default AdminContent;
