/* eslint-disable import/no-webpack-loader-syntax */
// internal components
import ProfessionalResumeStyle from "!!raw-loader!./ProfessionalResume.css";
import {
	RichTextParser,
	SectionTitle,
	SkillsLevelRound,
	Time
} from "../Components";
import {
	checkVariables,
	fetchingResumeData,
	isArrayEmpty,
	objHasAnyValue,
	removeEmptyObjects
} from "../Components/Utils";

const ProfessionalResume = ({ getResume }) => {
	// fetching resume's all data
	const {
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		professional_summary_title,
		rich_text_content,
		employment_history_title,
		employment_history_items,
		education_title,
		education_items,
		project_title,
		project_items,
		skills_title,
		skills_items,
		displaying_level,
		languages_title,
		languages_items,
		hobbies_title,
		hobbies_description,
		activity_title,
		activity_items,
		internship_title,
		internship_items,
		courses_title,
		courses_items,
		references_title,
		references_hide,
		references_items,
		customArr,
		disclaimer_title,
		disclaimer_description
	} = fetchingResumeData(getResume);

	//  professional_summary
	const professional_summary = rich_text_content ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img src="/assets/icons/user.png" alt="icon" className="icon" />

				<SectionTitle
					className="title"
					sectionName={professional_summary_title}
					equalTo="Professional Summary"
					initialName="Profile"
				/>
			</div>

			<div className="section__contents">
				{rich_text_content && (
					<RichTextParser
						className={"rich_editor__container remove-margin"}
						rich_text={rich_text_content}
					/>
				)}
			</div>
		</div>
	) : (
		""
	);

	//  employment_history
	const employment_history = !isArrayEmpty(employment_history_items) ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img src="/assets/icons/suitcase.png" alt="icon" className="icon" />

				<SectionTitle
					className="title"
					sectionName={employment_history_title}
					equalTo="Employment History"
					initialName="Work Experience"
				/>
			</div>

			<div className="section__contents">
				{employment_history_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item__header_wrapper">
									<div className="item__header">
										{item.employment_job_title}
									</div>

									{checkVariables(item.start_date, item.end_date) && (
										<div className="item__time">
											<Time start={item.start_date} end={item.end_date} />
										</div>
									)}
								</div>

								{checkVariables(item.employer, item.employment_city) && (
									<div className="item__sub_header">
										{item.employer}
										{item.employer && item.employment_city && ", "}
										{item.employment_city}
									</div>
								)}

								{item.employment_rich_text_content && (
									<RichTextParser
										className={"rich_editor__container"}
										rich_text={item.employment_rich_text_content}
									/>
								)}
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// projects
	const projects = !isArrayEmpty(project_items) ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img src="/assets/icons/project-icon.png" alt="icon" className="icon" />

				<SectionTitle
					className="title"
					sectionName={project_title}
					equalTo="Projects/Portfolio"
					initialName="Projects"
				/>
			</div>

			<div className="section__contents">
				{project_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item__header_wrapper">
									<div className="item__header">
										{item.project_name}

										{item.project_duration && ` (${item.project_duration})`}
									</div>

									<div className="project_links">
										{!isArrayEmpty(item.project_links) &&
											removeEmptyObjects(item.project_links, [
												"label",
												"link"
											]).map((label, index) => {
												return (
													<div key={index} className="label">
														<a
															href={label.link}
															target="_blank"
															rel="noopener noreferrer"
															className="project-url"
														>
															{label.label}
														</a>
														{index !== item.project_links.length - 1 && "  |  "}
													</div>
												);
											})}
									</div>
								</div>

								{checkVariables(item.technologies) && (
									<div className="item__sub_header remove-text-transform">
										<b>Technologies:&nbsp;</b>
										{item.technologies}
									</div>
								)}

								{item.pro_description && (
									<RichTextParser
										className={"rich_editor__container"}
										rich_text={item.pro_description}
									/>
								)}
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// education
	const education = !isArrayEmpty(education_items) ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img src="/assets/icons/graduate.png" alt="icon" className="icon" />

				<div className="title">{education_title}</div>
			</div>

			<div className="section__contents">
				{education_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item__header_wrapper">
									<div className="item__header">{item.degree}</div>

									{checkVariables(item.edu_start_date, item.edu_end_date) && (
										<div className="item__time">
											<Time
												start={item.edu_start_date}
												end={item.edu_end_date}
												fromWhere={"education"}
											/>
										</div>
									)}
								</div>

								{checkVariables(item.school, item.edu_city) && (
									<div className="item__sub_header">
										{item.school}
										{item.school && item.edu_city && ", "}
										{item.edu_city}
									</div>
								)}

								{item.edu_rich_text_content && (
									<RichTextParser
										className={"rich_editor__container"}
										rich_text={item.edu_rich_text_content}
									/>
								)}
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// internship
	const internship = !isArrayEmpty(internship_items) ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img src="/assets/icons/internship.png" alt="icon" className="icon" />

				<div className="title">{internship_title}</div>
			</div>

			<div className="section__contents">
				{internship_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item__header_wrapper">
									<div className="item__header">{item.intern_job}</div>

									{checkVariables(
										item.intern_start_date,
										item.intern_end_date
									) && (
										<div className="item__time">
											<Time
												start={item.intern_start_date}
												end={item.intern_end_date}
											/>
										</div>
									)}
								</div>

								{checkVariables(item.intern_employer, item.intern_city) && (
									<div className="item__sub_header">
										{item.intern_employer}
										{item.intern_employer && item.intern_city && ", "}
										{item.intern_city}
									</div>
								)}

								{item.intern_rich_text_content && (
									<RichTextParser
										className={"rich_editor__container"}
										rich_text={item.intern_rich_text_content}
									/>
								)}
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// courses
	const courses = !isArrayEmpty(courses_items) ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img
					src="/assets/icons/courses-black.png"
					alt="icon"
					className="icon"
				/>

				<div className="title">{courses_title}</div>
			</div>

			<div className="section__contents">
				{courses_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item__header_wrapper">
									<div className="item__header">{item.course_name}</div>

									{checkVariables(
										item.course_start_date,
										item.course_end_date
									) && (
										<div className="item__time">
											<Time
												start={item.course_start_date}
												end={item.course_end_date}
											/>
										</div>
									)}
								</div>

								{item.course_institution && (
									<div className="item__sub_header">
										{item.course_institution}
									</div>
								)}

								{item.course_rich_text_content && (
									<RichTextParser
										className={"rich_editor__container"}
										rich_text={item.course_rich_text_content}
									/>
								)}
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// extra curricular activities
	const activities = !isArrayEmpty(activity_items) ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img
					src="/assets/icons/activities-black.png"
					alt="icon"
					className="icon"
				/>

				<div className="title">{activity_title}</div>
			</div>

			<div className="section__contents">
				{activity_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item__header_wrapper">
									<div className="item__header">{item.activity_function}</div>

									{checkVariables(
										item.activity_start_date,
										item.activity_end_date
									) && (
										<div className="item__time">
											<Time
												start={item.activity_start_date}
												end={item.activity_end_date}
											/>
										</div>
									)}
								</div>

								{checkVariables(item.activity_employer, item.activity_city) && (
									<div className="item__sub_header">
										{item.activity_employer}
										{item.activity_employer && item.activity_city && ", "}
										{item.activity_city}
									</div>
								)}

								{item.activity_rich_text_content && (
									<RichTextParser
										className={"rich_editor__container"}
										rich_text={item.activity_rich_text_content}
									/>
								)}
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// references
	const references = !isArrayEmpty(references_items) ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img
					src="/assets/icons/reference-black.png"
					alt="icon"
					className="icon"
				/>

				<div className="title">{references_title}</div>
			</div>

			{references_hide && (
				<div className="section__contents">
					<div className="item__first">
						<div className="item__header_wrapper">
							<div className="item__header">
								Reference available upon request
							</div>
						</div>
					</div>
				</div>
			)}

			{!references_hide && (
				<div className="section__contents references-container">
					{references_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div
									className={
										index === 0 || index === 1 ? "item item__first" : "item"
									}
									key={index}
									id="reference-wrapper"
								>
									{item.references_name && (
										<div className="item__header_wrapper">
											<div className="item__header">{item.references_name}</div>
										</div>
									)}

									{item.references_company && (
										<div className="item__sub_header">
											{item.references_company}
										</div>
									)}

									{checkVariables(
										item.references_phone,
										item.references_email
									) && (
										<div className="item__sub_header remove-text-transform mt">
											{item.references_phone}
											{item.references_phone && <br />}
											{item.references_email}
										</div>
									)}
								</div>
							);
						})}
				</div>
			)}
		</div>
	) : (
		""
	);

	// custom
	const custom = (specificCustom) =>
		!isArrayEmpty(specificCustom.custom_items) ? (
			<div className="section__wrapper">
				<div className="section__identify">
					<img
						src="/assets/icons/custom-black.png"
						alt="icon"
						className="icon"
					/>

					<div className="title">{specificCustom.custom_title}</div>
				</div>

				<div className="section__contents">
					{specificCustom.custom_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div
									className={index === 0 ? "item item__first" : "item"}
									key={index}
								>
									<div className="item__header_wrapper">
										<div className="item__header">{item.custom_activity}</div>

										{checkVariables(
											item.custom_start_date,
											item.custom_end_date
										) && (
											<div className="item__time">
												<Time
													start={item.custom_start_date}
													end={item.custom_end_date}
												/>
											</div>
										)}
									</div>

									{item.custom_city && (
										<div className="item__sub_header">{item.custom_city}</div>
									)}

									{item.custom_rich_text_content && (
										<RichTextParser
											className={"rich_editor__container"}
											rich_text={item.custom_rich_text_content}
										/>
									)}
								</div>
							);
						})}
				</div>
			</div>
		) : (
			""
		);

	// skills
	const skills = !isArrayEmpty(skills_items) ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img src="/assets/icons/skills.png" alt="icon" className="icon" />

				<div className="title">{skills_title}</div>
			</div>

			<div className="section__contents skills-container">
				{skills_items.map((value, index) => {
					return (
						<div className="skill-wrapper" key={index}>
							<SkillsLevelRound
								title_class="skill_title"
								level_class="skill_level"
								bg_color="#1A1919"
								skill={value.skill}
								skill_level={value.skill_level}
								displaying_level={displaying_level}
							/>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// languages
	const languages = !isArrayEmpty(languages_items) ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img src="/assets/icons/languages.png" alt="icon" className="icon" />

				<div className="title">{languages_title}</div>
			</div>

			<div className="section__contents skills-container">
				{languages_items.map((value, index) => {
					return (
						<div className="skill-wrapper" key={index}>
							<SkillsLevelRound
								title_class="skill_title"
								level_class="skill_level"
								bg_color="#1A1919"
								skill={value.languages_name}
								skill_level={value.languages_level}
							/>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	//  hobbies
	const hobbies = hobbies_description ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img src="/assets/icons/hobbies.png" alt="icon" className="icon" />

				<div className="title">{hobbies_title}</div>
			</div>

			<div className="section__contents">
				<div className="hobbies-description">{hobbies_description}</div>
			</div>
		</div>
	) : (
		""
	);

	//  disclaimer
	const disclaimer = disclaimer_description ? (
		<div className="section__wrapper">
			<div className="section__identify">
				<img
					src="/assets/icons/disclaimer-black.png"
					alt="icon"
					className="icon"
				/>

				<div className="title">{disclaimer_title}</div>
			</div>

			<div className="section__contents">
				<div className="hobbies-description">{disclaimer_description}</div>
			</div>
		</div>
	) : (
		""
	);

	// for displaying additional section start
	const additionalSection = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Courses") {
			storeAdditionalSection.push(courses);
		} else if (value.key === "Extra-curricular Activities") {
			storeAdditionalSection.push(activities);
		} else if (value.key === "Internship") {
			storeAdditionalSection.push(internship);
		} else if (value.key === "Custom") {
			customArr.map(
				(item) =>
					item.cus_section_id === value._id &&
					storeAdditionalSection.push(custom(item))
			);
		}

		return storeAdditionalSection;
	});
	// for displaying additional section end

	// body array
	const bodyArr = [
		professional_summary,
		employment_history,
		projects,
		education,
		...additionalSection,
		skills,
		languages,
		hobbies,
		references,
		disclaimer
	];

	return (
		<>
			<style>{ProfessionalResumeStyle}</style>

			<div className="container">
				{/* top_header start  */}
				<div className="top_header">
					<div className="top_header__left">
						<div className="img-container">
							<img
								src={`/assets/profile-img/${
									cropped_profile_img
										? cropped_profile_img
										: "default-profile.png"
								}`}
								alt="profile-img"
								className="profile-img"
							/>
						</div>
					</div>

					<div className="top_header__right">
						<div className="left-section">
							{checkVariables(first_name, last_name) && (
								<div className="name">
									{first_name} &nbsp; {last_name}
								</div>
							)}

							{job_title && <div className="job-title">{job_title}</div>}

							<div className="left-section__additional">
								{/* phone start  */}
								{phone && (
									<div className="personal-details-content">
										<b>Phone:</b> {phone}
									</div>
								)}
								{/* phone end  */}

								{/* email start  */}
								{email && (
									<div className="personal-details-content">
										<b>Email:</b> {email}
									</div>
								)}
								{/* email end  */}

								{/* nid start  */}
								{nid && (
									<div className="personal-details-content">
										<b>{isNaN(nid) ? "Passport:" : "NID:"}</b> {nid}
									</div>
								)}
								{/* nid end  */}

								{/* driving_license start  */}
								{driving_license && (
									<div className="personal-details-content">
										<b>Driving License:</b> {driving_license}
									</div>
								)}
								{/* driving_license end  */}

								{/* date_of_birth start  */}
								{date_of_birth && (
									<div className="personal-details-content">
										<b>Date Of Birth:</b> {date_of_birth}
									</div>
								)}
								{/* date_of_birth end  */}
							</div>
						</div>

						<div className="right-section">
							{/* present_address start */}
							{checkVariables(
								present_address,
								present_city,
								present_area,
								present_zip
							) && (
								<div className="personal-details-section">
									<div className="personal-details-content">
										<b>Present Address</b> <br />
										{present_address}
										{present_address && present_city && `, ${present_city}`}
										{present_area && `, ${present_area}`}
										{present_zip && `, ${present_zip}`}
										{!checkVariables(
											permanent_address,
											permanent_city,
											permanent_area,
											permanent_zip
										) &&
											country &&
											`, ${country}`}
									</div>
								</div>
							)}
							{/* present_address end */}

							{/* permanent_address start */}
							{checkVariables(
								permanent_address,
								permanent_city,
								permanent_area,
								permanent_zip,
								same_address
							) && (
								<div className="personal-details-section">
									<div className="personal-details-content">
										<b>Permanent Address</b> <br />
										{same_address ? (
											<div>Same as above</div>
										) : (
											<div>
												{permanent_address}
												{permanent_address &&
													permanent_city &&
													`, ${permanent_city}`}

												{permanent_area && `, ${permanent_area}`}

												{permanent_zip && `, ${permanent_zip}`}

												{country && `, ${country}`}
											</div>
										)}
									</div>
								</div>
							)}
							{/* permanent_address end */}

							{/* nationality start  */}
							{nationality && (
								<div className="personal-details-section">
									<div className="personal-details-content">
										<b>Nationality</b> <br />
										{nationality}
									</div>
								</div>
							)}
							{/* nationality end  */}

							{/* place_of_birth start  */}
							{place_of_birth && (
								<div className="personal-details-section">
									<div className="personal-details-content">
										<b>Place Of Birth</b> <br />
										{place_of_birth}
									</div>
								</div>
							)}
							{/* place_of_birth end  */}
						</div>
					</div>
				</div>
				{/* top_header end  */}

				{/* body start  */}
				<div className="body">
					{bodyArr
						.filter((item) => item !== "")
						.map((value, index) => (
							<div key={index} className={index === 0 ? "first-section" : ""}>
								{value}
							</div>
						))}
				</div>
				{/* body end  */}
			</div>
		</>
	);
};

export default ProfessionalResume;
