// external components
import { toast } from "react-hot-toast";

const addOneMoreItem = async (
	userId,
	counter,
	section,
	item,
	newArr,
	controlActiveId,
	setArrCom,
	itemCom,
	setResume
) => {
	try {
		const response = await fetch(
			`/resume/add-one-more-item?user=${userId}&counter=${counter}&section=${section}&item=${item}`,
			{
				method: "PUT",
				body: JSON.stringify(newArr),
				headers: {
					"Content-Type": "application/json"
				}
			}
		);

		const result = await response.json();

		if (response.status === 200) {
			if (controlActiveId.activeId === -1) {
				controlActiveId.setActiveId(result._id);

				section === "projects"
					? setArrCom((prev) => [
							...prev,
							<itemCom.itemComponent
								itemValues={result}
								handleSetActive={controlActiveId.handleSetActive}
								updateResumeInfo={itemCom.updateResumeInfo}
								setDeleteItem={itemCom.setDeleteItem}
								contents={itemCom.contents}
								getResume={itemCom.getResume}
								setResume={setResume}
								delay={itemCom.delay}
							/>
					  ])
					: section === "skills" && newArr?.skill
					? setArrCom((prev) => [
							<itemCom.itemComponent
								itemValues={result}
								handleSetActive={controlActiveId.handleSetActive}
								updateResumeInfo={itemCom.updateResumeInfo}
								setDeleteItem={itemCom.setDeleteItem}
								contents={itemCom.contents}
								delay={itemCom.delay}
							/>,
							...prev
					  ])
					: section === "custom"
					? setArrCom((prev) => [
							...prev,
							<itemCom.itemComponent
								itemValues={result}
								allPhrasesRef={
									itemCom?.allPhrasesRef ? itemCom.allPhrasesRef : undefined
								}
								handleSetActive={controlActiveId.handleSetActive}
								updateResumeInfo={itemCom.updateResumeInfo}
								setDeleteItem={itemCom.setDeleteItem}
								contents={itemCom.contents}
								delay={itemCom.delay}
								cus_section_id={item}
							/>
					  ])
					: setArrCom((prev) => [
							...prev,
							<itemCom.itemComponent
								itemValues={result}
								allPhrasesRef={
									itemCom?.allPhrasesRef ? itemCom.allPhrasesRef : undefined
								}
								handleSetActive={controlActiveId.handleSetActive}
								updateResumeInfo={itemCom.updateResumeInfo}
								setDeleteItem={itemCom.setDeleteItem}
								contents={itemCom.contents}
								delay={itemCom.delay}
							/>
					  ]);
			} else {
				controlActiveId.setActiveId(-1);

				setTimeout(() => {
					controlActiveId.setActiveId(result._id);

					section === "projects"
						? setArrCom((prev) => [
								...prev,
								<itemCom.itemComponent
									itemValues={result}
									handleSetActive={controlActiveId.handleSetActive}
									updateResumeInfo={itemCom.updateResumeInfo}
									setDeleteItem={itemCom.setDeleteItem}
									contents={itemCom.contents}
									getResume={itemCom.getResume}
									setResume={setResume}
									delay={itemCom.delay}
								/>
						  ])
						: section === "skills" && newArr?.skill
						? setArrCom((prev) => [
								<itemCom.itemComponent
									itemValues={result}
									handleSetActive={controlActiveId.handleSetActive}
									updateResumeInfo={itemCom.updateResumeInfo}
									setDeleteItem={itemCom.setDeleteItem}
									contents={itemCom.contents}
									delay={itemCom.delay}
								/>,
								...prev
						  ])
						: section === "custom"
						? setArrCom((prev) => [
								...prev,
								<itemCom.itemComponent
									itemValues={result}
									allPhrasesRef={
										itemCom?.allPhrasesRef ? itemCom.allPhrasesRef : undefined
									}
									handleSetActive={controlActiveId.handleSetActive}
									updateResumeInfo={itemCom.updateResumeInfo}
									setDeleteItem={itemCom.setDeleteItem}
									contents={itemCom.contents}
									delay={itemCom.delay}
									cus_section_id={item}
								/>
						  ])
						: setArrCom((prev) => [
								...prev,
								<itemCom.itemComponent
									itemValues={result}
									allPhrasesRef={
										itemCom?.allPhrasesRef ? itemCom.allPhrasesRef : undefined
									}
									handleSetActive={controlActiveId.handleSetActive}
									updateResumeInfo={itemCom.updateResumeInfo}
									setDeleteItem={itemCom.setDeleteItem}
									contents={itemCom.contents}
									delay={itemCom.delay}
								/>
						  ]);
				}, 600);
			}

			if (section !== "custom") {
				setResume((prev) => ({
					...prev,
					[section]: {
						...prev[section],
						[item]:
							section === "skills" && newArr?.skill
								? [result, ...prev[section][item]]
								: [...prev[section][item], result]
					}
				}));
			} else {
				setResume((prev) => {
					const updatedCustom = prev.custom.map((customItem) => {
						if (customItem.cus_section_id === item) {
							return {
								...customItem,
								custom_items: [...customItem.custom_items, result]
							};
						}
						return customItem;
					});

					return {
						...prev,
						custom: updatedCustom
					};
				});
			}
		} else {
			toast.error("Try again", {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	} catch (error) {
		toast.error(error.message, {
			position: "top-right",
			autoClose: 2500,
			theme: "colored"
		});
	}
};

export default addOneMoreItem;
