// external components
import { useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// internal components
import AdminContent from "../../components/AdminContent";
import AdminCouponContents from "../../components/AdminCouponContents/AdminCouponContents";
import AdminDashboardContents from "../../components/AdminDashboardContents/AdminDashboardContents";
import AdminMessages from "../../components/AdminMessages/AdminMessages";
import AdminPackages from "../../components/AdminPackages/AdminPackages";
import AdminPhrasesContents from "../../components/AdminPhrasesContents/AdminPhrasesContents";
import AdminStudentRequest from "../../components/AdminStudentRequest/AdminStudentRequest";
import AdminUsersContents from "../../components/AdminUsersContents/AdminUsersContents";
import "./AdminDashboard.css";
import Logout from "./Logout";

const AdminDashboard = () => {
	// for redirect log-in page
	const Navigate = useNavigate();

	// for authentication checking and getting adminAccount start
	const [adminAccount, setAdminAccount] = useState("");

	useEffect(() => {
		(async () => {
			try {
				const response = await fetch("/admin/get-admin");

				const result = await response.json();

				if (result.error) {
					setAdminAccount("");
					Navigate("/admin/log-in");
				} else if (response.status === 200) {
					setAdminAccount(result ? result : "");
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setAdminAccount("");
				Navigate("/admin/log-in");
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for authentication checking and getting adminAccount end

	// control which tab section is active start
	const initialState = {
		dashboard: true,
		users: false,
		phrases: false,
		coupon: false,
		package: false,
		messages: false,
		content: false,
		student_request: false
	};

	const allFalsy = {
		dashboard: false,
		users: false,
		phrases: false,
		coupon: false,
		package: false,
		messages: false,
		content: false,
		student_request: false
	};

	const reducerFunction = (state, { type }) => {
		if (type === "dashboard") {
			return { ...allFalsy, dashboard: true };
		} else if (type === "users") {
			return { ...allFalsy, users: true };
		} else if (type === "phrases") {
			return { ...allFalsy, phrases: true };
		} else if (type === "coupon") {
			return { ...allFalsy, coupon: true };
		} else if (type === "package") {
			return { ...allFalsy, package: true };
		} else if (type === "messages") {
			return { ...allFalsy, messages: true };
		} else if (type === "content") {
			return { ...allFalsy, content: true };
		} else if (type === "student_request") {
			return { ...allFalsy, student_request: true };
		} else {
			return state;
		}
	};

	const [selector, dispatch] = useReducer(reducerFunction, initialState);
	// control which tab section is active end

	// for reload
	const [isReload, setIsReload] = useState(1);

	// for getting all users start
	const [allUsers, setAllUsers] = useState([]);

	// loading until fetching is not complete
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				const response = await fetch(`/admin/all-users`);

				const result = await response.json();

				if (response.status === 200) {
					setAllUsers(result);
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		})();
	}, [isReload]);
	// for getting all users end

	// for getting all resumes start
	const [allResumes, setAllResumes] = useState([]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const response = await fetch(`/admin/all-resumes`);

				const result = await response.json();

				if (response.status === 200) {
					setAllResumes(result);
					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});
					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		})();
	}, [isReload]);
	// for getting all resumes end

	// for option toggle
	const [optionT, setOptionT] = useState(false);

	// for hidden navbar when click outside start
	const myOption = useRef();

	const handleClickOutsideOp = (e) => {
		if (!myOption.current?.contains(e.target) && optionT) {
			setOptionT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideOp);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideOp);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionT]);
	// for hidden navbar when click outside end

	// for logout toggle
	const [isLogoutT, setIsLogoutT] = useState(false);

	// for change password toggle pop-up
	const [changePasswordT, setChangePasswordT] = useState(false);

	// for border animation
	const [isActiveChangePass, setIsActiveChangePass] = useState(false);

	// for getting admin-password
	const [getPassword, setPassword] = useState("");

	// for password eye toggle
	const [eyeTP, setEyeTP] = useState("");

	// changePasswordHandler start
	const changePasswordHandler = async () => {
		setIsLoading(true);
		try {
			const response = await fetch("/admin/change-password", {
				method: "POST",
				body: JSON.stringify({ password: getPassword }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});

				setIsLoading(false);
				setPassword("");
				setChangePasswordT(false);
			} else if (response.status === 400) {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setIsLoading(false);
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setIsLoading(false);
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
			setIsLoading(false);
		}
	};
	// changePasswordHandler end

	return (
		<>
			<div className="admin-dashboard-navbar">
				<div
					className="title"
					onClick={() => {
						Navigate("/");
					}}
				>
					<img src="/assets/images/main-logo.svg" alt="logo" />
				</div>

				<div className="right-section-container">
					{isLoading ? (
						<i className="fa-solid fa-spinner fa-spin"></i>
					) : (
						<i
							className="fa-solid fa-rotate"
							onClick={() => setIsReload(isReload + 1)}
						></i>
					)}

					<div className="img-container-admin" ref={myOption}>
						<img
							src="/assets/profile-img/default-profile.png"
							alt="profile-img"
							onClick={() => setOptionT(!optionT)}
						/>

						{optionT && (
							<div className="option-container" data-aos="fade-down">
								<ul>
									<li
										onClick={() => {
											setOptionT(false);
											setChangePasswordT(true);
										}}
									>
										Change Password
									</li>
									<li
										onClick={() => {
											setOptionT(false);
											dispatch({ type: "dashboard" });
										}}
									>
										Dashboard
									</li>
									<li
										onClick={() => {
											setOptionT(false);
											dispatch({ type: "users" });
										}}
									>
										Users
									</li>
									<li
										onClick={() => {
											setOptionT(false);
											dispatch({ type: "phrases" });
										}}
									>
										Phrases
									</li>

									<li
										onClick={() => {
											setOptionT(false);
											dispatch({ type: "coupon" });
										}}
									>
										Coupon
									</li>
									<li
										onClick={() => {
											setOptionT(false);
											dispatch({ type: "package" });
										}}
									>
										Packages
									</li>

									<li
										onClick={() => {
											setOptionT(false);
											dispatch({ type: "messages" });
										}}
									>
										Messages
									</li>

									<li
										onClick={() => {
											setOptionT(false);
											dispatch({ type: "student_request" });
										}}
									>
										Student Request
									</li>

									{/* <li
										onClick={() => {
											setOptionT(false);
											dispatch({ type: "content" });
										}}
									>
										Content
									</li> */}

									<li
										onClick={() => {
											setOptionT(false);
											setIsLogoutT(true);
										}}
									>
										Log Out
									</li>
								</ul>
							</div>
						)}
					</div>
				</div>

				{/* for admin-change password start  */}
				{changePasswordT && (
					<div className="change-password-container">
						<div className="change-password-wrapper" data-aos="fade-down">
							<div className="top-section">
								<h3>Change Admin Password</h3>
							</div>

							{/* email start  */}
							<div className="input-field" id="for-email">
								<input value={adminAccount.email} readOnly />
							</div>
							{/* email end  */}

							{/* password start  */}
							<div
								className="input-field"
								id={isActiveChangePass ? "active" : ""}
							>
								<span id="required">
									Minimum 8 digits &nbsp; <span id="important">*</span>
								</span>
								<input
									type={eyeTP ? "text" : "password"}
									onFocus={() => setIsActiveChangePass(true)}
									onBlur={() => {
										setIsActiveChangePass(false);
									}}
									onChange={(e) => setPassword(e.target.value)}
									value={getPassword}
									placeholder="Password . . ."
								/>

								{getPassword &&
									(eyeTP ? (
										<i
											className="fa-solid fa-eye"
											onClick={() => setEyeTP(!eyeTP)}
										></i>
									) : (
										<i
											className="fa-solid fa-eye-slash"
											onClick={() => setEyeTP(!eyeTP)}
										></i>
									))}
							</div>
							{/* password end  */}

							{/* btn-container start  */}
							<div className="btn-container">
								<button
									onClick={() => {
										setChangePasswordT(false);
										setPassword("");
									}}
								>
									Cancel
								</button>
								<button
									onClick={() =>
										getPassword &&
										getPassword.length >= 8 &&
										changePasswordHandler()
									}
									id={getPassword && getPassword.length >= 8 ? "" : "active"}
								>
									{isLoading ? "Changing" : "Change"}
								</button>
							</div>
							{/* btn-container end  */}
						</div>
					</div>
				)}
				{/* for admin-change password end  */}
			</div>

			<div className="admin-dashboard-container">
				<div className="left">
					<div className="navigation">
						<ul>
							<li
								className={`list ${selector.dashboard ? "active" : ""}`}
								onClick={() => dispatch({ type: "dashboard" })}
							>
								<div className="section">
									<span className="icon">
										<i className="fa-solid fa-braille" id="icon-style"></i>
									</span>
									<span className="title">Dashboard</span>
								</div>
							</li>

							<li
								className={`list ${selector.users ? "active" : ""}`}
								onClick={() => dispatch({ type: "users" })}
							>
								<div className="section">
									<span className="icon">
										<i className="fa-solid fa-user-group" id="icon-style"></i>
									</span>
									<span className="title">Users</span>
								</div>
							</li>

							<li
								className={`list ${selector.phrases ? "active" : ""}`}
								onClick={() => dispatch({ type: "phrases" })}
							>
								<div className="section">
									<span className="icon">
										<i className="fa-solid fa-align-left" id="icon-style"></i>
									</span>
									<span className="title">Phrases</span>
								</div>
							</li>

							<li
								className={`list ${selector.coupon ? "active" : ""}`}
								onClick={() => dispatch({ type: "coupon" })}
							>
								<div className="section">
									<span className="icon">
										<i className="fa-solid fa-ticket" id="icon-style"></i>
									</span>
									<span className="title">Coupon</span>
								</div>
							</li>

							<li
								className={`list ${selector.package ? "active" : ""}`}
								onClick={() => dispatch({ type: "package" })}
							>
								<div className="section">
									<span className="icon">
										<i className="fa-solid fa-tags" id="icon-style"></i>
									</span>
									<span className="title">Packages</span>
								</div>
							</li>

							<li
								className={`list ${selector.messages ? "active" : ""}`}
								onClick={() => dispatch({ type: "messages" })}
							>
								<div className="section">
									<span className="icon">
										<i className="fa-regular fa-envelope" id="icon-style"></i>
									</span>
									<span className="title">Messages</span>
								</div>
							</li>

							<li
								className={`list ${selector.student_request ? "active" : ""}`}
								onClick={() => dispatch({ type: "student_request" })}
							>
								<div className="section">
									<span className="icon">
										<i
											className="fa-solid fa-graduation-cap"
											id="icon-style"
										></i>
									</span>
									<span className="title">Student Request</span>
								</div>
							</li>

							{/* <li
								className={`list ${selector.content ? "active" : ""}`}
								onClick={() => dispatch({ type: "content" })}
							>
								<div className="section">
									<span className="icon">
										<i className="bi bi-body-text" id="icon-style"></i>
									</span>
									<span className="title">Content</span>
								</div>
							</li> */}
						</ul>
					</div>
				</div>

				<div className="right">
					{selector.dashboard && (
						<AdminDashboardContents
							isLoading={isLoading}
							allResumes={allResumes}
							allUsers={allUsers}
						/>
					)}
					{selector.users && (
						<AdminUsersContents
							isLoading={isLoading}
							allResumes={allResumes}
							allUsers={allUsers}
						/>
					)}
					{selector.phrases && <AdminPhrasesContents />}
					{selector.coupon && <AdminCouponContents />}

					{/* letter  */}
					{selector.package && <AdminPackages />}
					{selector.messages && <AdminMessages />}
					{selector.content && <AdminContent />}
					{selector.student_request && (
						<AdminStudentRequest allUsers={allUsers} />
					)}
				</div>
				{/* for logout */}
				{isLogoutT && <Logout />}
			</div>
		</>
	);
};

export default AdminDashboard;
