// external component
import dayjs from "dayjs";

const Time = ({ start, end, fromWhere, isDisplayColumn }) => {
	const format = fromWhere === "education" ? "YYYY" : "MMM YYYY";

	const time = isDisplayColumn ? (
		<>
			<div>{start ? dayjs(start).format(format) : ""}</div>
			<div>
				{end ? (end === "Present" ? "Present" : dayjs(end).format(format)) : ""}
			</div>
		</>
	) : (
		<>
			{start ? dayjs(start).format(format) : ""}
			{start && end && " - "}
			{end ? (end === "Present" ? "Present" : dayjs(end).format(format)) : ""}
		</>
	);

	return time;
};

export default Time;
