// external components
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// internal components
import { ShapeAnimation } from "../../common";
import { isValidateEmail } from "../../common/function";
import "./AdminLoginFields.css";

const AdminLoginFields = () => {
	// for redirect sign-up page
	const Navigate = useNavigate();

	// for loading until didn't submit on server
	const [isLoading, setIsLoading] = useState(false);

	// for getting input-fields values
	const [getEmail, setEmail] = useState("");
	const [getPassword, setPassword] = useState("");

	// for password eye toggle
	const [eyeTP, setEyeTP] = useState(false);

	// for checking button clicked for submit or not
	const [btnClicked, setBtnClicked] = useState(false);

	// login form submit on server start
	const submitHandler = async () => {
		if (getPassword && isValidateEmail(getEmail)) {
			try {
				setIsLoading(true);

				const adminObj = {
					email: getEmail,
					password: getPassword
				};

				const response = await fetch("/admin/log-in", {
					method: "POST",
					body: JSON.stringify(adminObj),
					headers: {
						"Content-Type": "application/json"
					}
				});

				const result = await response.json();

				if (response.status === 200) {
					setIsLoading(false);
					setPassword("");
					setEyeTP(false);
					setBtnClicked(false);

					setEmail("");
					Navigate("/admin/dashboard");
				} else if (response.status === 400) {
					toast.error(result.error, {
						position: "top-right",
						theme: "dark",
						autoClose: 2500
					});
					setIsLoading(false);
				} else {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 2500
					});
					setIsLoading(false);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
				setIsLoading(false);
			}
		}
	};
	// login form submit on server end

	return (
		<>
			<div className="admin-log-in-form">
				<div className="wrapper">
					<span id="title">Sign in admin account</span>

					<div className="input-fields">
						<div className="field">
							{btnClicked && !getEmail && (
								<p id="error-msg">E-mail address is required *</p>
							)}

							{btnClicked && getEmail && !isValidateEmail(getEmail) && (
								<p id="error-msg">Please enter valid e-mail address *</p>
							)}

							<label htmlFor="email">Email ID*</label>

							<input
								type="email"
								placeholder="Your E-mail . . ."
								id={btnClicked && !getEmail ? "not-fill" : "email"}
								onChange={(e) => setEmail(e.target.value)}
								value={getEmail}
							/>
						</div>

						<div className="field" id="when-password">
							{btnClicked && !getPassword && (
								<p id="error-msg">Password is required *</p>
							)}

							<label htmlFor="password">Password*</label>

							<input
								type={eyeTP ? "text" : "password"}
								placeholder="Password . . ."
								id={btnClicked && !getPassword ? "not-fill" : "password"}
								onChange={(e) => setPassword(e.target.value)}
								value={getPassword}
							/>

							{getPassword &&
								(eyeTP ? (
									<i
										className="fa-solid fa-eye"
										onClick={() => setEyeTP(!eyeTP)}
									></i>
								) : (
									<i
										className="fa-solid fa-eye-slash"
										onClick={() => setEyeTP(!eyeTP)}
									></i>
								))}
						</div>

						<button
							onClick={() => {
								setBtnClicked(true);
								submitHandler();
							}}
						>
							{isLoading ? "Logging In . . ." : "Log In"}
						</button>
					</div>
				</div>

				{/* shapes animation component   */}
				<ShapeAnimation />
			</div>
		</>
	);
};

export default AdminLoginFields;
