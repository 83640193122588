const SkillsLevelRound = ({
	skill,
	skill_level,
	bg_color,
	displaying_level,
	title_class,
	level_class,
	isNeed10,
	isNeedPercentage,
	isDisplayLevel,
	activeImg,
	inactiveImg
}) => {
	let classes = [];
	if (
		skill_level === "Novice" ||
		skill_level === "Working knowledge" ||
		skill_level === "A1"
	) {
		classes = ["active", "inactive", "inactive", "inactive", "inactive"];
	} else if (
		skill_level === "Beginner" ||
		skill_level === "Good working knowledge" ||
		skill_level === "A2"
	) {
		classes = ["active", "active", "inactive", "inactive", "inactive"];
	} else if (
		skill_level === "Skillful" ||
		skill_level === "Very good command" ||
		skill_level === "B1"
	) {
		classes = ["active", "active", "active", "inactive", "inactive"];
	} else if (
		skill_level === "Experienced" ||
		skill_level === "Highly proficient" ||
		skill_level === "B2"
	) {
		classes = ["active", "active", "active", "active", "inactive"];
	} else if (
		skill_level === "Expert" ||
		skill_level === "Native speaker" ||
		skill_level === "C1" ||
		skill_level === "C2"
	) {
		classes = ["active", "active", "active", "active", "active"];
	}

	const calculatePercentage = (arr) => {
		let totalPercentage = 0;

		arr.forEach((classItem) => {
			if (classItem === "active") {
				totalPercentage += 20;
			}
		});

		return totalPercentage;
	};

	return (
		<>
			<div className={title_class}>
				{skill} {isNeedPercentage && `( ${calculatePercentage(classes)}% )`}
			</div>

			{isDisplayLevel && <div className={isDisplayLevel}>{skill_level}</div>}

			{!displaying_level ? (
				<div className={level_class}>
					{classes.length > 0 && skill && isNeed10
						? classes.flatMap((item, index) => {
								return [
									<div className={item} key={index + "_1"} />,
									<div className={item} key={index + "_2"} />
								];
						  })
						: classes.map((item, index) => {
								return (
									<div className={item} key={index}>
										{activeImg && inactiveImg ? (
											<img
												src={`/assets/icons/${
													item === "active" ? activeImg : inactiveImg
												}`}
												alt="icon"
												style={{ width: "100%", height: "100%" }}
											/>
										) : null}
									</div>
								);
						  })}
				</div>
			) : (
				""
			)}
		</>
	);
};

export default SkillsLevelRound;
