// external components

// internal components
import "./Editor.css";

const Editor = () => {
	return (
		<>
			<div className="editor-container">resume editor</div>
		</>
	);
};

export default Editor;
