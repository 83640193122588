// external components
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import TextareaAutosize from "react-textarea-autosize";
import sortArray from "sort-array";

// internal components
import TableSkeleton from "../TableSkeleton";
import CategoriesDropdown from "./CategoriesDropdown/CategoriesDropdown";
import "./Phrases.css";

const Phrases = ({ isLoading, phrases, setIsUpdatePhrases, categories }) => {
	// for getting selected category
	const [getCategory, setCategory] = useState("");

	// for getting specific phrase's all keys
	const [keys, setKeys] = useState("");

	// for creating specific phrase
	const [createPhrases, setCreatePhrases] = useState([]);

	// for input-field active animation toggle
	const [isActiveCategory, setIsActiveCategory] = useState(false);
	const [isActiveKeys, setIsActiveKeys] = useState(false);

	// for adding a new phrases start
	const addNewPhrase = async () => {
		try {
			const response = await fetch(`/phrases/insert-new-phrase`, {
				method: "POST",
				body: JSON.stringify({
					category: getCategory,
					keys,
					phrases: createPhrases
				}),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				setCreatePhrases([]);
				setKeys("");
				setCategory("");
				setIsUpdatePhrases(Date.now());
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for adding a new phrases end

	// for updating specific phrase start
	const [updatePhrase, setUpdatePhrase] = useState("");

	useEffect(() => {
		if (updatePhrase) {
			setCategory(updatePhrase.category);
			setKeys(updatePhrase.keys.join("; "));
			setCreatePhrases(updatePhrase.phrases);
		}
	}, [updatePhrase]);

	const updateSpecificPhrase = async () => {
		try {
			const response = await fetch(`/phrases/update-phrase`, {
				method: "PUT",
				body: JSON.stringify({
					_id: updatePhrase._id,
					category: getCategory,
					keys,
					phrases: createPhrases
				}),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				setIsUpdatePhrases(Date.now());
				setUpdatePhrase("");
				setCreatePhrases([]);
				setKeys("");
				setCategory("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for updating specific phrase end

	// for deleting specific phrase start
	const [deletePhrase, setDeletePhrase] = useState("");

	useEffect(() => {
		if (deletePhrase) {
			setCategory(deletePhrase.category);
			setKeys(deletePhrase.keys.join("; "));
			setCreatePhrases(deletePhrase.phrases);
		}
	}, [deletePhrase]);

	const deletePhraseHandler = async () => {
		try {
			const response = await fetch(`/phrases/delete-phrase`, {
				method: "DELETE",
				body: JSON.stringify({ _id: deletePhrase._id }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				setIsUpdatePhrases(Date.now());
				setDeletePhrase("");
				setCreatePhrases([]);
				setKeys("");
				setCategory("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for deleting specific phrase end

	return (
		<>
			<div className="phrases-container">
				{/* phrases list start  */}
				<div className="phrases-list">
					<div className="header">
						<h5>All Phrases</h5>

						<button
							type="button"
							className="btn"
							onClick={() => setCreatePhrases([null])}
						>
							<i className="fa-solid fa-plus"></i> Add New Phrase
						</button>
					</div>
					{isLoading ? (
						<TableSkeleton />
					) : (
						<div className="table-container">
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col">#</th>

										<th scope="col">Category</th>

										<th scope="col">Keys</th>

										<th scope="col" style={{ width: "55%" }}>
											Phrases
										</th>

										<th scope="col">Action</th>
									</tr>
								</thead>
								<tbody>
									{phrases.length > 0 &&
										sortArray(phrases, {
											by: "category"
										}).map((value, index) => {
											return (
												<tr key={index}>
													<td id="id">
														<span>{index + 1}</span>
													</td>

													<td>
														<input readOnly value={value?.category} />
													</td>

													<td>
														<textarea
															rows={2}
															value={value?.keys.join(";  ")}
															readOnly
														/>
													</td>

													<td>
														<div className="phrases-fields">
															{value?.phrases.length > 0 &&
																value.phrases.map((phrase, index) => {
																	return (
																		<textarea
																			key={index}
																			readOnly
																			value={phrase.trim()}
																			rows={2}
																		></textarea>
																	);
																})}
														</div>
													</td>

													<td>
														<div className="btn-container">
															<button
																className="btn"
																onClick={() => setUpdatePhrase(value)}
															>
																<i className="fa-solid fa-pencil"></i> Edit
															</button>

															<button
																className="btn"
																onClick={() => setDeletePhrase(value)}
															>
																<i className="fa-solid fa-trash-can"></i> Delete
															</button>
														</div>
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					)}
				</div>
				{/* phrases list end  */}

				{/* for add new phrase start  */}
				{!updatePhrase && !deletePhrase && createPhrases.length > 0 && (
					<div className="add-new-phrase-container">
						<div
							className="add-new-phrase-wrapper"
							data-aos="fade-down"
							id="when-add-new"
						>
							<div className="top-section">
								<h3>Add New Phrase</h3>
							</div>

							<div
								className="input-field"
								id={isActiveCategory ? "active" : ""}
							>
								<CategoriesDropdown
									setIsActiveCategory={setIsActiveCategory}
									getCategory={getCategory}
									setCategory={setCategory}
									categories={categories}
									forWhat="add"
								/>
							</div>

							<div
								className="input-field phrase-keys"
								id={isActiveKeys ? "active" : ""}
							>
								<TextareaAutosize
									onFocus={() => setIsActiveKeys(true)}
									onBlur={() => {
										setIsActiveKeys(false);
									}}
									onChange={(e) => setKeys(e.target.value)}
									value={keys}
									placeholder="Keys  . . ."
								/>
							</div>

							<div className="new-phrases-container">
								<span id="phrase-text">
									{createPhrases.length === 1 ? "Phrase" : "Phrases"}
								</span>

								<div className="phrase-text-wrapper">
									{createPhrases.map((value, index) => {
										return (
											<div className="input-field" key={index}>
												<TextareaAutosize
													onChange={(e) =>
														setCreatePhrases((prevArr) => {
															const result = [...prevArr];
															result[index] = e.target.value;
															return result;
														})
													}
													placeholder="Phrase  . . ."
													value={createPhrases[index]}
												/>
											</div>
										);
									})}
								</div>

								{
									<span
										id="add-more"
										onClick={() => setCreatePhrases([...createPhrases, null])}
									>
										<i className="fa-solid fa-plus"></i> Add Another Phrase
									</span>
								}
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setCreatePhrases([]);
										setCategory("");
										setKeys("");
									}}
								>
									Cancel
								</button>
								<button
									onClick={() =>
										getCategory &&
										keys &&
										createPhrases.filter(Boolean).length > 0 &&
										addNewPhrase()
									}
									id={
										getCategory &&
										keys &&
										createPhrases.filter(Boolean).length > 0
											? ""
											: "disable-btn"
									}
								>
									Submit
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for add new phrase  end */}

				{/* for update specific phrase start  */}
				{updatePhrase && (
					<div className="add-new-phrase-container">
						<div
							className="add-new-phrase-wrapper"
							data-aos="fade-down"
							id="when-add-new"
						>
							<div className="top-section">
								<h3>Update A Phrase</h3>
							</div>

							<div
								className="input-field"
								id={isActiveCategory ? "active" : ""}
							>
								<CategoriesDropdown
									setIsActiveCategory={setIsActiveCategory}
									getCategory={getCategory}
									setCategory={setCategory}
									categories={categories}
									forWhat="update"
								/>
							</div>

							<div
								className="input-field phrase-keys"
								id={isActiveKeys ? "active" : ""}
							>
								<TextareaAutosize
									onFocus={() => setIsActiveKeys(true)}
									onBlur={() => {
										setIsActiveKeys(false);
									}}
									onChange={(e) => setKeys(e.target.value)}
									value={keys}
									placeholder="Keys  . . ."
								/>
							</div>

							<div className="new-phrases-container">
								<span id="phrase-text">
									{createPhrases.length === 1 ? "Phrase" : "Phrases"}
								</span>

								<div className="phrase-text-wrapper">
									{createPhrases.map((value, index) => {
										return (
											<div className="input-field" key={index}>
												<TextareaAutosize
													onChange={(e) =>
														setCreatePhrases((prevArr) => {
															const result = [...prevArr];
															result[index] = e.target.value;
															return result;
														})
													}
													placeholder="Phrase  . . ."
													value={createPhrases[index]}
												/>
											</div>
										);
									})}
								</div>

								{
									<span
										id="add-more"
										onClick={() => setCreatePhrases([...createPhrases, null])}
									>
										<i className="fa-solid fa-plus"></i> Add Another Phrase
									</span>
								}
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setUpdatePhrase("");
										setCreatePhrases([]);
										setCategory("");
										setKeys("");
									}}
								>
									Cancel
								</button>
								<button
									onClick={() =>
										getCategory &&
										keys &&
										createPhrases.filter(Boolean).length > 0 &&
										updateSpecificPhrase()
									}
									id={
										getCategory &&
										keys &&
										createPhrases.filter(Boolean).length > 0
											? ""
											: "disable-btn"
									}
								>
									Update
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for update specific phrase  end */}

				{/* for delete specific phrase start  */}
				{deletePhrase && (
					<div className="add-new-phrase-container">
						<div
							className="add-new-phrase-wrapper"
							data-aos="fade-down"
							id="when-add-new"
						>
							<div className="top-section">
								<h3>Delete A Phrase</h3>
							</div>

							<div
								className="input-field"
								id={isActiveCategory ? "active" : ""}
							>
								<CategoriesDropdown
									setIsActiveCategory={setIsActiveCategory}
									getCategory={getCategory}
									setCategory={setCategory}
									categories={categories}
									forWhat="delete"
								/>
							</div>

							<div
								className="input-field phrase-keys"
								id={isActiveKeys ? "active" : ""}
							>
								<TextareaAutosize
									onFocus={() => setIsActiveKeys(true)}
									onBlur={() => {
										setIsActiveKeys(false);
									}}
									value={keys}
									placeholder="Keys  . . ."
									readOnly
								/>
							</div>

							<div className="new-phrases-container">
								<span id="phrase-text">
									{createPhrases.length === 1 ? "Phrase" : "Phrases"}
								</span>

								<div className="phrase-text-wrapper">
									{createPhrases.map((value, index) => {
										return (
											<div className="input-field" key={index}>
												<TextareaAutosize
													readOnly
													placeholder="Phrase  . . ."
													value={createPhrases[index]}
												/>
											</div>
										);
									})}
								</div>
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setDeletePhrase("");
										setCreatePhrases([]);
										setCategory("");
										setKeys("");
									}}
								>
									Cancel
								</button>
								<button
									onClick={() =>
										getCategory &&
										keys &&
										createPhrases.filter(Boolean).length > 0 &&
										deletePhraseHandler()
									}
									id={
										getCategory &&
										keys &&
										createPhrases.filter(Boolean).length > 0
											? ""
											: "disable-btn"
									}
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for delete specific phrase  end */}
			</div>
		</>
	);
};

export default Phrases;
