// external components
import { convertToHTML } from "draft-convert";
import { EditorState, Modifier } from "draft-js";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

// internal components

const RichEditor = ({
	name,
	editorState,
	setInputValues,
	updateResumeInfo,
	_id,
	cus_section_id,
	maxWords,
	wordCount,
	setWordCount,
	placeholder,
	control,
	dispatchControl,
	delay
}) => {
	// updateWordCounter
	const updateWordCount = (contentState) => {
		const text = contentState.getPlainText("");
		const words = text.trim().split(/\s+/);
		const count = words.length === 1 && words[0] === "" ? 0 : words.length;
		setWordCount(count);
	};

	// Handle before pasted-text
	const handlePastedText = (text, html, editorState) => {
		const currentContentState = editorState.getCurrentContent();
		const currentWordCount = currentContentState
			.getPlainText("")
			.trim()
			.split(/\s+/).length;

		const remainingWords = maxWords - currentWordCount;

		let pastedContent = "";
		if (remainingWords > 0) {
			const pastedWords = text.trim().split(/\s+/).slice(0, remainingWords);
			pastedContent = pastedWords.join(" ");

			const newContentState = Modifier.replaceText(
				currentContentState,
				editorState.getSelection(),
				pastedContent
			);

			const newEditorState = EditorState.push(
				editorState,
				newContentState,
				"insert-characters"
			);

			setInputValues((prev) => ({ ...prev, [name]: newEditorState }));
		}

		return "handled";
	};

	// Handle before input
	const handleBeforeInput = (input, editorState) => {
		const currentContentState = editorState.getCurrentContent();
		const currentWordCount = currentContentState
			.getPlainText("")
			.trim()
			.split(/\s+/).length;

		if (currentWordCount >= maxWords && input !== " ") {
			return "handled";
		}

		const newContentState = Modifier.insertText(
			currentContentState,
			editorState.getSelection(),
			input
		);

		const newEditorState = EditorState.push(
			editorState,
			newContentState,
			"insert-characters"
		);

		setInputValues((prev) => ({ ...prev, [name]: newEditorState }));

		return "handled";
	};

	// updated editorState on db start
	const [latestUpdate, setLatestUpdate] = useState({});

	useEffect(() => {
		if (Object.keys(latestUpdate)[0]) {
			const timeoutId = setTimeout(() => {
				cus_section_id
					? updateResumeInfo({ ...latestUpdate, _id, cus_section_id })
					: updateResumeInfo({ ...latestUpdate, _id });
			}, delay);

			return () => {
				clearTimeout(timeoutId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestUpdate]);
	// updated editorState on db end

	const onEditorStateChange = (state) => {
		setInputValues((prev) => ({ ...prev, [name]: state }));
	};

	// for prevent initial rendering
	const isInitialRender = useRef(true);
	const prevEditorState = useRef(null);

	// onChange
	useEffect(() => {
		if (isInitialRender.current) {
			isInitialRender.current = false;
			return;
		}

		if (editorState) {
			const getCurrentContent = editorState.getCurrentContent();
			const updatedContent = convertToHTML(getCurrentContent);

			if (prevEditorState.current !== updatedContent) {
				updateWordCount(getCurrentContent);

				setLatestUpdate({
					[name]:
						updatedContent && updatedContent !== "<p></p>"
							? updatedContent
							: null
				});

				prevEditorState.current = updatedContent;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editorState]);

	return (
		<Editor
			editorState={editorState}
			onEditorStateChange={onEditorStateChange}
			handlePastedText={handlePastedText}
			handleBeforeInput={handleBeforeInput}
			toolbarClassName={
				control ? "toolbarClassName active" : "toolbarClassName"
			}
			wrapperClassName={
				maxWords - wordCount <= 5
					? "wrapperClassName when-limit-up"
					: control
					? "wrapperClassName active"
					: "wrapperClassName"
			}
			editorClassName="rich-editor-container"
			spellCheck={true}
			toolbar={{
				options: ["inline", "list", "link"],
				inline: {
					options: ["bold", "italic", "underline", "strikethrough"]
				},
				list: {
					options: ["unordered", "ordered"]
				},
				link: {
					options: ["link"]
				}
			}}
			onFocus={() => dispatchControl({ type: "focused-rich-editor" })}
			onBlur={() => {
				dispatchControl({ type: "blur-rich-editor" });
			}}
			placeholder={placeholder}
		/>
	);
};

export default RichEditor;
