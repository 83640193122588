// external components
import { useEffect, useState, memo } from "react";

// internal components
import { DeleteTooltip, FirstCol, SecondCol } from "../common";

const WebLinkItem = ({
	itemValues,
	handleSetActive,
	contents,
	setDeleteItem,
	updateResumeInfo,
	delay
}) => {
	// webLinkItem contents
	const { label, link, not_specific, deleteText } = contents.editor;

	// assign
	const _id = itemValues._id;

	// getting & updating links all values start
	const [inputValues, setInputValues] = useState(itemValues);

	const [latestUpdate, setLatestUpdate] = useState({});

	useEffect(() => {
		if (Object.keys(latestUpdate)[0]) {
			const timeoutId = setTimeout(() => {
				updateResumeInfo({ ...latestUpdate, _id });
			}, delay);

			return () => {
				clearTimeout(timeoutId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestUpdate]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setInputValues((prevValues) => ({
			...prevValues,
			[name]: value
		}));

		setLatestUpdate({
			[name]: value || null
		});
	};
	// getting & updating links all values end

	// collapse toggle
	const handleActivate = () => {
		handleSetActive(_id);
	};

	return (
		<>
			<div className="specific-item-container">
				<div className="specific-item-wrapper">
					<div className="content-title-wrapper" onClick={handleActivate}>
						<div className="content-title">
							<h4>
								{inputValues.link_label ? inputValues.link_label : not_specific}{" "}
							</h4>
							{inputValues.link_url && <span>{inputValues.link_url}</span>}
						</div>

						<i className="fa-solid fa-chevron-up" id="up"></i>

						<i className="fa-solid fa-chevron-down" id="down"></i>
					</div>

					{/* for delete that item */}
					<DeleteTooltip
						content={deleteText}
						setDeleteItem={setDeleteItem}
						_id={_id}
					/>
				</div>

				<div className="row-container-wrapper">
					{/* label and link start  */}
					<div className="row-container">
						<FirstCol
							content={label}
							name={"link_label"}
							value={inputValues.link_label}
							onChange={handleInputChange}
							haveIm={true}
						/>

						<SecondCol
							content={link}
							name={"link_url"}
							value={inputValues.link_url}
							onChange={handleInputChange}
							haveIm={true}
						/>
					</div>
					{/* label and link end  */}
				</div>
			</div>
		</>
	);
};

export default memo(WebLinkItem);
