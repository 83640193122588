const financialAnalyst = {
	employment_history_items: [
		{
			employment_job_title: "Financial Analyst",
			employer: "GEO Corp",
			start_date: "2020-09-30T18:00:00.000Z",
			end_date: "2023-08-31T18:00:00.000Z",
			employment_city: "New York",
			employment_rich_text_content: `<p>Ultrices. Tristique maecenas interdum a dapibus consequat potenti sodales lacinia. Quisque, vel gravida.
				Interdum. Mollis ligula. Dui mi viverra libero amet nonummy at tristique fermentum ullamcorper sit inceptos sit cum. Elementum mus.
				Quisque convallis molestie sociis lorem velit, pharetra Feugiat ligula non in parturient sagittis ut sagittis taciti donec lorem.</p>`,
			collapse: false
		},
		{
			employment_job_title: "Financial Analyst",
			employer: "Sisco Enterprises",
			start_date: "2008-08-31T18:00:00.000Z",
			end_date: "2010-04-30T18:00:00.000Z",
			employment_city: "New York",
			employment_rich_text_content:
				"<ul><li>Provided reports, ad-hoc analysis, annual operating plan budgets, monthly cash forecasts, and revenue forecasts.</li><li>Analyzed supplier contracts and advised in negotiations bringing budgets down by 6%.</li><li>Created weekly labor finance reports and presented the results to management.</li></ul>",
			collapse: false
		}
	],

	education_items: [
		{
			school: "Villanova University",
			degree: "Bachelor of Finance",
			edu_start_date: "2003-12-31T18:00:00.000Z",
			edu_end_date: "2007-12-31T18:00:00.000Z",
			edu_city: "King of Prussia",
			collapse: false
		},
		{
			school: "Athlone Institute of Technology",
			degree: "College Degree",
			edu_start_date: "1999-12-31T18:00:00.000Z",
			edu_end_date: "2003-12-31T18:00:00.000Z",
			edu_city: "Argentina",
			edu_rich_text_content: "",
			collapse: false
		},
		{
			school: "Camden High School",
			degree: "High School Diploma",
			edu_start_date: "1997-12-31T18:00:00.000Z",
			edu_end_date: "1999-10-31T18:00:00.000Z",
			edu_city: "Argentina",
			edu_rich_text_content: "",
			collapse: false
		}
	],

	skills_items: [
		{
			skill: "Financial Analysis",
			skill_level: "Expert",
			collapse: false
		},
		{
			skill: "Strategic Planning",
			skill_level: "Expert",
			collapse: false
		},
		{
			skill: "Trend Analysis",
			skill_level: "Expert",
			collapse: false
		},
		{
			skill: "Market Assessment",
			skill_level: "Expert",
			collapse: false
		},
		{
			skill: "Team Leadership",
			skill_level: "Expert",
			collapse: false
		}
	],

	languages_items: [
		{
			languages_name: "Bangla",
			languages_level: "Highly proficient",
			collapse: false
		},
		{
			languages_name: "English",
			languages_level: "Highly proficient",
			collapse: false
		},
		{
			languages_name: "Spanish",
			languages_level: "Highly proficient",
			collapse: false
		}
	],

	references_items: [
		{
			references_name: "Devin Strazza",
			references_company: "GEO Corp",
			references_phone: "01111111111",
			references_email: "refereremail@domain.com",
			collapse: false
		},
		{
			references_name: "M. Saeef",
			references_company: "Hitech Industries",
			references_phone: "01111111111",
			references_email: "refereremail@domain.com",
			collapse: false
		}
	],

	personal_details: [
		{
			personal_details_title: "Personal Details",
			job_title: "Financial Analyst",
			original_profile_img: "",
			cropped_profile_img: "",
			first_name: "Matthew",
			last_name: "Jones",
			email: "emailID@domain.com",
			phone: "+8801711122233",
			country: "",
			nationality: "",
			present_address: "Block D",
			present_city: "Rampura",
			present_area: "Dhaka",
			present_zip: "721437",
			same_address: false,
			permanent_address: "",
			permanent_city: "",
			permanent_area: "",
			permanent_zip: "",
			driving_license: "",
			nid: "",
			place_of_birth: "",
			date_of_birth: ""
		}
	],

	rich_text_content:
		"<p>Experienced and driven Financial Analyst with an impressive background of managing multi-million dollar budgets while providing analysis and account support within product development departments. Worked to reduce business expenses and develop logical and advantageous operating plan budgets. Experience creating quarterly accruals based on trends and forecasted expenses.</p>",

	additional_section: [
		{
			key: "Languages"
		},
		{
			key: "References"
		}
	]
};

export default financialAnalyst;
