// external components
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import sortArray from "sort-array";

// internal components
import RestrictionDialog from "../../common/RestrictionDialog";
import { getResumeCounter } from "../../common/function";
import { GetContextApi } from "../../storage/ContextApi";
import "./SelectTemplate.css";

const SelectTemplate = ({ allTemplates }) => {
	// for assign selected-resume-template, currentUser & contents
	const {
		setSelectedRTemplate,
		currentUser,
		setCurrentUser,
		contents,
		freeTemplateName
	} = GetContextApi();

	// selectTemplates contents
	const { title, button_text } = contents.select_template;

	// restriction contents
	const {
		fu_premium_template,
		multiple_free_template,
		additional_resume_restriction,
		additional_template_restriction
	} = contents.package_limit;
	const { upgrade_now } = contents.navbar;

	// for redirect page
	const Navigate = useNavigate();

	// for updating counter number into server start
	const updatingCounter = async (obj) => {
		try {
			const response = await fetch(`/counter/update`, {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				Navigate(`/resume/${obj.count}/create-resume`);
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for updating counter number into server end

	// for fetching currentUser's all resumes from db start
	const [getResumes, setResumes] = useState([]);

	const getAllResumes = async () => {
		try {
			const response = await fetch("/resume/all-resumes");

			const result = await response.json();

			if (result.error || response.status !== 200) {
				setResumes([]);
			} else if (response.status === 200) {
				setResumes(result);
			}
		} catch (error) {
			toast.error("Maintenance mode, Try again later!", {
				position: "top-right",
				theme: "colored",
				autoClose: 2500
			});

			setResumes([]);
		}
	};

	useEffect(() => {
		if (currentUser) {
			getAllResumes();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for fetching currentUser's all resumes from db end

	// for redirectHandler start
	const [restrictionObj, setRestrictionObj] = useState("");

	const redirectHandler = (value) => {
		if (currentUser) {
			const resumeChecker = currentUser.maximum_resumes > getResumes.length;

			const freeTemplateChecker = value.name === freeTemplateName;

			if (
				(resumeChecker && currentUser.used_templates.includes(value._id)) ||
				(resumeChecker && currentUser.available_templates) ||
				(resumeChecker && freeTemplateChecker) ||
				(getResumes.length === 0 && freeTemplateChecker)
			) {
				setSelectedRTemplate(value._id);

				(async () => {
					const result = await getResumeCounter();

					if (result) {
						await updatingCounter({
							selectedTab: "Resume",
							count: result.resume + 1
						});
					}
				})();

				setCurrentUser((prev) => {
					const checker = prev.used_templates.includes(value._id);

					const updatedTemplates = checker
						? [...prev.used_templates]
						: [...prev.used_templates, value._id];

					const available_templates =
						checker || freeTemplateChecker
							? prev.available_templates
							: prev.available_templates - 1;

					return {
						...prev,
						used_templates: updatedTemplates,
						available_templates
					};
				});
			} else {
				if (
					!currentUser.available_templates &&
					currentUser.package["Package Name"] !== "Free Plan"
				) {
					setRestrictionObj(additional_template_restriction);
				} else if (
					!resumeChecker &&
					currentUser.package["Package Name"] !== "Free Plan"
				) {
					setRestrictionObj(additional_resume_restriction);
				} else if (!resumeChecker && freeTemplateChecker) {
					setRestrictionObj(multiple_free_template);
				} else if (currentUser.package["Package Name"] === "Free Plan") {
					setRestrictionObj(fu_premium_template);
				}
			}
		} else {
			Navigate("/sign-up");
		}
	};
	// for redirectHandler end

	return (
		<>
			<div className="container-fluid p-0">
				<div className="row m-0 select-template-container">
					<div className="col select-template-wrapper">
						<div className="text-contents" data-aos="fade-up">
							<h2>{title}</h2>
						</div>

						{/* slide-container start  */}
						<div
							className="slide-container"
							data-aos="fade-left"
							data-aos-delay="100"
							data-aos-duration="800"
						>
							<Carousel
								additionalTransfrom={1}
								arrows
								autoPlaySpeed={3000}
								transitionDuration={500}
								centerMode={false}
								className=""
								containerClass="container-with-dots"
								dotListClass=""
								draggable={true}
								focusOnSelect={false}
								infinite
								itemClass=""
								keyBoardControl
								minimumTouchDrag={80}
								pauseOnHover
								renderArrowsWhenDisabled={false}
								renderButtonGroupOutside={false}
								renderDotsOutside={true}
								partialVisible={true}
								responsive={{
									monitor: {
										breakpoint: {
											max: 3000,
											min: 1600
										},
										items: 4,
										partialVisibilityGutter: 0
									},

									desktop: {
										breakpoint: {
											max: 1600,
											min: 1200
										},
										items: 3,
										partialVisibilityGutter: 0
									},

									tablet: {
										breakpoint: {
											max: 1200,
											min: 900
										},
										items: 2,
										partialVisibilityGutter: 0
									},
									mobile: {
										breakpoint: {
											max: 900,
											min: 0
										},
										items: 1,
										partialVisibilityGutter: 0
									}
								}}
								rewind={false}
								rewindWithAnimation={false}
								rtl={false}
								shouldResetAutoplay
								showDots={true}
								sliderClass=""
								slidesToSlide={1}
								swipeable
								// autoPlay
							>
								{allTemplates.length > 0 &&
									sortArray(allTemplates, {
										by: "updatedAt",
										order: "desc"
									}).map((value, index) => {
										return (
											<div key={index} className="wrapper">
												<div className="img-container">
													<img
														src={`/assets/templates/${value.img}`}
														alt={`template-${index}`}
													/>

													<div
														className="use-template"
														onClick={() => redirectHandler(value)}
													>
														<button>{button_text}</button>
													</div>

													{/* for free text  */}
													{[freeTemplateName].includes(value.name) && (
														<div className="free-txt">Free</div>
													)}
												</div>
											</div>
										);
									})}
							</Carousel>
						</div>
						{/* slide-container end */}
					</div>

					{/* animation shape start  */}
					<div className="section-icons">
						<div className="shape">
							<svg
								className="triangle-1 svg-item inline shape-rotate"
								id="Layer_4"
								data-name="Layer 4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 33.83 33.83"
							>
								<defs></defs>
								<title></title>
								<path
									style={{
										fill: "none",
										stroke: "#d3e1fd",
										strokeMiterlimit: "10",
										strokeWidth: "4px",
										fillRule: "evenodd"
									}}
									d="M895,191v27H868Z"
									transform="translate(-863.17 -186.17)"
								></path>
							</svg>
						</div>

						<div className="shape">
							<svg
								className="triangle-2 svg-item inline shape-rotate"
								id="Layer_4"
								data-name="Layer 4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 33.83 33.83"
							>
								<defs></defs>
								<title></title>
								<path
									style={{
										fill: "none",
										stroke: "#d3e1fd",
										strokeMiterlimit: "10",
										strokeWidth: "4px",
										fillRule: "evenodd"
									}}
									d="M895,191v27H868Z"
									transform="translate(-863.17 -186.17)"
								></path>
							</svg>
						</div>

						<div className="shape">
							<svg
								className="rectangular-1 svg-item inline shape-rotate"
								id="Layer_5"
								data-name="Layer 5"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 56.3 56.3"
							>
								<defs></defs>
								<title></title>
								<path
									style={{
										fill: "none",
										stroke: "#ffd3d8",
										strokeMiterlimit: "10",
										strokeWidth: "5px",
										fillRule: "evenodd"
									}}
									d="M766.87,597.9l22.23-27,27,22.23-22.23,27Z"
									transform="translate(-763.35 -567.35)"
								></path>
							</svg>
						</div>

						<div className="shape">
							<svg
								className="rectangular-2 svg-item inline shape-rotate"
								id="Layer_5"
								data-name="Layer 5"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 56.3 56.3"
							>
								<defs></defs>
								<title></title>
								<path
									style={{
										fill: "none",
										stroke: "#ffd3d8",
										strokeMiterlimit: "10",
										strokeWidth: "5px",
										fillRule: "evenodd"
									}}
									d="M766.87,597.9l22.23-27,27,22.23-22.23,27Z"
									transform="translate(-763.35 -567.35)"
								></path>
							</svg>
						</div>

						<div className="shape">
							<svg
								className="circle svg-item inline rotate3d"
								id="Layer_6"
								data-name="Layer 6"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 15 15"
							>
								<defs></defs>
								<title></title>
								<path
									style={{ fill: "#ffd3d8", fillRule: "evenodd" }}
									d="M561.5,474a7.5,7.5,0,1,1-7.5,7.5A7.5,7.5,0,0,1,561.5,474Z"
									transform="translate(-554 -474)"
								></path>
							</svg>
						</div>
					</div>
					{/* animation shape end */}
				</div>

				{/* for restriction dialog box */}
				{restrictionObj && (
					<RestrictionDialog
						restrictionObj={restrictionObj}
						setRestrictionObj={setRestrictionObj}
						btn_text={upgrade_now}
					/>
				)}
			</div>
		</>
	);
};

export default SelectTemplate;
