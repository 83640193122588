// external components
import { toast } from "react-hot-toast";

// general email validation
const isValidateEmail = (email) => {
	if (!email) return false;
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

// getting currentUser
const getCurrentUser = async () => {
	try {
		const response = await fetch("/user");

		const result = await response.json();

		if (response.status === 200) {
			return result;
		} else if (result.error) {
			return null;
		} else {
			return null;
		}
	} catch (error) {
		toast.error("Maintenance mode, Try again later!", {
			position: "top-right",
			theme: "colored",
			autoClose: 2500
		});
		return null;
	}
};

// getting counter
const getResumeCounter = async () => {
	try {
		const response = await fetch("/counter");

		const result = await response.json();

		if (result.error || response.status !== 200) {
			toast.error(result.error, {
				position: "top-right",
				theme: "colored",
				autoClose: 2500
			});

			return "";
		} else if (response.status === 200) {
			return result;
		}
	} catch (error) {
		return "";
	}
};

export { getCurrentUser, getResumeCounter, isValidateEmail };
