// external components
import { NavLink, useNavigate } from "react-router-dom";

// internal components
import "./_style.scss";

const Stairs = ({ from_where, contents, currentUser }) => {
	// for redirect pages
	const Navigate = useNavigate();

	// stairs contents
	const {
		create_resume,
		choose_plan,
		payment_details,
		download_resume,

		choose_template,
		enter_your_details
	} = contents.stairs;

	return (
		<>
			<div className="stairs-container">
				<div className="container">
					<div className="row stairs-wrapper">
						<div className="col-11 col-sm-12">
							{/* stairs start  */}
							<div className="stairs">
								{/* project-title start */}
								<div className="project-logo">
									<NavLink to={"/"}>
										<img src="/assets/images/main-logo.svg" alt="logo" />
									</NavLink>
								</div>
								{/* project-title end */}

								{/* progress-bar start  */}
								<div className="stairs-progress-bar">
									<div className="section">
										<div className="counter">
											<i className="fa-solid fa-check" id="step-checked"></i>
										</div>
										<div className="step-title">
											{from_where === "create_account"
												? choose_template
												: create_resume}
										</div>
									</div>

									<div className="section">
										<div className="counter">2</div>
										<div className="step-title">
											{from_where === "create_account"
												? enter_your_details
												: choose_plan}
										</div>
									</div>

									<div className="section">
										<div
											className="counter"
											id={from_where === "payment" ? "" : "inactive"}
										>
											3
										</div>
										<div className="step-title">
											{from_where === "create_account"
												? download_resume
												: payment_details}
										</div>
									</div>

									{from_where !== "create_account" && (
										<div className="section">
											<div className="counter" id="inactive">
												4
											</div>
											<div className="step-title">{download_resume}</div>
										</div>
									)}
								</div>

								{/* when-mobile start  */}
								<div className="stairs-progress-bar-mb">
									<div className="stairs-progress-bar-mb-title">
										{choose_plan}
									</div>
									<div className="stairs-progress-bar-mb-bullets">
										<div className="bullet" id="active"></div>
										<div
											className="bullet"
											id={
												["payment", "create_account"].includes(from_where)
													? "active"
													: ""
											}
										></div>
										<div className="bullet"></div>
									</div>
								</div>
								{/* when-mobile end  */}
								{/* progress-bar end */}

								{/* header-close start  */}
								<div className="header-close">
									<i
										className="fa-solid fa-xmark header-close__icon"
										onClick={() =>
											currentUser
												? (from_where === "subscription" &&
														Navigate("/user-dashboard")) ||
												  (from_where === "payment" &&
														Navigate("/subscription"))
												: Navigate("/sign-up")
										}
									></i>
								</div>
								{/* header-close end */}
							</div>
							{/* stairs end  */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Stairs;
