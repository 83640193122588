// external components

// internal components
import "./_AuthInfoMsg.scss";

const AuthInfoMsg = ({
	heading,
	description,

	or,

	resend_btn,
	sending_btn,

	isLoading,
	resendEmailHandler
}) => {
	return (
		<>
			<div className="auth-info-msg">
				<img
					src="/assets/images/inbox.png"
					alt="inbox-img"
					className="auth-info-msg__img"
				/>

				<h4
					className="auth-info-msg__heading"
					dangerouslySetInnerHTML={{ __html: heading }}
				></h4>

				<p
					className="auth-info-msg__description"
					dangerouslySetInnerHTML={{ __html: description }}
				></p>

				<span className="auth-info-msg__or">{or}</span>

				<button onClick={resendEmailHandler} className="auth-info-msg__btn">
					{!isLoading ? resend_btn : sending_btn}
				</button>
			</div>
		</>
	);
};

export default AuthInfoMsg;
