// external components
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TagManager from "react-gtm-module";

// internal components
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ContextHandler from "./storage/ContextApi";

// for changing mui primary color
const theme = createTheme({
	palette: {
		primary: {
			main: "#f82049"
		}
	}
});

// tag manager
const tagManagerArgs = {
	gtmId: "GTM-KK444TM7",
	dataLayer: {
		userId: "001",
		userProject: "project"
	}
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<ContextHandler>
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ThemeProvider>
	</ContextHandler>
);
