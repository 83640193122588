// external components
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import {
	LoginSocialFacebook,
	LoginSocialGoogle,
	LoginSocialLinkedin
} from "reactjs-social-login";

// internal components
import {
	AgreeCheckbox,
	AuthInfoMsg,
	AuthLeft,
	PasswordRequired,
	ShapeAnimation,
	SubFooter
} from "../../common";
import { GetContextApi } from "../../storage/ContextApi";
import { isValidateEmail } from "./../../common/function";
import "./SignUp.css";

const SignUp = () => {
	// for updating currentUser & contents & userCountry
	const { currentUser, setCurrentUser, contents, userCountry } =
		GetContextApi();

	// signUp contents
	const {
		c_gen_account,
		c_stu_account,

		setup_account,
		setup_account_password,

		user_name,
		user_name_placeholder,

		emailAddress,
		sutEmailAddress,
		email_placeholder,
		email_error_msg,

		stu_card_checkbox,

		passwordTxt,
		enter_pass_placeholder,

		signUpBtn,
		signingUpBtn,

		submit,
		submitting,

		signUpSocialNet,

		alreadyAcc,
		loginLabel,

		ifStudent,
		ifNotStudent,
		signUPLabel,
		here
	} = contents.sign_up;

	// authInfoMsg contents
	const {
		heading_ac_email,
		description_ac_email,

		or,

		resend_btn,
		sending_btn
	} = contents.auth_info_msg;

	// for redirect login page
	const Navigate = useNavigate();

	// if currentUser have then redirect to user-dashboard
	useEffect(() => {
		if (currentUser) {
			Navigate("/user-dashboard");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// for loading until didn't submit on server
	const [isLoading, setIsLoading] = useState(false);

	// for displaying check mail inbox message
	const [isDisplayMass, setIsDisplayMass] = useState(false);

	// for getting input-fields values
	const [getName, setName] = useState("");
	const [getEmail, setEmail] = useState("");
	const [getPassword, setPassword] = useState("");
	const [stuCard, setStuCard] = useState("");

	// for password eye toggle
	const [eyeTP, setEyeTP] = useState("");

	// checkbox for term and policy
	const [isCheckTerm, setIsCheckTerm] = useState(false);
	const [isSendUpdate, setIsSendUpdate] = useState(false);
	const [isHaveStuCard, setIsHaveStuCard] = useState(false);

	// for password's required fields checking start
	const [passwordRequired, setPasswordRequired] = useState(false);

	// for checking button clicked for submit or not
	const [btnClicked, setBtnClicked] = useState(false);

	// sign-up form submit on server start
	const uploadStudentCard = async (userEmail) => {
		try {
			const formData = new FormData();

			const extension = stuCard.name.split(".").pop();

			formData.append("file", stuCard, `stu_card_${userEmail}.${extension}`);

			const response = await fetch(
				`/user/sign-up/student?userEmail=${userEmail}`,
				{
					method: "POST",
					body: formData
				}
			);

			const result = await response.json();

			if (response.status === 200) {
				setStuCard("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const submitHandler = async () => {
		if (
			getName &&
			getEmail &&
			getPassword?.length >= 8 &&
			passwordRequired &&
			isCheckTerm &&
			isValidateEmail(getEmail)
		) {
			setIsLoading(true);

			try {
				const userObj = {
					name: getName,
					email: getEmail,
					password: getPassword,
					send_update: isSendUpdate,
					userType
				};

				const response = await fetch("/user/sign-up", {
					method: "POST",
					body: JSON.stringify(userObj),
					headers: {
						"Content-Type": "application/json"
					}
				});

				const result = await response.json();

				if (response.status === 200) {
					toast.success(result.message, {
						position: "top-right",
						theme: "colored",
						autoClose: 2500
					});

					if (userType === "student" && isHaveStuCard && stuCard) {
						uploadStudentCard(result.userEmail);
					}

					setIsLoading(false);
					setPassword("");
					setEyeTP(false);
					setBtnClicked(false);

					setName("");
					setIsDisplayMass(true);
				} else if (response.status === 400) {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 2500
					});
					setIsLoading(false);
				} else {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 2500
					});
					setIsLoading(false);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
				setIsLoading(false);
			}
		}
	};
	// sign-up form submit on server end

	// for sending email again start
	const resendEmailHandler = async () => {
		setIsLoading(true);
		try {
			const response = await fetch("/user/resend-email", {
				method: "POST",
				body: JSON.stringify({ email: getEmail }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});

				setIsLoading(false);
			} else if (response.status === 202) {
				toast.error(result.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setIsLoading(false);
				setEmail("");

				Navigate("/log-in");
				setIsDisplayMass(false);
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setIsLoading(false);
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
			setIsLoading(false);
		}
	};
	// for sending email again end

	// for login with socials start

	// set email & password when social login
	const [socialPassword, setSocialPassword] = useState("");

	// socialPasswordHandler start
	const socialPasswordHandler = async () => {
		if (!(getPassword?.length >= 8 && passwordRequired)) {
			toast.error("Password requirement didn't achieved.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark"
			});
		} else if (isCheckTerm) {
			setIsLoading(true);
			try {
				const response = await fetch("/user/setup-account", {
					method: "PUT",
					body: JSON.stringify({
						email: getEmail,
						password: getPassword,
						_id: socialPassword._id
					}),
					headers: {
						"Content-Type": "application/json"
					}
				});

				const result = await response.json();

				if (response.status === 200) {
					setCurrentUser(result.currentUser);
					Navigate("/user-dashboard");
					setSocialPassword("");
					setIsLoading(false);
					setPassword("");
					setBtnClicked(false);
				} else if (response.status === 202) {
					toast.error(result.message, {
						position: "top-right",
						theme: "dark",
						autoClose: 2000
					});

					setTimeout(() => {
						setCurrentUser(result.currentUser);
						Navigate("/user-dashboard");
					}, 2500);

					setSocialPassword("");
					setIsLoading(false);
					setPassword("");
					setBtnClicked(false);
				} else if (response.status === 400) {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 2500
					});

					setIsLoading(false);
				} else {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 2500
					});
					setIsLoading(false);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
				setIsLoading(false);
			}
		}
	};
	// socialPasswordHandler end

	// loginWithSocialHandler start
	const loginWithSocialHandler = async (response) => {
		setIsLoading(true);

		const name =
			response.provider === "linkedin"
				? response.data.localizedFirstName +
				  " " +
				  response.data.localizedLastName
				: response.data.name;

		const profile_img =
			response.provider === "facebook"
				? response.data.picture.data.url
				: response.provider === "linkedin"
				? ""
				: response.data.picture;

		const provider = response.provider;

		// for get linkedin email
		let getEmail = "";

		if (response.provider === "linkedin") {
			const responseLinkedin = await fetch("/user/get-linkedin-email", {
				method: "POST",
				body: JSON.stringify({ access_token: response.data.access_token }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const resultLinkedin = await responseLinkedin.json();

			if (responseLinkedin.status === 200) {
				getEmail = resultLinkedin;
			} else {
				getEmail = "";
			}
		}

		const email =
			response.provider === "linkedin" ? getEmail : response.data.email;

		try {
			const userObj = {
				name,
				email,
				profile_img,
				provider
			};

			const response = await fetch("/user/login-with-socials", {
				method: "POST",
				body: JSON.stringify(userObj),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				setIsLoading(false);
				setCurrentUser(result);
				result.password
					? Navigate("/user-dashboard")
					: setSocialPassword({ provider, email, _id: result._id });
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
				setIsLoading(false);
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
			setIsLoading(false);
		}
	};
	// loginWithSocialHandler end
	// for login with socials end

	// getting userType
	const [userType, setUserType] = useState("general");

	return (
		<>
			<div
				className="main-signup-container"
				id={userCountry !== "BD" ? "when-foreigner-viewport" : ""}
			>
				<div className="container">
					<div className="row signup-container">
						<div className="col-11 col-sm-12 signup-wrapper">
							{/* signup-left start  */}
							<AuthLeft contents={contents} />
							{/* signup-left end  */}

							{/* signup-right start  */}
							<div className="signup-right">
								{!isDisplayMass ? (
									<div className="sign-up-form">
										{socialPassword ? (
											<h4 id="title">
												{!socialPassword.email
													? setup_account
													: setup_account_password}
											</h4>
										) : (
											<div className="tab-container">
												<div
													className="tab-item"
													onClick={() => setUserType("general")}
													id={userType === "general" ? "active" : ""}
												>
													{c_gen_account}
												</div>
												<div
													className="tab-item"
													onClick={() => setUserType("student")}
													id={userType === "student" ? "active" : ""}
												>
													{c_stu_account}
												</div>
											</div>
										)}

										<div className="input-fields">
											{!socialPassword && (
												<div className="field">
													<label htmlFor="name">{user_name}</label>
													<input
														type="text"
														id={btnClicked && !getName ? "not-fill" : "name"}
														placeholder={user_name_placeholder}
														onChange={(e) => setName(e.target.value)}
														value={getName}
													/>
												</div>
											)}

											<div className="field">
												{!socialPassword.email &&
													btnClicked &&
													!isValidateEmail(getEmail) && (
														<p id="error-msg">{email_error_msg}</p>
													)}

												<label htmlFor="email">
													{userType === "general"
														? emailAddress
														: sutEmailAddress}
												</label>

												<input
													type="email"
													placeholder={email_placeholder}
													id={
														btnClicked && !getEmail && !socialPassword?.email
															? "not-fill"
															: "email"
													}
													onChange={(e) => setEmail(e.target.value)}
													value={
														socialPassword?.email
															? socialPassword.email
															: getEmail
													}
													readOnly={socialPassword?.email ? true : false}
												/>
											</div>

											{/* uploading student card  */}
											{!socialPassword && userType === "student" && (
												<>
													<div className="checkbox-item">
														<input
															type="checkbox"
															id="get-stu-card"
															value="true"
															onClick={() => setIsHaveStuCard(!isHaveStuCard)}
															readOnly
															className="checkbox"
														/>

														<label
															htmlFor="get-stu-card"
															className={
																isHaveStuCard ? "checked label" : "label"
															}
														>
															<p>{stu_card_checkbox}</p>
														</label>
													</div>

													{/* get student card start  */}
													<div
														className={
															isHaveStuCard
																? "student-card-wrapper active"
																: "student-card-wrapper"
														}
													>
														<label
															className={
																btnClicked && isHaveStuCard && !stuCard
																	? "get-stu-card-wrapper inactive"
																	: "get-stu-card-wrapper"
															}
															id="stu-card"
														>
															<i
																className="fa-solid fa-cloud-arrow-up"
																id="icon"
															></i>

															<span id="support">
																{stuCard
																	? stuCard?.name
																	: "Supports : JPEG, JPG, PNG"}
															</span>

															<input
																style={{ display: "none" }}
																type="file"
																id="stu-card"
																accept="image/png, image/jpg, image/jpeg"
																onChange={(e) => setStuCard(e.target.files[0])}
															/>
														</label>
													</div>
													{/* get student card end */}
												</>
											)}

											<div className="field" id="when-password">
												<label htmlFor="password">{passwordTxt}</label>
												<input
													type={eyeTP ? "text" : "password"}
													placeholder={enter_pass_placeholder}
													id={
														btnClicked &&
														!(getPassword?.length >= 8 && passwordRequired)
															? "not-fill"
															: ""
													}
													onChange={(e) => setPassword(e.target.value)}
													value={getPassword}
												/>

												{getPassword &&
													(eyeTP ? (
														<i
															className="fa-solid fa-eye"
															onClick={() => setEyeTP(!eyeTP)}
														></i>
													) : (
														<i
															className="fa-solid fa-eye-slash"
															onClick={() => setEyeTP(!eyeTP)}
														></i>
													))}

												<PasswordRequired
													getPassword={getPassword}
													passwordRequired={passwordRequired}
													setPasswordRequired={setPasswordRequired}
													contents={contents}
												/>
											</div>

											{/* agree checkbox  */}
											<AgreeCheckbox
												isCheckTerm={isCheckTerm}
												setIsCheckTerm={setIsCheckTerm}
												btnClicked={btnClicked}
												isSendUpdate={isSendUpdate}
												setIsSendUpdate={setIsSendUpdate}
												contents={contents}
											/>

											{!socialPassword ? (
												<button
													onClick={() => {
														setBtnClicked(true);
														submitHandler();
													}}
												>
													{!isLoading ? signUpBtn : signingUpBtn}
												</button>
											) : (
												<button
													onClick={() => {
														setBtnClicked(true);
														socialPassword?.email
															? socialPasswordHandler()
															: isValidateEmail(getEmail) &&
															  socialPasswordHandler();
													}}
												>
													{isLoading ? submitting : submit}
												</button>
											)}
										</div>

										{/* sign-up with social  */}
										{!socialPassword && userType === "general" && (
											<>
												<div id="signup-with-social">{signUpSocialNet}</div>
												<div className="signup-with-social">
													<LoginSocialFacebook
														appId={process.env.REACT_APP_APPID}
														onResolve={loginWithSocialHandler}
														onReject={(error) => {
															toast.error("Login with facebook failed!", {
																position: "top-right",
																theme: "colored",
																autoClose: 2500
															});
														}}
														className="social-icon"
													>
														<i className="fa-brands fa-facebook-f"></i>
													</LoginSocialFacebook>

													<LoginSocialGoogle
														client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
														discoveryDocs="claims_supported"
														access_type="offline"
														scope="profile email"
														onResolve={loginWithSocialHandler}
														onReject={(error) => {
															toast.error("Login with google failed!", {
																position: "top-right",
																theme: "colored",
																autoClose: 2500
															});
														}}
														className="social-icon"
													>
														<i className="fa-brands fa-google-plus-g"></i>
													</LoginSocialGoogle>

													<LoginSocialLinkedin
														client_id={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
														client_secret={
															process.env.REACT_APP_LINKEDIN_SECRET_KEY
														}
														redirect_uri={`${process.env.REACT_APP_DOMAIN}/user-dashboard`}
														onResolve={loginWithSocialHandler}
														scope="r_liteprofile r_emailaddress"
														onReject={(error) => {
															toast.error("Login with linkedin failed!", {
																position: "top-right",
																theme: "colored",
																autoClose: 2500
															});
														}}
														className="social-icon"
													>
														<i className="fa-brands fa-linkedin-in"></i>
													</LoginSocialLinkedin>
												</div>
											</>
										)}

										{/* auth toggle  */}
										{!socialPassword && (
											<p id="first-time" className="login">
												{alreadyAcc}{" "}
												<NavLink to={"/log-in"}>{loginLabel}</NavLink>
											</p>
										)}

										{/* user_type toggle  */}
										{!socialPassword && (
											<p id="first-time" style={{ marginTop: "5px" }}>
												{userType === "student" ? ifNotStudent : ifStudent}{" "}
												<span
													onClick={() =>
														setUserType((prev) =>
															prev === "student" ? "general" : "student"
														)
													}
												>
													{signUPLabel}
												</span>{" "}
												{here}
											</p>
										)}
									</div>
								) : (
									<AuthInfoMsg
										heading={heading_ac_email}
										description={description_ac_email}
										or={or}
										resend_btn={resend_btn}
										sending_btn={sending_btn}
										isLoading={isLoading}
										resendEmailHandler={resendEmailHandler}
									/>
								)}
							</div>
							{/* signup-right end  */}
						</div>
					</div>
				</div>

				{/* add sub-footer  */}
				<SubFooter contents={contents} />
			</div>
			{/* shapes animation component   */}
			<ShapeAnimation />
		</>
	);
};

export default SignUp;
