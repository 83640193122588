// external components
import { Tooltip, Zoom } from "@mui/material";

const DeleteTooltip = ({ content, setDeleteItem, _id }) => {
	return (
		<div className="for-item-deleted">
			<Tooltip
				title={content}
				placement="top"
				arrow
				TransitionComponent={Zoom}
				componentsProps={{
					tooltip: {
						sx: {
							bgcolor: "#040026",
							"& .MuiTooltip-arrow": {
								color: "#040026"
							},
							fontSize: "13px",
							fontWeight: "400",
							textAlign: "center"
						}
					}
				}}
				onClick={() => setDeleteItem({ _id })}
			>
				<i className="fa-solid fa-trash-can" id="delete-icon"></i>
			</Tooltip>
		</div>
	);
};

export default DeleteTooltip;
