// external components
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

// internal components

const AccordionCom = ({ title, contents, expanded, setExpanded }) => {
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Accordion
			expanded={expanded === title}
			onChange={handleChange(title)}
			className="accordion"
		>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
			>
				<h2 className="accordion-title">{title}</h2>
			</AccordionSummary>

			<AccordionDetails>
				<div className="edit-icon-wrapper">
					<i className="fa-solid fa-pen-to-square edit-icon"></i>
					Edit
				</div>

				<div className="input-fields-wrapper">{contents}</div>

				<div className="btn-wrapper">
					<button className="primary-btn-styles" id="cancel-btn">
						Cancel
					</button>
					<button className="primary-btn-styles">Save</button>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default AccordionCom;
