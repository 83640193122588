// external components
import { memo, useEffect, useState } from "react";

// internal components
import { DeleteTooltip, FirstCol } from "../common";

const SkillItem = ({
	itemValues,
	handleSetActive,
	contents,
	setDeleteItem,
	updateResumeInfo,
	delay
}) => {
	// skillItem contents
	const {
		skill,
		level,
		novice,
		beginner,
		skillful,
		experienced,
		expert,
		not_specific,
		deleteText
	} = contents.editor;

	// assign
	const _id = itemValues._id;

	// getting & updating links all values start
	const [inputValues, setInputValues] = useState(itemValues);

	const [latestUpdate, setLatestUpdate] = useState({});

	useEffect(() => {
		if (Object.keys(latestUpdate)[0]) {
			const timeoutId = setTimeout(() => {
				updateResumeInfo({ ...latestUpdate, _id });
			}, delay);

			return () => {
				clearTimeout(timeoutId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestUpdate]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setInputValues((prevValues) => ({
			...prevValues,
			[name]: value
		}));

		setLatestUpdate({
			[name]: value || null
		});
	};
	// getting & updating links all values end

	// collapse toggle
	const handleActivate = () => {
		handleSetActive(_id);
	};

	// for level style start
	const changeContainerBg = (level) => {
		if (level === "Novice") {
			return { backgroundColor: "#FFEAEC" };
		} else if (level === "Beginner") {
			return { backgroundColor: "#FEEBE3" };
		} else if (level === "Skillful") {
			return { backgroundColor: "#FFF2CC" };
		} else if (level === "Experienced") {
			return { backgroundColor: "#E7F4ED" };
		} else if (level === "Expert") {
			return { backgroundColor: "#F1F2FF" };
		}
	};

	const changeTranslateX = (level) => {
		if (level === "Novice") {
			return { transform: "translateX(0%)", backgroundColor: "#FE7D8B" };
		} else if (level === "Beginner") {
			return { transform: "translateX(100%)", backgroundColor: "#F68559" };
		} else if (level === "Skillful") {
			return { transform: "translateX(200%)", backgroundColor: "#EC930C" };
		} else if (level === "Experienced") {
			return { transform: "translateX(300%)", backgroundColor: "#48BA75" };
		} else if (level === "Expert") {
			return { transform: "translateX(400%)", backgroundColor: "#9BA1FB" };
		}
	};

	const changeBorderColor = (level) => {
		if (level === "Novice") {
			return { backgroundColor: "#FE7D8B" };
		} else if (level === "Beginner") {
			return { backgroundColor: "#F68559" };
		} else if (level === "Skillful") {
			return { backgroundColor: "#EC930C" };
		} else if (level === "Experienced") {
			return { backgroundColor: "#48BA75" };
		} else if (level === "Expert") {
			return { backgroundColor: "#9BA1FB" };
		}
	};

	const changeTextColor = (level) => {
		if (level === "Novice") {
			return { color: "#FE7D8B" };
		} else if (level === "Beginner") {
			return { color: "#F68559" };
		} else if (level === "Skillful") {
			return { color: "#EC930C" };
		} else if (level === "Experienced") {
			return { color: "#48BA75" };
		} else if (level === "Expert") {
			return { color: "#9BA1FB" };
		}
	};

	const hoverColor = (event) => {
		if (inputValues.skill_level === "Novice") {
			event.target.style.background = "#ffd0d5";
		} else if (inputValues.skill_level === "Beginner") {
			event.target.style.background = "#fdd2c0";
		} else if (inputValues.skill_level === "Skillful") {
			event.target.style.background = "#fddb8c";
		} else if (inputValues.skill_level === "Experienced") {
			event.target.style.background = "#c6e4d2";
		} else if (inputValues.skill_level === "Expert") {
			event.target.style.background = "#dbdeff";
		}
	};

	const whenHoveOut = (event) => {
		event.target.style.background = "";
	};
	// for level style end

	return (
		<>
			<div className="specific-item-container">
				<div className="specific-item-wrapper">
					<div className="content-title-wrapper" onClick={handleActivate}>
						<div className="content-title">
							<h4>{inputValues.skill ? inputValues.skill : not_specific} </h4>
							{inputValues.skill_level && (
								<span>{inputValues.skill_level}</span>
							)}
						</div>

						<i className="fa-solid fa-chevron-up" id="up"></i>

						<i className="fa-solid fa-chevron-down" id="down"></i>
					</div>

					{/* for delete that item */}
					<DeleteTooltip
						content={deleteText}
						setDeleteItem={setDeleteItem}
						_id={_id}
					/>
				</div>

				<div className="row-container-wrapper">
					{/* Skill and level start  */}
					<div className="row-container">
						<FirstCol
							content={skill}
							name={"skill"}
							value={inputValues.skill}
							onChange={handleInputChange}
							haveIm={true}
						/>

						<div className="second-col">
							<div className="sub-label">
								<label htmlFor="Level">
									{level}&nbsp;—&nbsp;
									<span style={changeTextColor(inputValues.skill_level)}>
										{contents.title === "bangla"
											? (inputValues.skill_level === "Novice" && novice) ||
											  (inputValues.skill_level === "Beginner" && beginner) ||
											  (inputValues.skill_level === "Skillful" && skillful) ||
											  (inputValues.skill_level === "Experienced" &&
													experienced) ||
											  (inputValues.skill_level === "Expert" && expert)
											: inputValues.skill_level}
									</span>{" "}
								</label>
							</div>

							<div
								className="input-field level-container"
								style={changeContainerBg(inputValues.skill_level)}
							>
								<div
									className="level"
									onClick={() => {
										setInputValues((prev) => ({
											...prev,
											skill_level: "Novice"
										}));
										updateResumeInfo({
											skill_level: "Novice",
											_id
										});
									}}
									onMouseOver={hoverColor}
									onMouseOut={whenHoveOut}
								>
									<span
										id="for-border"
										style={changeBorderColor(inputValues.skill_level)}
									></span>
								</div>

								<div
									className="level"
									onMouseOver={hoverColor}
									onMouseOut={whenHoveOut}
									onClick={() => {
										setInputValues((prev) => ({
											...prev,
											skill_level: "Beginner"
										}));
										updateResumeInfo({
											skill_level: "Beginner",
											_id
										});
									}}
								>
									<span
										id="for-border"
										style={changeBorderColor(inputValues.skill_level)}
									></span>
								</div>

								<div
									className="level"
									onMouseOver={hoverColor}
									onMouseOut={whenHoveOut}
									onClick={() => {
										setInputValues((prev) => ({
											...prev,
											skill_level: "Skillful"
										}));
										updateResumeInfo({
											skill_level: "Skillful",
											_id
										});
									}}
								>
									<span
										id="for-border"
										style={changeBorderColor(inputValues.skill_level)}
									></span>
								</div>

								<div
									className="level"
									onMouseOver={hoverColor}
									onMouseOut={whenHoveOut}
									onClick={() => {
										setInputValues((prev) => ({
											...prev,
											skill_level: "Experienced"
										}));
										updateResumeInfo({
											skill_level: "Experienced",
											_id
										});
									}}
								>
									<span
										id="for-border"
										style={changeBorderColor(inputValues.skill_level)}
									></span>
								</div>

								<div
									className="level"
									onMouseOver={hoverColor}
									onMouseOut={whenHoveOut}
									onClick={() => {
										setInputValues((prev) => ({
											...prev,
											skill_level: "Expert"
										}));
										updateResumeInfo({
											skill_level: "Expert",
											_id
										});
									}}
								></div>

								<div
									className="selected-level"
									style={changeTranslateX(inputValues.skill_level)}
								></div>
							</div>
						</div>
					</div>
					{/* label and Level end  */}
				</div>
			</div>
		</>
	);
};

export default memo(SkillItem);
