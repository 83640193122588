// internal components
import "./_PaymentMethods.scss";

const PaymentMethod = () => {
	const imgNames = [
		"visa-svg.svg",
		"mastercard-svg.svg",
		"amex-svg.svg",
		"unionapy-svg.svg",
		"janina-svg.png",
		"dbbl-svg.svg",
		"bkash-svg.svg",
		"nagad-svg.svg",
		"rocket-svg.svg",
		"upay-svg.png",
		"tap-svg.svg",
		"surecash-svg.svg",
		"mcash-svg.svg",
		"mycash-svg.svg",
		"qcash-svg.svg",
		"fastcash-svg.svg",
		"tappay-svg.svg",
		"citytouch-svg.svg",
		"bracbank-svg.svg",
		"bankasia-svg.svg",
		"islamibank-svg.svg",
		"abbank-svg.jpg,",
		"mtb-svg.svg",
		"janatabank-svg.jpg",
		"sbbank-svg.jpg",
		"fsib-svg.png",
		"modhumoti-svg.svg",
		"ok-svg.svg",
		"dmoney-svg.svg",
		"islamicwallet-svg.jpg",
		"ipay-svg.svg"
	];
	return (
		<>
			<div className="footer-above">
				<div className="container">
					<div className="row">
						<div className="footer-payment col-md-12">
							<div className="row">
								<div className="col-md-12 paywith-img">
									<ul>
										<li className="br-1">Pay with</li>
										<li>
											<img alt="" src="/assets/paywith/visa-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/mastercard-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/amex-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/unionapy-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/janina-svg.png" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/dbbl-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/bkash-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/nagad-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/rocket-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/upay-svg.png" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/tap-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/surecash-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/mcash-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/mycash-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/qcash-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/fastcash-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/tappay-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/citytouch-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/bracbank-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/bankasia-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/islamibank-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/abbank-svg.jpg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/mtb-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/janatabank-svg.jpg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/sbbank-svg.jpg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/fsib-svg.png" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/modhumoti-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/ok-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/dmoney-svg.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/islamicwallet-svg.jpg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/ipay-svg.svg" />
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="footer-payment col-md-12">
							<div className="row">
								<div className="col-md-4 col-xs-7 secure-img">
									<ul>
										<li className="br-1">Secured Payment</li>
										<li>
											<img alt="" src="/assets/paywith/verified-by-visa.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/mater-card.svg" />
										</li>
										<li>
											<img alt="" src="/assets/paywith/pcidss.svg" />
										</li>
									</ul>
								</div>
								<div
									className="col-md-7 col-xs-5 br-1"
									style={{ paddingRight: 0 }}
								>
									<ul>
										<li className="br-1">POWERED BY</li>
										<li>
											<a
												href="https://www.sslcommerz.com/"
												target="_blank"
												rel="noreferrer"
											>
												<img
													alt=""
													width="86"
													src="/assets/paywith/sslcommerz-logo.svg"
												/>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PaymentMethod;
