// external components

// internal components
const PreWritten = ({ setPhrasesT, phrasesT, phrasesBtnRef, contents }) => {
	return (
		<div
			id="pre-written"
			onClick={() => setPhrasesT(!phrasesT)}
			ref={phrasesBtnRef}
		>
			<span className={phrasesT ? "active" : ""} id="pre-written-txt">
				<span id="first-part">
					{contents.title === "bangla" ? "পূর্ব-লিখিত" : "Pre-written"}
				</span>
				&nbsp;
				{contents.title === "bangla" ? "বাক্যাংশ" : "Phrases"}
				{phrasesT ? (
					<i className="fa-solid fa-circle-xmark" id="active"></i>
				) : (
					<i className="fa-solid fa-circle-plus"></i>
				)}
			</span>
		</div>
	);
};

export default PreWritten;
