// external components
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import sortArray from "sort-array";

// internal components
import RestrictionDialog from "../../common/RestrictionDialog";
import { getResumeCounter } from "../../common/function";
import { GetContextApi } from "../../storage/ContextApi";
import "./ResumeTemplates.css";

const ResumeTemplates = ({ allTemplates }) => {
	// for redirect page
	const Navigate = useNavigate();

	// for getting selectedTemplate
	const {
		currentUser,
		setCurrentUser,
		setSelectedRTemplate,
		contents,
		freeTemplateName
	} = GetContextApi();

	// contents
	const {
		title,
		sub_title,
		button_text,
		all_templates,
		creative,
		simple,
		professional,
		modern,
		choose_button_text
	} = contents.resume_templates;

	// restriction contents
	const {
		fu_premium_template,
		multiple_free_template,
		additional_resume_restriction,
		additional_template_restriction
	} = contents.package_limit;
	const { upgrade_now } = contents.navbar;

	// for getting selected templates
	const [selectedTab, setSelectedTab] = useState("All templates");

	// separate template base on category start
	const [displayingTemplates, setDisplayingTemplates] = useState([]);

	useEffect(() => {
		if (allTemplates.length > 0) {
			if (selectedTab === "All templates") {
				setDisplayingTemplates(allTemplates);
			} else {
				setDisplayingTemplates(
					allTemplates.filter((value) => value.category === selectedTab)
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allTemplates.length > 0, selectedTab]);
	// separate template base on category end

	// for fetching currentUser's all resumes from db start
	const [getResumes, setResumes] = useState([]);

	const getAllResumes = async () => {
		try {
			const response = await fetch("/resume/all-resumes");

			const result = await response.json();

			if (result.error || response.status !== 200) {
				setResumes([]);
			} else if (response.status === 200) {
				setResumes(result);
			}
		} catch (error) {
			toast.error("Maintenance mode, Try again later!", {
				position: "top-right",
				theme: "colored",
				autoClose: 2500
			});
			setResumes([]);
		}
	};

	useEffect(() => {
		if (currentUser) {
			getAllResumes();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for fetching currentUser's all resumes from db end

	// for updating counter number into server start
	const updatingCounter = async (obj) => {
		try {
			const response = await fetch(`/counter/update`, {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				Navigate(`/resume/${obj.count}/create-resume`);
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for updating counter number into server end

	// for redirectHandler start
	const [restrictionObj, setRestrictionObj] = useState("");

	const redirectHandler = (value) => {
		if (currentUser) {
			const resumeChecker = currentUser.maximum_resumes > getResumes.length;

			const freeTemplateChecker = value.name === freeTemplateName;

			if (
				(resumeChecker && currentUser.used_templates.includes(value._id)) ||
				(resumeChecker && currentUser.available_templates) ||
				(resumeChecker && freeTemplateChecker) ||
				(getResumes.length === 0 && freeTemplateChecker)
			) {
				setSelectedRTemplate(value._id);

				(async () => {
					const result = await getResumeCounter();

					if (result) {
						await updatingCounter({
							selectedTab: "Resume",
							count: result.resume + 1
						});
					}
				})();

				setCurrentUser((prev) => {
					const checker = prev.used_templates.includes(value._id);

					const updatedTemplates = checker
						? [...prev.used_templates]
						: [...prev.used_templates, value._id];

					const available_templates =
						checker || freeTemplateChecker
							? prev.available_templates
							: prev.available_templates - 1;

					return {
						...prev,
						used_templates: updatedTemplates,
						available_templates
					};
				});
			} else {
				if (
					!currentUser.available_templates &&
					currentUser.package["Package Name"] !== "Free Plan"
				) {
					setRestrictionObj(additional_template_restriction);
				} else if (
					!resumeChecker &&
					currentUser.package["Package Name"] !== "Free Plan"
				) {
					setRestrictionObj(additional_resume_restriction);
				} else if (!resumeChecker && freeTemplateChecker) {
					setRestrictionObj(multiple_free_template);
				} else if (currentUser.package["Package Name"] === "Free Plan") {
					setRestrictionObj(fu_premium_template);
				}
			}
		} else {
			Navigate("/sign-up");
		}
	};
	// for redirectHandler end

	return (
		<>
			<div className="container">
				<div className="resume-templates-container row">
					<div className="resume-templates-wrapper col-11 col-sm-12">
						{/* templates-header start  */}
						<div className="templates-header">
							<h1 className="templates-root__header-title">{title}</h1>

							<p className="templates-root__header-subtitle">{sub_title}</p>

							<div className="templates-root__header-btn-container">
								<button
									type="button"
									className="primary-btn-styles"
									onClick={() =>
										currentUser
											? Navigate("/user-dashboard")
											: Navigate("/sign-up")
									}
								>
									{button_text}
								</button>
							</div>
						</div>
						{/* templates-header end  */}

						{/* templates-filter start  */}
						<div className="templates-filter">
							<div className="templates-filter__container">
								<div className="templates-filter__content">
									<div className="templates-filter__tabs">
										<div
											className="templates-filter__tabs-selector"
											id={
												(selectedTab === "All templates" &&
													"when-all-templates") ||
												(selectedTab === "Creative" && "when-creative") ||
												(selectedTab === "Simple" && "when-simple") ||
												(selectedTab === "Professional" &&
													"when-professional") ||
												(selectedTab === "Modern" && "when-modern")
											}
										></div>

										<span
											className={
												selectedTab === "All templates"
													? "templates-filter__tab active"
													: "templates-filter__tab"
											}
											onClick={() => setSelectedTab("All templates")}
										>
											<img
												src="/assets/icons/all-templates.png"
												alt="icon"
												className="cat-icon"
												id={selectedTab === "All templates" ? "active" : ""}
											/>

											{all_templates}
										</span>

										<span
											className={
												selectedTab === "Creative"
													? "templates-filter__tab active"
													: "templates-filter__tab"
											}
											onClick={() => setSelectedTab("Creative")}
										>
											<img
												src="/assets/icons/creative-templates.png"
												alt="icon"
												className="cat-icon"
												id={selectedTab === "Creative" ? "active" : ""}
											/>
											{creative}
										</span>

										<span
											className={
												selectedTab === "Simple"
													? "templates-filter__tab active"
													: "templates-filter__tab"
											}
											onClick={() => setSelectedTab("Simple")}
										>
											<img
												src="/assets/icons/simple-templates.png"
												alt="icon"
												className="cat-icon"
												id={selectedTab === "Simple" ? "active" : ""}
											/>
											{simple}
										</span>

										<span
											className={
												selectedTab === "Professional"
													? "templates-filter__tab active"
													: "templates-filter__tab"
											}
											onClick={() => setSelectedTab("Professional")}
										>
											<img
												src="/assets/icons/professional-templates.png"
												alt="icon"
												className="cat-icon"
												id={selectedTab === "Professional" ? "active" : ""}
											/>
											{professional}
										</span>

										<span
											className={
												selectedTab === "Modern"
													? "templates-filter__tab active"
													: "templates-filter__tab"
											}
											onClick={() => setSelectedTab("Modern")}
										>
											<img
												src="/assets/icons/modern-templates.png"
												alt="icon"
												className="cat-icon"
												id={selectedTab === "Modern" ? "active" : ""}
											/>
											{modern}
										</span>
									</div>
								</div>
							</div>
						</div>
						{/* templates-filter end */}

						{/* displaying-all-templates start */}
						<div className="displaying-all-templates">
							<div className="templates-grid">
								{displayingTemplates.length > 0 &&
									sortArray(displayingTemplates, {
										by: "updatedAt",
										order: "desc"
									}).map((value, index) => {
										return (
											<div
												className="templates-grid__cell"
												key={index}
												data-aos="fade-up"
											>
												<div className="templates-card-body">
													<div className="templates-card-content">
														<div className="templates-card-preview">
															<img
																src={`/assets/templates/${value.img}`}
																alt={`${value.name}`}
																className="img-fluid"
															/>

															<div className="templates-card__action">
																<div
																	className="button"
																	onClick={() => redirectHandler(value)}
																>
																	{choose_button_text}
																</div>
															</div>
														</div>

														{/* templates-card__options start  */}
														<div className="templates-card__options">
															<div className="templates-card__colors">
																{value.colors.length > 0 &&
																	value.colors.map((color, index) => {
																		return (
																			<div
																				className="templates-card__colors-item"
																				style={{ background: color }}
																				key={index}
																				onClick={() => redirectHandler(value)}
																			></div>
																		);
																	})}
															</div>

															{/* for free text  */}
															{[freeTemplateName].includes(value.name) && (
																<div className="free-txt">Free</div>
															)}
														</div>
														{/* templates-card__options end  */}
													</div>
												</div>

												<div className="templates-card__footer d-none">
													<div className="templates-card__name">
														{value.name}
													</div>
													<div className="templates-card__caption">
														{value.caption}
													</div>
												</div>
											</div>
										);
									})}
							</div>
						</div>
						{/* displaying-all-templates end */}
					</div>
				</div>

				{/* for restriction dialog box */}
				{restrictionObj && (
					<RestrictionDialog
						restrictionObj={restrictionObj}
						setRestrictionObj={setRestrictionObj}
						btn_text={upgrade_now}
					/>
				)}
			</div>
		</>
	);
};

export default ResumeTemplates;
