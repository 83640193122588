const SecondCol = ({ content, name, haveIm, value, onChange }) => {
	return (
		<div className="second-col">
			<div className="sub-label">
				<label htmlFor={name}>
					{content} {haveIm && <span id="important">*</span>}
				</label>
			</div>

			<div className="input-field">
				<input id={name} name={name} value={value} onChange={onChange} />
			</div>
		</div>
	);
};

export default SecondCol;
