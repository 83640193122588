// internal components
import { addOneMoreItem } from "./function";

const AddMore = ({
	userId,
	counter,

	section,
	item,
	newArr,

	arrCom,
	setArrCom,
	itemCom,

	setResume,

	controlActiveId,

	initialContent,
	moreContent
}) => {
	return (
		<div
			id="add-more"
			onClick={() => {
				addOneMoreItem(
					userId,
					counter,
					section,
					item,
					newArr,
					controlActiveId,
					setArrCom,
					itemCom,
					setResume
				);
			}}
		>
			{arrCom.length === 0 ? (
				<span>
					<i className="fa-solid fa-plus"></i> {initialContent}
				</span>
			) : (
				<span>
					<i className="fa-solid fa-plus"></i> {moreContent}
				</span>
			)}
		</div>
	);
};

export default AddMore;
