// external components
import { toBengaliNumber } from "bengali-number";

const EditorTips = ({ contents, maxWords, wordCount }) => {
	const { recruiter_tip, write, words_maximum, words_left } = contents.editor;

	return (
		<div className="tips">
			<div className="text">
				{recruiter_tip}: {write}{" "}
				{contents.title === "bangla" ? toBengaliNumber(maxWords) : maxWords}{" "}
				{words_maximum}
			</div>

			<div
				className="counter"
				id={maxWords - wordCount <= 5 ? "when-limit-up" : ""}
			>
				{contents.title === "bangla"
					? toBengaliNumber(maxWords - wordCount)
					: maxWords - wordCount}{" "}
				{words_left}
			</div>
		</div>
	);
};

export default EditorTips;
