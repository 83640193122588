// external components

// internal components
import PageHeader from "../../common/PageHeader";
import { GetContextApi } from "../../storage/ContextApi";
import "./RefundPolicy.css";

const RefundPolicy = () => {
	// contents
	const { contents } = GetContextApi();

	const {
		issuance_of_refunds,
		issuance_of_refunds_1,
		issuance_of_refunds_2,
		issuance_of_refunds_3,
		refund_type,
		refund_type_heading,
		refund_type_1_heading,
		refund_type_2_heading,
		refund_type_3_heading,
		refund_type_2_li_1,
		refund_type_2_li_2,
		refund_type_2_li_3,
		refund_type_2_li_4,
		refund_type_2_li_5,
		refund_type_2_li_6,
		refund_type_2_li_7,
		refund_type_2_li_8,
		refund_type_2_note,

		method_th_1,
		method_th_2,
		method_th_3,

		method_td_1_method,
		method_td_1_option,
		method_td_1_time,

		method_td_2_method,
		method_td_2_option,
		method_td_2_time,

		method_td_3_method,
		method_td_3_option,
		method_td_3_time,

		method_td_4_method,
		method_td_4_option,
		method_td_4_time,
		method_table_note,

		model_th_1,
		model_th_2,

		model_td_1_title,
		model_td_1_description,

		model_td_2_title,
		model_td_2_description
	} = contents.refund_policy;

	return (
		<>
			<PageHeader
				pageName={contents.title === "bangla" ? "ফেরত নীতি" : "REFUND POLICY"}
				link={contents.title === "bangla" ? "ফেরত নীতি" : "Refund-Policy"}
				whichLanguage={contents.title}
			/>

			{/* contents-section start  */}
			<div className="container">
				<div className="refund-policy-container row ">
					<div className="refund-policy-wrapper col-11 col-sm-12">
						<div
							className="clearfix"
							style={{ textAlign: "justify" }}
							data-spm-anchor-id="a2a0e.11887082.4745536990.i6.5eac18ceYMJh3e"
						>
							{/* title  */}
							<h4 id="title">{issuance_of_refunds}</h4>

							<div className="issuance-of-refunds-items">
								<div>{issuance_of_refunds_1}</div>

								<div
									dangerouslySetInnerHTML={{ __html: issuance_of_refunds_2 }}
								></div>

								<div>{issuance_of_refunds_3}</div>
							</div>
						</div>

						<div className="clearfix" style={{ textAlign: "justify" }}>
							<h4 style={{ marginTop: "20px" }}>{refund_type}</h4>

							<p
								id="sub-title"
								dangerouslySetInnerHTML={{ __html: refund_type_heading }}
							></p>

							<div className="issuance-of-refunds-items">
								{/* section-1 */}
								<div
									dangerouslySetInnerHTML={{ __html: refund_type_1_heading }}
									className="section"
								></div>

								{/* section-2  */}
								<div
									dangerouslySetInnerHTML={{ __html: refund_type_2_heading }}
									className="section"
								></div>

								<ul className="refund-type-lists">
									<li
										dangerouslySetInnerHTML={{ __html: refund_type_2_li_1 }}
									></li>
									<li
										dangerouslySetInnerHTML={{ __html: refund_type_2_li_2 }}
									></li>
									<li
										dangerouslySetInnerHTML={{ __html: refund_type_2_li_3 }}
									></li>
									<li
										dangerouslySetInnerHTML={{ __html: refund_type_2_li_4 }}
									></li>
									<li
										dangerouslySetInnerHTML={{ __html: refund_type_2_li_5 }}
									></li>
									<li
										dangerouslySetInnerHTML={{ __html: refund_type_2_li_6 }}
									></li>
									<li
										dangerouslySetInnerHTML={{ __html: refund_type_2_li_7 }}
									></li>
									<li
										dangerouslySetInnerHTML={{ __html: refund_type_2_li_8 }}
									></li>
								</ul>

								{/* note  */}
								<p
									id="note"
									dangerouslySetInnerHTML={{ __html: refund_type_2_note }}
								></p>

								{/* section-3  */}
								<div
									dangerouslySetInnerHTML={{ __html: refund_type_3_heading }}
									className="section"
								></div>
							</div>
						</div>

						<div className="panel-group">
							<div className="panel">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th className="th">{method_th_1}</th>

											<th className="th">{method_th_2}</th>

											<th className="th">{method_th_3}</th>
										</tr>
									</thead>

									<tbody>
										<tr>
											<td>{method_td_1_method}</td>
											<td>{method_td_1_option}</td>
											<td
												dangerouslySetInnerHTML={{ __html: method_td_1_time }}
											></td>
										</tr>

										<tr>
											<td>{method_td_2_method}</td>
											<td>{method_td_2_option}</td>

											<td
												dangerouslySetInnerHTML={{ __html: method_td_2_time }}
											></td>
										</tr>

										<tr>
											<td>{method_td_3_method}</td>
											<td>{method_td_3_option}</td>

											<td
												dangerouslySetInnerHTML={{ __html: method_td_3_time }}
											></td>
										</tr>

										<tr>
											<td>{method_td_4_method}</td>
											<td>{method_td_4_option}</td>

											<td
												dangerouslySetInnerHTML={{ __html: method_td_4_time }}
											></td>
										</tr>
									</tbody>
								</table>
							</div>

							<p
								id="note"
								className="inside-table"
								dangerouslySetInnerHTML={{ __html: method_table_note }}
							></p>

							<div className="panel">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th className="th">{model_th_1}</th>

											<th className="th">{model_th_2}</th>
										</tr>
									</thead>

									<tbody>
										<tr>
											<td>{model_td_1_title}</td>
											<td>{model_td_1_description}</td>
										</tr>

										<tr>
											<td>{model_td_2_title}</td>
											<td>{model_td_2_description}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* contents-section end  */}
		</>
	);
};

export default RefundPolicy;
