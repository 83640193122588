const SkillsLevel = ({
	title_class,
	level_class,
	skill,
	skill_level,
	bg_color,
	displaying_level,
	end_bullet
}) => {
	return (
		<>
			<div className={title_class}>{skill}</div>
			{!displaying_level
				? skill && (
						<div
							className={level_class}
							style={{ overflow: end_bullet ? "" : "hidden" }}
						>
							<div
								style={{
									width:
										((skill_level === "Novice" ||
											skill_level === "Working knowledge" ||
											skill_level === "A1") &&
											"20%") ||
										((skill_level === "Beginner" ||
											skill_level === "Good working knowledge" ||
											skill_level === "A2") &&
											"40%") ||
										((skill_level === "Skillful" ||
											skill_level === "Very good command" ||
											skill_level === "B1") &&
											"60%") ||
										((skill_level === "Experienced" ||
											skill_level === "Highly proficient" ||
											skill_level === "B2") &&
											"80%") ||
										((skill_level === "Expert" ||
											skill_level === "Native speaker" ||
											skill_level === "C1" ||
											skill_level === "C2") &&
											"100%"),
									position: "absolute",
									height: "100%",
									backgroundColor: bg_color
								}}
							>
								{end_bullet && (
									<div
										style={{
											width: "5px",
											height: "5px",
											borderRadius: "50%",
											position: "absolute",
											right: 0,
											top: "-2px",

											backgroundColor: bg_color
										}}
									></div>
								)}
							</div>
						</div>
				  )
				: ""}
		</>
	);
};

export default SkillsLevel;
