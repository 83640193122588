// external components
import { toast } from "react-hot-toast";

// internal components
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import "./_AdminStudentRequest.scss";

const AdminStudentRequest = ({ allUsers }) => {
	const getThatUsers = allUsers.filter(
		(user) => user.type === "general" && user.stu_card
	);

	const updateUser = async (obj) => {
		try {
			const response = await fetch("/admin/update-user", {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					theme: "dark",
					autoClose: 2500
				});
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
		}
	};

	return (
		<>
			{getThatUsers.length > 0 &&
				getThatUsers.map((user) => (
					<div className="get-that-users-container">
						<div className="name">{user.name}</div>
						<div className="name">{user.email}</div>
						<div className="name">{user.type}</div>
						<div className="stu-card">
							<PhotoProvider>
								<PhotoView src={`/assets/student-cards/${user.stu_card}`}>
									<img
										src={`/assets/student-cards/${user.stu_card}`}
										alt="stu-card"
										id="stu-card"
									/>
								</PhotoView>
							</PhotoProvider>
						</div>

						<button
							onClick={() => updateUser({ type: "student", _id: user._id })}
							className="primary-btn-styles"
						>
							Make as student
						</button>
					</div>
				))}
		</>
	);
};

export default AdminStudentRequest;
