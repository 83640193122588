/* eslint-disable import/no-webpack-loader-syntax */
// internal components
import GraphicsDesignerStyle from "!!raw-loader!./GraphicsDesigner.css";
import {
	RichTextParser,
	SectionTitle,
	SkillsLevelRound,
	Time
} from "../Components";
import {
	checkVariables,
	divideAndAddBrTag,
	fetchingResumeData,
	isArrayEmpty,
	objHasAnyValue,
	removeEmptyObjects
} from "../Components/Utils";

const GraphicsDesigner = ({ getResume }) => {
	// fetching resume's all data
	const {
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		professional_summary_title,
		rich_text_content,
		employment_history_title,
		employment_history_items,
		education_title,
		education_items,
		project_title,
		project_items,
		web_link_items,
		skills_title,
		skills_items,
		displaying_level,
		languages_title,
		languages_items,
		hobbies_title,
		hobbies_description,
		activity_title,
		activity_items,
		internship_title,
		internship_items,
		courses_title,
		courses_items,
		references_title,
		references_hide,
		references_items,
		customArr,
		disclaimer_title,
		disclaimer_description
	} = fetchingResumeData(getResume);

	//  professional_summary
	const professional_summary = rich_text_content ? (
		<div className="section">
			<SectionTitle
				className="title"
				sectionName={professional_summary_title}
				equalTo="Professional Summary"
				initialName="About"
			/>

			<div className="section__contents">
				<RichTextParser
					className={"rich-text-container"}
					rich_text={rich_text_content}
				/>
			</div>
		</div>
	) : (
		""
	);

	//  employment_history
	const employmentHistoryItemsArr = employment_history_items.filter((obj) =>
		objHasAnyValue(obj)
	);

	const employmentHistoryLastItem = employmentHistoryItemsArr.length - 1;

	const employment_history = !isArrayEmpty(employmentHistoryItemsArr) ? (
		<div className="section">
			<SectionTitle
				className="title"
				sectionName={employment_history_title}
				equalTo="EmploymenT History"
				initialName="Work Experience"
			/>

			<div className="section__contents">
				{employmentHistoryItemsArr.map((item, index) => {
					const checkTime = checkVariables(item.start_date, item.end_date);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							{checkTime && (
								<div className="item_time__wrapper">
									<Time
										start={item.start_date}
										end={item.end_date}
										isDisplayColumn={true}
									/>
								</div>
							)}

							<div
								className="item-contents"
								id={index === employmentHistoryLastItem ? "last" : ""}
							>
								<div className="item-dot-outside"></div>
								<div className="item-dot-inside"></div>

								<div className="item__header">
									{item.employment_job_title}

									<div className="item__sub_header">
										{item.employer}

										{item.employer && item.employment_city && ", "}

										{item.employment_city}
									</div>
								</div>

								{item.employment_rich_text_content && (
									<RichTextParser
										className={"item__rich_editor"}
										rich_text={item.employment_rich_text_content}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// projects
	const projectsItemsArr = project_items.filter((obj) => objHasAnyValue(obj));

	const projectsLastItem = projectsItemsArr.length - 1;

	const projects = !isArrayEmpty(projectsItemsArr) ? (
		<div className="section">
			<SectionTitle
				className="title"
				sectionName={project_title}
				equalTo="Projects/Portfolio"
				initialName="Projects"
			/>

			<div className="section__contents">
				{projectsItemsArr.map((item, index) => {
					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item_time__wrapper">
								{!isArrayEmpty(item.project_links) &&
									removeEmptyObjects(item.project_links, ["label", "link"]).map(
										(label, index) => {
											return (
												<div key={index}>
													<a
														href={label.link}
														target="_blank"
														rel="noopener noreferrer"
														className="link"
													>
														{label.label}
													</a>
												</div>
											);
										}
									)}
							</div>

							<div
								className="item-contents"
								id={index === projectsLastItem ? "last" : ""}
							>
								<div className="item-dot-outside"></div>
								<div className="item-dot-inside"></div>

								<div className="item__header">
									{item.project_name}

									<div className="item__sub_header">
										{item.project_duration && ` ( ${item.project_duration} )`}
									</div>
								</div>

								{checkVariables(item.technologies) && (
									<div className="item__sub_header when-technologies">
										<strong>Technologies&nbsp;:&nbsp;</strong>
										{item.technologies}
									</div>
								)}

								{item.pro_description && (
									<RichTextParser
										className={"item__rich_editor"}
										rich_text={item.pro_description}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// education
	const educationItemsArr = education_items.filter((obj) =>
		objHasAnyValue(obj)
	);

	const educationLastItem = educationItemsArr.length - 1;

	const education = !isArrayEmpty(educationItemsArr) ? (
		<div className="section">
			<div className="title">{education_title}</div>

			<div className="section__contents">
				{educationItemsArr.map((item, index) => {
					const checkTime = checkVariables(
						item.edu_start_date,
						item.edu_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							{checkTime && (
								<div className="item_time__wrapper">
									<Time
										start={item.edu_start_date}
										end={item.edu_end_date}
										isDisplayColumn={true}
										fromWhere={"education"}
									/>
								</div>
							)}

							<div
								className="item-contents"
								id={index === educationLastItem ? "last" : ""}
							>
								<div className="item-dot-outside"></div>
								<div className="item-dot-inside"></div>

								<div className="item__header">
									{item.school}

									<div className="item__sub_header">
										{item.degree}

										{item.degree && item.edu_city && ", "}

										{item.edu_city}
									</div>
								</div>

								{item.edu_rich_text_content && (
									<RichTextParser
										className={"item__rich_editor"}
										rich_text={item.edu_rich_text_content}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// internship
	const internshipArr = internship_items.filter((obj) => objHasAnyValue(obj));

	const internshipLastItem = internshipArr.length - 1;

	const internship = !isArrayEmpty(internshipArr) ? (
		<div className="section">
			<div className="title">{internship_title}</div>

			<div className="section__contents">
				{internshipArr.map((item, index) => {
					const checkTime = checkVariables(
						item.intern_start_date,
						item.intern_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							{checkTime && (
								<div className="item_time__wrapper">
									<Time
										start={item.intern_start_date}
										end={item.intern_end_date}
										isDisplayColumn={true}
									/>
								</div>
							)}

							<div
								className="item-contents"
								id={index === internshipLastItem ? "last" : ""}
							>
								<div className="item-dot-outside"></div>
								<div className="item-dot-inside"></div>

								<div className="item__header">
									{item.intern_job}

									<div className="item__sub_header">
										{item.intern_employer}

										{item.intern_employer && item.intern_city && ", "}

										{item.intern_city}
									</div>
								</div>

								{item.intern_rich_text_content && (
									<RichTextParser
										className={"item__rich_editor"}
										rich_text={item.intern_rich_text_content}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// courses
	const coursesArr = courses_items.filter((obj) => objHasAnyValue(obj));

	const coursesLastItem = coursesArr.length - 1;

	const courses = !isArrayEmpty(coursesArr) ? (
		<div className="section">
			<div className="title">{courses_title}</div>

			<div className="section__contents">
				{coursesArr.map((item, index) => {
					const checkTime = checkVariables(
						item.course_start_date,
						item.course_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							{checkTime && (
								<div className="item_time__wrapper">
									<Time
										start={item.course_start_date}
										end={item.course_end_date}
										isDisplayColumn={true}
									/>
								</div>
							)}

							<div
								className="item-contents"
								id={index === coursesLastItem ? "last" : ""}
							>
								<div className="item-dot-outside"></div>
								<div className="item-dot-inside"></div>

								<div className="item__header">
									{item.course_name}

									<div className="item__sub_header">
										{item.course_institution}
									</div>
								</div>

								{item.course_rich_text_content && (
									<RichTextParser
										className={"item__rich_editor"}
										rich_text={item.course_rich_text_content}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// extra curricular activities
	const activitiesArr = activity_items.filter((obj) => objHasAnyValue(obj));

	const activitiesLastItem = activitiesArr.length - 1;

	const activities = !isArrayEmpty(activitiesArr) ? (
		<div className="section">
			<div className="title">{activity_title}</div>

			<div className="section__contents">
				{activitiesArr.map((item, index) => {
					const checkTime = checkVariables(
						item.activity_start_date,
						item.activity_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							{checkTime && (
								<div className="item_time__wrapper">
									<Time
										start={item.activity_start_date}
										end={item.activity_end_date}
										isDisplayColumn={true}
									/>
								</div>
							)}

							<div
								className="item-contents"
								id={index === activitiesLastItem ? "last" : ""}
							>
								<div className="item-dot-outside"></div>
								<div className="item-dot-inside"></div>

								<div className="item__header">
									{item.activity_function}

									<div className="item__sub_header">
										{item.activity_employer}

										{item.activity_employer && item.activity_city && ", "}

										{item.activity_city}
									</div>
								</div>

								{item.activity_rich_text_content && (
									<RichTextParser
										className={"item__rich_editor"}
										rich_text={item.activity_rich_text_content}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// references
	const referArr = references_items.filter((obj) => objHasAnyValue(obj));

	const referLastItem = referArr.length - 1;

	const references = !isArrayEmpty(referArr) ? (
		<div className="section">
			<div className="title">{references_title}</div>

			<div className="section__contents">
				{references_hide && (
					<p className="rich-text-container">
						Reference available upon request
					</p>
				)}

				{!references_hide && (
					<div className="reference-container">
						{referArr.map((item, index) => {
							return (
								<div
									className={
										index % 2 === 0
											? "item-contents when-refer reference-item"
											: "reference-item"
									}
									key={index}
									id={index === referLastItem ? "last" : ""}
								>
									{(index === 0 || index % 2 === 0) && (
										<>
											<div className="item-dot-outside"></div>
											<div className="item-dot-inside"></div>
										</>
									)}

									<div className="item__header">
										{item.references_name}
										{item.references_name && item.references_company && ", "}
										{item.references_company}
									</div>

									{checkVariables(
										item.references_phone,
										item.references_email
									) && (
										<p>
											{item.references_phone}
											{item.references_phone && <br />}
											{item.references_email}
										</p>
									)}
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	) : (
		""
	);

	// custom
	const custom = (title, itemsArr, lastIndex) =>
		!isArrayEmpty(itemsArr) ? (
			<div className="section">
				<div className="title">{title}</div>

				<div className="section__contents">
					{itemsArr.map((item, index) => {
						const checkTime = checkVariables(
							item.custom_start_date,
							item.custom_end_date
						);

						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								{checkTime && (
									<div className="item_time__wrapper">
										<Time
											start={item.custom_start_date}
											end={item.custom_end_date}
											isDisplayColumn={true}
										/>
									</div>
								)}

								<div
									className="item-contents"
									id={index === lastIndex ? "last" : ""}
								>
									<div className="item-dot-outside"></div>
									<div className="item-dot-inside"></div>

									<div className="item__header">
										{item.custom_activity}

										<div className="item__sub_header">{item.custom_city}</div>
									</div>

									{item.custom_rich_text_content && (
										<RichTextParser
											className={"item__rich_editor"}
											rich_text={item.custom_rich_text_content}
										/>
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		) : (
			""
		);

	//  personal_details
	const personal_details = checkVariables(
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		web_link_items,
		phone,
		email
	) ? (
		<div className="section">
			<div className="title">Contact Us:</div>

			{/* present_address start */}
			{checkVariables(
				present_address,
				present_city,
				present_area,
				present_zip
			) && (
				<div className="personal__sub-header">
					<div className="header">Present Address</div>

					<div className="content">
						{present_address}

						{present_address && present_city && ", "}

						{present_city}

						{present_area && `, ${present_area}`}

						{present_zip && `, ${present_zip}`}

						{!checkVariables(
							permanent_address,
							permanent_city,
							permanent_area,
							permanent_zip
						) &&
							country &&
							`, ${country}`}
					</div>
				</div>
			)}
			{/* present_address end */}

			{/* permanent_address start */}
			{checkVariables(
				permanent_address,
				permanent_city,
				permanent_area,
				permanent_zip,
				same_address
			) && (
				<div className="personal__sub-header">
					<div className="header">Permanent Address</div>

					{same_address ? (
						<div className="content">Same as above</div>
					) : (
						<div className="content">
							{permanent_address}

							{permanent_address && permanent_city && ", "}

							{permanent_city}

							{permanent_area && `, ${permanent_area}`}

							{permanent_zip && `, ${permanent_zip}`}

							{country && `, ${country}`}
						</div>
					)}
				</div>
			)}
			{/* permanent_address end */}

			{nationality && (
				<div className="personal__sub-header">
					<div className="header">Nationality</div>

					<div className="content">{nationality}</div>
				</div>
			)}

			{nid && (
				<div className="personal__sub-header">
					<div className="header">{isNaN(nid) ? "Passport" : "NID"}</div>

					<div className="content">{nid}</div>
				</div>
			)}

			{driving_license && (
				<div className="personal__sub-header">
					<div className="header">Driving License</div>

					<div className="content">{driving_license}</div>
				</div>
			)}

			{place_of_birth && (
				<div className="personal__sub-header">
					<div className="header">Place Of Birth</div>

					<div className="content">{place_of_birth}</div>
				</div>
			)}

			{date_of_birth && (
				<div className="personal__sub-header">
					<div className="header">Date Of Birth</div>

					<div className="content">{date_of_birth}</div>
				</div>
			)}

			{phone && (
				<div className="personal__sub-header">
					<div className="header">Phone Number</div>

					<div className="content">
						<a
							href={`tel:${phone}`}
							target="_blank"
							rel="noopener noreferrer"
							className="link__url"
						>
							{phone}
						</a>
					</div>
				</div>
			)}

			{email && (
				<div className="personal__sub-header">
					<div className="header">Email</div>

					<div className="content">
						<a
							href={`mailto:${email}`}
							target="_blank"
							rel="noopener noreferrer"
							className="link__url"
							dangerouslySetInnerHTML={{ __html: divideAndAddBrTag(email, 26) }}
						></a>
					</div>
				</div>
			)}

			{/* web_link start  */}
			{!isArrayEmpty(
				removeEmptyObjects(web_link_items, ["link_label", "link_url"])
			) && (
				<div className="personal__sub-header">
					<div className="header">Social Links</div>

					{removeEmptyObjects(web_link_items, ["link_label", "link_url"]).map(
						(label, index) => {
							return (
								<div key={index} className="content">
									<a
										href={label.link_url}
										target="_blank"
										className="link__url"
										rel="noreferrer"
									>
										{label.link_label}
									</a>
								</div>
							);
						}
					)}
				</div>
			)}
			{/* web_link end */}
		</div>
	) : (
		""
	);

	// skills
	const skills = !isArrayEmpty(skills_items) ? (
		<div className="section">
			<div className="title">{skills_title}</div>

			{skills_items.map((value, index) => {
				return (
					<div className="skill-wrapper" key={index}>
						<SkillsLevelRound
							title_class="skill_title"
							level_class="skill_level"
							skill={value.skill}
							skill_level={value.skill_level}
							displaying_level={displaying_level}
							activeImg="star-fill-white.png"
							inactiveImg="star-stroke-white.png"
						/>
					</div>
				);
			})}
		</div>
	) : (
		""
	);

	// languages
	const languages = !isArrayEmpty(languages_items) ? (
		<div className="section">
			<div className="title">{languages_title}</div>

			{languages_items.map((value, index) => {
				return (
					<div className="skill-wrapper" key={index}>
						<SkillsLevelRound
							title_class="skill_title"
							level_class="skill_level"
							skill={value.languages_name}
							skill_level={value.languages_level}
							activeImg="star-fill-white.png"
							inactiveImg="star-stroke-white.png"
						/>
					</div>
				);
			})}
		</div>
	) : (
		""
	);

	//  hobbies
	const hobbies = hobbies_description ? (
		<div className="section">
			<div className="title">{hobbies_title}</div>

			<div className="mt">
				{hobbies_description.split(",").map((value, index) => {
					return (
						<div
							key={index}
							className={
								index === 0
									? "item__sub_header when-left"
									: "item__sub_header when-left refer-item-mt"
							}
						>
							<div className="dash"> - </div>

							{value}
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	//  disclaimer
	const disclaimer = disclaimer_description ? (
		<div className="section">
			<div className="title">{disclaimer_title}</div>

			<div className="section__contents">
				<p className="rich-text-container">{disclaimer_description}</p>
			</div>
		</div>
	) : (
		""
	);

	// for displaying additional section start
	const additionalSection = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Courses") {
			storeAdditionalSection.push(courses);
		} else if (value.key === "Extra-curricular Activities") {
			storeAdditionalSection.push(activities);
		} else if (value.key === "Internship") {
			storeAdditionalSection.push(internship);
		} else if (value.key === "Custom") {
			customArr.map((item) => {
				if (item.cus_section_id === value._id) {
					const filteredItems = item.custom_items.filter((obj) =>
						objHasAnyValue(obj)
					);
					if (filteredItems.length > 0) {
						storeAdditionalSection.push(
							custom(
								item.custom_title,
								filteredItems,
								filteredItems[filteredItems.length - 1]
							)
						);
					}
				}

				return storeAdditionalSection;
			});
		}

		return storeAdditionalSection;
	});
	// for displaying additional section end

	// left-section array
	const leftSection = [personal_details, skills, languages, hobbies];

	// right-section array
	const rightSection = [
		professional_summary,
		employment_history,
		projects,
		education,
		...additionalSection,
		references,
		disclaimer
	];

	return (
		<>
			<style>{GraphicsDesignerStyle}</style>

			<div className="container">
				{/* top-header  */}
				<div className="top_header"></div>

				<div className="body">
					{/* body__left start  */}
					<div className="body__left">
						<div className="body__left_bg"></div>

						<div className="img-container">
							<img
								src={`/assets/profile-img/${
									cropped_profile_img
										? cropped_profile_img
										: "default-profile.png"
								}`}
								alt="profile-img"
								className="profile-img"
							/>
						</div>

						{leftSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div key={index} id={index === 0 ? "left-first-section" : ""}>
									{value}
								</div>
							))}
					</div>
					{/* body__left end  */}

					{/* body__right start  */}
					<div className="body__right">
						{/* name, job-title  start */}
						<div className="identity">
							{checkVariables(first_name, last_name) && (
								<div className="name-wrapper">
									<div className="hello">Hello I'm</div>
									<div className="name">
										{first_name} &nbsp;
										{last_name && <span id="last-name">{last_name}</span>}
									</div>
								</div>
							)}

							{job_title && <div className="job-title">{job_title}</div>}
						</div>
						{/* name, job-title  end */}

						{rightSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div key={index}>{value}</div>
							))}
					</div>
					{/* body__right end  */}
				</div>
			</div>
		</>
	);
};

export default GraphicsDesigner;
