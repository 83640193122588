// external components
import { NavLink } from "react-router-dom";

// internal components
import { bangla } from "../../contents/bangla";
import { english } from "../../contents/english";
import { GetContextApi } from "../../storage/ContextApi";
import "./Footer.css";

const Footer = ({ getQuery }) => {
	// footer contents & userCountry
	const { contents, setContents, userCountry } = GetContextApi();

	const {
		description,

		site_map,
		home,
		resume,
		about_us,
		contact_us,
		faq,
		blog,
		term_condition,
		privacy_policy,
		refund_policy,
		cancel_subscription,
		pricing,

		address_title,
		address,
		city,
		phone_title,
		phone_number,
		email_title,
		email_address,

		trade_license_text,
		trade_license_num,

		copy_right_text
	} = contents.footer;

	return (
		<>
			<div className="footer-main-container">
				<div className="container">
					<div className="footer-container row">
						<div className="col-11 col-sm-12 footer-wrapper">
							{/* footer-left start  */}
							<div className="col-4 footer-left">
								<div className="project-logo">
									<NavLink to={"/"}>
										<img
											src="/assets/images/footer-project-logo.svg"
											alt="footer-project-logo"
											className="img-fluid"
										/>
									</NavLink>
								</div>

								<p>{description}</p>

								<div className="icon-container">
									<a
										href="https://www.facebook.com/BigCvWave/"
										target="__blank"
										id="social-link"
									>
										<span>
											<i className="fa-brands fa-facebook-f"></i>
										</span>
									</a>

									<a
										href="https://www.instagram.com/cv.wave/"
										target="__blank"
										id="social-link"
									>
										<span>
											<i className="fa-brands fa-instagram"></i>
										</span>
									</a>

									<a
										href="https://linkedin.com/company/cvwavebig"
										target="__blank"
										id="social-link"
									>
										<span>
											<i className="fa-brands fa-linkedin-in"></i>
										</span>
									</a>

									<a
										href="https://www.youtube.com/@cv_wave"
										target="__blank"
										id="social-link"
									>
										<span>
											<i className="fa-brands fa-youtube"></i>
										</span>
									</a>
								</div>
							</div>
							{/* footer-left end  */}

							{/* footer-middle start  */}
							<div className="col-5 footer-middle">
								<div className="useful-links">
									<h6 id="site-map">{site_map}</h6>

									<ul>
										<li>
											<NavLink to={"/"} id="link">
												{home}
											</NavLink>
										</li>

										<li>
											{" "}
											<NavLink to={"/resume-templates"} id="link">
												{resume}
											</NavLink>
										</li>

										<li>
											<NavLink to={"/about-us"} id="link">
												{about_us}
											</NavLink>
										</li>

										<li>
											<NavLink
												to={"/contact-us"}
												id="link"
												className={({ isActive }) =>
													isActive && !getQuery ? "active" : ""
												}
											>
												{contact_us}
											</NavLink>
										</li>

										<li>
											<NavLink to={"/faq"} id="link">
												{faq}
											</NavLink>
										</li>

										{/* <li>
											<a href="https://blog.cvwave.com" id="link">
												{blog}
											</a>
										</li> */}
									</ul>
								</div>

								<div className="services">
									<h6 id="site-map">Site Map</h6>

									<ul>
										<li>
											<NavLink to={"/terms-and-conditions"} id="link">
												{term_condition}
											</NavLink>
										</li>

										<li>
											<NavLink to={"/privacy-policy"} id="link">
												{privacy_policy}
											</NavLink>
										</li>

										<li>
											<NavLink to={"/refund-policy"} id="link">
												{refund_policy}
											</NavLink>
										</li>

										<li>
											<NavLink
												to={"/contact-us?selectedTab=cancel-subscription"}
												id="link"
												className={({ isActive }) =>
													isActive && getQuery ? "active" : ""
												}
											>
												{cancel_subscription}
											</NavLink>
										</li>

										<li>
											<NavLink to={"/subscription"} id="link">
												{pricing}
											</NavLink>
										</li>
									</ul>
								</div>
							</div>
							{/* footer-middle end  */}

							{/* footer-right start  */}
							<div className="footer-right col-3" id="contact-with-us">
								<div className="contact">
									<h6 id="site-map">{address_title}</h6>
									<div id="address">
										{address}
										<br /> {city}
										<br />
										<a href="tel:+8801701680168">
											{phone_title}&nbsp;:&nbsp;{phone_number}
										</a>
										<br />
										<a href="mailto:info@cvwave.com">
											{email_title}&nbsp;:&nbsp;
											{email_address}
										</a>
										<p style={{ margin: "10px 0 0 0" }}>
											{trade_license_text} <br />
											{trade_license_num}
										</p>
									</div>
								</div>
							</div>
							{/* footer-right end  */}

							<div
								className={
									userCountry === "BD"
										? "language-container d-flex"
										: "language-container d-none"
								}
							>
								<h6>
									{contents.title === "bangla" ? "ভাষা" : "Language"}&nbsp;:
								</h6>
								<h6
									onClick={() => setContents(english)}
									id={contents.title === "english" ? "active" : ""}
								>
									English
								</h6>

								<h6
									onClick={() => setContents(bangla)}
									id={contents.title === "bangla" ? "active" : ""}
								>
									বাংলা
								</h6>
							</div>

							<div id="bottom-line"></div>
						</div>
					</div>
				</div>

				{/* copy-right start  */}
				<div className="copy-right">
					<i
						className="fa-regular fa-copyright fa-spin"
						id="copy-right-icon"
					></i>
					&nbsp;
					<p dangerouslySetInnerHTML={{ __html: copy_right_text }}></p>
				</div>
				{/* copy-right end  */}
			</div>
		</>
	);
};

export default Footer;
