// external components
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import {
	Alert,
	Box,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fab,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

// internal components
import TableSkeleton from "../AdminDashboardContents/TableSkeleton";
import "./_AdminPackages.scss";

const AdminPackages = () => {
	// for loading until fetching not complete
	const [isLoading, setIsLoading] = useState(false);

	// for checking is updated coupon or not
	const [isUpdatePackage, setIsUpdatePackage] = useState("");

	// for getting all packages start
	const [allPackages, setAllPackages] = useState([]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const response = await fetch(`/package/all-packages`);

				const result = await response.json();

				if (response.status === 200) {
					setAllPackages(result);

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		})();
	}, [isUpdatePackage]);
	// for getting all package end

	// modal toggle start
	const [open, setOpen] = useState({
		create: false,
		update: false,
		delete: false
	});

	const handleClickOpen = (whichOne) => {
		setOpen({ ...open, [whichOne]: true });
	};

	const handleClose = () => {
		setOpen({
			create: false,
			update: false,
			delete: false
		});
	};
	// modal toggle end

	// all packages textFields name
	const forTextFields = [
		"Regular Price",
		"Discount Rate",
		"Discounted Price",
		"Subscription Period",
		"Templates Use Limits",
		"Resume Customization",
		"Resume Download"
	];

	// all packages checkbox name
	const forCheckBox = [
		"Spelling Check",
		"Pre-Written Phrases",
		"Exclusive Templates",
		"Expert Advice",
		"Value Added Service"
	];

	// for getting all input-fields value start
	const initialFieldsValue = {
		"Regular Price": "",
		"Discount Rate": "",
		"Discounted Price": "",
		"Subscription Period": "",
		"Templates Use Limits": "",
		"Resume Customization": "",
		"Resume Download": "",
		State: "",
		"Spelling Check": false,
		"Pre-Written Phrases": false,
		"Exclusive Templates": false,
		"Expert Advice": false,
		"Value Added Service": false
	};

	const [fieldsValue, setFieldsValue] = useState(initialFieldsValue);

	const handleOnChange = (e) => {
		const { name, value, checked, type } = e.target;

		if (type !== "checkbox") {
			setFieldsValue((prevFormData) => ({
				...prevFormData,
				[name]: value
			}));
		} else {
			setFieldsValue((prevFormData) => ({
				...prevFormData,
				[name]: checked
			}));
		}
	};
	// for getting all input-fields value end

	// for calculate discounted price
	const generateDiscountPrice = ({ price, discount }) => {
		const havePercentage = discount.includes("%");

		if (havePercentage) {
			const removedPercentage = discount.slice(0, -1);
			return Math.round(price - price * (removedPercentage / 100));
		} else {
			return price - discount;
		}
	};

	function containsOnlyNumbers(str) {
		return /^[0-9%]+$/.test(str);
	}

	useEffect(() => {
		if (
			containsOnlyNumbers(fieldsValue["Regular Price"]) &&
			containsOnlyNumbers(fieldsValue["Discount Rate"])
		) {
			setFieldsValue((pre) => ({
				...pre,
				"Discounted Price": generateDiscountPrice({
					price: fieldsValue["Regular Price"],
					discount: fieldsValue["Discount Rate"]
				})
			}));
		} else {
			setFieldsValue((pre) => ({
				...pre,
				"Discounted Price": 0
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldsValue["Regular Price"], fieldsValue["Discount Rate"]]);

	// for submit input-fields value
	const submitHandler = async () => {
		try {
			const response = await fetch(`/package/add-new`, {
				method: "POST",
				body: JSON.stringify(fieldsValue),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				handleClose();
				setIsUpdatePackage(Date.now());
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};

	// for update input-fields value
	const updateHandler = async () => {
		try {
			const response = await fetch(`/package/update-specific-package`, {
				method: "PUT",
				body: JSON.stringify(fieldsValue),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				handleClose();
				setIsUpdatePackage(Date.now());
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};

	// for delete specific package
	const deleteHandler = async () => {
		try {
			const response = await fetch(`/package/delete-specific-package`, {
				method: "DELETE",
				body: JSON.stringify({ _id: fieldsValue._id }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				handleClose();
				setIsUpdatePackage(Date.now());
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};

	return (
		<>
			<Box
				sx={{
					padding: "10px 40px 0"
				}}
			>
				<Paper elevation={24} sx={{ borderRadius: "16px" }}>
					{/* header start  */}
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							paddingTop: "15px"
						}}
					>
						<Typography
							variant="h6"
							sx={{
								wordSpacing: "5px",
								paddingLeft: "20px",
								borderLeft: "5px solid #f30a5c",
								alignSelf: "center",
								opacity: 0.9,
								lineHeight: 1
							}}
						>
							Packages
						</Typography>

						<Alert severity="warning">
							The maximum number of publish packages will be 4.
						</Alert>

						<Fab
							variant="extended"
							color="primary"
							aria-label="for-new"
							sx={{ marginRight: "20px" }}
							onClick={() => {
								handleClickOpen("create");
								setFieldsValue(initialFieldsValue);
							}}
						>
							<AddIcon sx={{ mr: "4px" }} />
							<Typography variant="h6" sx={{ fontSize: "14px" }}>
								Add Package
							</Typography>
						</Fab>
					</Box>
					{/* header end  */}

					{/* table start  */}
					{isLoading ? (
						<TableSkeleton />
					) : (
						<div className="package-table-container">
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col" style={{ width: "5%" }}>
											#
										</th>

										<th scope="col">Name</th>

										<th scope="col">Regular Price</th>

										<th scope="col">Discount Rate</th>

										<th scope="col">Discounted Price</th>

										<th scope="col">Period</th>

										<th scope="col">State</th>

										<th scope="col">Action</th>
									</tr>
								</thead>

								<tbody>
									{allPackages.length > 0 &&
										allPackages
											.map((value, index) => {
												return (
													<tr key={index}>
														<td id="id">
															<span>{index + 1}</span>
														</td>

														<td>
															<input readOnly value={value["Package Name"]} />
														</td>

														<td>
															<input readOnly value={value["Regular Price"]} />
														</td>

														<td>
															<input readOnly value={value["Discount Rate"]} />
														</td>

														<td>
															<input
																readOnly
																value={value["Discounted Price"]}
															/>
														</td>

														<td>
															<input
																readOnly
																value={value["Subscription Period"]}
															/>
														</td>

														<td>
															<input readOnly value={value?.State} />
														</td>

														<td>
															<Box>
																<Tooltip title="Edit" aria-label="edit">
																	<IconButton
																		onClick={() => {
																			setFieldsValue(value);
																			handleClickOpen("update");
																		}}
																	>
																		<EditNoteIcon />
																	</IconButton>
																</Tooltip>

																<Tooltip title="Delete" aria-label="delete">
																	<IconButton
																		onClick={() => {
																			setFieldsValue(value);
																			handleClickOpen("delete");
																		}}
																	>
																		<DeleteIcon />
																	</IconButton>
																</Tooltip>
															</Box>
														</td>
													</tr>
												);
											})
											.reverse()}
								</tbody>
							</table>
						</div>
					)}
					{/* table end */}

					{/* for create new package start */}
					<Dialog
						open={Object.values(open).some((value) => value === true)}
						onClose={handleClose}
						fullWidth
					>
						<DialogTitle textAlign={"center"}>
							{open.create && "Create New Package"}
							{open.update && "Update a Package"}
							{open.delete && "Delete a Package"}
						</DialogTitle>

						<DialogContent>
							<Box
								component="form"
								sx={{
									"& > :not(style)": { mb: 2 }
								}}
							>
								<TextField
									autoFocus
									name="Package Name"
									label="Package Name"
									fullWidth
									variant="filled"
									size="small"
									value={fieldsValue["Package Name"]}
									onChange={handleOnChange}
									disabled={open.delete ? true : false}
								/>

								{forTextFields.map((item, index) => {
									return (
										<TextField
											key={index}
											name={item}
											label={item}
											fullWidth
											variant="filled"
											size="small"
											onChange={handleOnChange}
											value={fieldsValue[item]}
											disabled={open.delete ? true : false}
											readOnly={fieldsValue["Discounted Price"] ? true : false}
											placeholder={
												item === "Subscription Period" ? "number of months" : ""
											}
										/>
									);
								})}

								<FormGroup>
									{forCheckBox.map((item, index) => {
										return (
											<FormControlLabel
												control={
													<Checkbox
														name={item}
														checked={fieldsValue[item]}
														onChange={handleOnChange}
														disabled={open.delete ? true : false}
													/>
												}
												label={item}
												key={index}
											/>
										);
									})}
								</FormGroup>

								<FormControl variant="filled" fullWidth size="small">
									<InputLabel id="demo-simple-select-filled-label">
										State
									</InputLabel>
									<Select
										labelId="demo-simple-select-filled-label"
										id="demo-simple-select-filled"
										value={fieldsValue.State}
										onChange={handleOnChange}
										name="State"
										disabled={open.delete ? true : false}
									>
										<MenuItem value={"Draft"}>Draft</MenuItem>
										<MenuItem value={"Publish"}>Publish</MenuItem>
									</Select>
								</FormControl>
							</Box>
						</DialogContent>

						<DialogActions>
							<Fab
								onClick={handleClose}
								variant="extended"
								color="primary"
								aria-label="for-cancel"
							>
								Cancel
							</Fab>

							{open.create && (
								<Fab
									onClick={submitHandler}
									variant="extended"
									color="primary"
									aria-label="for-submit"
								>
									Submit
								</Fab>
							)}

							{open.update && (
								<Fab
									onClick={updateHandler}
									variant="extended"
									color="primary"
									aria-label="for-update"
								>
									Update
								</Fab>
							)}

							{open.delete && (
								<Fab
									onClick={deleteHandler}
									variant="extended"
									color="primary"
									aria-label="for-delete"
								>
									Delete
								</Fab>
							)}
						</DialogActions>
					</Dialog>
					{/* for create new package end */}
				</Paper>
			</Box>
		</>
	);
};

export default AdminPackages;
