// external components
import { memo, useEffect, useRef, useState } from "react";

// internal components
import "./LanguageLevel.css";

const LanguageLevel = ({
	languages_level,
	setInputValues,
	updateResumeInfo,
	_id
}) => {
	const [levelDrop, setLevelDrop] = useState("");

	// for close dropdown when outside clicked start
	const myRef = useRef();

	const handleClickOutside = (e) => {
		if (!myRef.current?.contains(e.target) && levelDrop) {
			setLevelDrop(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [levelDrop]);
	// for close dropdown when outside clicked end

	useEffect(() => {
		if (languages_level) {
			updateResumeInfo({
				languages_level,
				_id
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [languages_level]);

	return (
		<>
			<div
				className={
					levelDrop ? "lan-level-container active" : "lan-level-container"
				}
				onClick={() => setLevelDrop(!levelDrop)}
				ref={myRef}
			>
				<input
					type="text"
					id="Level"
					placeholder="Select level"
					value={languages_level}
					readOnly
				/>

				<div className="option" ref={myRef}>
					<div
						onClick={() =>
							setInputValues((prev) => ({
								...prev,
								languages_level: "Native speaker"
							}))
						}
					>
						<span>Native speaker</span>
					</div>
					<div
						onClick={() =>
							setInputValues((prev) => ({
								...prev,
								languages_level: "Highly proficient"
							}))
						}
					>
						<span>Highly proficient</span>
					</div>

					<div
						onClick={() =>
							setInputValues((prev) => ({
								...prev,
								languages_level: "Very good command"
							}))
						}
					>
						<span>Very good command</span>
					</div>

					<div
						onClick={() =>
							setInputValues((prev) => ({
								...prev,
								languages_level: "Good working knowledge"
							}))
						}
					>
						<span>Good working knowledge</span>
					</div>

					<div
						onClick={() =>
							setInputValues((prev) => ({
								...prev,
								languages_level: "Working knowledge"
							}))
						}
					>
						<span>Working knowledge</span>
					</div>

					<div
						onClick={() =>
							setInputValues((prev) => ({ ...prev, languages_level: "C2" }))
						}
					>
						<span>C2</span>
					</div>

					<div
						onClick={() =>
							setInputValues((prev) => ({ ...prev, languages_level: "C1" }))
						}
					>
						<span>C1</span>
					</div>

					<div
						onClick={() =>
							setInputValues((prev) => ({ ...prev, languages_level: "B2" }))
						}
					>
						<span>B2</span>
					</div>

					<div
						onClick={() =>
							setInputValues((prev) => ({ ...prev, languages_level: "B1" }))
						}
					>
						<span>B1</span>
					</div>

					<div
						onClick={() =>
							setInputValues((prev) => ({ ...prev, languages_level: "A2" }))
						}
					>
						<span>A2</span>
					</div>

					<div
						onClick={() =>
							setInputValues((prev) => ({ ...prev, languages_level: "A1" }))
						}
					>
						<span>A1</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(LanguageLevel);
