// external components
import { toBengaliNumber } from "bengali-number";
import { memo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// internal components
import { GetContextApi } from "../../storage/ContextApi";
import "./_RestrictionDialog.scss";

const RestrictionDialog = ({ restrictionObj, setRestrictionObj, btn_text }) => {
	// context api
	const { currentUser, contents } = GetContextApi();

	// for redirecting
	const Navigate = useNavigate();

	// for closing delete-popup start
	const restrictionRef = useRef();

	const handleClickOutsideDelete = (e) => {
		if (!restrictionRef.current?.contains(e.target) && restrictionObj) {
			setRestrictionObj("");
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideDelete);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideDelete);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [restrictionObj]);
	// for closing delete-popup end

	// restriction number
	useEffect(() => {
		if (restrictionObj && currentUser) {
			const element = document.getElementById("maxNoTemplate");

			if (element?.length > 0 || element !== null) {
				element.innerHTML =
					contents.title === "bangla"
						? toBengaliNumber(currentUser.maximum_resumes)
						: currentUser.maximum_resumes;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="restriction-dialog-container">
			<div
				className="restriction-dialog-wrapper"
				data-aos="fade-down"
				ref={restrictionRef}
			>
				<div className="restriction-dialog-section">
					<h3 dangerouslySetInnerHTML={{ __html: restrictionObj.header }}></h3>
				</div>

				<div
					className="restriction-dialog-description"
					dangerouslySetInnerHTML={{ __html: restrictionObj.description }}
				></div>

				{btn_text && (
					<div className="restriction-dialog-btn-container">
						<button
							onClick={() => {
								Navigate("/subscription");
							}}
							className="primary-btn-styles"
						>
							{btn_text}
						</button>
					</div>
				)}

				<div
					className="restriction-dialog-close-btn"
					onClick={() => {
						setRestrictionObj("");
					}}
				>
					<i className="fa-solid fa-xmark"></i>
				</div>
			</div>
		</div>
	);
};

export default memo(RestrictionDialog);
