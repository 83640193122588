// external components
import { arrayMoveImmutable } from "array-move";
import { memo, useEffect, useReducer, useState } from "react";

// internal components
import { EditorContextApi } from "../../../../../pages/ResumeEditor/EditorContextApi";
import { ProjectItem } from "../SectionItems";
import {
	AddMore,
	ControlCollapse,
	DeleteDialog,
	Description,
	SectionTitle
} from "../common";
import {
	SortableContainer,
	SortableItem,
	updateBeforeSortStart
} from "../common/drag&drop";
import { updateItems } from "../common/function";

const Projects = ({ contents }) => {
	// projects content
	const { project_subtitle, add_project, add_more_project, rename, revert } =
		contents.editor;

	// editor contextApi
	const { updateResumeInfo, getResume, setResume, delay, userId } =
		EditorContextApi();

	// assign
	const _id = getResume.projects._id;
	const counter = getResume.counter;

	// for delete specific items
	const [deleteItem, setDeleteItem] = useState("");

	// for title and collapse controls start
	const initialController = {
		border: false,
		editTitle: false,
		collapse: true
	};

	const controlHandler = (state, action) => {
		switch (action.type) {
			case "open-border":
				return {
					...state,
					border: true
				};

			case "open-editor":
				return {
					...state,
					editTitle: true
				};

			case "close-border-editor":
				return {
					...state,
					border: false,
					editTitle: false
				};

			case "open-collapse":
				return {
					...state,
					collapse: true
				};

			case "close-collapse":
				return {
					...state,
					collapse: false
				};

			default:
				return state;
		}
	};

	const [control, dispatchControl] = useReducer(
		controlHandler,
		initialController
	);
	// for title and collapse controls end

	// assign projects's all values start
	const [getTitle, setTitle] = useState({
		project_title: getResume.projects.project_title
	});

	const [proItems, setProItems] = useState([]);
	useEffect(() => {
		if (getResume?.projects?.project_items.length > 0) {
			let getProItemArray = [];

			getResume?.projects?.project_items.map((value) =>
				getProItemArray.push(
					<ProjectItem
						itemValues={value}
						handleSetActive={handleSetActive}
						contents={contents}
						setDeleteItem={setDeleteItem}
						updateResumeInfo={updateResumeInfo}
						getResume={getResume}
						setResume={setResume}
						delay={delay}
					/>
				)
			);

			setProItems(getProItemArray);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// assign employment_history's all values end

	// handle activate id start
	const [activeId, setActiveId] = useState(-1);

	const handleSetActive = (id) => {
		setActiveId((prevActiveId) => (prevActiveId === id ? -1 : id));
	};
	// handle activate id end

	// handle when dragEnd
	const onSortEnd = async ({ oldIndex, newIndex }) => {
		setProItems(arrayMoveImmutable(proItems, oldIndex, newIndex));

		const arr = arrayMoveImmutable(
			getResume?.projects?.project_items,
			oldIndex,
			newIndex
		);

		setResume((prev) => ({
			...prev,
			projects: {
				...prev.projects,
				project_items: arr
			}
		}));

		updateItems(arr, userId, counter, "projects", "project_items");
	};

	// delete item handler
	useEffect(() => {
		if (deleteItem._id && (deleteItem?.conform || getResume.do_not_ask_again)) {
			setProItems((prev) =>
				prev.filter((item) => item.props.itemValues._id !== deleteItem._id)
			);

			const arr = getResume.projects.project_items.filter(
				(item) => item._id !== deleteItem._id
			);

			setResume((prev) => ({
				...prev,
				projects: {
					...prev.projects,
					project_items: arr
				}
			}));

			updateItems(arr, userId, counter, "projects", "project_items");

			setDeleteItem("");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteItem?._id, deleteItem?.conform]);

	return (
		<div className="section-container">
			{/* label start  */}
			<div className="label" id={control.collapse ? "active" : ""}>
				<SectionTitle
					title="Projects/Portfolio"
					name="project_title"
					control={control}
					dispatchControl={dispatchControl}
					_id={_id}
					value={getTitle.project_title}
					setInputValues={setTitle}
					updateResumeInfo={updateResumeInfo}
					rename={rename}
					revert={revert}
					drag={true}
				/>

				<ControlCollapse
					collapse={control.collapse}
					dispatchControl={dispatchControl}
				/>
			</div>
			{/* label end  */}

			{/* content start  */}
			<div
				className="content-container remove-margin"
				id={control.collapse ? "active" : ""}
			>
				{/* description  */}
				<Description content={project_subtitle} />

				{/* for displaying projectItems's array start  */}
				{proItems.length > 0 && (
					<SortableContainer
						onSortEnd={onSortEnd}
						useDragHandle
						lockAxis="y"
						lockToContainerEdges={true}
						updateBeforeSortStart={() => updateBeforeSortStart(setActiveId)}
					>
						{proItems.map((item, index) => (
							<SortableItem
								key={item.props.itemValues._id}
								index={index}
								value={item}
								className="display-section-item"
								id={activeId === item.props.itemValues._id ? "active" : ""}
							/>
						))}
					</SortableContainer>
				)}
				{/* for displaying projectItems's array end */}

				{/* add-more  */}
				<AddMore
					userId={userId}
					counter={counter}
					section={"projects"}
					item={"project_items"}
					newArr={{
						project_name: "",
						project_duration: "",
						project_links: [
							{
								label: "",
								link: ""
							}
						],
						technologies: "",
						pro_description: ""
					}}
					arrCom={proItems}
					setArrCom={setProItems}
					itemCom={{
						itemComponent: ProjectItem,
						contents,
						updateResumeInfo,
						setDeleteItem,
						getResume,
						delay
					}}
					setResume={setResume}
					controlActiveId={{ activeId, setActiveId, handleSetActive }}
					initialContent={add_project}
					moreContent={add_more_project}
				/>
			</div>
			{/* content end */}

			{/* delete modal  */}
			{!getResume.do_not_ask_again && deleteItem && (
				<DeleteDialog
					contents={contents}
					deleteItem={deleteItem}
					setDeleteItem={setDeleteItem}
				/>
			)}
		</div>
	);
};

export default memo(Projects);
