/* eslint-disable import/no-webpack-loader-syntax */
// internal components
import GraphicWebDesigner2Style from "!!raw-loader!./GraphicWebDesigner2.css";
import {
	RichTextParser,
	SectionTitle,
	SkillsLevelRound,
	Time
} from "../Components";
import {
	checkVariables,
	divideAndAddBrTag,
	fetchingResumeData,
	generateIconBlack,
	isArrayEmpty,
	objHasAnyValue,
	removeEmptyObjects
} from "../Components/Utils";

const GraphicWebDesigner2 = ({ getResume }) => {
	// fetching resume's all data
	const {
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		professional_summary_title,
		rich_text_content,
		employment_history_title,
		employment_history_items,
		education_title,
		education_items,
		project_title,
		project_items,
		web_link_items,
		skills_title,
		skills_items,
		displaying_level,
		languages_title,
		languages_items,
		hobbies_title,
		hobbies_description,
		activity_title,
		activity_items,
		internship_title,
		internship_items,
		courses_title,
		courses_items,
		references_title,
		references_hide,
		references_items,
		customArr,
		disclaimer_title,
		disclaimer_description
	} = fetchingResumeData(getResume);

	//  employment_history
	const employment_history = !isArrayEmpty(employment_history_items) ? (
		<div className="section item-wrapper">
			<SectionTitle
				className="section-title"
				sectionName={employment_history_title}
				equalTo="EmploymenT History"
				initialName="Work Experience"
			/>

			<div className="dot with-title"></div>

			{employment_history_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(item.start_date, item.end_date);

					return (
						<div
							className={
								index === 0 ? "item item__first d-flex" : "item d-flex"
							}
							key={index}
						>
							<div className="dot with-item"></div>

							{checkTime && (
								<div className="item_left__section item-header">
									<Time
										start={item.start_date}
										end={item.end_date}
										isDisplayColumn={true}
									/>
								</div>
							)}

							{checkVariables(
								item.employment_job_title,
								item.employer,
								item.employment_city,
								item.employment_rich_text_content
							) && (
								<div className="item_right__section">
									<div className="item-header">{item.employment_job_title}</div>

									{checkVariables(item.employer, item.employment_city) && (
										<div className="item-content p-0">
											{item.employer}
											{item.employer && item.employment_city && ", "}
											{item.employment_city}
										</div>
									)}

									{item.employment_rich_text_content && (
										<RichTextParser
											className={"rich_editor__container"}
											rich_text={item.employment_rich_text_content}
										/>
									)}
								</div>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// projects
	const projects = !isArrayEmpty(project_items) ? (
		<div className="section item-wrapper">
			<SectionTitle
				className="section-title"
				sectionName={project_title}
				equalTo="Projects/Portfolio"
				initialName="Projects"
			/>

			<div className="dot with-title"></div>

			{project_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					return (
						<div
							className={
								index === 0 ? "item item__first d-flex" : "item d-flex"
							}
							key={index}
						>
							<div className="dot with-item"></div>

							{!isArrayEmpty(item.project_links) && (
								<div className="item_left__section item-header">
									{removeEmptyObjects(item.project_links, [
										"label",
										"link"
									]).map((label, index) => {
										return (
											<div key={index}>
												<a
													href={label.link}
													target="_blank"
													rel="noopener noreferrer"
												>
													{label.label}
												</a>
											</div>
										);
									})}
								</div>
							)}

							{checkVariables(
								item.project_name,
								item.technologies,
								item.project_duration,
								item.pro_description
							) && (
								<div className="item_right__section">
									<div className="item-header">{item.project_name}</div>

									{item.project_duration && (
										<div className="item-content p-0">
											{item.project_duration}
										</div>
									)}

									{item.technologies && (
										<div className="item-content">
											<strong>Technologies:&nbsp;</strong>
											{item.technologies}
										</div>
									)}

									{item.pro_description && (
										<RichTextParser
											className={"rich_editor__container"}
											rich_text={item.pro_description}
										/>
									)}
								</div>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// education
	const education = !isArrayEmpty(education_items) ? (
		<div className="section">
			<div className="section-title">{education_title}</div>

			{education_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.edu_start_date,
						item.edu_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item-header">{item.degree}</div>

							{checkVariables(item.school, item.edu_city) && (
								<div className="item-content">
									{item.school}

									{item.school && item.edu_city && " / "}

									{item.edu_city}
								</div>
							)}

							{checkTime && (
								<div className="item-content">
									<Time
										start={item.edu_start_date}
										end={item.edu_end_date}
										fromWhere={"education"}
									/>
								</div>
							)}

							{item.edu_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.edu_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// internship
	const internship = !isArrayEmpty(internship_items) ? (
		<div className="section">
			<div className="section-title">{internship_title}</div>

			{internship_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.intern_start_date,
						item.intern_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item-header">{item.intern_job}</div>

							{checkVariables(item.intern_employer, item.intern_city) && (
								<div className="item-content">
									{item.intern_employer}

									{item.intern_employer && item.intern_city && " / "}

									{item.intern_city}
								</div>
							)}

							{checkTime && (
								<div className="item-content">
									<Time
										start={item.intern_start_date}
										end={item.intern_end_date}
									/>
								</div>
							)}

							{item.intern_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.intern_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// courses
	const courses = !isArrayEmpty(courses_items) ? (
		<div className="section">
			<div className="section-title">{courses_title}</div>

			{courses_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.course_start_date,
						item.course_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item-header">{item.course_name}</div>

							{item.course_institution && (
								<div className="item-content">{item.course_institution}</div>
							)}

							{checkTime && (
								<div className="item-content">
									<Time
										start={item.course_start_date}
										end={item.course_end_date}
									/>
								</div>
							)}

							{item.course_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.course_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// extra curricular activities
	const activities = !isArrayEmpty(activity_items) ? (
		<div className="section item-wrapper">
			<div className="section-title">{activity_title}</div>

			<div className="dot with-title"></div>

			{activity_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.activity_start_date,
						item.activity_end_date
					);

					return (
						<div
							className={
								index === 0 ? "item item__first d-flex" : "item d-flex"
							}
							key={index}
						>
							<div className="dot with-item"></div>

							{checkTime && (
								<div className="item_left__section item-header">
									<Time
										start={item.activity_start_date}
										end={item.activity_end_date}
										isDisplayColumn={true}
									/>
								</div>
							)}

							{checkVariables(
								item.activity_function,
								item.activity_employer,
								item.activity_city,
								item.activity_rich_text_content
							) && (
								<div className="item_right__section">
									<div className="item-header">{item.activity_function}</div>

									{checkVariables(
										item.activity_employer,
										item.activity_city
									) && (
										<div className="item-content p-0">
											{item.activity_employer}
											{item.activity_employer && item.activity_city && ", "}
											{item.activity_city}
										</div>
									)}

									{item.activity_rich_text_content && (
										<RichTextParser
											className={"rich_editor__container"}
											rich_text={item.activity_rich_text_content}
										/>
									)}
								</div>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// references
	const references = !isArrayEmpty(references_items) ? (
		<div className="section item-wrapper">
			<div className="section-title">{references_title}</div>

			<div className="dot with-title"></div>

			{references_hide && (
				<div className="item__first">
					<div className="item-content p-0">
						Reference available upon request
					</div>
				</div>
			)}

			{!references_hide &&
				references_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="dot with-item"></div>

								<div className="item-header">{item.references_name}</div>

								{item.references_company && (
									<div className="item-content p-0">
										{item.references_company}
									</div>
								)}

								{item.references_phone && (
									<div className="item-content">{item.references_phone}</div>
								)}

								{item.references_email && (
									<div className="item-content">{item.references_email}</div>
								)}
							</div>
						);
					})}
		</div>
	) : (
		""
	);

	// custom
	const custom = (specificCustom) =>
		!isArrayEmpty(specificCustom.custom_items) ? (
			<div className="section item-wrapper">
				<div className="section-title">{specificCustom.custom_title}</div>

				<div className="dot with-title"></div>

				{specificCustom.custom_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						const checkTime = checkVariables(
							item.custom_start_date,
							item.custom_end_date
						);

						return (
							<div
								className={
									index === 0 ? "item item__first d-flex" : "item d-flex"
								}
								key={index}
							>
								<div className="dot with-item"></div>

								{checkTime && (
									<div className="item_left__section item-header">
										<Time
											start={item.custom_start_date}
											end={item.custom_end_date}
											isDisplayColumn={true}
										/>
									</div>
								)}

								{checkVariables(
									item.custom_activity,
									item.custom_rich_text_content,
									item.custom_city
								) && (
									<div className="item_right__section">
										<div className="item-header">{item.custom_activity}</div>

										{item.custom_city && (
											<div className="item-content p-0">{item.custom_city}</div>
										)}

										{item.custom_rich_text_content && (
											<RichTextParser
												className={"rich_editor__container"}
												rich_text={item.custom_rich_text_content}
											/>
										)}
									</div>
								)}
							</div>
						);
					})}
			</div>
		) : (
			""
		);

	//  personal_details
	const personal_details = checkVariables(
		present_address,
		present_city,
		present_area,
		present_zip,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		same_address,
		nationality,
		nid,
		driving_license,
		place_of_birth,
		date_of_birth
	) ? (
		<div className="item-wrapper">
			<div className="dot"></div>
			{/* present_address start */}
			{checkVariables(
				present_address,
				present_city,
				present_area,
				present_zip
			) && (
				<div className="personal-d-item">
					<b>Present Address:</b> &nbsp;
					{present_address}
					{present_address && present_city && `, ${present_city}`}
					{present_area && `, ${present_area}`}
					{present_zip && `, ${present_zip}`}
					{!checkVariables(
						permanent_address,
						permanent_city,
						permanent_area,
						permanent_zip
					) &&
						country &&
						`, ${country}`}
				</div>
			)}
			{/* present_address end */}

			{/* permanent_address start */}
			{checkVariables(
				permanent_address,
				permanent_city,
				permanent_area,
				permanent_zip,
				same_address
			) && (
				<div className="personal-d-item">
					{same_address ? (
						<>
							<b>Permanent Address:</b> &nbsp; Same as above{" "}
						</>
					) : (
						<>
							<b>Permanent Address:</b> &nbsp;
							{permanent_address}
							{permanent_address && permanent_city && `, ${permanent_city}`}
							{permanent_area && `, ${permanent_area}`}
							{permanent_zip && `, ${permanent_zip}`}
							{country && `, ${country}`}
						</>
					)}
				</div>
			)}
			{/* permanent_address end */}

			{nationality && (
				<div className="personal-d-item">
					<b>Nationality:</b> &nbsp; {nationality}
				</div>
			)}

			{nid && (
				<div className="personal-d-item">
					<b>{isNaN(nid) ? "Passport" : "NID"}:</b> &nbsp; {nid}
				</div>
			)}

			{driving_license && (
				<div className="personal-d-item">
					<b>Driving License:</b> &nbsp; {driving_license}
				</div>
			)}

			{place_of_birth && (
				<div className="personal-d-item">
					<b>Place Of Birth:</b> &nbsp; {place_of_birth}
				</div>
			)}

			{date_of_birth && (
				<div className="personal-d-item">
					<b>Date Of Birth:</b> &nbsp; {date_of_birth}
				</div>
			)}
		</div>
	) : (
		""
	);

	// skills
	const skills = !isArrayEmpty(skills_items) ? (
		<div className="section">
			<div className="section-title">{skills_title}</div>

			{skills_items.map((value, index) => {
				return (
					<div className="skill-wrapper" key={index}>
						<SkillsLevelRound
							title_class="skill_title"
							level_class="skill_level"
							bg_color=""
							skill={value.skill}
							skill_level={value.skill_level}
							displaying_level={displaying_level}
							isNeed10={true}
						/>
					</div>
				);
			})}
		</div>
	) : (
		""
	);

	// languages
	const languages = !isArrayEmpty(languages_items) ? (
		<div className="section">
			<div className="section-title">{languages_title}</div>

			{languages_items.map((value, index) => {
				return (
					<div className="skill-wrapper" key={index}>
						<SkillsLevelRound
							title_class="skill_title"
							level_class="skill_level"
							bg_color=""
							skill={value.languages_name}
							skill_level={value.languages_level}
							isNeed10={true}
						/>
					</div>
				);
			})}
		</div>
	) : (
		""
	);

	//  hobbies
	const hobbies = hobbies_description ? (
		<div className="section">
			<div className="section-title">{hobbies_title}</div>

			<div className="hobbies-description">
				{hobbies_description.split(",").map((value, index) => {
					return (
						<div key={index} className="item-content when-hobbies">
							<div className="bullet"></div>

							{value}
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// web_links
	const web_links = !isArrayEmpty(web_link_items) ? (
		<div className="section">
			<div className="section-title">Follow Me</div>

			{!isArrayEmpty(web_link_items) &&
				removeEmptyObjects(web_link_items, ["link_label", "link_url"]).map(
					(label, index) => {
						return (
							<div key={index} className="link__wrapper">
								<img
									src={`/assets/icons/${generateIconBlack(label.link_url)}`}
									alt="icon"
									className="icon"
								/>

								<a
									href={label.link_url}
									target="_blank"
									rel="noopener noreferrer"
									className="link__url"
								>
									{label.link_label}
								</a>
							</div>
						);
					}
				)}
		</div>
	) : (
		""
	);

	//  disclaimer
	const disclaimer = disclaimer_description ? (
		<div className="section item-wrapper">
			<div className="section-title">{disclaimer_title}</div>

			<div className="dot with-title"></div>

			<div className="item__first">
				<div className="item-content p-0">{disclaimer_description}</div>
			</div>
		</div>
	) : (
		""
	);

	// for displaying additional left section start
	const additionalSectionLeft = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Courses") {
			storeAdditionalSection.push(courses);
		} else if (value.key === "Internship") {
			storeAdditionalSection.push(internship);
		}

		return storeAdditionalSection;
	});
	// for displaying additional left section end

	// for displaying additional right-section start
	const additionalSectionRight = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Extra-curricular Activities") {
			storeAdditionalSection.push(activities);
		} else if (value.key === "Custom") {
			customArr.map(
				(item) =>
					item.cus_section_id === value._id &&
					storeAdditionalSection.push(custom(item))
			);
		}

		return storeAdditionalSection;
	});
	// for displaying additional right-section end

	// left-section array
	const leftSection = [
		education,
		skills,
		languages,
		...additionalSectionLeft,
		hobbies,
		web_links
	];

	// right-section array
	const rightSection = [
		employment_history,
		projects,
		...additionalSectionRight,
		references,
		disclaimer
	];

	return (
		<>
			<style>{GraphicWebDesigner2Style}</style>

			<div className="container">
				<div className="body">
					{/* top-header start  */}
					<div className="top-header space-between">
						<div className="name-job-title-wrapper section-right-scale when-reverse">
							{checkVariables(first_name, last_name) && (
								<div className="name">
									{first_name} &nbsp;
									{last_name}
								</div>
							)}

							{job_title && <div className="job-title">{job_title}</div>}
						</div>

						{checkVariables(phone, email) && (
							<div className="item-wrapper phone-email-wrapper section-left-scale when-reverse">
								<div className="item-header">Contact</div>
								<div className="dot"></div>

								{/* phone start  */}
								{phone && (
									<div className="personal-d-item">
										<b>Phone:</b> &nbsp;
										<a
											href={`tel:${phone}`}
											target="_blank"
											rel="noopener noreferrer"
											className="anchor"
										>
											{phone}
										</a>
									</div>
								)}
								{/* phone end  */}

								{/* email start  */}
								{email && (
									<div className="personal-d-item">
										<b>Email:</b> &nbsp;
										<a
											href={`mailto:${email}`}
											target="_blank"
											rel="noopener noreferrer"
											dangerouslySetInnerHTML={{
												__html: divideAndAddBrTag(email, 100)
											}}
											className="anchor"
										></a>
									</div>
								)}
								{/* email end  */}
							</div>
						)}
					</div>
					{/* top-header end */}

					{/* personal-details-professional-summary start  */}
					<div className="personal-details-professional-summary-container">
						<div className="profile-personal-details-wrapper space-between">
							<div className="section-left-scale all-center">
								<div className="img-container">
									<img
										src={`/assets/profile-img/${
											cropped_profile_img
												? cropped_profile_img
												: "default-profile.png"
										}`}
										alt="profile-img"
										className="profile-img"
									/>
								</div>
							</div>

							<div className="section-right-scale">{personal_details}</div>
						</div>

						{rich_text_content && (
							<div className="professional-summary-wrapper space-between">
								<div className="section-left-scale center-top">
									<SectionTitle
										className="section-title when-professional-summary"
										sectionName={professional_summary_title}
										equalTo="Professional Summary"
										initialName="Profile"
									/>
								</div>

								<div className="section-right-scale">
									<RichTextParser className="" rich_text={rich_text_content} />
								</div>
							</div>
						)}
					</div>
					{/* personal-details-professional-summary end */}

					{/* section__wrapper start  */}
					<div className="section__wrapper d-flex">
						{/* section__left start  */}
						<div className="section-left-scale">
							{leftSection
								.filter((item) => item !== "")
								.map((value, index) => (
									<div key={index} id={index === 0 ? "left-first-section" : ""}>
										{value}
									</div>
								))}
						</div>
						{/* section__left end  */}

						{/* section__right start  */}
						<div className="section-right-scale">
							{rightSection
								.filter((item) => item !== "")
								.map((value, index) => (
									<div key={index}>{value}</div>
								))}
						</div>
						{/* section__right end  */}
					</div>
					{/* section__wrapper end  */}
				</div>
			</div>
		</>
	);
};

export default GraphicWebDesigner2;
