// external components

// internal components
import Editor from "../../components/for_cover_editor/Editor/Editor";
import Preview from "../../components/for_cover_editor/Preview/Preview";
import "./CoverEditor.css";

const CoverEditor = () => {
	return (
		<>
			<div className="cover-letter-container">
				<div className="left">
					<Editor />
				</div>
				<div className="right">
					<Preview />
				</div>
			</div>
		</>
	);
};

export default CoverEditor;
