// external components
import { NavLink } from "react-router-dom";

// internal components
import { GetContextApi } from "../../storage/ContextApi";
import "./Landing.css";

const Landing = () => {
	// landing contents
	const { contents } = GetContextApi();

	const { title_1, title_2_1, title_2_2, title_3, description, button_text } =
		contents.landing;

	return (
		<>
			<div className="front-page-wrapper">
				{/* main-content start  */}
				<div className="container">
					<div className="row body-container">
						<div className="col-11 col-sm-12">
							<div className="row m-0 body-wrapper">
								{/* left-side start  */}
								<div className="col-md-6  col-12 body-left p-0">
									<div className="contents">
										<h3 id="heading-3">{title_1}</h3>

										<h2 id="heading-2">
											{title_2_1} <span id="resumes-for-text">{title_2_2}</span>
										</h2>

										<h1 id="heading-1">{title_3}</h1>

										<p id="description">{description}</p>

										<NavLink to={"/resume-templates"}>
											<button type="button" id="btn">
												{button_text}
											</button>
										</NavLink>
									</div>
								</div>
								{/* left-side end  */}

								{/* right-side start  */}
								<div className="col-md-6 col-12 body-right p-0">
									<img
										src="/assets/images/banner-img.png"
										alt="banner-img"
										className="img-fluid right-banner-img"
									/>
								</div>
								{/* right-side end  */}
							</div>
						</div>
					</div>
				</div>
				{/* main-content end */}

				{/* animation shape start  */}
				<div className="section-shapes">
					<div className="shape">
						<svg
							className="banner-shape-one svg-item inline shape-zoom"
							id="Layer_3"
							data-name="Layer 3"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 62 62"
						>
							<defs></defs>
							<path
								style={{
									fill: "none",
									stroke: "#e7e8fc",
									strokeMiterlimit: "10",
									strokeWidth: "10px",
									fillRule: "evenodd"
								}}
								d="M332,620a26,26,0,1,1-26,26A26,26,0,0,1,332,620Z"
								transform="translate(-301 -615)"
							></path>
						</svg>
					</div>
					<div className="shape">
						<svg
							className="banner-shape-two svg-item inline shape-rotate"
							id="Layer_4"
							data-name="Layer 4"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 33.83 33.83"
						>
							<defs></defs>
							<title></title>
							<path
								style={{
									fill: "none",
									stroke: "#d3e1fd",
									strokeMiterlimit: "10",
									strokeWidth: "4px",
									fillRule: "evenodd"
								}}
								d="M895,191v27H868Z"
								transform="translate(-863.17 -186.17)"
							></path>
						</svg>
					</div>
					<div className="shape">
						<svg
							className="banner-shape-three svg-item inline shape-rotate"
							id="Layer_5"
							data-name="Layer 5"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 56.3 56.3"
						>
							<defs></defs>
							<title></title>
							<path
								style={{
									fill: "none",
									stroke: "#ffd3d8",
									strokeMiterlimit: "10",
									strokeWidth: "5px",
									fillRule: "evenodd"
								}}
								d="M766.87,597.9l22.23-27,27,22.23-22.23,27Z"
								transform="translate(-763.35 -567.35)"
							></path>
						</svg>
					</div>
					<div className="shape">
						<svg
							className="banner-shape-four svg-item inline rotate3d"
							id="Layer_6"
							data-name="Layer 6"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 15 15"
						>
							<defs></defs>
							<title></title>
							<path
								style={{ fill: "#ffd3d8", fillRule: "evenodd" }}
								d="M561.5,474a7.5,7.5,0,1,1-7.5,7.5A7.5,7.5,0,0,1,561.5,474Z"
								transform="translate(-554 -474)"
							></path>
						</svg>
					</div>
				</div>
				{/* animation shape end */}
			</div>
		</>
	);
};

export default Landing;
