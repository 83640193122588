// external components
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	FacebookIcon,
	FacebookMessengerIcon,
	FacebookMessengerShareButton,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	WhatsappIcon,
	WhatsappShareButton
} from "react-share";

// for create and pdf renderer
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useAsync } from "react-use";

// internal components
import RestrictionDialog from "../../../common/RestrictionDialog";
import { EditorContextApi } from "../../../pages/ResumeEditor/EditorContextApi";
import { GetContextApi } from "../../../storage/ContextApi";
import "./Preview.css";
import Template from "./Template";

// define pdf worker
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Preview = ({ isSavingResume }) => {
	// for getting current-user &  contents
	const { currentUser, contents } = GetContextApi();

	// get editor Editor contentApi
	const { getResume } = EditorContextApi();

	// assign _id
	const _id = getResume._id;

	// define contents for preview
	const { saved, saving, choose_template, download_pdf } = contents.preview;

	// define contents for share-link
	const { header, sub_header, url_title, open_link, copy_link, copied } =
		contents.share_link_modal;

	// restriction contents
	const { download_restriction } = contents.package_limit;
	const { upgrade_now } = contents.navbar;

	// for redirect specific resume editor
	const Navigate = useNavigate();

	// for share link toggle
	const [shareLinkT, setShareLinkT] = useState("");

	// restriction dialog box toggle
	const [restrictionObj, setRestrictionObj] = useState("");

	// for getting url
	const [getUrl, setUrl] = useState("");

	// for handle pdf render start
	// for displaying section handler
	const [isDisplaying, setIsDisplaying] = useState(false);

	// for getting pdf's blob-data
	const [getBlob, setBlob] = useState("");

	// for getting pdf data
	const [numPages, setNumPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [previousRenderValue, setPreviousRenderValue] = useState(null);

	const onDocumentLoad = (d) => {
		setNumPages(d.numPages);
		setCurrentPage((prev) => Math.min(prev, d.numPages));

		!isDisplaying &&
			setTimeout(() => {
				setIsDisplaying(true);
			}, 150);
	};

	const onPreviousPage = () => {
		setCurrentPage((prev) => prev - 1);
	};

	const onNextPage = () => {
		setCurrentPage((prev) => prev + 1);
	};

	const render = useAsync(async () => {
		if (!getBlob) return null;

		const url = URL.createObjectURL(getBlob);

		return url;
	}, [getBlob]);

	const isFirstRendering = !previousRenderValue;

	const isLatestValueRendered = previousRenderValue === render.value;
	const isBusy = render.loading || !isLatestValueRendered;

	const shouldShowPreviousDocument = !isFirstRendering && isBusy;
	// for handle pdf render end

	// for closing shareUrl start
	const myRef = useRef();

	const handleClickOutside = (e) => {
		if (!myRef.current?.contains(e.target) && shareLinkT) {
			setShareLinkT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shareLinkT]);
	// for closing shareUrl end

	// social-share
	const title = "CVWAVE - Online Resume Builder | Free Resume Maker";
	const shareUrl = `${process.env.REACT_APP_DOMAIN}/resume/share/${_id}`;

	return (
		<>
			<div className="preview-container">
				<div className="preview-wrapper">
					<BlobProvider document={<Template getResume={getResume} />}>
						{({ blob }) => {
							if (blob) {
								setBlob(blob);
							}
							return null;
						}}
					</BlobProvider>

					{/* render-wrapper start  */}
					<div className="render-wrapper">
						{shouldShowPreviousDocument && previousRenderValue ? (
							<Document
								key={previousRenderValue}
								className="previous-document"
								file={previousRenderValue}
								error={null}
								loading={null}
								noData={null}
								renderMode="canvas"
							>
								<Page
									key={currentPage}
									pageNumber={currentPage}
									renderTextLayer={false}
									renderAnnotationLayer={false}
									loading={null}
									error={null}
									noData={null}
								/>
							</Document>
						) : null}

						<Document
							key={render.value}
							className={
								shouldShowPreviousDocument ? "rendering-document" : null
							}
							file={render.value}
							onLoadSuccess={onDocumentLoad}
							error={null}
							loading={null}
							noData={null}
							renderMode="canvas"
						>
							<Page
								key={currentPage}
								pageNumber={currentPage}
								onRenderSuccess={() => setPreviousRenderValue(render.value)}
								renderTextLayer={false}
								renderAnnotationLayer={false}
								loading={null}
								error={null}
								noData={null}
							/>
						</Document>

						{/* preview-icon-container start  */}
						<Link
							to={`../resume/share/${_id}`}
							target="_blank"
							rel="noreferrer"
						>
							<div className="preview-icon-container">
								<i className="fa-solid fa-expand" id="icon"></i>
							</div>
						</Link>
						{/* preview-icon-container end  */}
					</div>
					{/* render-wrapper end  */}

					{/* page-number start  */}
					{isDisplaying && (
						<div className="page-number">
							<img
								src="/assets/icons/left-page-arrow.png"
								alt="left-arrow"
								className="img-fluid"
								id={currentPage !== 1 ? "active" : ""}
								onClick={() => currentPage !== 1 && onPreviousPage()}
							/>
							<span>
								{numPages} / {currentPage}
							</span>

							<img
								src="/assets/icons/right-page-arrow.png"
								alt="right-arrow"
								className="img-fluid"
								id={numPages !== currentPage ? "active" : ""}
								onClick={() => numPages !== currentPage && onNextPage()}
							/>
						</div>
					)}
					{/* page-number end  */}

					{/* preview-footer start  */}
					{isDisplaying && (
						<div className="preview-footer">
							<div
								className="select-template"
								onClick={() => Navigate(`../resume/${getResume.counter}/edit`)}
							>
								<img
									src="/assets/icons/choose-template-icon.png"
									alt="choose-template-icon"
								/>
								<span>{choose_template}</span>
							</div>

							<div className="right-btn-container">
								{currentUser.package["Package Name"] === "Free Plan" ? (
									<button
										type="button"
										className="primary-btn-styles"
										onClick={() => setRestrictionObj(download_restriction)}
									>
										<span id="download-text">{download_pdf}</span>
										<span id="download-icon">
											<i className="fa-solid fa-download"></i>
										</span>
									</button>
								) : (
									<PDFDownloadLink
										document={<Template getResume={getResume} />}
										fileName={`${currentUser.name.replace(
											/\s/g,
											"-"
										)}-cvwave.pdf`}
									>
										{({ blob, url, loading, error }) => (
											<button type="button" className="primary-btn-styles">
												<span id="download-text">{download_pdf}</span>
												<span id="download-icon">
													<i className="fa-solid fa-download"></i>
												</span>
											</button>
										)}
									</PDFDownloadLink>
								)}

								<button
									type="button"
									className="primary-btn-styles"
									id="share"
									onClick={() => setShareLinkT(true)}
								>
									<span className="icon-con">
										<img
											src="/assets/icons/share-icon.png"
											alt="share-icon"
											className="img-fluid"
										/>
										<h6>Share resume</h6>
									</span>
								</button>
							</div>
						</div>
					)}
					{/* preview-footer end */}
				</div>

				{/* save-container start  */}
				{isDisplaying && (
					<div className="save-container">
						{isSavingResume ? (
							<div>
								<div className="icon-container">
									<i className="fa-solid fa-spinner fa-spin"></i>{" "}
								</div>

								<span>{saving}</span>
							</div>
						) : (
							<div>
								<img
									src="/assets/icons/saved-icon.png"
									alt="saved-icon"
									className="img-fluid"
								/>
								<span>{saved}</span>
							</div>
						)}
					</div>
				)}
				{/* save-container end  */}

				{/* for share link start  */}
				{shareLinkT && (
					<div className="share-link-container">
						<div
							className="share-link-wrapper"
							data-aos="fade-down"
							ref={myRef}
						>
							<div className="share-top-section">
								<h3>{header}</h3>
							</div>

							<div className="share-description">{sub_header}</div>

							<div className="share-input-field">
								<label htmlFor="url">{url_title}</label>
								<input
									className={getUrl ? "active" : ""}
									value={shareUrl}
									readOnly
									id="url"
								/>
							</div>

							<div className="share-btn-container">
								<div className="share-left-btns">
									<FacebookShareButton
										url={shareUrl}
										quote={title}
										hashtag={"#freeresumetemplate"}
										className="share-btn"
									>
										<FacebookIcon className="icon" />
									</FacebookShareButton>

									<FacebookMessengerShareButton
										url={shareUrl}
										appId={process.env.REACT_APP_APPID}
										quote={title}
										className="share-btn"
									>
										<FacebookMessengerIcon className="icon" />
									</FacebookMessengerShareButton>

									<WhatsappShareButton
										url={shareUrl}
										quote={title}
										hashtag={"#freeresumetemplate"}
										className="share-btn"
									>
										<WhatsappIcon className="icon" />
									</WhatsappShareButton>

									<LinkedinShareButton
										url={shareUrl}
										title={title}
										className="share-btn"
									>
										<LinkedinIcon className="icon" />
									</LinkedinShareButton>
								</div>

								<div className="share-right-btns">
									<button
										onClick={() => {
											setShareLinkT(false);
										}}
									>
										<Link
											to={`../resume/share/${_id}`}
											target="_blank"
											rel="noreferrer"
										>
											{open_link}
										</Link>
									</button>

									<button
										onClick={() => {
											setUrl(shareUrl);
											navigator.clipboard.writeText(shareUrl);
										}}
									>
										{getUrl ? copied : copy_link}
									</button>
								</div>
							</div>

							<div
								className="share-close-btn"
								onClick={() => {
									setShareLinkT(false);
									setUrl("");
								}}
							>
								<i className="fa-solid fa-xmark"></i>
							</div>
						</div>
					</div>
				)}
				{/* for share link end  */}

				{/* for restriction dialog box */}
				{restrictionObj && (
					<RestrictionDialog
						restrictionObj={restrictionObj}
						setRestrictionObj={setRestrictionObj}
						btn_text={upgrade_now}
					/>
				)}
			</div>
		</>
	);
};

export default Preview;
