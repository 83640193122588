// external components
import { Document, Font, Page, StyleSheet } from "@react-pdf/renderer";
import ReactDomServer from "react-dom/server";
import Html from "react-pdf-html";

// internal components
import {
	chooseTemplate,
	defineBackgroundColor,
	generateFooter,
	generatePadding,
	selectedFont
} from "./utilities";

const Template = ({ getResume }) => {
	const template = getResume.template.name;

	// for page style
	const styles = StyleSheet.create({
		page: {
			backgroundColor: defineBackgroundColor(template),
			position: "relative",
			overflow: "hidden",
			padding: generatePadding(template),
			fontFamily: selectedFont(template)
		}
	});

	// prevent hyphen
	Font.registerHyphenationCallback((word) => {
		return [word];
	});

	return (
		<Document>
			<Page size={"A4"} style={styles.page} wrap>
				<Html>
					{ReactDomServer.renderToStaticMarkup(chooseTemplate(getResume))}
				</Html>

				{generateFooter({
					template,
					web_link: getResume.web_link.web_link_items
				})}
			</Page>
		</Document>
	);
};

export default Template;
