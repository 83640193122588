function checkVariables(...variables) {
	for (let variable of variables) {
		if (typeof variable === "string" && variable.trim().length > 0) {
			return true;
		} else if (typeof variable === "number") {
			return true;
		} else if (typeof variable === "boolean" && variable === true) {
			return true;
		} else if (Array.isArray(variable) && variable.length > 0) {
			return true;
		}
	}

	return false;
}

const fetchingResumeData = (resume) => {
	// personal-details[0]
	const {
		personal_details_title,
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth
	} = resume?.personal_details[0];

	// professional_summary
	const { professional_summary_title, rich_text_content } =
		resume?.professional_summary[0];

	// employment history
	const { employment_history_title, employment_history_items } =
		resume?.employment_history;

	// eduction
	const { education_title, education_items } = resume?.education;

	// projects
	const { project_title, project_items } = resume?.projects;

	// web_link
	const { web_link_items } = resume?.web_link;

	// skills
	const { skills_title, skills_items, displaying_level } = resume?.skills;

	// languages
	const { languages_title, languages_items } = resume?.languages;

	// hobbies
	const { hobbies_title, hobbies_description } = resume?.hobbies[0];

	// Extra-curricular Activities
	const { activity_title, activity_items } = resume?.activity;

	// internship
	const { internship_title, internship_items } = resume?.internship;

	// courses
	const { courses_title, courses_items } = resume?.courses;

	// references
	const { references_title, references_hide, references_items } =
		resume?.references;

	// custom
	const customArr = resume.custom;

	// disclaimer
	const { disclaimer_title, disclaimer_description } = resume?.disclaimer[0];

	return {
		personal_details_title,
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		professional_summary_title,
		rich_text_content,
		employment_history_title,
		employment_history_items,
		education_title,
		education_items,
		project_title,
		project_items,
		web_link_items,
		skills_title,
		skills_items,
		displaying_level,
		languages_title,
		languages_items,
		hobbies_title,
		hobbies_description,
		activity_title,
		activity_items,
		internship_title,
		internship_items,
		courses_title,
		courses_items,
		references_title,
		references_hide,
		references_items,
		customArr,
		disclaimer_title,
		disclaimer_description
	};
};

const isArrayEmpty = (arr) => {
	return arr.length === 0;
};

const removeEmptyObjects = (array, check) => {
	return array.filter((item) => {
		return check.every((key) => item[key] && item[key].trim() !== "");
	});
};

function objHasAnyValue(obj) {
	// Check if any value of the object (excluding _id key) is truthy
	const keysToCheck = Object.keys(obj).filter((key) => key !== "_id");
	if (keysToCheck.length === 0) return false; // If there are no keys to check, return false

	return keysToCheck.some((key) => {
		const value = obj[key];
		if (Array.isArray(value)) {
			return value.length > 1;
		} else {
			return !!value;
		}
	});
}

// originalIcon
const generateIcon = (value) => {
	if (value?.includes("youtube")) {
		return "youtube.png";
	} else if (value?.includes("twitter")) {
		return "twitter.png";
	} else if (value?.includes("instagram")) {
		return "instagram.png";
	} else if (value?.includes("facebook")) {
		return "facebook-circle.png";
	} else if (value?.includes("linkedin")) {
		return "linkedin-circle.png";
	} else if (value?.includes("github")) {
		return "github.png";
	} else if (value?.includes("@")) {
		return "email.png";
	} else {
		return "internet.png";
	}
};

const generateIconBlack = (value) => {
	if (value?.includes("youtube")) {
		return "account-youtube.png";
	} else if (value?.includes("twitter")) {
		return "account-twitter.png";
	} else if (value?.includes("instagram")) {
		return "account-instagram.png";
	} else if (value?.includes("facebook")) {
		return "account-facebook.png";
	} else if (value?.includes("linkedin")) {
		return "account-linkedin.png";
	} else if (value?.includes("github")) {
		return "github.png";
	} else if (value?.includes("@")) {
		return "account-email.png";
	} else {
		return "internet.png";
	}
};

// whiteIcon
const generateIconWhite = (value) => {
	if (value?.includes("youtube")) {
		return "youtube-white.png";
	} else if (value?.includes("twitter")) {
		return "twitter-white.png";
	} else if (value?.includes("instagram")) {
		return "instagram-white.png";
	} else if (value?.includes("facebook")) {
		return "facebook-white.png";
	} else if (value?.includes("linkedin")) {
		return "linkedin-white.png";
	} else if (value?.includes("github")) {
		return "github-white.png";
	} else if (value?.includes("@")) {
		return "at-white.png";
	} else {
		return "internet-white.png";
	}
};

// for email
const divideAndAddBrTag = (str, length = 22) => {
	if (str.length > length) {
		const firstPart = str.substring(0, length);
		const secondPart = str.substring(length);

		return `${firstPart}<br/>${secondPart}`;
	} else {
		return str;
	}
};

export {
	checkVariables,
	divideAndAddBrTag,
	fetchingResumeData,
	generateIcon,
	generateIconBlack,
	generateIconWhite,
	isArrayEmpty,
	objHasAnyValue,
	removeEmptyObjects
};
