// external components
import { ClickAwayListener, Tooltip, Zoom } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { memo } from "react";

// internal components

const DatePickerCom = ({
	startDateName,
	endDateName,
	inputValues,
	setInputValues,
	setLatestUpdate,
	contents,
	controller,
	dispatch,
	fromWhere
}) => {
	// define minimum and maximum dates
	const minimumDate = dayjs("1950-01-01");
	const maximumDate = dayjs(`${dayjs().year()}-12-31`);

	return (
		<div className="first-col">
			<div className="sub-label">
				<label htmlFor="#">{contents.date}</label>

				<div className="edit-icon">
					<ClickAwayListener
						onClickAway={() => dispatch({ type: "close-date-tooltip" })}
					>
						<Tooltip
							title={
								<p
									dangerouslySetInnerHTML={{ __html: contents.date_tooltip }}
								></p>
							}
							placement="top"
							arrow
							TransitionComponent={Zoom}
							componentsProps={{
								tooltip: {
									sx: {
										bgcolor: "#040026",
										"& .MuiTooltip-arrow": {
											color: "#040026"
										},
										fontSize: "13px",
										fontWeight: "400",
										textAlign: "center"
									}
								}
							}}
							onClose={() => dispatch({ type: "close-date-tooltip" })}
							open={controller.dateTooltip}
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<i
								onClick={() => dispatch({ type: "open-date-tooltip" })}
								className="fa-regular fa-circle-question"
							></i>
						</Tooltip>
					</ClickAwayListener>
				</div>
			</div>

			<div className="input-field-con">
				<div className="input-field">
					<input
						style={{ cursor: "pointer" }}
						type="text"
						onClick={() => dispatch({ type: "startDate" })}
						placeholder={fromWhere !== "education" ? "Month Year" : "Year"}
						value={
							inputValues[startDateName]
								? dayjs(inputValues[startDateName]).format(
										fromWhere !== "education" ? "MMM YYYY" : "YYYY"
								  )
								: ""
						}
						readOnly
					/>

					{controller.startDate && (
						<DatePicker
							className="custom-datepicker"
							views={fromWhere !== "education" ? ["month", "year"] : ["year"]}
							open={controller.startDate}
							onClose={() => dispatch({ type: "close" })}
							onChange={(date) => {
								setInputValues((prevValues) => ({
									...prevValues,
									[startDateName]: date.toISOString()
								}));

								setLatestUpdate({
									[startDateName]: date.toISOString()
								});
							}}
							minDate={minimumDate}
							maxDate={maximumDate}
						/>
					)}
				</div>

				<div className="input-field present-work-field">
					<input
						style={{ cursor: "pointer" }}
						type="text"
						onClick={() => dispatch({ type: "endDate" })}
						placeholder={fromWhere !== "education" ? "Month Year" : "Year"}
						value={
							inputValues[endDateName] && inputValues[endDateName] !== "Present"
								? dayjs(inputValues[endDateName]).format(
										fromWhere !== "education" ? "MMM YYYY" : "YYYY"
								  )
								: inputValues[endDateName] === "Present"
								? "Present"
								: ""
						}
						readOnly
						disabled={controller.isPresent ? true : false}
					/>

					{controller.endDate && (
						<DatePicker
							className="custom-datepicker"
							name={endDateName}
							views={fromWhere !== "education" ? ["month", "year"] : ["year"]}
							open={controller.endDate}
							onClose={() => dispatch({ type: "close" })}
							onChange={(date) => {
								setInputValues((prevValues) => ({
									...prevValues,
									[endDateName]: date.toISOString()
								}));

								setLatestUpdate({
									[endDateName]: date.toISOString()
								});
							}}
							minDate={dayjs(inputValues[startDateName])}
							maxDate={maximumDate}
						/>
					)}

					<div className="present-work-wrapper">
						<input
							type="checkbox"
							checked={inputValues[endDateName] === "Present" ? true : false}
							onClick={() => {
								dispatch({ type: "toggle-present" });
								setInputValues((prevValues) => ({
									...prevValues,
									[endDateName]: controller.isPresent ? "" : "Present"
								}));

								!controller.isPresent
									? setLatestUpdate({
											[endDateName]: "Present"
									  })
									: setLatestUpdate({
											[endDateName]: null
									  });
							}}
							readOnly
						/>
						<span>{contents.present}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(DatePickerCom);
