/* eslint-disable react-hooks/exhaustive-deps */
// external components
import { toBengaliNumber } from "bengali-number";
import { memo, useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";

// internal components
import { EditorContextApi } from "../../../../../../pages/ResumeEditor/EditorContextApi";

const Percentage = ({ contents }) => {
	// contents
	const {
		percentage_text,
		percentage_text_short,
		per_personal,
		per_professional,
		per_employment,
		per_education,
		per_links,
		per_skills
	} = contents.editor;

	// get Editor ContextApi
	const { getResume } = EditorContextApi();

	// for suggestion category toggle
	const [suggestionCategoryT, setSuggestionCategoryT] = useState(false);

	// for close suggestion section when outside clicked start
	const myRef = useRef();
	const myRef1 = useRef();

	const handleClickOutside = (e) => {
		if (
			!myRef.current?.contains(e.target) &&
			!myRef1.current?.contains(e.target) &&
			suggestionCategoryT
		) {
			setSuggestionCategoryT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [suggestionCategoryT]);
	// for close suggestion section when outside clicked end

	// for percentage & suggestion start
	const [getPercentage, setPercentage] = useState(0);
	const showSuggestionRef = useRef([]);

	useEffect(() => {
		if (getResume) {
			// for percentage
			const { job_title, first_name, last_name, email, phone } =
				getResume.personal_details[0];

			const rich_text_content =
				getResume.professional_summary[0]?.rich_text_content;

			const employment =
				getResume.employment_history?.employment_history_items?.length;

			const education = getResume.education?.education_items?.length;

			const webLink = getResume.web_link?.web_link_items?.length;

			const skill = getResume.skills?.skills_items?.length;

			// percentage
			setPercentage(
				Number(job_title && 5) +
					Number(first_name && 5) +
					Number(last_name && 5) +
					Number(email && 5) +
					Number(phone && 5) +
					Number(rich_text_content && 15) +
					Number(employment && 15) +
					Number(education && 15) +
					Number(webLink && 15) +
					Number(skill && 15)
			);

			// for suggestion
			const suggestion = [
				{ item: per_personal, percentage: "25" },
				{ item: per_professional, percentage: "15" },
				{ item: per_employment, percentage: "15" },
				{ item: per_education, percentage: "15" },
				{ item: per_links, percentage: "15" },
				{ item: per_skills, percentage: "15" }
			];

			let suggestionArr = [];
			if (job_title && first_name && last_name && email && phone) {
				suggestionArr.push({ item: per_personal, percentage: "25" });
			}

			if (rich_text_content) {
				suggestionArr.push({
					item: per_professional,
					percentage: "15"
				});
			}

			if (employment) {
				suggestionArr.push({ item: per_employment, percentage: "15" });
			}

			if (education) {
				suggestionArr.push({ item: per_education, percentage: "15" });
			}

			if (webLink) {
				suggestionArr.push({
					item: per_links,
					percentage: "15"
				});
			}

			if (skill) {
				suggestionArr.push({ item: per_skills, percentage: "15" });
			}

			showSuggestionRef.current = suggestion.filter(
				(element1) =>
					!suggestionArr.some((element2) => element1.item === element2.item)
			);
		}
	}, [
		getResume?.personal_details?.[0]?.job_title,
		getResume?.personal_details?.[0]?.first_name,
		getResume?.personal_details?.[0]?.last_name,
		getResume?.personal_details?.[0]?.email,
		getResume?.personal_details?.[0]?.phone,
		getResume?.professional_summary?.[0]?.rich_text_content,
		getResume?.employment_history?.employment_history_items?.length,
		getResume?.education?.education_items?.length,
		getResume?.web_link?.web_link_items?.length,
		getResume?.skills?.skills_items?.length
	]);
	// for percentage end

	return (
		<div className="progress-bar-container">
			<div className="top-section-progress">
				<div className="total-percentage">
					<div className="percentage">
						{contents.title === "bangla"
							? toBengaliNumber(getPercentage)
							: getPercentage}
						&nbsp;%
					</div>
					<span id="total-percentage-text">{percentage_text}</span>
					<span id="total-percentage-text-mb">{percentage_text_short}</span>
				</div>

				<div className="suggestion-container">
					<div className="suggestion-wrapper">
						{showSuggestionRef.current.length > 0 && (
							<div
								in="entered"
								className="suggestion"
								onClick={() => setSuggestionCategoryT(!suggestionCategoryT)}
							>
								<div className="percentage">
									+
									{contents.title === "bangla"
										? toBengaliNumber(showSuggestionRef.current[0].percentage)
										: showSuggestionRef.current[0].percentage}
									%
								</div>
								{showSuggestionRef.current[0].item}
							</div>
						)}

						<div
							className="suggestion-help"
							onClick={() => setSuggestionCategoryT(!suggestionCategoryT)}
							ref={myRef1}
						>
							{suggestionCategoryT ? (
								<i className="fa-solid fa-circle-xmark"></i>
							) : (
								<i className="fa-solid fa-circle-question"></i>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="bottom-section-progress">
				<div
					className="scale"
					style={{
						transform: `scaleX( ${getPercentage === 100 ? "" : "."}${
							getPercentage === 100 ? 1 : getPercentage
						})`
					}}
				></div>
			</div>

			{suggestionCategoryT && (
				<div className="suggestion-category" ref={myRef}>
					<div className="suggestion-category-wrapper" data-aos="flip-down">
						<div className="item">
							<div class="percentage">
								{contents.title === "bangla" ? "+২৫%" : "+25%"}
							</div>
							<Link
								to="personal-details"
								spy={true}
								smooth={true}
								offset={-70}
								duration={400}
								onClick={() => setSuggestionCategoryT(false)}
							>
								{per_personal}
							</Link>
						</div>

						<div className="item">
							<div class="percentage">
								{contents.title === "bangla" ? "+১৫%" : "+15%"}
							</div>

							<Link
								to="professional-summary"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}
								onClick={() => setSuggestionCategoryT(false)}
							>
								{per_professional}
							</Link>
						</div>

						<div className="item">
							<div class="percentage">
								{contents.title === "bangla" ? "+১৫%" : "+15%"}
							</div>

							<Link
								to="employment-history"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}
								onClick={() => setSuggestionCategoryT(false)}
							>
								{per_employment}
							</Link>
						</div>

						<div className="item">
							<div class="percentage">
								{contents.title === "bangla" ? "+১৫%" : "+15%"}
							</div>
							<Link
								to="education"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}
								onClick={() => setSuggestionCategoryT(false)}
							>
								{per_education}
							</Link>
						</div>

						<div className="item">
							<div class="percentage">
								{contents.title === "bangla" ? "+১৫%" : "+15%"}
							</div>
							<Link
								to="website_social_links"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}
								onClick={() => setSuggestionCategoryT(false)}
							>
								{per_links}
							</Link>
						</div>

						<div className="item">
							<div class="percentage">
								{contents.title === "bangla" ? "+১৫%" : "+15%"}
							</div>

							<Link
								to="skills"
								spy={true}
								smooth={true}
								offset={-70}
								duration={500}
								onClick={() => setSuggestionCategoryT(false)}
							>
								{per_skills}
							</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default memo(Percentage);
