// external components
import { memo, useEffect, useState } from "react";

// internal components
import { DeleteTooltip, FirstCol, SecondCol } from "../common";

const ReferencesItem = ({
	itemValues,
	handleSetActive,
	contents,
	setDeleteItem,
	updateResumeInfo,
	delay
}) => {
	// referencesItem contents
	const { refer_name, refer_company, not_specific, email, phone, deleteText } =
		contents.editor;

	// assign
	const _id = itemValues._id;

	// getting & updating links all values start
	const [inputValues, setInputValues] = useState(itemValues);

	const [latestUpdate, setLatestUpdate] = useState({});

	useEffect(() => {
		if (Object.keys(latestUpdate)[0]) {
			const timeoutId = setTimeout(() => {
				updateResumeInfo({ ...latestUpdate, _id });
			}, delay);

			return () => {
				clearTimeout(timeoutId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestUpdate]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		if (name === "references_phone") {
			setInputValues((prevValues) => ({
				...prevValues,
				[name]: value.replace(/[^0-9+]/g, "")
			}));

			setLatestUpdate({
				[name]: value.replace(/[^0-9+]/g, "") || null
			});
		} else {
			setInputValues((prevValues) => ({
				...prevValues,
				[name]: value
			}));

			setLatestUpdate({
				[name]: value || null
			});
		}
	};
	// getting & updating links all values end

	// collapse toggle
	const handleActivate = () => {
		handleSetActive(_id);
	};

	return (
		<>
			<div className="specific-item-container">
				<div className="specific-item-wrapper">
					<div className="content-title-wrapper" onClick={handleActivate}>
						<div className="content-title">
							<h4>
								{inputValues.references_name
									? inputValues.references_name
									: not_specific}{" "}
							</h4>
							{inputValues.references_company && (
								<span>{inputValues.references_company}</span>
							)}
						</div>

						<i className="fa-solid fa-chevron-up" id="up"></i>

						<i className="fa-solid fa-chevron-down" id="down"></i>
					</div>

					{/* for delete that item */}
					<DeleteTooltip
						content={deleteText}
						setDeleteItem={setDeleteItem}
						_id={_id}
					/>
				</div>

				<div className="row-container-wrapper">
					{/* referName and company start  */}
					<div className="row-container">
						<FirstCol
							content={refer_name}
							name={"references_name"}
							value={inputValues.references_name}
							onChange={handleInputChange}
						/>

						<SecondCol
							content={refer_company}
							name={"references_company"}
							value={inputValues.references_company}
							onChange={handleInputChange}
						/>
					</div>
					{/* referName and company end  */}

					{/* phone and email start  */}
					<div className="row-container">
						<FirstCol
							content={phone}
							name={"references_phone"}
							value={inputValues.references_phone}
							onChange={handleInputChange}
						/>

						<SecondCol
							content={email}
							name={"references_email"}
							value={inputValues.references_email}
							onChange={handleInputChange}
						/>
					</div>
					{/* phone and email end  */}
				</div>
			</div>
		</>
	);
};

export default memo(ReferencesItem);
