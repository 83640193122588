// external components
import { DatePicker } from "@mui/x-date-pickers";

// internal components

const DatePickerCom = ({
	name,
	value,
	onChange,
	labelName,
	placeholder,
	haveIm
}) => {
	return (
		<div className="input-field">
			<label htmlFor={name} className="label">
				{labelName ? labelName : name.replace(/_/g, " ")}{" "}
				{haveIm && <span id="important">*</span>}
			</label>

			<DatePicker
				className="input"
				id={name}
				name={name}
				label={placeholder ? placeholder : ""}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};

export default DatePickerCom;
