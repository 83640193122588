// external component
import { Image, Link, StyleSheet, View } from "@react-pdf/renderer";
import {
	generateIconBlack,
	isArrayEmpty,
	removeEmptyObjects
} from "../Templates/Components/Utils";

const generateFooter = ({ template, web_link }) => {
	if (["Account Manager"].includes(template)) {
		const styles = StyleSheet.create({
			account_manager_footer: {
				position: "absolute",
				bottom: 0,
				left: 0,
				height: "35px",
				width: "100%",
				display: "flex",
				flexDirection: "row"
			},

			one: {
				width: "45%",
				height: "100%",
				backgroundColor: "#0a2640"
			},

			two: {
				width: "45%",
				height: "100%",
				backgroundColor: "#D2D1D1"
			},

			three: {
				width: "10%",
				height: "100%",
				backgroundColor: "#0a2640"
			}
		});

		return (
			<View style={styles.account_manager_footer} fixed>
				<View style={styles.one}></View>
				<View style={styles.two}></View>
				<View style={styles.three}></View>
			</View>
		);
	} else if (["Product Designer"].includes(template)) {
		const styles = StyleSheet.create({
			product_designer_footer: {
				position: "absolute",
				bottom: 0,
				left: "30px",
				height: "16px",
				width: "540px",
				backgroundColor: "#2D2D2B"
			}
		});

		return <View style={styles.product_designer_footer} fixed></View>;
	} else if (["Professional Resume"].includes(template)) {
		const styles = StyleSheet.create({
			web_links_container: {
				position: "absolute",
				bottom: 0,
				left: 0,
				height: "25px",
				width: "100%",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
				borderTop: "1px solid #87757a"
			},

			web_link_wrapper: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				padding: "0 7px"
			},

			img: {
				width: "auto",
				height: "12px",
				marginRight: "5px"
			},

			label: {
				fontSize: "9px",
				fontWeight: "500",
				color: "#313133",
				textDecoration: "none"
			}
		});

		return (
			<View style={styles.web_links_container} fixed>
				{!isArrayEmpty(web_link) &&
					removeEmptyObjects(web_link, ["link_label", "link_url"]).map(
						(label, index) => {
							return (
								<View key={index} style={styles.web_link_wrapper}>
									<Image
										src={`/assets/icons/${generateIconBlack(label.link_url)}`}
										alt="icon"
										style={styles.img}
									/>

									<Link
										href={label.link_url}
										target="_blank"
										rel="noopener noreferrer"
										style={styles.label}
									>
										{label.link_label}
									</Link>
								</View>
							);
						}
					)}
			</View>
		);
	} else if (["Creative Designer"].includes(template)) {
		const styles = StyleSheet.create({
			top_triangle: {
				width: "100px",
				height: "100px",
				backgroundColor: "#f2ab02",
				transform: "rotate(135deg)",
				position: "absolute",
				top: "-50px",
				left: "-50px"
			},

			bottom_triangle: {
				width: "100px",
				height: "100px",
				backgroundColor: "#f2ab02",
				transform: "rotate(134deg)",
				position: "absolute",
				bottom: "-50px",
				right: "-50px"
			}
		});

		return (
			<>
				<View style={styles.top_triangle} fixed></View>
				<View style={styles.bottom_triangle} fixed></View>
			</>
		);
	} else if (["Graphics Designer 2"].includes(template)) {
		const styles = StyleSheet.create({
			layer: {
				position: "absolute",
				top: 0,
				width: "40%",
				height: "100%",
				left: "-20px",
				zIndex: 1
			},

			first_layer: {
				backgroundColor: "#7b7e84",
				transform: "skewX(2deg) scaleY(100%)"
			},

			last_layer: {
				backgroundColor: "#404040",
				transform: "skewX(-3deg) scaleY(100%)"
			}
		});

		return (
			<>
				<View style={[styles.layer, styles.first_layer]} fixed></View>
				<View style={[styles.layer, styles.last_layer]} fixed></View>
			</>
		);
	} else {
		return null;
	}
};

export default generateFooter;
