const generateString = (value) => {
	let sentences = "";

	for (let i = 0; i < value.length; i++) {
		sentences = sentences + value[i] + ". ";
	}

	return sentences;
};

const searchingPhrasesFunction = async (
	searchValue,
	setSuggestKeys,
	setSearchPhrases,
	setIsLoading
) => {
	try {
		setIsLoading(true);

		const response = await fetch(`/phrases/search-key?key=${searchValue}`);

		const result = await response.json();

		if (response.status === 200) {
			// getting suggestKeys start
			let keysArr = [];

			if (result?.suggestKeys.length > 0) {
				result.suggestKeys.map(
					(value) =>
						value.keys.length > 0 &&
						value.keys.map((item) => keysArr.push(item))
				);
			} else {
				result?.documents.length > 0 &&
					result.documents.map(
						(value) =>
							value.keys.length > 0 &&
							value.keys.map((item) => keysArr.push(item))
					);
			}

			setSuggestKeys(keysArr);
			// getting suggestKeys end

			// getting searchPhrases start
			setSearchPhrases(
				result?.documents
					? Array.isArray(result.documents)
						? result.documents
						: [result.documents]
					: []
			);
			// getting searchPhrases end

			setIsLoading(false);
		} else if (result.error) {
			setSearchPhrases([]);
			setIsLoading(false);
		}
	} catch (error) {
		setSearchPhrases([]);
		setIsLoading(false);
	}
};

export { generateString, searchingPhrasesFunction };
