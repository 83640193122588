// external components
import { convertFromHTML } from "draft-convert";
import { EditorState } from "draft-js";
import { memo, useEffect, useReducer, useState } from "react";
import { toast } from "react-hot-toast";

// internal components
import { updateItems } from "../../common/function";
import {
	DeleteDialog,
	DeleteTooltip,
	EditorTips,
	FirstCol,
	RichEditor,
	SecondCol
} from "./../../common";
import AddLink from "./AddLink";

const ProjectItem = ({
	itemValues,
	handleSetActive,
	contents,
	setDeleteItem,
	updateResumeInfo,
	getResume,
	setResume,
	delay
}) => {
	// projects contents
	const {
		project_name,
		project_duration,
		technologies,
		add_link,
		add_more_link,
		description,
		not_specific,
		deleteText
	} = contents.editor;

	// define maximum words & counter
	const maxWords = 300;
	const [wordCount, setWordCount] = useState(0);

	// assign
	const _id = itemValues._id;
	const userId = getResume.user;
	const counter = getResume.counter;

	// for delete specific items
	const [deleteLink, setDeleteLink] = useState("");

	// getting & updating projects all values start
	const [inputValues, setInputValues] = useState({
		...itemValues,
		pro_description: itemValues.pro_description
			? EditorState.createWithContent(
					convertFromHTML(itemValues.pro_description)
			  )
			: EditorState.createEmpty()
	});

	const [latestUpdate, setLatestUpdate] = useState({});

	useEffect(() => {
		if (Object.keys(latestUpdate)[0]) {
			const timeoutId = setTimeout(() => {
				updateResumeInfo({ ...latestUpdate, _id });
			}, delay);

			return () => {
				clearTimeout(timeoutId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestUpdate]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setInputValues((prevValues) => ({
			...prevValues,
			[name]: value
		}));

		setLatestUpdate({
			[name]: value || null
		});
	};
	// getting & updating projects all values end

	// useReducer for datepicker toggle start
	const initialState = {
		editor: false
	};

	const reducerFunction = (state, action) => {
		const { type } = action;

		if (type === "focused-rich-editor") {
			return {
				...state,
				editor: true
			};
		} else if (type === "blur-rich-editor") {
			return {
				...state,
				editor: false
			};
		} else {
			return state;
		}
	};

	const [controller, dispatch] = useReducer(reducerFunction, initialState);
	// useReducer for datepicker toggle end

	// collapse toggle
	const handleActivate = () => {
		handleSetActive(_id);
	};

	// project's links start
	const [proLinksArr, setProLinksArr] = useState([]);
	useEffect(() => {
		if (inputValues?.project_links?.length > 0) {
			let getProjectLinks = [];

			inputValues.project_links.map((item) =>
				getProjectLinks.push(
					<AddLink
						value={item}
						updateResumeInfo={updateResumeInfo}
						parentId={_id}
						setDeleteLink={setDeleteLink}
						contents={contents}
						delay={delay}
					/>
				)
			);

			setProLinksArr(getProjectLinks);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addMoreProjectLink = async () => {
		try {
			const response = await fetch(
				`/resume/add-more-project-item-link?user=${userId}&counter=${counter}&item=${_id}`
			);

			const result = await response.json();

			if (response.status === 200) {
				setProLinksArr((prev) => [
					...prev,
					<AddLink
						value={result}
						updateResumeInfo={updateResumeInfo}
						parentId={_id}
						setDeleteLink={setDeleteLink}
						contents={contents}
						delay={delay}
					/>
				]);

				setResume((prev) => {
					const updatedProjectLinks = prev.projects.project_items.map(
						(item) => {
							if (item._id === _id) {
								const updatedArr = [...item.project_links, result];
								return { ...item, project_links: updatedArr };
							} else {
								return item;
							}
						}
					);

					return {
						...prev,
						projects: {
							...prev.projects,
							project_items: updatedProjectLinks
						}
					};
				});
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};

	// delete item handler
	useEffect(() => {
		if (
			deleteLink._id &&
			deleteLink.parentId &&
			(deleteLink?.conform || getResume.do_not_ask_again)
		) {
			setProLinksArr((prev) =>
				prev.filter((item) => item.props.value._id !== deleteLink._id)
			);

			setResume((prev) => {
				const updatedProjectItems = prev.projects.project_items.map((item) => {
					if (item._id === deleteLink.parentId) {
						const updatedArr = item.project_links.filter(
							(link) => link._id !== deleteLink._id
						);

						return { ...item, project_links: updatedArr };
					} else {
						return item;
					}
				});

				updateItems(
					updatedProjectItems,
					userId,
					counter,
					"projects",
					"project_items"
				);

				return {
					...prev,
					projects: {
						...prev.projects,
						project_items: updatedProjectItems
					}
				};
			});

			setDeleteLink("");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteLink?._id, deleteLink?.conform]);
	// project's links end

	return (
		<>
			<div className="specific-item-container">
				<div className="specific-item-wrapper">
					<div className="content-title-wrapper" onClick={handleActivate}>
						<div className="content-title">
							<h4>
								{inputValues.project_name
									? inputValues.project_name
									: not_specific}
							</h4>

							<span>{inputValues.technologies}</span>
						</div>

						<i className="fa-solid fa-chevron-up" id="up"></i>

						<i className="fa-solid fa-chevron-down" id="down"></i>
					</div>

					{/* for delete that item */}
					<DeleteTooltip
						content={deleteText}
						setDeleteItem={setDeleteItem}
						_id={_id}
					/>
				</div>

				<div className="row-container-wrapper project-item">
					{/* name & duration start  */}
					<div className="row-container">
						<FirstCol
							content={project_name}
							name={"project_name"}
							value={inputValues.project_name}
							onChange={handleInputChange}
						/>

						<SecondCol
							content={project_duration}
							name={"project_duration"}
							value={inputValues.project_duration}
							onChange={handleInputChange}
						/>
					</div>
					{/* name & duration end  */}

					{/* technologies start  */}
					<div className="row-container">
						<FirstCol
							content={technologies}
							name={"technologies"}
							value={inputValues.technologies}
							onChange={handleInputChange}
							id={"when-description"}
							placeholder={"HTML, CSS, React Js, ..."}
						/>
					</div>
					{/* technologies end */}

					{/* add links start  */}
					{proLinksArr.length > 0 &&
						proLinksArr.map((item) => {
							return (
								<div
									className="displaying-links-arr"
									key={item.props.value._id}
								>
									{item}
								</div>
							);
						})}

					{/* add-more start  */}
					<div
						id="add-more"
						className={proLinksArr.length < 3 ? "" : "disable-add-more"}
						onClick={() => {
							proLinksArr.length < 3 && addMoreProjectLink();
						}}
						style={{ margin: "0 0 10px 10px" }}
					>
						{proLinksArr.length === 0 ? (
							<span>
								<i className="fa-solid fa-plus"></i> {add_link}
							</span>
						) : (
							<span>
								<i className="fa-solid fa-plus"></i> {add_more_link}
							</span>
						)}
					</div>
					{/* add-more end */}
					{/* add links end */}

					{/* rich-editor start  */}
					<div className="row-container">
						<div className="first-col" id="when-description">
							<div className="sub-label">
								<label htmlFor="description">{description}</label>
							</div>
							<div className="input-field rich-editor">
								<RichEditor
									name="pro_description"
									editorState={inputValues.pro_description}
									setInputValues={setInputValues}
									updateResumeInfo={updateResumeInfo}
									_id={_id}
									maxWords={maxWords}
									wordCount={wordCount}
									setWordCount={setWordCount}
									placeholder="Project's description..."
									control={controller.editor}
									dispatchControl={dispatch}
									delay={delay}
								/>
							</div>

							{/* tips   */}
							<EditorTips
								contents={contents}
								maxWords={maxWords}
								wordCount={wordCount}
							/>
						</div>
					</div>
					{/* rich-editor end */}
				</div>

				{/* delete modal  */}
				{!getResume.do_not_ask_again && deleteLink && (
					<DeleteDialog
						contents={contents}
						deleteItem={deleteLink}
						setDeleteItem={setDeleteLink}
					/>
				)}
			</div>
		</>
	);
};

export default memo(ProjectItem);
