// external components

// internal components
import "./Preview.css";

const Preview = () => {
	return (
		<>
			<div className="preview-container">resume preview</div>
		</>
	);
};

export default Preview;
