/* eslint-disable jsx-a11y/anchor-is-valid */
// external components

// internal components
import PageHeader from "../../common/PageHeader";
import { GetContextApi } from "../../storage/ContextApi";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
	// contents
	const { contents } = GetContextApi();

	const {
		title,

		intro,
		intro_contents,

		what_info_collect,
		what_info_collect_contents,

		used_data,
		used_data_contents,

		how_access_data,
		how_access_data_contents,

		payment,
		payment_contents,

		refunds,
		refunds_contents,

		used_cookies,
		used_cookies_contents,

		third_party_disclosure,
		third_party_disclosure_contents,

		for_children,
		for_children_contents,

		data_security,
		data_security_contents,

		termination,
		termination_contents,

		update_privacy,
		update_privacy_contents,

		contact_us,
		contact_us_contents
	} = contents.privacy_policy;

	return (
		<>
			<PageHeader
				pageName={
					contents.title === "bangla" ? "গোপনীয়তা নীতি" : "PRIVACY POLICY"
				}
				link={contents.title === "bangla" ? "গোপনীয়তা নীতি" : "Privacy-Policy"}
				whichLanguage={contents.title}
			/>

			{/* contents-section start  */}
			<div className="container">
				<div className="privacy-policy-container row ">
					<div className="privacy-policy-wrapper col-11 col-sm-12">
						<h4 id="top-header">{title}</h4>

						<h5 id="intro">{intro}</h5>
						{intro_contents.map((value, index) => (
							<div
								key={index}
								className="content"
								dangerouslySetInnerHTML={{ __html: value }}
							></div>
						))}

						<h5>{what_info_collect}</h5>
						{what_info_collect_contents.map((value, index) => (
							<div
								key={index}
								className="content"
								dangerouslySetInnerHTML={{ __html: value }}
							></div>
						))}

						<h5>{used_data}</h5>
						{used_data_contents.map((value, index) => (
							<div
								key={index}
								className="content"
								dangerouslySetInnerHTML={{ __html: value }}
							></div>
						))}

						<h5>{how_access_data}</h5>
						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: how_access_data_contents }}
						></div>

						<h5>{payment}</h5>
						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: payment_contents }}
						></div>

						<h5>{refunds}</h5>
						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: refunds_contents }}
						></div>

						<h5>{used_cookies}</h5>
						{used_cookies_contents.map((value, index) => (
							<div
								key={index}
								className="content"
								dangerouslySetInnerHTML={{ __html: value }}
							></div>
						))}

						<h5>{third_party_disclosure}</h5>
						{third_party_disclosure_contents.map((value, index) => (
							<div
								key={index}
								className="content"
								dangerouslySetInnerHTML={{ __html: value }}
							></div>
						))}

						<h5>{for_children}</h5>
						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: for_children_contents }}
						></div>

						<h5>{data_security}</h5>
						{data_security_contents.map((value, index) => (
							<div
								key={index}
								className="content"
								dangerouslySetInnerHTML={{ __html: value }}
							></div>
						))}

						<h5>{termination}</h5>
						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: termination_contents }}
						></div>

						<h5>{update_privacy}</h5>
						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: update_privacy_contents }}
						></div>

						<h5>{contact_us}</h5>
						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: contact_us_contents }}
						></div>
					</div>
				</div>
			</div>
			{/* contents-section end  */}
		</>
	);
};

export default PrivacyPolicy;
