// external components
import { Tooltip, Zoom } from "@mui/material";

// internal components
import { GetContextApi } from "./../../../../../storage/ContextApi";

const DragIcon = ({ className }) => {
	// contents
	const { contents } = GetContextApi();
	const { drag } = contents.editor;

	return (
		<div className={className}>
			<Tooltip
				title={drag}
				placement="top"
				arrow
				TransitionComponent={Zoom}
				componentsProps={{
					tooltip: {
						sx: {
							bgcolor: "#040026",
							"& .MuiTooltip-arrow": {
								color: "#040026"
							},
							fontSize: "13px",
							fontWeight: "400",
							textAlign: "center"
						}
					}
				}}
			>
				<i className="fa-solid fa-grip-vertical" id="drag-icon"></i>
			</Tooltip>
		</div>
	);
};

export default DragIcon;
