// external components
import { EditorState, Modifier, SelectionState } from "draft-js";
import { Fragment, memo, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import sortArray from "sort-array";

// internal components
import { generateString, searchingPhrasesFunction } from "./function";

const PhrasesPreview = memo(
	({
		name,
		phrasesT,
		setPhrasesT,
		phrasesComponentRef,
		phrasesBtnRef,
		searchValue,
		setSearchValue,
		editorState,
		setInputValues,
		maxWords,
		allPhrasesRef
	}) => {
		// for insert phrases into editorState
		const insertText = (text) => {
			const currentContentState = editorState.getCurrentContent();
			const lastBlock = currentContentState.getLastBlock();

			const combineText = currentContentState.getPlainText("") + text;

			const currentWordCount = combineText.trim().split(/\s+/).length;

			if (currentWordCount >= maxWords) {
				return "handled";
			}

			if (
				lastBlock.getType() === "unordered-list-item" ||
				lastBlock.getType() === "ordered-list-item"
			) {
				const blockKey = lastBlock.getKey();
				const lastBlockText = lastBlock.getText();
				const newText = lastBlockText + ` ${text}`;

				const newContentState = Modifier.replaceText(
					currentContentState,
					SelectionState.createEmpty(blockKey).merge({
						anchorOffset: 0,
						focusOffset: lastBlockText.length
					}),
					newText
				);

				const newEditorState = EditorState.push(
					editorState,
					newContentState,
					"insert-characters"
				);

				setInputValues((prev) => ({ ...prev, [name]: newEditorState }));
			} else {
				const currentSelectionState = editorState.getSelection();

				// Create a new ContentState with the inserted text
				const contentStateWithText = Modifier.insertText(
					currentContentState,
					currentSelectionState,
					text
				);

				// Update the EditorState with the new ContentState
				const newEditorState = EditorState.push(
					editorState,
					contentStateWithText,
					"insert-characters"
				);

				setInputValues((prev) => ({ ...prev, [name]: newEditorState }));
			}
		};

		// for searching phrases start

		const [isLoading, setIsLoading] = useState(false);

		// if getting same category then don't display
		let checkCategory = "";

		// getting searching phrases
		const [searchPhrases, setSearchPhrases] = useState([]);

		// for getting suggestKeys
		const [suggestKeys, setSuggestKeys] = useState([]);

		useEffect(() => {
			if (searchValue && searchValue.length >= 3) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				checkCategory = "";

				searchingPhrasesFunction(
					searchValue,
					setSuggestKeys,
					setSearchPhrases,
					setIsLoading
				);
			}
		}, [searchValue]);
		// for searching phrases end

		// for hidden Phrases when click outside start
		const handleClickOutside = (e) => {
			if (
				!phrasesComponentRef.current?.contains(e.target) &&
				!phrasesBtnRef.current?.contains(e.target) &&
				phrasesT
			) {
				setPhrasesT(false);
				setSearchValue("");
				setSearchPhrases([]);
				setSuggestKeys([]);
			}
		};

		useEffect(() => {
			document.addEventListener("mousedown", handleClickOutside);
			return () =>
				document.removeEventListener("mousedown", handleClickOutside);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [phrasesT]);
		// for hidden Phrases when click outside end

		return (
			<div
				className="phrases-preview-wrapper"
				id={phrasesT ? "active" : ""}
				style={{
					maxWidth: phrasesT ? "" : 0,
					maxHeight: phrasesT ? "" : 0
				}}
			>
				<div className="phrases-preview" ref={phrasesComponentRef}>
					{/* search-bar start  */}
					<div className="search-bar">
						<label htmlFor="for-search">
							<i className="bi bi-search"></i>
						</label>

						<input
							type="text"
							id="for-search"
							placeholder="Filter phrases by keyword and job title"
							onChange={(e) => setSearchValue(e.target.value)}
							value={searchValue}
							autoComplete="false"
						/>

						{searchValue && isLoading ? (
							<i className="fa-solid fa-spinner fa-spin" id="spin"></i>
						) : (
							<i
								className="fa-solid fa-xmark"
								id="close"
								onClick={() => {
									setPhrasesT(false);
									setSearchValue("");
								}}
							></i>
						)}
					</div>
					{/* search-bar end  */}

					{/* suggestion keys start  */}
					{searchValue.length >= 3 && (
						<div className="suggestion">
							{suggestKeys.length <= 0 ? (
								<span id="not-found">
									No results found. Try different keywords or use common phrases
									below.
								</span>
							) : (
								<div className="item-container">
									{suggestKeys
										.map((value, index) => {
											return (
												<span
													key={index}
													id="item"
													onClick={() => setSearchValue(value)}
												>
													{value}
												</span>
											);
										})
										.splice(0, 8)}
								</div>
							)}
						</div>
					)}
					{/* suggestion keys end  */}

					{/* displaying all phrases start  */}
					<div className="phrases-contents-container">
						{searchPhrases.length > 0
							? sortArray(searchPhrases.slice(0, 15), {
									by: "category"
							  }).map((value, index) => {
									return (
										<div
											className="specific-category"
											key={index}
											id={
												checkCategory && checkCategory !== value?.category
													? "separation"
													: ""
											}
										>
											{checkCategory !== value?.category && (
												<div className="category">
													<span>{value?.category}</span>
												</div>
											)}

											{/* all phrases content start  */}
											{value.phrases?.length > 0 &&
												// eslint-disable-next-line array-callback-return
												value.phrases.map((item, index) => {
													if (
														item
															.toLowerCase()
															.includes(searchValue.toLowerCase()) ||
														(suggestKeys?.length > 0 &&
															suggestKeys.includes(searchValue))
													) {
														let eachItem = [];

														const lastChar = item.substr(item.length - 1);

														const subString =
															lastChar === "."
																? item.substr(0, item.length - 1)
																: item;

														const splitText = subString.split(". ");

														eachItem.push(splitText);

														checkCategory = value?.category;

														return (
															<div className="phrases-wrapper" key={index}>
																{eachItem.length > 0 &&
																	eachItem.map((phrase, index) => {
																		return (
																			<div className="item" key={index}>
																				<div
																					className="icon-container"
																					onClick={() => {
																						insertText(generateString(phrase));
																					}}
																				>
																					<i className="fa-solid fa-arrow-left"></i>
																				</div>

																				<div className="content">
																					{phrase.length > 0 &&
																						phrase.map((sentence, index) => {
																							return (
																								<Fragment key={index}>
																									<span
																										className="sentence"
																										onClick={() => {
																											insertText(
																												sentence + ". "
																											);
																										}}
																									>
																										<Highlighter
																											searchWords={[
																												searchValue
																											]}
																											autoEscape={true}
																											textToHighlight={sentence}
																										/>
																									</span>

																									<span id="dot"></span>
																								</Fragment>
																							);
																						})}
																				</div>
																			</div>
																		);
																	})}
															</div>
														);
													}
												})}
											{/* all phrases content end  */}
										</div>
									);
							  })
							: allPhrasesRef.current?.length > 0 &&
							  sortArray(allPhrasesRef.current.slice(0, 15), {
									by: "category"
							  }).map((value, index) => {
									return (
										<div
											className="specific-category"
											key={index}
											id={
												checkCategory && checkCategory !== value?.category
													? "separation"
													: ""
											}
										>
											{checkCategory !== value?.category && (
												<div className="category">
													<span>{value?.category}</span>
												</div>
											)}

											{/* all phrases content start  */}
											{value.phrases?.length > 0 &&
												value.phrases.map((item, index) => {
													let eachItem = [];

													const lastChar = item.substr(item.length - 1);

													const subString =
														lastChar === "."
															? item.substr(0, item.length - 1)
															: item;

													const splitText = subString.split(". ");

													eachItem.push(splitText);

													checkCategory = value?.category;

													return (
														<div className="phrases-wrapper" key={index}>
															{eachItem.length > 0 &&
																eachItem.map((phrase, index) => {
																	return (
																		<div className="item" key={index}>
																			<div
																				className="icon-container"
																				onClick={() => {
																					insertText(generateString(phrase));
																				}}
																			>
																				<i className="fa-solid fa-arrow-left"></i>
																			</div>

																			<div className="content">
																				{phrase.length > 0 &&
																					phrase.map((sentence, index) => {
																						return (
																							<Fragment key={index}>
																								<span
																									className="sentence"
																									onClick={() => {
																										insertText(sentence + ". ");
																									}}
																								>
																									{sentence}
																								</span>

																								<span id="dot"></span>
																							</Fragment>
																						);
																					})}
																			</div>
																		</div>
																	);
																})}
														</div>
													);
												})}
											{/* all phrases content end  */}
										</div>
									);
							  })}
					</div>
					{/* displaying all phrases start  */}
				</div>
			</div>
		);
	}
);

export default PhrasesPreview;
