// external components
import {
	FacebookIcon,
	FacebookMessengerIcon,
	FacebookMessengerShareButton,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton
} from "react-share";

// internal components
import "./ShareSocial.css";

const ShareSocial = () => {
	const shareUrl = process.env.REACT_APP_DOMAIN;
	const title = "CVWAVE - Online Resume Builder | Free Resume Maker";
	return (
		<ul id="floating-share-buttons">
			<li>
				<FacebookShareButton
					url={shareUrl}
					quote={title}
					hashtag={"#freeresumetemplate"}
					className="share-btn"
				>
					<FacebookIcon className="icon" />
				</FacebookShareButton>
			</li>

			<li>
				<FacebookMessengerShareButton
					url={shareUrl}
					appId={process.env.REACT_APP_APPID}
					quote={title}
					className="share-btn"
				>
					<FacebookMessengerIcon className="icon" />
				</FacebookMessengerShareButton>
			</li>

			<li>
				<WhatsappShareButton
					url={shareUrl}
					quote={title}
					hashtag={"#freeresumetemplate"}
					className="share-btn"
				>
					<WhatsappIcon className="icon" />
				</WhatsappShareButton>
			</li>

			<li>
				<LinkedinShareButton url={shareUrl} title={title} className="share-btn">
					<LinkedinIcon className="icon" />
				</LinkedinShareButton>
			</li>

			<li>
				<TwitterShareButton url={shareUrl} title={title} className="share-btn">
					<img src="/assets/icons/twitter-x.png" alt="icon" className="icon" />
				</TwitterShareButton>
			</li>
		</ul>
	);
};

export default ShareSocial;
