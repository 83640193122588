// external components
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@mui/material";
import { memo, useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-hot-toast";

// for datepicker
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// internal components
import { EditorContextApi } from "../../../pages/ResumeEditor/EditorContextApi";
import "./Editor.css";
import { Percentage } from "./components/Provider";
import {
	AddSection,
	Education,
	EmploymentHistory,
	Hobbies,
	PersonalDetails,
	ProfessionalSummary,
	Projects,
	Skills,
	WebsiteSocialLinks
} from "./components/Section";
import { SectionTitle, ToolTips, pickDemoData } from "./components/common";

const Editor = ({ contents }) => {
	// editor contents start
	const {
		// header
		import_text,
		dummy_title,
		dummy_subtitle,
		demo_tooltip,
		rename
	} = contents.editor;

	const { agree, disagree } = contents.account_setting;
	// editor contents end

	// get Editor ContextApi
	const {
		getResume,
		setResume,
		updateResumeInfo,
		gettingCurrentResume,
		userId
	} = EditorContextApi();

	// all pre-written phrases start
	const allPhrasesRef = useRef([]);

	useEffect(() => {
		(async () => {
			try {
				const response = await fetch(`/phrases/all-phrases`);
				const result = await response.json();

				if (response.status === 200) {
					allPhrasesRef.current = result;
				}
			} catch (error) {
				allPhrasesRef.current = [];
			}
		})();
	}, []);
	// all pre-written phrases end

	// for resume-title start
	const initialController = {
		border: false,
		editTitle: false
	};

	const controlHandler = (state, action) => {
		switch (action.type) {
			case "open-border":
				return {
					...state,
					border: true
				};

			case "open-editor":
				return {
					...state,
					editTitle: true
				};

			case "close-border-editor":
				return {
					...state,
					border: false,
					editTitle: false
				};

			default:
				return state;
		}
	};

	const [control, dispatchControl] = useReducer(
		controlHandler,
		initialController
	);
	// for resume-title end

	// for inserting dummy data start
	const [openDummy, setOpenDummy] = useState(false);

	const insertDummyData = async () => {
		try {
			const response = await fetch(
				`/resume/insert-dummy?user=${userId}&counter=${getResume.counter}`,
				{
					method: "PUT",
					body: JSON.stringify(pickDemoData(getResume.template.name)),
					headers: {
						"Content-Type": "application/json"
					}
				}
			);

			const result = await response.json();

			if (response.status === 200) {
				setResume("");
				gettingCurrentResume(false);
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for inserting dummy data end

	// for tooltip states controls start
	const initialTooltip = {
		demo_tooltip: false
	};

	const reducer = (state, action) => {
		switch (action.type) {
			case "OPEN_TOOLTIP":
				return {
					...state,
					[action.tooltipId]: true
				};
			case "CLOSE_TOOLTIP":
				return {
					...state,
					[action.tooltipId]: false
				};
			default:
				return state;
		}
	};

	const [tooltipStates, dispatchToolTip] = useReducer(reducer, initialTooltip);
	// for tooltip states controls end
	// for inserting dummy data end

	// handle additional section
	const [addSecArr, setAddSecArr] = useState(getResume?.additional_section);

	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<div className="editor-container">
					<div className="editor-header">
						{/* title start  */}
						<div className="resume-title">
							<SectionTitle
								title="Untitled"
								name="resume_title"
								control={control}
								dispatchControl={dispatchControl}
								_id={getResume._id}
								value={getResume.resume_title}
								setInputValues={setResume}
								updateResumeInfo={updateResumeInfo}
								rename={rename}
							/>
						</div>
						{/* title end  */}

						{/* for insert demo data start  */}
						<div style={{ display: "flex", alignItems: "center" }}>
							<button
								className="primary-btn-styles"
								onClick={() => setOpenDummy(true)}
							>
								{import_text}
							</button>

							<div
								style={{
									color: "var(--theme-color)",
									marginLeft: "10px",
									cursor: "pointer"
								}}
							>
								<ToolTips
									title={demo_tooltip}
									name={"demo_tooltip"}
									condition={tooltipStates.demo_tooltip}
									dispatch={dispatchToolTip}
								/>
							</div>
						</div>

						{/* conformation dialog start  */}
						<Dialog
							open={openDummy}
							keepMounted
							onClose={() => setOpenDummy(false)}
							aria-describedby="alert-dialog-slide-description"
						>
							<DialogTitle>{dummy_title}</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-slide-description">
									{dummy_subtitle}
								</DialogContentText>
							</DialogContent>
							<DialogActions sx={{ padding: "0 25px 20px 0" }}>
								<button
									onClick={() => {
										setOpenDummy(false);
									}}
									className="primary-btn-styles"
								>
									{disagree}
								</button>

								<button
									onClick={() => {
										setOpenDummy(false);
										insertDummyData();
									}}
									className="primary-btn-styles"
								>
									{agree}
								</button>
							</DialogActions>
						</Dialog>
						{/* conformation dialog end  */}
						{/* for insert demo data end */}
					</div>

					{/* percentage start  */}
					<Percentage contents={contents} />
					{/* percentage end */}

					{/* getting info section start  */}
					<div className="getting-info">
						<PersonalDetails contents={contents} />

						<ProfessionalSummary
							contents={contents}
							allPhrasesRef={allPhrasesRef}
						/>

						<EmploymentHistory
							contents={contents}
							allPhrasesRef={allPhrasesRef}
						/>

						<Education contents={contents} />

						<Projects contents={contents} />

						<WebsiteSocialLinks contents={contents} />

						<Skills contents={contents} />

						<Hobbies contents={contents} />

						{/* displaying additional section */}
						{addSecArr.length > 0 &&
							addSecArr.map((value, index) => {
								return <div key={index}>{value?.component}</div>;
							})}

						<AddSection
							addSecArr={addSecArr}
							setAddSecArr={setAddSecArr}
							contents={contents}
							allPhrasesRef={allPhrasesRef}
							updateResumeInfo={updateResumeInfo}
							setResume={setResume}
							getResume={getResume}
						/>
					</div>
					{/* getting info section end */}
				</div>
			</LocalizationProvider>
		</>
	);
};

export default memo(Editor);
