// external components
import { useState } from "react";

// internal components
import "./AdminUsersContents.css";
import AllUsersList from "./AllUsersList";
import SpecificUserResumes from "./SpecificUserResumes";

const AdminUsersContents = ({ isLoading, allUsers, allResumes }) => {
	// for getting selectedTab
	const [selectedTab, setSelectedTab] = useState("All Users List");
	return (
		<>
			<div className="admin-users-contents-container">
				{selectedTab === "All Users List" ? (
					<AllUsersList
						isLoading={isLoading}
						allUsers={allUsers}
						allResumes={allResumes}
						setSelectedTab={setSelectedTab}
					/>
				) : (
					<SpecificUserResumes
						allResumes={allResumes}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
					/>
				)}
				{}
			</div>
		</>
	);
};

export default AdminUsersContents;
