// internal components
import financialAnalyst from "./financialAnalyst";

const pickDemoData = (template) => {
	if (true) {
		return financialAnalyst;
	}
};

export default pickDemoData;
