// external components

// internal components
import PageHeader from "../../common/PageHeader";
import { GetContextApi } from "../../storage/ContextApi";
import { FAQComponent } from "./../../common";

const FAQ = () => {
	// get contents
	const { contents } = GetContextApi();

	return (
		<>
			<PageHeader
				pageName={contents.title === "bangla" ? "সচরাচর জিজ্ঞাস্য" : "FAQ"}
				link={contents.title === "bangla" ? "সচরাচর জিজ্ঞাস্য" : "FAQ"}
				whichLanguage={contents.title}
			/>

			<FAQComponent />
		</>
	);
};

export default FAQ;
