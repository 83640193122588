// external components

import { ClickAwayListener, Tooltip, Zoom } from "@mui/material";

const ToolTips = ({ title, name, condition, dispatch }) => {
	const handleTooltipClose = (tooltipId) => {
		dispatch({ type: "CLOSE_TOOLTIP", tooltipId });
	};

	const handleTooltipOpen = (tooltipId) => {
		dispatch({ type: "OPEN_TOOLTIP", tooltipId });
	};

	return (
		<ClickAwayListener onClickAway={() => handleTooltipClose(name)}>
			<Tooltip
				title={title}
				placement="top"
				arrow
				TransitionComponent={Zoom}
				componentsProps={{
					tooltip: {
						sx: {
							bgcolor: "#040026",
							"& .MuiTooltip-arrow": {
								color: "#040026"
							},
							fontSize: "13px",
							fontWeight: "400",
							textAlign: "center"
						}
					}
				}}
				onClose={() => handleTooltipClose(name)}
				open={condition}
				disableFocusListener
				disableHoverListener
				disableTouchListener
			>
				<i
					onClick={() => handleTooltipOpen(name)}
					className="fa-regular fa-circle-question"
				></i>
			</Tooltip>
		</ClickAwayListener>
	);
};

export default ToolTips;
