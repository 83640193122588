// external components
import { arrayMoveImmutable } from "array-move";
import { memo, useEffect, useReducer, useState } from "react";

// internal components
import { EditorContextApi } from "../../../../../pages/ResumeEditor/EditorContextApi";
import { ReferencesItem } from "../SectionItems";
import {
	AddMore,
	ControlCollapse,
	DeleteDialog,
	SectionTitle
} from "../common";
import {
	SortableContainer,
	SortableItem,
	updateBeforeSortStart
} from "../common/drag&drop";
import { updateItems } from "../common/function";

const References = ({ contents, sectionId, setRemoveSection }) => {
	// reference contents
	const {
		add_refer,
		add_more_refer,
		is_hide_refer,
		rename,
		revert,
		deleteText
	} = contents.editor;

	// editor contextApi
	const { updateResumeInfo, getResume, setResume, delay, userId } =
		EditorContextApi();

	// assign
	const _id = getResume.references._id;
	const counter = getResume.counter;

	// for delete specific items
	const [deleteItem, setDeleteItem] = useState("");

	// for title and collapse controls start
	const initialController = {
		border: false,
		editTitle: false,
		collapse: true
	};

	const controlHandler = (state, action) => {
		switch (action.type) {
			case "open-border":
				return {
					...state,
					border: true
				};

			case "open-editor":
				return {
					...state,
					editTitle: true
				};

			case "close-border-editor":
				return {
					...state,
					border: false,
					editTitle: false
				};

			case "open-collapse":
				return {
					...state,
					collapse: true
				};

			case "close-collapse":
				return {
					...state,
					collapse: false
				};

			default:
				return state;
		}
	};

	const [control, dispatchControl] = useReducer(
		controlHandler,
		initialController
	);
	// for title and collapse controls end

	// assign references's all values start
	const [inputValues, setInputValues] = useState({
		references_title: getResume.references.references_title,
		references_hide: getResume.references.references_hide
	});

	const [referItems, setReferItems] = useState([]);
	useEffect(() => {
		if (getResume?.references?.references_items.length > 0) {
			let getReferItemArray = [];

			getResume?.references?.references_items.map((value) =>
				getReferItemArray.push(
					<ReferencesItem
						itemValues={value}
						handleSetActive={handleSetActive}
						contents={contents}
						setDeleteItem={setDeleteItem}
						updateResumeInfo={updateResumeInfo}
						delay={delay}
					/>
				)
			);

			setReferItems(getReferItemArray);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// assign references all values end

	// handle activate id start
	const [activeId, setActiveId] = useState(-1);

	const handleSetActive = (id) => {
		setActiveId((prevActiveId) => (prevActiveId === id ? -1 : id));
	};
	// handle activate id end

	// handle when dragEnd
	const onSortEnd = async ({ oldIndex, newIndex }) => {
		setReferItems(arrayMoveImmutable(referItems, oldIndex, newIndex));

		const arr = arrayMoveImmutable(
			getResume?.references?.references_items,
			oldIndex,
			newIndex
		);

		setResume((prev) => ({
			...prev,
			references: {
				...prev.references,
				references_items: arr
			}
		}));

		updateItems(arr, userId, counter, "references", "references_items");
	};

	// delete item handler
	useEffect(() => {
		if (deleteItem._id && (deleteItem?.conform || getResume.do_not_ask_again)) {
			setReferItems((prev) =>
				prev.filter((item) => item.props.itemValues._id !== deleteItem._id)
			);

			const arr = getResume.references.references_items.filter(
				(item) => item._id !== deleteItem._id
			);

			setResume((prev) => ({
				...prev,
				references: {
					...prev.references,
					references_items: arr
				}
			}));

			updateItems(arr, userId, counter, "references", "references_items");

			setDeleteItem("");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteItem?._id, deleteItem?.conform]);

	return (
		<div className="section-container">
			{/* label start  */}
			<div className="label" id={control.collapse ? "active" : ""}>
				<SectionTitle
					title="References"
					name="references_title"
					control={control}
					dispatchControl={dispatchControl}
					_id={_id}
					value={inputValues.references_title}
					setInputValues={setInputValues}
					updateResumeInfo={updateResumeInfo}
					rename={rename}
					revert={revert}
					drag={true}
					whenAdditionalSection={{
						deleteText,
						sectionId,
						setRemoveSection,
						key: "References"
					}}
				/>

				<ControlCollapse
					collapse={control.collapse}
					dispatchControl={dispatchControl}
				/>
			</div>
			{/* label end  */}

			{/* content start  */}
			<div
				className="content-container remove-margin"
				id={control.collapse ? "active" : ""}
			>
				{/* for-hide reference toggle start  */}
				<div id="hide-refer-toggle">
					<input
						type="checkbox"
						checked={inputValues.references_hide ? true : false}
						onClick={() => {
							updateResumeInfo({
								references_hide: !inputValues.references_hide,
								_id
							});

							setInputValues((prev) => ({
								...prev,
								references_hide: !prev.references_hide
							}));
						}}
						readOnly
					/>
					<span>{is_hide_refer}</span>
				</div>
				{/* for-hide reference toggle end */}

				{/* for displaying references's array start  */}
				{referItems.length > 0 && (
					<SortableContainer
						onSortEnd={onSortEnd}
						useDragHandle
						lockAxis="y"
						lockToContainerEdges={true}
						updateBeforeSortStart={() => updateBeforeSortStart(setActiveId)}
					>
						{referItems.map((item, index) => (
							<SortableItem
								key={item.props.itemValues._id}
								index={index}
								value={item}
								className="display-section-item"
								id={activeId === item.props.itemValues._id ? "active" : ""}
							/>
						))}
					</SortableContainer>
				)}
				{/* for displaying references's array end */}

				{/* add-more  */}
				<AddMore
					userId={userId}
					counter={counter}
					section={"references"}
					item={"references_items"}
					newArr={{
						references_name: "",
						references_company: "",
						references_phone: "",
						references_email: ""
					}}
					arrCom={referItems}
					setArrCom={setReferItems}
					itemCom={{
						itemComponent: ReferencesItem,
						contents,
						updateResumeInfo,
						setDeleteItem,
						delay
					}}
					setResume={setResume}
					controlActiveId={{ activeId, setActiveId, handleSetActive }}
					initialContent={add_refer}
					moreContent={add_more_refer}
				/>
			</div>
			{/* content end */}

			{/* delete modal  */}
			{!getResume.do_not_ask_again && deleteItem && (
				<DeleteDialog
					contents={contents}
					deleteItem={deleteItem}
					setDeleteItem={setDeleteItem}
				/>
			)}
		</div>
	);
};

export default memo(References);
