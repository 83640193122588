// external components
import moment from "moment";
import { useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import sortArray from "sort-array";

// internal components

const SpecificUserResumes = ({ selectedTab, setSelectedTab, allResumes }) => {
	// filter for specific user  resumes start
	const [specificUserResumes, setSpecificUserResumes] = useState([]);

	useEffect(() => {
		setSpecificUserResumes(
			allResumes.filter((value) => value.user === selectedTab._id)
		);
	}, [allResumes, selectedTab]);
	// filter for specific user resumes end

	return (
		<>
			{/* statistic start  */}
			<div className="statistic">
				<div className="statistic-section" id="registered">
					<span className="title">Total &nbsp;Resumes</span>
					<div className="icon-number-wrapper">
						<span className="number">{specificUserResumes.length}</span>
						<span className="icon" id="registered-icon">
							<i className="fa-solid fa-list-check"></i>
						</span>
					</div>
				</div>

				<div className="statistic-section" id="resume">
					<span className="title">Total &nbsp;Templates</span>

					<div className="icon-number-wrapper">
						<span className="number">1</span>

						<span className="icon" id="resume-icon">
							<i className="fa-solid fa-circle-check"></i>
						</span>
					</div>
				</div>

				<div className="statistic-section" id="cover-letter">
					<div className="title">Total &nbsp;Downloaded</div>

					<div className="icon-number-wrapper">
						<span className="number">{0}</span>

						<span className="icon" id="cover-letter-icon">
							<i className="fa-solid fa-circle-check"></i>
						</span>
					</div>
				</div>
			</div>
			{/* statistic end  */}

			{/* all-users-list start  */}
			<div className="all-users-list">
				<div className="header">
					<h5>Specific User's All Resumes</h5>

					<button
						type="button"
						className="btn"
						onClick={() => setSelectedTab("All Users List")}
					>
						<i className="fa-solid fa-delete-left"></i> All Users
					</button>
				</div>

				{/* table-container start  */}
				<div className="table-container">
					<table className="table table-hover">
						<thead>
							<tr>
								<th scope="col">#</th>

								<th scope="col">Title</th>

								<th scope="col" style={{ width: "10%" }}>
									Downloaded
								</th>

								<th scope="col" style={{ width: "30%" }}>
									Skills
								</th>

								<th scope="col" style={{ width: "13%" }}>
									Updated At
								</th>

								<th scope="col" style={{ width: "20%" }}>
									Template
								</th>

								<th scope="col" style={{ width: "10%" }}>
									Preview
								</th>

								<th scope="col">View</th>
							</tr>
						</thead>
						<tbody>
							{specificUserResumes.length > 0 &&
								sortArray(specificUserResumes, {
									by: "updatedAt"
								})
									.map((value, index) => {
										// for getting all skills
										const getAllSkills = [];

										value.skills?.skills_items.map((value) =>
											getAllSkills.push(value.skill)
										);

										return (
											<tr key={index}>
												<td id="id">
													<span>{index + 1}</span>
												</td>

												<td>
													<input readOnly value={value?.resume_title} />
												</td>

												<td>
													<input readOnly value={0} />
												</td>

												<td>
													<textarea
														value={
															getAllSkills.length > 0
																? getAllSkills.join(", ")
																: "Empty"
														}
														readOnly
													/>
												</td>

												<td>
													<input
														readOnly
														value={moment(value?.updatedAt).format(
															"MMMM DD, YYYY"
														)}
													/>
												</td>

												<td>
													<input readOnly value={value?.template.name} />
												</td>

												<td>
													<div className="img-container-template">
														<PhotoProvider>
															<PhotoView
																src={`/assets/templates/${value.template.img}`}
															>
																<img
																	src={`/assets/templates/${value.template.img}`}
																	alt="stu-card"
																	id="stu-card"
																/>
															</PhotoView>
														</PhotoProvider>
													</div>
												</td>

												<td>
													<div className="btn-container">
														<button className="btn">
															<a
																href={`../resume/share/${value._id}`}
																id="send-email"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fa-solid fa-eye"></i>
															</a>
														</button>
													</div>
												</td>
											</tr>
										);
									})
									.reverse()}
						</tbody>
					</table>
				</div>
				{/* table-container end  */}
			</div>
			{/* all-users-list end  */}
		</>
	);
};

export default SpecificUserResumes;
