// external components
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

// internal components
import RestrictionDialog from "../../common/RestrictionDialog";
import { GetContextApi } from "../../storage/ContextApi";
import "./ChooseTemplate.css";

const ChooseTemplate = ({ allTemplates }) => {
	// for redirect editor page
	const Navigate = useNavigate();

	// for getting currentUser & checking start
	const { currentUser, setCurrentUser, contents } = GetContextApi();

	useEffect(() => {
		if (currentUser) {
		} else {
			Navigate("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for getting currentUser & checking end

	// restriction contents
	const { additional_template_restriction, fu_premium_template } =
		contents.package_limit;
	const { upgrade_now } = contents.navbar;

	// for selector & preview toggle
	const [getSelectorPreviewT, setSelectorPreviewT] = useState("When Selector");

	// resume counter number
	const { counter } = useParams();

	// user restriction
	const [restrictionObj, setRestrictionObj] = useState("");

	// getting selected template
	const [selectedTemplate, setSelectedTemplate] = useState("");

	// getting current-resume's info
	const [currentResumeInfo, setCurrentResumeInfo] = useState({
		userId: "",
		_id: "",
		currentTemplate: ""
	});

	// for getting specific resume start
	useEffect(() => {
		if (allTemplates.length > 0) {
			(async () => {
				try {
					const response = await fetch(`/resume?counter=${counter}`);

					const result = await response.json();

					if (response.status === 200) {
						// updating current-resume's info
						setCurrentResumeInfo({
							userId: result.user,
							_id: result._id,
							currentTemplate: result?.template._id
						});

						// get current template
						setSelectedTemplate(
							allTemplates.filter(
								(template) => template._id === result?.template._id
							)[0]
						);
					} else if (result.error) {
						toast.error(result.error, {
							position: "top-right",
							autoClose: 2500,
							theme: "dark"
						});
					}
				} catch (error) {
					toast.error(error.message, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allTemplates.length > 0]);
	// for getting specific resume end

	// for updating resume values start
	const updateResumeInfo = async () => {
		if (currentResumeInfo.userId) {
			try {
				const response = await fetch(
					`/resume/update?user=${currentResumeInfo.userId}&counter=${counter}`,
					{
						method: "POST",
						body: JSON.stringify({
							template: selectedTemplate._id,
							_id: currentResumeInfo._id
						}),
						headers: {
							"Content-Type": "application/json"
						}
					}
				);

				const result = await response.json();

				if (response.status === 200) {
					setCurrentResumeInfo((prev) => ({
						...prev,
						currentTemplate: selectedTemplate._id
					}));
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		}
	};
	// for updating resume values end

	// update used-template start
	const updateTemplate = async (obj) => {
		try {
			const response = await fetch(`/user/update-service`, {
				method: "PUT",
				body: JSON.stringify(obj),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				setCurrentUser((prev) => {
					const updatedTemplates = [
						...prev.used_templates,
						selectedTemplate._id
					];
					return {
						...prev,
						used_templates: updatedTemplates,
						available_templates: prev.available_templates - 1
					};
				});
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// update used-template end

	// restrictionHandler start
	const restrictionHandler = () => {
		if (currentUser.used_templates.includes(selectedTemplate._id)) {
			updateResumeInfo();
		} else {
			if (currentUser.available_templates !== 0) {
				updateResumeInfo();
				updateTemplate({ addResumeTemplate: selectedTemplate._id });
			} else {
				if (currentUser.package["Package Name"] === "Free Plan") {
					setRestrictionObj(fu_premium_template);
				} else {
					setRestrictionObj(additional_template_restriction);
				}
			}
		}
	};
	// restrictionHandler end

	return (
		<>
			{!selectedTemplate ? (
				<div className="loader-container" id="full-viewport">
					<CircularProgress />
				</div>
			) : (
				<div className="resume-edit-container">
					{/* .header-container start  */}
					<div className="header-container">
						{/* back-to-editor section start  */}
						<div className="back-to-editor-container">
							<div
								className="back-to-editor-wrapper"
								onClick={() => Navigate(`../resume/${counter}/create-resume`)}
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									version="1.1"
									xmlns="http://www.w3.org/2000/svg"
									className="back-to-editor-svg"
									fill="#ffffff"
								>
									<path d="M9.32427537,7.23715414 L10.6757246,5.76284586 L16.6757246,11.2628459 C17.1080918,11.6591824 17.1080918,12.3408176 16.6757246,12.7371541 L10.6757246,18.2371541 L9.32427537,16.7628459 L14.5201072,12 L9.32427537,7.23715414 Z"></path>
								</svg>
								<div>Back to editor</div>
							</div>
						</div>
						{/* back-to-editor section end  */}

						{/* customize-style section start  */}
						<div className="customize-style-container">
							<div className="color-picker-container">
								{selectedTemplate &&
									selectedTemplate.colors.length > 0 &&
									selectedTemplate.colors.map((value, index) => {
										return (
											<div
												className="color"
												id={
													selectedTemplate.colors.includes(value)
														? "active"
														: ""
												}
												key={index}
												style={{ background: value }}
											>
												<i className="fa-solid fa-check"></i>
											</div>
										);
									})}
							</div>
						</div>
						{/* customize-style section end  */}
					</div>
					{/* .header-container start  */}

					<div className="resume-edit-body-container">
						{/* displaying-all-templates start  */}
						<div
							className="displaying-all-templates"
							id={
								getSelectorPreviewT === "When Selector"
									? "for-100"
									: "for-hidden"
							}
						>
							<div className="all-templates-container">
								<div className="all-templates-wrapper">
									{allTemplates.length > 0 &&
										allTemplates.map((value, index) => {
											return (
												<div className="template" key={index}>
													<div className="template-name">{value.name}</div>
													<div
														className="template-img"
														onClick={() => setSelectedTemplate(value)}
														id={
															JSON.stringify(selectedTemplate) ===
															JSON.stringify(value)
																? "active"
																: ""
														}
													>
														<div
															className="check-container"
															style={{
																display: currentUser.used_templates.includes(
																	value._id
																)
																	? "flex"
																	: "none"
															}}
														>
															<svg
																width="24"
																height="24"
																viewBox="0 0 24 24"
																version="1.1"
																xmlns="http://www.w3.org/2000/svg"
																fill="#ffffff"
															>
																<path d="M16.6917162,8.75389189 L18.3082838,10.2461081 L12.3082838,16.7461081 C11.8845221,17.2051833 11.1639539,17.2195889 10.7221825,16.7778175 L6.22218254,12.2778175 L7.77781746,10.7221825 L11.4682653,14.4126304 L16.6917162,8.75389189 Z"></path>
															</svg>
														</div>
														<img
															src={`/assets/templates/${value.img}`}
															alt="template"
														/>
													</div>
												</div>
											);
										})}
								</div>
							</div>
						</div>
						{/* displaying-all-templates end  */}

						{/* .preview-template start  */}
						<div
							className="preview-template-container"
							id={
								getSelectorPreviewT === "When Preview"
									? "for-100"
									: "for-hidden"
							}
						>
							<div className="preview-template-wrapper">
								<div className="preview-template">
									<button
										className="primary-btn-styles use-this-btn"
										onClick={restrictionHandler}
										id={
											currentResumeInfo.currentTemplate === selectedTemplate._id
												? "activated"
												: ""
										}
									>
										<i
											className="fa-solid fa-circle-check"
											id="icon"
											style={{
												display: currentUser.used_templates.includes(
													selectedTemplate._id
												)
													? "flex"
													: "none"
											}}
										></i>{" "}
										Use This Template
									</button>

									<div className="img-container">
										<img
											src={`/assets/templates/${selectedTemplate?.img}`}
											alt="template"
										/>
									</div>
								</div>
							</div>
						</div>
						{/* .preview-template end */}
					</div>

					{/* for selector-preview toggle button start  */}
					<button
						className="selector-preview-btn"
						id={getSelectorPreviewT !== "When Selector" ? "preview-mb" : ""}
						onClick={() =>
							setSelectorPreviewT(
								getSelectorPreviewT === "When Selector"
									? "When Preview"
									: "When Selector"
							)
						}
					>
						{getSelectorPreviewT === "When Selector" ? (
							<div className="selector-preview-text">Preview</div>
						) : (
							<div className="selector-preview-text">Select Template</div>
						)}

						{getSelectorPreviewT === "When Preview" ? (
							<div className="selector-preview-icon">
								<i className="bi bi-hand-index"></i>
							</div>
						) : (
							<div className="selector-preview-icon">
								<i className="fa-solid fa-expand"></i>
							</div>
						)}
					</button>
					{/* for selector-preview toggle button end */}

					{/* for restriction dialog box */}
					{restrictionObj && (
						<RestrictionDialog
							restrictionObj={restrictionObj}
							setRestrictionObj={setRestrictionObj}
							btn_text={upgrade_now}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default ChooseTemplate;
