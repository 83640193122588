// external components
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// internal components

const TableSkeleton = () => {
	return (
		<>
			<div data-aos="fade-right" data-aos-delay="0">
				<Skeleton
					count={7}
					height={37}
					style={{ margin: "10px 25px", width: "95%" }}
				/>
			</div>
		</>
	);
};

export default TableSkeleton;
