// external components
import { arrayMoveImmutable } from "array-move";
import { memo, useEffect, useReducer, useState } from "react";

// internal components
import { EditorContextApi } from "../../../../../pages/ResumeEditor/EditorContextApi";
import { WebLinkItem } from "../SectionItems";
import {
	AddMore,
	ControlCollapse,
	DeleteDialog,
	Description,
	SectionTitle
} from "../common";
import {
	SortableContainer,
	SortableItem,
	updateBeforeSortStart
} from "../common/drag&drop";
import { updateItems } from "../common/function";

const WebsiteSocialLinks = ({ contents }) => {
	// web-links contents
	const { web_link_subtitle, add_link, add_more_link, rename, revert } =
		contents.editor;

	// editor contextApi
	const { updateResumeInfo, getResume, setResume, delay, userId } =
		EditorContextApi();

	// assign
	const _id = getResume.web_link._id;
	const counter = getResume.counter;

	// for delete specific items
	const [deleteItem, setDeleteItem] = useState("");

	// for title and collapse controls start
	const initialController = {
		border: false,
		editTitle: false,
		collapse: true
	};

	const controlHandler = (state, action) => {
		switch (action.type) {
			case "open-border":
				return {
					...state,
					border: true
				};

			case "open-editor":
				return {
					...state,
					editTitle: true
				};

			case "close-border-editor":
				return {
					...state,
					border: false,
					editTitle: false
				};

			case "open-collapse":
				return {
					...state,
					collapse: true
				};

			case "close-collapse":
				return {
					...state,
					collapse: false
				};

			default:
				return state;
		}
	};

	const [control, dispatchControl] = useReducer(
		controlHandler,
		initialController
	);
	// for title and collapse controls end

	// assign web_link's all values start
	const [getTitle, setTitle] = useState({
		web_link_title: getResume.web_link.web_link_title
	});

	const [webItems, setWebItems] = useState([]);
	useEffect(() => {
		if (getResume?.web_link?.web_link_items.length > 0) {
			let getWebItemArray = [];

			getResume?.web_link?.web_link_items.map((value) =>
				getWebItemArray.push(
					<WebLinkItem
						itemValues={value}
						handleSetActive={handleSetActive}
						contents={contents}
						setDeleteItem={setDeleteItem}
						updateResumeInfo={updateResumeInfo}
						delay={delay}
					/>
				)
			);

			setWebItems(getWebItemArray);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// assign web_link's all values end

	// handle activate id start
	const [activeId, setActiveId] = useState(-1);

	const handleSetActive = (id) => {
		setActiveId((prevActiveId) => (prevActiveId === id ? -1 : id));
	};
	// handle activate id end

	// handle when dragEnd
	const onSortEnd = async ({ oldIndex, newIndex }) => {
		setWebItems(arrayMoveImmutable(webItems, oldIndex, newIndex));

		const arr = arrayMoveImmutable(
			getResume?.web_link?.web_link_items,
			oldIndex,
			newIndex
		);

		setResume((prev) => ({
			...prev,
			web_link: {
				...prev.web_link,
				web_link_items: arr
			}
		}));

		updateItems(arr, userId, counter, "web_link", "web_link_items");
	};

	// delete item handler
	useEffect(() => {
		if (deleteItem._id && (deleteItem?.conform || getResume.do_not_ask_again)) {
			setWebItems((prev) =>
				prev.filter((item) => item.props.itemValues._id !== deleteItem._id)
			);

			const arr = getResume.web_link.web_link_items.filter(
				(item) => item._id !== deleteItem._id
			);

			setResume((prev) => ({
				...prev,
				web_link: {
					...prev.web_link,
					web_link_items: arr
				}
			}));

			updateItems(arr, userId, counter, "web_link", "web_link_items");

			setDeleteItem("");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteItem?._id, deleteItem?.conform]);

	return (
		<div className="section-container" id="website_social_links">
			{/* label start  */}
			<div className="label" id={control.collapse ? "active" : ""}>
				<SectionTitle
					title="Website & Social Links"
					name="web_link_title"
					control={control}
					dispatchControl={dispatchControl}
					_id={_id}
					value={getTitle.web_link_title}
					setInputValues={setTitle}
					updateResumeInfo={updateResumeInfo}
					rename={rename}
					revert={revert}
					drag={true}
				/>

				<ControlCollapse
					collapse={control.collapse}
					dispatchControl={dispatchControl}
				/>
			</div>
			{/* label end  */}

			{/* content start  */}
			<div
				className="content-container remove-margin"
				id={control.collapse ? "active" : ""}
			>
				{/* description  */}
				<Description content={web_link_subtitle} />

				{/* for displaying webLinksItem's array start  */}
				{webItems.length > 0 && (
					<SortableContainer
						onSortEnd={onSortEnd}
						useDragHandle
						lockAxis="y"
						lockToContainerEdges={true}
						updateBeforeSortStart={() => updateBeforeSortStart(setActiveId)}
					>
						{webItems.map((item, index) => (
							<SortableItem
								key={item.props.itemValues._id}
								index={index}
								value={item}
								className="display-section-item"
								id={activeId === item.props.itemValues._id ? "active" : ""}
							/>
						))}
					</SortableContainer>
				)}
				{/* for displaying webLinksItem's array end */}

				{/* add-more  */}
				<AddMore
					userId={userId}
					counter={counter}
					section={"web_link"}
					item={"web_link_items"}
					newArr={{
						link_label: "",
						link_url: ""
					}}
					arrCom={webItems}
					setArrCom={setWebItems}
					itemCom={{
						itemComponent: WebLinkItem,
						contents,
						updateResumeInfo,
						setDeleteItem,
						delay
					}}
					setResume={setResume}
					controlActiveId={{ activeId, setActiveId, handleSetActive }}
					initialContent={add_link}
					moreContent={add_more_link}
				/>
			</div>
			{/* content end */}

			{/* delete modal  */}
			{!getResume.do_not_ask_again && deleteItem && (
				<DeleteDialog
					contents={contents}
					deleteItem={deleteItem}
					setDeleteItem={setDeleteItem}
				/>
			)}
		</div>
	);
};

export default memo(WebsiteSocialLinks);
