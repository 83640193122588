// external components
import { Font } from "@react-pdf/renderer";

// for templates
import AccountManager from "../Templates/AccountManager/AccountManager";
import ArtDirector from "../Templates/ArtDirector/ArtDirector";
import CreativeDesigner from "../Templates/CreativeDesigner/CreativeDesigner";
import FinancialAnalyst from "../Templates/FinancialAnalyst/FinancialAnalyst";
import FrontendDeveloper from "../Templates/FrontendDeveloper/FrontendDeveloper";
import GraphicWebDesigner from "../Templates/GraphicWebDesigner/GraphicWebDesigner";
import GraphicWebDesigner2 from "../Templates/GraphicWebDesigner2/GraphicWebDesigner2";
import GraphicsDesigner from "../Templates/GraphicsDesigner/GraphicsDesigner";
import GraphicsDesigner2 from "../Templates/GraphicsDesigner2/GraphicsDesigner2";
import MarketingSpecialist from "../Templates/MarketingSpecialist/MarketingSpecialist";
import ProductDesigner from "../Templates/ProductDesigner/ProductDesigner";
import ProfessionalResume from "../Templates/ProfessionalResume/ProfessionalResume";
import ProfessionalResume2 from "../Templates/ProfessionalResume2/ProfessionalResume2";
import SimpleGeneral from "../Templates/SimpleGeneral/SimpleGeneral";
import SrAccountManager from "../Templates/SrAccountManager/SrAccountManager";
import UiUxDesigner from "../Templates/UiUxDesigner/UiUxDesigner";
import UxDesigner from "../Templates/UxDesigner/UxDesigner";
import WebDesigner from "../Templates/WebDesigner/WebDesigner";
import WebDesigner2 from "../Templates/WebDesigner2/WebDesigner2";
import WebDeveloper from "../Templates/WebDeveloper/WebDeveloper";
import WebUIUXDesigner from "../Templates/WebUIUXDesigner/WebUIUXDesigner";

// for choosing template
const chooseTemplate = (getResume) => {
	const template = getResume.template.name;
	if (template === "Creative Designer") {
		return <CreativeDesigner getResume={getResume} />;
	} else if (template === "Financial Analyst") {
		return <FinancialAnalyst getResume={getResume} />;
	} else if (template === "Account Manager") {
		return <AccountManager getResume={getResume} />;
	} else if (template === "Simple General") {
		return <SimpleGeneral getResume={getResume} />;
	} else if (template === "Product Designer") {
		return <ProductDesigner getResume={getResume} />;
	} else if (template === "Art Director") {
		return <ArtDirector getResume={getResume} />;
	} else if (template === "Web Developer") {
		return <WebDeveloper getResume={getResume} />;
	} else if (template === "Web Designer") {
		return <WebDesigner getResume={getResume} />;
	} else if (template === "Web Designer 2") {
		return <WebDesigner2 getResume={getResume} />;
	} else if (template === "UI/UX Designer") {
		return <UiUxDesigner getResume={getResume} />;
	} else if (template === "Professional Resume") {
		return <ProfessionalResume getResume={getResume} />;
	} else if (template === "Frontend Developer") {
		return <FrontendDeveloper getResume={getResume} />;
	} else if (template === "UX Designer") {
		return <UxDesigner getResume={getResume} />;
	} else if (template === "Graphics Designer") {
		return <GraphicsDesigner getResume={getResume} />;
	} else if (template === "Sr. Account Manager") {
		return <SrAccountManager getResume={getResume} />;
	} else if (template === "Professional Resume 2") {
		return <ProfessionalResume2 getResume={getResume} />;
	} else if (template === "Marketing Specialist") {
		return <MarketingSpecialist getResume={getResume} />;
	} else if (template === "Web UI UX Designer") {
		return <WebUIUXDesigner getResume={getResume} />;
	} else if (template === "Graphic/Web Designer") {
		return <GraphicWebDesigner getResume={getResume} />;
	} else if (template === "Graphic/Web Designer 2") {
		return <GraphicWebDesigner2 getResume={getResume} />;
	} else if (template === "Graphics Designer 2") {
		return <GraphicsDesigner2 getResume={getResume} />;
	}
};

const selectedFont = (template) => {
	if (
		[
			"Financial Analyst",
			"Account Manager",
			"Web Developer",
			"UI/UX Designer",
			"Sr. Account Manager"
		].includes(template)
	) {
		// Poppins font register
		Font.register({
			family: "Poppins",
			fonts: [
				{
					src: "/assets/fonts/Poppins-Light.ttf",
					fontStyle: "normal",
					fontWeight: 300
				},
				{
					src: "/assets/fonts/Poppins-Regular.ttf",
					fontStyle: "normal",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/Poppins-Medium.ttf",
					fontStyle: "normal",
					fontWeight: 500
				},
				{
					src: "/assets/fonts/Poppins-SemiBold.ttf",
					fontStyle: "normal",
					fontWeight: 600
				},
				{
					src: "/assets/fonts/Poppins-Bold.ttf",
					fontStyle: "normal",
					fontWeight: 700
				},
				{
					src: "/assets/fonts/Poppins-Italic.ttf",
					fontStyle: "italic",
					fontWeight: 400
				}
			]
		});

		return "Poppins";
	} else if (
		[
			"Simple General",
			"Product Designer",
			"Web Designer",
			"Frontend Developer",
			"UX Designer",
			"Web UI UX Designer"
		].includes(template)
	) {
		// Montserrat font register
		Font.register({
			family: "Montserrat",
			fonts: [
				{
					src: "/assets/fonts/Montserrat-Light.ttf",
					fontStyle: "normal",
					fontWeight: 300
				},
				{
					src: "/assets/fonts/Montserrat-Regular.ttf",
					fontStyle: "normal",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/Montserrat-Medium.ttf",
					fontStyle: "normal",
					fontWeight: 500
				},
				{
					src: "/assets/fonts/Montserrat-SemiBold.ttf",
					fontStyle: "normal",
					fontWeight: 600
				},
				{
					src: "/assets/fonts/Montserrat-Bold.ttf",
					fontStyle: "normal",
					fontWeight: 700
				},
				{
					src: "/assets/fonts/Montserrat-Italic.ttf",
					fontStyle: "italic",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/Montserrat-MediumItalic.ttf",
					fontStyle: "italic",
					fontWeight: 500
				}
			]
		});

		return "Montserrat";
	} else if (["Art Director"].includes(template)) {
		// Inter font register
		Font.register({
			family: "Inter",
			fonts: [
				{
					src: "/assets/fonts/Inter-Light.ttf",
					fontStyle: "normal",
					fontWeight: 300
				},
				{
					src: "/assets/fonts/Inter-Regular.ttf",
					fontStyle: "normal",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/Inter-Medium.ttf",
					fontStyle: "normal",
					fontWeight: 500
				},
				{
					src: "/assets/fonts/Inter-SemiBold.ttf",
					fontStyle: "normal",
					fontWeight: 600
				},
				{
					src: "/assets/fonts/Inter-Bold.ttf",
					fontStyle: "normal",
					fontWeight: 700
				}
			]
		});

		return "Inter";
	} else if (["Professional Resume", "Creative Designer"].includes(template)) {
		// Roboto font register
		Font.register({
			family: "Roboto",
			fonts: [
				{
					src: "/assets/fonts/Roboto-Light.ttf",
					fontStyle: "normal",
					fontWeight: 300
				},
				{
					src: "/assets/fonts/Roboto-Regular.ttf",
					fontStyle: "normal",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/Roboto-Medium.ttf",
					fontStyle: "normal",
					fontWeight: 500
				},
				{
					src: "/assets/fonts/Roboto-Bold.ttf",
					fontStyle: "normal",
					fontWeight: 600
				},
				{
					src: "/assets/fonts/Roboto-Italic.ttf",
					fontStyle: "italic",
					fontWeight: 400
				}
			]
		});

		return "Roboto";
	} else if (["Graphics Designer"].includes(template)) {
		// BarlowSemiCondensed font register
		Font.register({
			family: "BarlowSemiCondensed",
			fonts: [
				{
					src: "/assets/fonts/BarlowSemiCondensed-Light.ttf",
					fontStyle: "normal",
					fontWeight: 300
				},
				{
					src: "/assets/fonts/BarlowSemiCondensed-Regular.ttf",
					fontStyle: "normal",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/BarlowSemiCondensed-Medium.ttf",
					fontStyle: "normal",
					fontWeight: 500
				},
				{
					src: "/assets/fonts/BarlowSemiCondensed-SemiBold.ttf",
					fontStyle: "normal",
					fontWeight: 600
				},
				{
					src: "/assets/fonts/BarlowSemiCondensed-Bold.ttf",
					fontStyle: "normal",
					fontWeight: 700
				}
			]
		});

		return "BarlowSemiCondensed";
	} else if (
		["Professional Resume 2", "Marketing Specialist"].includes(template)
	) {
		// OpenSans font register
		Font.register({
			family: "OpenSans",
			fonts: [
				{
					src: "/assets/fonts/OpenSans-Light.ttf",
					fontStyle: "normal",
					fontWeight: 300
				},
				{
					src: "/assets/fonts/OpenSans-Regular.ttf",
					fontStyle: "normal",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/OpenSans-Medium.ttf",
					fontStyle: "normal",
					fontWeight: 500
				},
				{
					src: "/assets/fonts/OpenSans-SemiBold.ttf",
					fontStyle: "normal",
					fontWeight: 600
				},
				{
					src: "/assets/fonts/OpenSans-Bold.ttf",
					fontStyle: "normal",
					fontWeight: 700
				},
				{
					src: "/assets/fonts/OpenSans-ExtraBold.ttf",
					fontStyle: "normal",
					fontWeight: 800
				},
				{
					src: "/assets/fonts/OpenSans-Italic.ttf",
					fontStyle: "italic",
					fontWeight: 400
				}
			]
		});

		return "OpenSans";
	} else if (["Graphic/Web Designer"].includes(template)) {
		// Raleway font register
		Font.register({
			family: "Raleway",
			fonts: [
				{
					src: "/assets/fonts/Raleway-Light.ttf",
					fontStyle: "normal",
					fontWeight: 300
				},
				{
					src: "/assets/fonts/Raleway-Regular.ttf",
					fontStyle: "normal",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/Raleway-Medium.ttf",
					fontStyle: "normal",
					fontWeight: 500
				},
				{
					src: "/assets/fonts/Raleway-SemiBold.ttf",
					fontStyle: "normal",
					fontWeight: 600
				},
				{
					src: "/assets/fonts/Raleway-Bold.ttf",
					fontStyle: "normal",
					fontWeight: 700
				},
				{
					src: "/assets/fonts/Raleway-ExtraBold.ttf",
					fontStyle: "normal",
					fontWeight: 800
				},
				{
					src: "/assets/fonts/Raleway-Italic.ttf",
					fontStyle: "italic",
					fontWeight: 400
				}
			]
		});

		return "Raleway";
	} else if (["Web Designer 2"].includes(template)) {
		// Rockwell-GillSanMT font register
		Font.register({
			family: "Rockwell-GillSanMT",
			fonts: [
				{
					src: "/assets/fonts/gillsansmt-regular.ttf",
					fontStyle: "normal",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/gillsansmt-bold.ttf",
					fontStyle: "normal",
					fontWeight: 500
				},
				{
					src: "/assets/fonts/rockwell.ttf",
					fontStyle: "normal",
					fontWeight: 600
				},
				{
					src: "/assets/fonts/gillsansmt-italic.ttf",
					fontStyle: "italic",
					fontWeight: 400
				}
			]
		});

		return "Rockwell-GillSanMT";
	} else if (["Graphic/Web Designer 2"].includes(template)) {
		// Montserrat-Lato font register
		Font.register({
			family: "Montserrat-Lato",
			fonts: [
				{
					src: "/assets/fonts/Lato-Regular.ttf",
					fontStyle: "normal",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/Montserrat-Medium.ttf",
					fontStyle: "normal",
					fontWeight: 500
				},
				{
					src: "/assets/fonts/Montserrat-SemiBold.ttf",
					fontStyle: "normal",
					fontWeight: 600
				},
				{
					src: "/assets/fonts/Lato-Bold.ttf",
					fontStyle: "normal",
					fontWeight: 700
				}
			]
		});

		return "Montserrat-Lato";
	} else if (["Graphics Designer 2"].includes(template)) {
		// Montserrat-Muli-Roboto font register
		Font.register({
			family: "Montserrat-Muli-Roboto",
			fonts: [
				{
					src: "/assets/fonts/Roboto-Regular.ttf",
					fontStyle: "normal",
					fontWeight: 300
				},
				{
					src: "/assets/fonts/Muli-Regular.ttf",
					fontStyle: "normal",
					fontWeight: 400
				},
				{
					src: "/assets/fonts/Montserrat-SemiBold.ttf",
					fontStyle: "normal",
					fontWeight: 500
				},
				{
					src: "/assets/fonts/Montserrat-Bold.ttf",
					fontStyle: "normal",
					fontWeight: 600
				}
			]
		});

		return "Montserrat-Muli-Roboto";
	}
};

const generatePadding = (template) => {
	if (["Account Manager"].includes(template)) {
		return "30px 0 45px";
	} else if (
		[
			"Simple General",
			"Graphics Designer",
			"Professional Resume 2",
			"Marketing Specialist",
			"Web UI UX Designer",
			"Graphic/Web Designer"
		].includes(template)
	) {
		return "30px 0 20px";
	} else if (["Product Designer"].includes(template)) {
		return "28px 0 36px";
	} else if (
		["Art Director", "Graphic/Web Designer 2", "Graphics Designer 2"].includes(
			template
		)
	) {
		return "30px 0 25px";
	} else if (["Professional Resume"].includes(template)) {
		return "20px 0 45px";
	} else if (
		[
			"Web Developer",
			"Web Designer",
			"Web Designer 2",
			"Frontend Developer",
			"UX Designer",
			"Sr. Account Manager"
		].includes(template)
	) {
		return "25px 0 20px";
	} else if (["UI/UX Designer"].includes(template)) {
		return "16px";
	} else if (["Creative Designer"].includes(template)) {
		return "55px 0";
	} else {
		return "30px 40px 20px";
	}
};

const defineBackgroundColor = (template) => {
	if (["Graphic/Web Designer 2"].includes(template)) {
		return "#F3F3EF";
	} else {
		return "white";
	}
};

export { chooseTemplate, defineBackgroundColor, generatePadding, selectedFont };
