// external components
import { NavLink } from "react-router-dom";

// internal components
import { GetContextApi } from "../../storage/ContextApi";
import "./Procedure.css";

const Procedure = () => {
	// procedure contents
	const { contents } = GetContextApi();

	const {
		title,

		procedure_1_heading,
		procedure_1_description,

		procedure_2_heading,
		procedure_2_description,

		procedure_3_heading,
		procedure_3_description,

		button_text
	} = contents.procedure;

	return (
		<>
			<div className="procedure-main-container">
				<div className="procedure-wrapper">
					{/* procedure-header start  */}
					<div className="procedure-header" data-aos="fade-up">
						<h3>{title}</h3>
					</div>
					{/* procedure-header end  */}

					<img
						src={`/assets/images/${
							contents.title === "bangla"
								? "procedure-sequence-banner-bn"
								: "procedure-sequence-banner"
						}.png`}
						alt="sequence-banner"
						className="img-fluid procedure-sequence-banner"
					/>

					{/* sequence-container section start  */}
					<div className="sequence-container">
						<div className="sequence-section">
							<img
								src="/assets/images/procedure-a-icon.png"
								alt="procedure-icon"
								className="img-fluid icon"
							/>

							<img
								src={`/assets/images/${
									contents.title === "bangla" ? "step_1_bn" : "step_1"
								}.png`}
								alt="step-1"
								className="img-fluid step-icon"
							/>

							<h5>{procedure_1_heading}</h5>

							<p>{procedure_1_description}</p>
						</div>

						<div className="sequence-section">
							<img
								src={`/assets/images/${
									contents.title === "bangla" ? "step_2_bn" : "step_2"
								}.png`}
								alt="step-2"
								className="img-fluid step-icon"
							/>

							<img
								src="/assets/images/procedure-b-icon.png"
								alt="procedure-icon"
								className="img-fluid icon"
							/>

							<h5>{procedure_2_heading}</h5>

							<p>{procedure_2_description}</p>
						</div>

						<div className="sequence-section">
							<img
								src={`/assets/images/${
									contents.title === "bangla" ? "step_3_bn" : "step_3"
								}.png`}
								alt="step-3"
								className="img-fluid step-icon"
							/>

							<img
								src="/assets/images/procedure-c-icon.png"
								alt="procedure-icon"
								className="img-fluid icon"
							/>

							<h5>{procedure_3_heading}</h5>

							<p>{procedure_3_description}</p>
						</div>
					</div>
					{/* sequence-container section start  */}

					<div className="get-started-btn">
						<NavLink to={"/resume-templates"}>
							<button type="button" id="get-started">
								{button_text}
							</button>
						</NavLink>
					</div>
				</div>
			</div>
		</>
	);
};

export default Procedure;
