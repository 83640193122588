const FirstCol = ({
	content,
	name,
	haveIm,
	value,
	onChange,
	id,
	placeholder
}) => {
	return (
		<div className="first-col" id={id ? id : ""}>
			<div className="sub-label">
				<label htmlFor={name}>
					{content} {haveIm && <span id="important">*</span>}
				</label>
			</div>

			<div className="input-field">
				<input
					id={name}
					name={name}
					value={value}
					onChange={onChange}
					placeholder={placeholder ? placeholder : ""}
				/>
			</div>
		</div>
	);
};

export default FirstCol;
