const SkillsLevelVerticalLine = ({
	skill,
	skill_level,
	bg_color,
	displaying_level,
	title_class,
	level_class
}) => {
	let classes = [];
	if (
		skill_level === "Novice" ||
		skill_level === "Working knowledge" ||
		skill_level === "A1"
	) {
		classes = [
			"active",
			"active",
			"inactive",
			"inactive",
			"inactive",
			"inactive",
			"inactive",
			"inactive",
			"inactive",
			"inactive"
		];
	} else if (
		skill_level === "Beginner" ||
		skill_level === "Good working knowledge" ||
		skill_level === "A2"
	) {
		classes = [
			"active",
			"active",
			"active",
			"active",
			"inactive",
			"inactive",
			"inactive",
			"inactive",
			"inactive",
			"inactive"
		];
	} else if (
		skill_level === "Skillful" ||
		skill_level === "Very good command" ||
		skill_level === "B1"
	) {
		classes = [
			"active",
			"active",
			"active",
			"active",
			"active",
			"active",
			"inactive",
			"inactive",
			"inactive",
			"inactive"
		];
	} else if (
		skill_level === "Experienced" ||
		skill_level === "Highly proficient" ||
		skill_level === "B2"
	) {
		classes = [
			"active",
			"active",
			"active",
			"active",
			"active",
			"active",
			"active",
			"active",
			"inactive",
			"inactive"
		];
	} else if (
		skill_level === "Expert" ||
		skill_level === "Native speaker" ||
		skill_level === "C1" ||
		skill_level === "C2"
	) {
		classes = [
			"active",
			"active",
			"active",
			"active",
			"active",
			"active",
			"active",
			"active",
			"active",
			"active"
		];
	}

	return (
		<>
			<div className={title_class}>{skill}</div>
			{!displaying_level ? (
				<div className={level_class}>
					{classes.length > 0 &&
						skill &&
						classes.map((item, index) => {
							return <div className={item} key={index}></div>;
						})}
				</div>
			) : (
				""
			)}
		</>
	);
};

export default SkillsLevelVerticalLine;
