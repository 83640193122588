// external components
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@mui/material";
import { toBengaliNumber } from "bengali-number";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
	LoginSocialFacebook,
	LoginSocialGoogle,
	LoginSocialLinkedin
} from "reactjs-social-login";

// internal components
import { PasswordRequired, StuModal } from "../../common";
import { GetContextApi } from "../../storage/ContextApi";
import { isValidateEmail } from "./../../common/function";
import "./AccountSettings.css";

const AccountSettings = () => {
	// for redirect page
	const Navigate = useNavigate();

	// for getting currentUser && routing fromWhere
	const { currentUser, setCurrentUser, contents } = GetContextApi();

	useEffect(() => {
		if (currentUser) {
		} else {
			Navigate("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for getting currentUser & checking end

	// account-settings contents
	const {
		page_title,

		your_plan,
		upgrade_text,

		free_plan,
		basic_plan,
		student_plan,
		standard_plan,

		account,
		first_name,
		last_name,
		email,
		email_des,
		current_password,
		new_password,
		info_text,
		save_btn,
		saved_btn,

		f_name_error,
		l_name_error,

		student_verification,

		social_profile,
		connect,
		disconnect,

		email_notification,

		update_offers,
		update_offers_des,

		resume_analytics,
		resume_analytics_des,

		newsletter,
		newsletter_des,

		danger_zone,
		danger_zone_warning,
		delete_account_btn,

		delete_acc_header,
		delete_acc_sub_header,
		agree,
		disagree,

		help_text,
		contact_us_link,

		billing_details,
		cancel_subscription,
		refund_request
	} = contents.account_setting;

	const {
		act_cancel_subscription,
		act_refund_request,
		stu_verification_alert,
		yes,
		no
	} = contents.package_limit;
	const { email_error_msg } = contents.log_in;
	const { month, year, months, years } = contents.subscription;

	// for getting currentUser's info start
	const [getFName, setFName] = useState(
		currentUser ? currentUser.name.split(" ").slice(0, -1).join(" ") : ""
	);

	const [getLName, setLName] = useState(
		currentUser ? currentUser.name.split(" ").slice(-1).join(" ") : ""
	);
	const [getEmail, setEmail] = useState(currentUser ? currentUser.email : "");
	const [getStuEmail, setStuEmail] = useState("");

	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");

	// eyes toggle for password
	const [eyeForCurrent, setEyeForCurrent] = useState(false);
	const [eysForNew, setEysForNew] = useState(false);

	// for checking button clicked for submit or not
	const [btnClicked, setBtnClicked] = useState(false);

	// for updating userAccount's info start
	// for loading until not updated on db
	const [isLoading, setIsLoading] = useState(false);

	// passwordRequired check
	const [passwordRequired, setPasswordRequired] = useState(false);

	// updateUserAccount handler start
	const updateUserAccount = async () => {
		if (forWhat) {
			handleClose();
		}
		if (isValidateEmail(getEmail)) {
			let name = getFName + " " + getLName;

			if (
				name === currentUser.name &&
				getEmail === currentUser.email &&
				!newPassword &&
				!getStuEmail
			) {
				toast.success("Nothing have to changed.", {
					position: "top-right",
					autoClose: 2500,
					theme: "dark"
				});
			} else {
				try {
					setIsLoading(true);

					const response = await fetch(`/user/update-info`, {
						method: "PUT",
						body: JSON.stringify({
							name,
							email: getStuEmail ? getStuEmail : getEmail,
							currentPassword,
							password: newPassword
						}),
						headers: {
							"Content-Type": "application/json"
						}
					});

					const result = await response.json();

					if (response.status === 200) {
						toast.success(result.message, {
							position: "top-right",
							autoClose: 2500,
							theme: "colored"
						});

						setFName(
							result
								? result.document?.name.split(" ").slice(0, -1).join(" ")
								: ""
						);
						setLName(
							result ? result.document?.name.split(" ").slice(-1).join(" ") : ""
						);

						setCurrentUser(result ? result.document : currentUser);

						setVerifyStuModal(false);

						setStuEmail("");

						setTimeout(() => {
							setIsLoading(false);
						}, 1000);
					} else if (result.error) {
						toast.error(result.error, {
							position: "top-right",
							autoClose: 2500,
							theme: "colored"
						});

						setIsLoading(false);
					}
				} catch (error) {
					toast.error(error.message, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});

					setIsLoading(false);
				}
			}
		} else {
			toast.error("Please enter valid email.", {
				position: "top-right",
				autoClose: 2500,
				theme: "dark"
			});
		}
	};
	// updateUserAccount handler send

	// for check social-connected start
	const [facebook, setFacebook] = useState("");
	const [google, setGoogle] = useState("");
	const [linkedin, setLinkedin] = useState("");

	useEffect(() => {
		if (currentUser) {
			// eslint-disable-next-line array-callback-return
			currentUser.social_connected.map((value) => {
				if (value.provider === "facebook") {
					setFacebook(value);
				} else if (value.provider === "google") {
					setGoogle(value);
				} else if (value.provider === "linkedin") {
					setLinkedin(value);
				}
			});
		}
	}, [currentUser]);

	// for connection with socials start
	const connectWithSocialHandler = async (response) => {
		const provider = response.provider;

		// for get linkedin email
		let getEmail = "";

		if (response.provider === "linkedin") {
			const responseLinkedin = await fetch("/user/get-linkedin-email", {
				method: "POST",
				body: JSON.stringify({ access_token: response.data.access_token }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const resultLinkedin = await responseLinkedin.json();

			if (responseLinkedin.status === 200) {
				getEmail = resultLinkedin;
			} else {
				getEmail = "";
			}
		}

		const email =
			response.provider === "linkedin" ? getEmail : response.data.email;

		try {
			const userObj = {
				email,
				provider
			};

			const response = await fetch("/user/connect-with-socials", {
				method: "PUT",
				body: JSON.stringify(userObj),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				setCurrentUser(result ? result : currentUser);
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "dark",
					autoClose: 2500
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
		}
	};
	// for connection with socials end
	// for check social-connected end

	// for checkbox toggle start
	const [updateT, setUpdateT] = useState(currentUser.updates_offers);
	const [analyticsT, setAnalyticsT] = useState(currentUser.resume_analytics);
	const [tipsT, setTipsT] = useState(currentUser.tips);
	const [isCancelSubs, setIsCancelSubs] = useState(
		currentUser.cancel_subscription
	);

	// submit on database
	const toggleHandler = async (obj) => {
		try {
			const response = await fetch(`/user/update-service`, {
				method: "PUT",
				body: JSON.stringify(obj),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				if (Object.keys(obj)[0] === "cancel_subscription") {
					setIsCancelSubs(true);
					handleClose();
				} else {
					return null;
				}
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for checkbox toggle end

	// for delete account start
	const [open, setOpen] = useState(false);
	const [forWhat, setForWhat] = useState("");

	const handleClickOpen = (forWhat) => {
		setForWhat(forWhat);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);

		setTimeout(() => {
			setForWhat("");
		}, 500);
	};

	const deleteAccountHandler = async () => {
		try {
			const response = await fetch(`/user/delete-user`, {
				method: "DELETE"
			});

			const result = await response.json();

			if (response.status === 200) {
				setCurrentUser("");
				Navigate("/");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for delete account end

	// display currentUser plan
	const generateCurrentPlan = () => {
		const plan = currentUser.package["Package Name"];
		if (plan === "Free Plan") {
			return free_plan;
		} else if (plan === "Basic") {
			return basic_plan;
		} else if (plan === "Student") {
			return student_plan;
		} else if (plan === "Standard") {
			return standard_plan;
		}
	};
	const currentPlan = generateCurrentPlan();

	// subscription date calculation start
	const paymentDate = moment(new Date(currentUser.payment_date));
	const expiredRefundDate = moment(paymentDate).add(7, "days");

	const canRefund = moment().isSameOrBefore(expiredRefundDate);

	// generate subscription period
	const generateSubscriptionPeriod = () => {
		const getMonths = moment(new Date(currentUser.package_expireDate)).diff(
			paymentDate,
			"months"
		);

		const convertYears = getMonths / 12;

		if (Number.isInteger(convertYears)) {
			const number =
				contents.title === "bangla"
					? toBengaliNumber(convertYears)
					: convertYears;
			return convertYears === 1 ? `${number} ${year}` : `${number} ${years}`;
		} else {
			const number =
				contents.title === "bangla" ? toBengaliNumber(getMonths) : getMonths;

			return getMonths === 1 ? `${number} ${month}` : `${number} ${months}`;
		}
	};
	// subscription date calculation end

	// submitting refund request start
	const submittingRefundRequest = async () => {
		try {
			const tranIdArr = currentUser.tran_id;

			const obj = {
				user_email: currentUser.email,
				trans_id: tranIdArr[tranIdArr.length - 1],
				purchase_date: currentUser.payment_date,
				submit_date: moment(),
				currentPackage: currentUser.package["Package Name"]
			};

			const response = await fetch(`/refund/request`, {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				setCurrentUser(result);
				handleClose();
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// submitting refund request end

	// refund restriction calculate less then 70% or not
	const isActualPriceLess = (actualPrice, discountPrice) => {
		const seventyPercent = 0.7 * discountPrice;

		return actualPrice > seventyPercent;
	};

	// for student verification start
	const [verifyStuModal, setVerifyStuModal] = useState(false);

	const modalRef = useRef();
	const dialogRef = useRef();

	// for modal close start
	const handleClickOutside = (e) => {
		if (
			!modalRef.current?.contains(e.target) &&
			!dialogRef.current?.contains(e.target) &&
			verifyStuModal
		) {
			setVerifyStuModal("");
			setStuEmail("");
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [verifyStuModal]);
	// for modal close end
	// for student verification end

	return (
		<>
			<div className="account-sitting-main-container">
				<div className="container">
					<div className="account-sittings-container row">
						<div className="account-sittings-wrapper col-11 col-sm-12">
							<div className="top-header">{page_title}</div>

							{/* user-plan-container start  */}
							<div className="user-plan-container">
								<span>{your_plan}</span>
								<div className="plan-wrapper">
									<div className="left-section">
										<div className="icon-wrapper">
											<img
												src={`/assets/icons/package/${
													(currentUser.package["Package Name"] ===
														"Free Plan" &&
														"free-plan.png") ||
													(currentUser.package["Package Name"] === "Basic" &&
														"basic.png") ||
													(currentUser.package["Package Name"] === "Student" &&
														"student.png") ||
													(currentUser.package["Package Name"] === "Standard" &&
														"standard.png")
												}`}
												alt="icon"
												className="icon"
											/>
										</div>

										<div className="contents">
											<div className="text">{currentPlan.plan}</div>
											<div>{currentPlan.description}</div>
										</div>
									</div>
									<div className="right-section">
										<NavLink to="../subscription">
											<button className="primary-btn-without-bg upgrade-btn">
												{upgrade_text}
											</button>{" "}
										</NavLink>
									</div>
								</div>
							</div>
							{/* user-plan-container end  */}

							{/* account-section start  */}
							<div className="account-section">
								<div className="header">{account}</div>

								<div className="account-fields">
									{/* row-1 start */}
									<div className="row-container">
										<div className="row-wrapper">
											<div className="contents">
												<label>
													<div>{first_name}*</div>
												</label>

												<div className="input-field">
													<input
														id={btnClicked && !getFName ? "not-fill" : ""}
														onChange={(e) => setFName(e.target.value)}
														value={getFName}
													/>

													{btnClicked && !getFName && (
														<p id="error-msg">{f_name_error}</p>
													)}
												</div>
											</div>
										</div>

										<div className="row-wrapper">
											<div className="contents">
												<label>
													<div>{last_name}*</div>
												</label>

												<div className="input-field">
													<input
														id={btnClicked && !getLName ? "not-fill" : ""}
														onChange={(e) => setLName(e.target.value)}
														value={getLName}
													/>
													{btnClicked && !getLName && (
														<p id="error-msg">{l_name_error}</p>
													)}
												</div>
											</div>
										</div>
									</div>
									{/* row-1 end */}

									{/* row-2 start  */}
									<div className="row-container">
										<div className="row-wrapper">
											<div className="contents">
												<label>
													<div>{email}*</div>
												</label>

												<div className="input-field">
													<input
														id={btnClicked && !getEmail ? "not-fill" : ""}
														onChange={(e) => setEmail(e.target.value)}
														value={getEmail}
													/>
													{btnClicked && !getEmail && (
														<p id="error-msg">{email_error_msg}</p>
													)}
												</div>
											</div>
										</div>

										<div className="row-wrapper">
											<div className="contents">
												<div
													className="email-description"
													dangerouslySetInnerHTML={{ __html: email_des }}
												></div>
											</div>
										</div>
									</div>
									{/* row-2 end */}

									{/* row-3 start */}
									<div className="row-container">
										<div className="row-wrapper">
											<div className="contents">
												<label>
													<div>{current_password}*</div>
												</label>

												<div className="input-field when-password">
													<input
														id={
															btnClicked &&
															(currentUser.password ? !currentPassword : false)
																? "not-fill"
																: ""
														}
														type={eyeForCurrent ? "text" : "password"}
														onChange={(e) => setCurrentPassword(e.target.value)}
														value={currentPassword}
													/>

													{btnClicked &&
														(currentUser.password
															? !currentPassword
															: false) && (
															<p id="error-msg">
																Current password is required *
															</p>
														)}

													{/* for currentPassword eyes toggle start */}
													{currentPassword &&
														(eyeForCurrent ? (
															<i
																className="fa-solid fa-eye"
																onClick={() => setEyeForCurrent(!eyeForCurrent)}
															></i>
														) : (
															<i
																className="fa-solid fa-eye-slash"
																onClick={() => setEyeForCurrent(!eyeForCurrent)}
															></i>
														))}
													{/* for currentPassword eyes toggle end */}
												</div>
											</div>
										</div>

										<div className="row-wrapper">
											<div className="contents">
												<label>
													<div>{new_password}</div>
												</label>

												<div className="input-field when-password new-password">
													<input
														type={eysForNew ? "text" : "password"}
														onChange={(e) => setNewPassword(e.target.value)}
														value={newPassword}
													/>

													{/* for newPassword eyes toggle start */}
													{newPassword &&
														(eysForNew ? (
															<i
																className="fa-solid fa-eye"
																onClick={() => setEysForNew(!eysForNew)}
															></i>
														) : (
															<i
																className="fa-solid fa-eye-slash"
																onClick={() => setEysForNew(!eysForNew)}
															></i>
														))}
													{/* for newPassword eyes toggle end */}

													{/* for password require validation start  */}
													<div style={{ marginTop: "20px" }}>
														<PasswordRequired
															getPassword={newPassword}
															passwordRequired={passwordRequired}
															setPasswordRequired={setPasswordRequired}
															contents={contents}
														/>
													</div>
													{/* for password require validation end */}
												</div>
											</div>
										</div>
									</div>
									{/* row-3 end */}

									{/* row-4 start  */}
									<div className="save-btn">
										<div id="help-txt">
											<i className="fa-solid fa-circle-info" id="icon"></i>{" "}
											{info_text}{" "}
										</div>

										<button
											className="btn"
											id={
												newPassword
													? getFName &&
													  getLName &&
													  getEmail &&
													  (currentUser.password ? currentPassword : true) &&
													  newPassword.length >= 8 &&
													  passwordRequired
														? "active"
														: ""
													: getFName &&
													  getLName &&
													  getEmail &&
													  (currentUser.password ? currentPassword : true)
													? "active"
													: ""
											}
											onClick={() => {
												newPassword
													? getFName &&
													  getLName &&
													  getEmail &&
													  (currentUser.password ? currentPassword : true) &&
													  newPassword.length >= 8 &&
													  passwordRequired &&
													  updateUserAccount()
													: getFName &&
													  getLName &&
													  getEmail &&
													  (currentUser.password ? currentPassword : true) &&
													  updateUserAccount();

												newPassword
													? getFName &&
													  getLName &&
													  getEmail &&
													  (currentUser.password ? currentPassword : true) &&
													  newPassword.length >= 8 &&
													  passwordRequired &&
													  setBtnClicked(true)
													: getFName &&
													  getLName &&
													  getEmail &&
													  (currentUser.password ? currentPassword : true) &&
													  setBtnClicked(true);
											}}
											style={{
												cursor: newPassword
													? getFName &&
													  getLName &&
													  getEmail &&
													  (currentUser.password ? currentPassword : true) &&
													  newPassword.length >= 8 &&
													  passwordRequired
														? "pointer"
														: "default"
													: getFName &&
													  getLName &&
													  getEmail &&
													  (currentUser.password ? currentPassword : true)
													? "pointer"
													: "default"
											}}
										>
											{isLoading ? saved_btn : save_btn}
										</button>
									</div>
									{/* row-4 end */}
								</div>
							</div>
							{/* account-section end */}

							{/* student-verification start  */}
							<div className="settings-section">
								<div className="header">{student_verification.header}</div>

								<div className="contents">
									{currentUser.type === "general" ? (
										<div className="left-section">
											{student_verification.change_to_stu}
										</div>
									) : (
										<div className="left-section" id="for-student">
											<i className="fa-solid fa-circle-check" id="verified"></i>{" "}
											{student_verification.stu_verified}
										</div>
									)}

									{currentUser.type === "general" && (
										<div className="right-section">
											<button
												className="primary-btn-without-bg"
												style={{ whiteSpace: "nowrap" }}
												onClick={() => setVerifyStuModal(true)}
												disabled={
													currentUser.stu_card && currentUser.type === "general"
														? true
														: false
												}
											>
												{currentUser.stu_card && currentUser.type === "general"
													? student_verification.pending_btn
													: student_verification.verify_as_stu}
											</button>
										</div>
									)}
								</div>
							</div>
							{/* student-verification end  */}

							{/* .social-profile-section start  */}
							<div className="social-profile-section">
								<div className="header">{social_profile}</div>

								<div className="social-profile-wrapper">
									{/* for facebook start  */}

									<div className="row-section">
										<div className="left-section">
											<div className="svg-container">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													version="1.1"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill="#3B5998"
														d="M9.73 6.486v2.478H8v3.03h1.73l.013 8.506h3.552l-.014-8.505h2.384s.223-1.453.331-3.042h-2.701V6.881c0-.31.387-.726.77-.726H16V3h-2.631c-3.728 0-3.64 3.033-3.64 3.486z"
													></path>
												</svg>
											</div>
											<div className="social-media">
												{facebook ? (
													<>
														<span>Facebook</span>
														<span id="dot-icon"></span>
														<span id="identifier">{facebook.email}</span>
													</>
												) : (
													"Facebook"
												)}
											</div>
										</div>
										<div className="right-section">
											{facebook ? (
												<button className="primary-btn-without-bg">
													{disconnect}
												</button>
											) : (
												<LoginSocialFacebook
													appId={process.env.REACT_APP_APPID}
													onResolve={connectWithSocialHandler}
													onReject={(error) => {
														toast.error("Login with facebook failed!", {
															position: "top-right",
															theme: "colored",
															autoClose: 2500
														});
													}}
												>
													<button className="primary-btn-without-bg">
														{connect}
													</button>
												</LoginSocialFacebook>
											)}
										</div>
									</div>

									{/* for facebook end  */}

									{/* for linkedIn start  */}
									<div className="row-section">
										<div className="left-section">
											<div className="svg-container">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													version="1.1"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill="#0077B5"
														d="M7.86 7.704V18.5H4.214V7.704H7.86zm.24-3.34c0 1.037-.792 1.867-2.063 1.867h-.023C4.791 6.23 4 5.4 4 4.365 4 3.306 4.815 2.5 6.061 2.5c1.247 0 2.015.806 2.038 1.865zM21 12.31v6.19h-3.644v-5.776c0-1.45-.527-2.44-1.846-2.44-1.007 0-1.606.667-1.87 1.311-.096.231-.12.553-.12.876V18.5H9.876s.048-9.783 0-10.796h3.644v1.53c.484-.735 1.35-1.783 3.285-1.783C19.202 7.45 21 8.994 21 12.31z"
													></path>
												</svg>
											</div>
											<div className="social-media">
												{linkedin ? (
													<>
														<span>Linkedin</span>
														<span id="dot-icon"></span>
														<span id="identifier">{linkedin.email}</span>
													</>
												) : (
													"Linkedin"
												)}
											</div>
										</div>
										<div className="right-section">
											{linkedin ? (
												<button className="primary-btn-without-bg">
													{disconnect}
												</button>
											) : (
												<LoginSocialLinkedin
													client_id={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
													client_secret={
														process.env.REACT_APP_LINKEDIN_SECRET_KEY
													}
													redirect_uri={`${process.env.REACT_APP_DOMAIN}/account-settings`}
													onResolve={connectWithSocialHandler}
													scope="r_liteprofile r_emailaddress"
													onReject={(error) => {
														toast.error("Login with linkedin failed!", {
															position: "top-right",
															theme: "colored",
															autoClose: 2500
														});
													}}
												>
													<button className="primary-btn-without-bg">
														{connect}
													</button>
												</LoginSocialLinkedin>
											)}
										</div>
									</div>
									{/* for linkedIn end  */}

									{/* for google start  */}
									<div className="row-section">
										<div className="left-section">
											<div className="svg-container">
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													version="1.1"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g>
														<path
															d="M19 12.3058C19 11.8203 18.9606 11.3322 18.8767 10.8546L12.1404 10.8546V13.6048H15.9979C15.8379 14.4917 15.3235 15.2764 14.5704 15.775V17.5594H16.8718C18.2232 16.3155 19 14.4786 19 12.3058Z"
															fill="#4285F4"
														></path>
														<path
															d="M12.1404 19.2833C14.0665 19.2833 15.6909 18.6509 16.8744 17.5592L14.573 15.7748C13.9327 16.2104 13.1061 16.4571 12.143 16.4571C10.2798 16.4571 8.70009 15.2001 8.13327 13.5101H5.75839V15.3497C6.97076 17.7613 9.44011 19.2833 12.1404 19.2833Z"
															fill="#34A853"
														></path>
														<path
															d="M8.13065 13.5102C7.83149 12.6233 7.83149 11.6628 8.13065 10.7758V8.93628H5.75839C4.74545 10.9543 4.74545 13.3318 5.75839 15.3498L8.13065 13.5102Z"
															fill="#FBBC04"
														></path>
														<path
															d="M12.1404 7.82623C13.1586 7.81049 14.1426 8.19362 14.88 8.8969L16.919 6.85791C15.6279 5.64554 13.9143 4.979 12.1404 4.99999C9.44011 4.99999 6.97076 6.52202 5.75839 8.93626L8.13065 10.7758C8.69485 9.08322 10.2772 7.82623 12.1404 7.82623Z"
															fill="#EA4335"
														></path>
													</g>
												</svg>
											</div>
											<div className="social-media">
												{google ? (
													<>
														<span>Google</span>
														<span id="dot-icon"></span>
														<span id="identifier">{google.email}</span>
													</>
												) : (
													"Google"
												)}
											</div>
										</div>
										<div className="right-section">
											{google ? (
												<button className="primary-btn-without-bg">
													{disconnect}
												</button>
											) : (
												<LoginSocialGoogle
													client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
													discoveryDocs="claims_supported"
													access_type="offline"
													scope="profile email"
													onResolve={connectWithSocialHandler}
													onReject={(error) => {
														toast.error("Login with google failed!", {
															position: "top-right",
															theme: "colored",
															autoClose: 2500
														});
													}}
												>
													<button className="primary-btn-without-bg">
														{connect}
													</button>
												</LoginSocialGoogle>
											)}
										</div>
									</div>
								</div>
								{/* for google end  */}
							</div>
							{/* .social-profile-section end */}

							{/* email-notification section start  */}
							<div className="email-notification-section">
								<div className="header">{email_notification}</div>

								<div className="email-notification-wrapper">
									{/* update section start  */}
									<div
										className="section"
										onClick={() => {
											toggleHandler({ updates_offers: !updateT });
											setUpdateT(!updateT);
										}}
									>
										<div className="left-section">
											<div className="title">{update_offers}</div>

											<div className="sub-title">{update_offers_des}</div>
										</div>

										<div className="right-section">
											<input
												type="checkbox"
												readOnly
												checked={updateT ? true : false}
											/>
										</div>
									</div>
									{/* update section end  */}

									{/* Analytics section start  */}
									<div
										className="section"
										onClick={() => {
											toggleHandler({ resume_analytics: !analyticsT });
											setAnalyticsT(!analyticsT);
										}}
									>
										<div className="left-section">
											<div className="title">{resume_analytics}</div>

											<div className="sub-title">{resume_analytics_des}</div>
										</div>

										<div className="right-section">
											<input
												type="checkbox"
												readOnly
												checked={analyticsT ? true : false}
											/>
										</div>
									</div>
									{/* Analytics section end  */}

									{/* tips section start  */}
									<div
										className="section"
										onClick={() => {
											toggleHandler({ tips: !tipsT });
											setTipsT(!tipsT);
										}}
									>
										<div className="left-section">
											<div className="title">{newsletter}</div>

											<div className="sub-title">{newsletter_des}</div>
										</div>

										<div className="right-section">
											<input
												type="checkbox"
												readOnly
												checked={tipsT ? true : false}
											/>
										</div>
									</div>
									{/* tips section end  */}
								</div>
							</div>
							{/* email-notification section end */}

							{/* danger-zone start  */}
							{/* for refund  */}
							{currentUser.package["Package Name"] !== "Free Plan" && (
								<div className="danger-zone-container">
									<div className="header">{billing_details.header}</div>

									<div className="contents" id="package-info-contents">
										<div className="left-section">
											{billing_details.sub_header}
										</div>

										<div className="left-section" id="package-info">
											<div>
												<b>{billing_details.your_package}</b> {currentPlan.plan}
											</div>

											<div>
												<b>{billing_details.subscription_start_date}</b>{" "}
												{contents.title === "bangla"
													? toBengaliNumber(paymentDate.format("DD-MM-YYYY"))
													: paymentDate.format("DD-MM-YYYY")}
											</div>
											<div>
												<b>{billing_details.subscription_period}</b>{" "}
												{generateSubscriptionPeriod()}
											</div>

											<div>
												<b>{billing_details.subscription_price}</b> Tk.{" "}
												{contents.title === "bangla"
													? toBengaliNumber(
															currentUser.package["Discounted Price"]
													  )
													: currentUser.package["Discounted Price"]}
											</div>

											{currentUser.package["Discounted Price"] !==
												currentUser.payment_amount &&
												currentUser.package["Discounted Price"] -
													currentUser.payment_amount !==
													0 && (
													<div>
														<b>{billing_details.coupon}</b> Tk.{" "}
														{contents.title === "bangla"
															? toBengaliNumber(
																	currentUser.package["Discounted Price"] -
																		currentUser.payment_amount
															  )
															: currentUser.package["Discounted Price"] -
															  currentUser.payment_amount}
													</div>
												)}

											<div>
												<b>{billing_details.total_paid}</b> Tk.{" "}
												{contents.title === "bangla"
													? toBengaliNumber(currentUser.payment_amount)
													: currentUser.payment_amount}
											</div>
										</div>
									</div>

									{isCancelSubs ? (
										<div className="contents">
											<div className="left-section">
												Your subscription will be automatic cancel on date{" "}
												<b>
													{moment(
														new Date(currentUser.package_expireDate)
													).format("MMMM DD, YYYY")}
												</b>
											</div>
										</div>
									) : (
										<div className="contents">
											<div className="left-section">
												{cancel_subscription.description}
											</div>

											<div className="right-section">
												<button
													className="primary-btn-without-bg"
													style={{ whiteSpace: "nowrap" }}
													onClick={() => handleClickOpen("subscription")}
												>
													{cancel_subscription.btn_text}
												</button>
											</div>
										</div>
									)}

									{isCancelSubs &&
										canRefund &&
										isActualPriceLess(
											currentUser.payment_amount,
											currentUser.package["Discounted Price"]
										) && (
											<div className="contents" id="for-refund">
												<div className="left-section">
													{refund_request.description}
												</div>

												<div className="right-section">
													<button
														className="primary-btn-without-bg"
														style={{ whiteSpace: "nowrap" }}
														onClick={() => handleClickOpen("refund")}
													>
														{refund_request.btn_text}
													</button>
												</div>
											</div>
										)}
								</div>
							)}

							{/* for delete account  */}
							<div className="danger-zone-container">
								<div className="header">{danger_zone}</div>

								<div className="contents">
									<div className="left-section">{danger_zone_warning}</div>
									<div className="right-section">
										<button
											className="primary-btn-without-bg"
											style={{ whiteSpace: "nowrap" }}
											onClick={() => handleClickOpen("account-delete")}
										>
											{delete_account_btn}
										</button>
									</div>
								</div>
							</div>
							{/* danger-zone end */}

							{/* need-help section start  */}
							<div className="need-help-container">
								{help_text}
								<Link to={"/contact-us"}>{contact_us_link}</Link>
							</div>
							{/* need-help section end  */}

							{/* dialog box start  */}
							<Dialog open={open} onClose={handleClose} ref={dialogRef}>
								<Box sx={{ p: 2 }}>
									<DialogTitle>
										{(forWhat === "subscription" &&
											act_cancel_subscription.header) ||
											(forWhat === "refund" && act_refund_request.header) ||
											(forWhat === "student-verification" &&
												stu_verification_alert.header) ||
											delete_acc_header}
									</DialogTitle>
									<DialogContent>
										<DialogContentText>
											<div
												dangerouslySetInnerHTML={{
													__html:
														(forWhat === "subscription" &&
															act_cancel_subscription.description) ||
														(forWhat === "refund" &&
															act_refund_request.description) ||
														(forWhat === "student-verification" &&
															stu_verification_alert.description) ||
														delete_acc_sub_header
												}}
											></div>
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<button
											onClick={handleClose}
											className="primary-btn-styles"
										>
											{forWhat === "subscription" ? no : disagree}
										</button>
										<button
											className="primary-btn-styles"
											onClick={() =>
												(forWhat === "subscription" &&
													toggleHandler({ cancel_subscription: true })) ||
												(forWhat === "refund" && submittingRefundRequest()) ||
												(forWhat === "account-delete" &&
													deleteAccountHandler()) ||
												(forWhat === "student-verification" &&
													updateUserAccount())
											}
										>
											{forWhat === "subscription" ? yes : agree}
										</button>
									</DialogActions>
								</Box>
							</Dialog>
							{/* dialog box end  */}
						</div>
					</div>
				</div>

				{/* student verification modal start  */}
				{verifyStuModal && (
					<StuModal
						verifyStuModal={verifyStuModal}
						setVerifyStuModal={setVerifyStuModal}
						handleClickOpen={handleClickOpen}
						modalRef={modalRef}
						getEmail={getStuEmail}
						setEmail={setStuEmail}
						currentPassword={currentPassword}
						setCurrentPassword={setCurrentPassword}
						contents={contents}
						userEmail={currentUser.email}
						setCurrentUser={setCurrentUser}
					/>
				)}
				{/* student verification modal end */}
			</div>
		</>
	);
};

export default AccountSettings;
