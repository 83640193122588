// external components
import { useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";

// internal components
import OnImgPopupOption from "../../common/OnImgPopupOptions";
import { getCurrentUser } from "../../common/function";
import Editor from "../../components/for_resume_editor/Editor/Editor";
import Preview from "../../components/for_resume_editor/Preview/Preview";
import { GetContextApi } from "../../storage/ContextApi";
import EditorContextHandler from "./EditorContextApi";
import "./_ResumeEditor.scss";

const ResumeEditor = () => {
	// contentApi
	const { selectedRTemplate, contents, currentUser, setCurrentUser } =
		GetContextApi();

	// for resume counter-number
	const { counter } = useParams();

	// for redirect "/" home page
	const Navigate = useNavigate();

	// for controls start
	const initialController = {
		loading: true,
		isSavingResume: false,
		EditorOrPreview: "When Editor"
	};

	const controlHandler = (state, action) => {
		switch (action.type) {
			case "start-loading":
				return {
					...state,
					loading: true
				};

			case "stop-loading":
				return {
					...state,
					loading: false
				};

			case "saving-start":
				return {
					...state,
					isSavingResume: true
				};

			case "saving-end":
				return {
					...state,
					isSavingResume: false
				};

			case "toggle-editor-or-preview":
				return {
					...state,
					EditorOrPreview:
						state.EditorOrPreview === "When Editor"
							? "When Preview"
							: "When Editor"
				};

			default:
				return state;
		}
	};

	const [control, dispatchControl] = useReducer(
		controlHandler,
		initialController
	);
	// for controls end

	// check currentUser exists or not
	useEffect(() => {
		if (!currentUser) {
			(async () => {
				const result = await getCurrentUser();

				if (result) {
					setCurrentUser(result);
				} else {
					Navigate("/");
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<EditorContextHandler
				counter={counter}
				selectedRTemplate={selectedRTemplate}
				control={control.loading}
				dispatchControl={dispatchControl}
			>
				<div className="resume-editor-container">
					<div
						className="main-editor-container"
						id={
							control.EditorOrPreview === "When Editor"
								? "for-100"
								: "for-hidden"
						}
					>
						<Editor contents={contents} />
					</div>

					<div
						className="main-preview-container"
						id={
							control.EditorOrPreview === "When Preview"
								? "for-100"
								: "for-hidden"
						}
					>
						<Preview isSavingResume={control.isSavingResume} />
					</div>

					<OnImgPopupOption contents={contents} currentUser={currentUser} />

					{/* for preview_download toggle button start  */}
					<button
						className="preview-download-btn"
						id={control.EditorOrPreview !== "When Editor" ? "preview-mb" : ""}
						onClick={() =>
							dispatchControl({ type: "toggle-editor-or-preview" })
						}
					>
						{control.EditorOrPreview === "When Editor" ? (
							<div className="preview-download-text">
								{contents.title === "bangla"
									? "প্রিভিউ ডাউনলোড"
									: "Preview Download"}
							</div>
						) : (
							<div className="preview-download-text">
								{contents.title === "bangla" ? "সম্পাদক" : "Editor"}
							</div>
						)}

						{control.EditorOrPreview === "When Editor" ? (
							<div className="preview-download-icon">
								<i className="fa-solid fa-expand"></i>
							</div>
						) : (
							<div className="preview-download-icon">
								<i className="fa-solid fa-pencil"></i>
							</div>
						)}
					</button>
					{/* for preview_download toggle button end */}
				</div>
			</EditorContextHandler>
		</>
	);
};

export default ResumeEditor;
