/* eslint-disable import/no-webpack-loader-syntax */
// internal components
import MarketingSpecialistStyle from "!!raw-loader!./MarketingSpecialist.css";
import { RichTextParser, SectionTitle, SkillsLevel, Time } from "../Components";
import {
	checkVariables,
	divideAndAddBrTag,
	fetchingResumeData,
	generateIconBlack,
	isArrayEmpty,
	objHasAnyValue,
	removeEmptyObjects
} from "../Components/Utils";

const MarketingSpecialist = ({ getResume }) => {
	// fetching resume's all data
	const {
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		professional_summary_title,
		rich_text_content,
		employment_history_title,
		employment_history_items,
		education_title,
		education_items,
		project_title,
		project_items,
		web_link_items,
		skills_title,
		skills_items,
		displaying_level,
		languages_title,
		languages_items,
		hobbies_title,
		hobbies_description,
		activity_title,
		activity_items,
		internship_title,
		internship_items,
		courses_title,
		courses_items,
		references_title,
		references_hide,
		references_items,
		customArr,
		disclaimer_title,
		disclaimer_description
	} = fetchingResumeData(getResume);

	//  professional_summary
	const professional_summary = rich_text_content ? (
		<div className="section">
			<SectionTitle
				className="title"
				sectionName={professional_summary_title}
				equalTo="Professional Summary"
				initialName="Profile"
			/>

			{rich_text_content && (
				<RichTextParser
					className={"rich_editor__container"}
					rich_text={rich_text_content}
				/>
			)}
		</div>
	) : (
		""
	);

	//  employment_history
	const employment_history = !isArrayEmpty(employment_history_items) ? (
		<div className="section">
			<SectionTitle
				className="title"
				sectionName={employment_history_title}
				equalTo="Employment History"
				initialName="WORK EXPERIENCE"
			/>

			{employment_history_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(item.start_date, item.end_date);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">{item.employment_job_title}</div>

							{checkVariables(
								item.employer,
								item.employment_city,
								checkTime
							) && (
								<div className="item__sub_header_italic">
									{item.employer}

									{item.employer && item.employment_city && ", "}

									{item.employment_city}

									{(item.employer || item.employment_city) && checkTime && ", "}
									{checkTime && (
										<Time start={item.start_date} end={item.end_date} />
									)}
								</div>
							)}

							{item.employment_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.employment_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// projects
	const projects = !isArrayEmpty(project_items) ? (
		<div className="section">
			<SectionTitle
				className="title"
				sectionName={project_title}
				equalTo="Projects/Portfolio"
				initialName="Projects"
			/>

			{project_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header project-header">
								{item.project_name}
								{item.project_name && item.project_duration && " - "}
								{item.project_duration}

								<div className="project_links">
									{!isArrayEmpty(item.project_links) &&
										removeEmptyObjects(item.project_links, [
											"label",
											"link"
										]).map((label, index) => {
											return (
												<div key={index} className="label">
													<a
														href={label.link}
														target="_blank"
														rel="noopener noreferrer"
														className="project-url"
													>
														{label.label}
													</a>
													{index !== item.project_links.length - 1 && " | "}
												</div>
											);
										})}
								</div>
							</div>

							{checkVariables(item.technologies) && (
								<div className="item__sub_header">
									<b>Technologies&nbsp;:&nbsp;</b>
									{item.technologies}
								</div>
							)}

							{item.pro_description && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.pro_description}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// education
	const education = !isArrayEmpty(education_items) ? (
		<div className="section">
			<div className="title">{education_title}</div>

			{education_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.edu_start_date,
						item.edu_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">{item.degree}</div>

							{item.school && (
								<div className="item__sub_header">{item.school}</div>
							)}

							{checkVariables(item.edu_city, checkTime) && (
								<div className="item__sub_header_italic">
									{item.edu_city}

									{item.edu_city && checkTime && ", "}

									{checkTime && (
										<Time
											start={item.edu_start_date}
											end={item.edu_end_date}
											fromWhere={"education"}
										/>
									)}
								</div>
							)}

							{item.edu_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.edu_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// internship
	const internship = !isArrayEmpty(internship_items) ? (
		<div className="section">
			<div className="title">{internship_title}</div>

			{internship_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.intern_start_date,
						item.intern_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">{item.intern_job}</div>

							{item.intern_employer && (
								<div className="item__sub_header">{item.intern_employer}</div>
							)}

							{checkVariables(item.intern_city, checkTime) && (
								<div className="item__sub_header_italic">
									{item.intern_city}

									{item.intern_city && checkTime && ", "}

									{checkTime && (
										<Time
											start={item.intern_start_date}
											end={item.intern_end_date}
										/>
									)}
								</div>
							)}

							{item.intern_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.intern_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// courses
	const courses = !isArrayEmpty(courses_items) ? (
		<div className="section">
			<div className="title">{courses_title}</div>

			{courses_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.course_start_date,
						item.course_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">{item.course_name}</div>

							{item.course_institution && (
								<div className="item__sub_header">
									{item.course_institution}
								</div>
							)}

							{checkTime && (
								<div className="item__sub_header_italic">
									<Time
										start={item.course_start_date}
										end={item.course_end_date}
									/>
								</div>
							)}

							{item.course_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.course_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// extra curricular activities
	const activities = !isArrayEmpty(activity_items) ? (
		<div className="section">
			<div className="title">{activity_title}</div>

			{activity_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.activity_start_date,
						item.activity_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">{item.activity_function}</div>

							{checkVariables(
								item.activity_employer,
								item.activity_city,
								checkTime
							) && (
								<div className="item__sub_header_italic">
									{item.activity_employer}

									{item.activity_employer && item.activity_city && ", "}

									{item.activity_city}

									{(item.activity_employer || item.activity_city) &&
										checkTime &&
										", "}
									{checkTime && (
										<Time
											start={item.activity_start_date}
											end={item.activity_end_date}
										/>
									)}
								</div>
							)}

							{item.activity_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.activity_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// references
	const references = !isArrayEmpty(references_items) ? (
		<div className="section">
			<div className="title">{references_title}</div>

			{references_hide && (
				<div className="item__sub_header mt-5">
					Reference available upon request
				</div>
			)}

			{!references_hide && (
				<div className="references-wrapper">
					{references_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div
									className={
										index === 0 || index === 1 ? "item item__first" : "item"
									}
									id="reference-item"
									key={index}
								>
									<div className="item__header">{item.references_name}</div>

									<div className="item__sub_header">
										{item.references_company}
									</div>

									{checkVariables(
										item.references_phone,
										item.references_email
									) && (
										<div className="item__sub_header">
											{item.references_phone}
											{item.references_phone && <br />}
											{item.references_email}
										</div>
									)}
								</div>
							);
						})}
				</div>
			)}
		</div>
	) : (
		""
	);

	// custom
	const custom = (specificCustom) =>
		!isArrayEmpty(specificCustom.custom_items) ? (
			<div className="section">
				<div className="title">{specificCustom.custom_title}</div>

				{specificCustom.custom_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						const checkTime = checkVariables(
							item.custom_start_date,
							item.custom_end_date
						);

						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item__header">{item.custom_activity}</div>

								{checkVariables(item.custom_city, checkTime) && (
									<div className="item__sub_header_italic">
										{item.custom_city}
										{item.custom_city && checkTime && ", "}
										{checkTime && (
											<Time
												start={item.custom_start_date}
												end={item.custom_end_date}
											/>
										)}
									</div>
								)}

								{item.custom_rich_text_content && (
									<RichTextParser
										className={"rich_editor__container"}
										rich_text={item.custom_rich_text_content}
									/>
								)}
							</div>
						);
					})}
			</div>
		) : (
			""
		);

	//  personal_details
	const personal_details = checkVariables(
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth
	) ? (
		<div className="section">
			<div className="title">Contact</div>

			<div className="phone-email-wrapper">
				{/* phone start  */}
				{phone && (
					<div className="personal__sub-header">
						<img
							src="/assets/icons/phone-white.png"
							alt="icon"
							className="icon"
						/>

						<div className="content">
							<a
								href={`tel:${phone}`}
								target="_blank"
								rel="noopener noreferrer"
								className="anchor"
							>
								{phone}
							</a>
						</div>
					</div>
				)}
				{/* phone end  */}

				{/* email start  */}
				{email && (
					<div className="personal__sub-header" id={phone ? "ml" : ""}>
						<img src="/assets/icons/at-white.png" alt="icon" className="icon" />

						<div className="content">
							<a
								href={`mailto:${email}`}
								target="_blank"
								rel="noopener noreferrer"
								dangerouslySetInnerHTML={{
									__html: divideAndAddBrTag(email, 100)
								}}
								className="anchor"
							></a>
						</div>
					</div>
				)}
				{/* email end  */}
			</div>

			{/* present_address start */}
			{checkVariables(
				present_address,
				present_city,
				present_area,
				present_zip
			) && (
				<div className="personal__sub-header">
					<img src="/assets/icons/map-white.png" alt="icon" className="icon" />

					<div className="content">
						<b>Present Address:</b> &nbsp;
						{present_address}
						{present_address && present_city && `, ${present_city}`}
						{present_area && `, ${present_area}`}
						{present_zip && `, ${present_zip}`}
						{!checkVariables(
							permanent_address,
							permanent_city,
							permanent_area,
							permanent_zip
						) &&
							country &&
							`, ${country}`}
					</div>
				</div>
			)}
			{/* present_address end */}

			{/* permanent_address start */}
			{checkVariables(
				permanent_address,
				permanent_city,
				permanent_area,
				permanent_zip,
				same_address
			) && (
				<div className="personal__sub-header">
					<img src="/assets/icons/map-white.png" alt="icon" className="icon" />

					{same_address ? (
						<div className="content">
							<b>Permanent Address:</b> &nbsp; Same as above
						</div>
					) : (
						<div className="content">
							<b>Permanent Address:</b> &nbsp;
							{permanent_address}
							{permanent_address && permanent_city && `, ${permanent_city}`}
							{permanent_area && `, ${permanent_area}`}
							{permanent_zip && `, ${permanent_zip}`}
							{country && `, ${country}`}
						</div>
					)}
				</div>
			)}
			{/* permanent_address end */}

			{/* others start  */}
			{checkVariables(
				nationality,
				nid,
				place_of_birth,
				date_of_birth,
				driving_license
			) && (
				<div className="personal__sub-header">
					<img
						src="/assets/icons/others-white.png"
						alt="icon"
						className="icon"
					/>

					<div className="content">
						<b>Others</b>
					</div>
				</div>
			)}
			{/* others end */}

			<div className="others-wrapper" id="ml">
				{/* nationality start  */}
				{nationality && (
					<div className="personal__sub-header">
						<div className="content">
							<b>Nationality:</b> &nbsp; {nationality}
							{checkVariables(
								nid,
								driving_license,
								date_of_birth,
								place_of_birth
							) && ", "}
						</div>
					</div>
				)}
				{/* nationality end  */}

				{/* nid start  */}
				{nid && (
					<div className="personal__sub-header">
						<div className="content">
							<b>{isNaN(nid) ? "Passport" : "NID"}:</b> &nbsp; {nid}
							{checkVariables(driving_license, date_of_birth, place_of_birth) &&
								", "}
						</div>
					</div>
				)}
				{/* nid end  */}

				{/* driving_license start  */}
				{driving_license && (
					<div className="personal__sub-header">
						<div className="content">
							<b>Driving License:</b> &nbsp; {driving_license}
							{checkVariables(date_of_birth, place_of_birth) && ", "}
						</div>
					</div>
				)}
				{/* driving_license end  */}

				{/* date_of_birth start  */}
				{date_of_birth && (
					<div className="personal__sub-header">
						<div className="content">
							<b>Date Of Birth:</b> &nbsp; {date_of_birth}
							{checkVariables(place_of_birth) && ", "}
						</div>
					</div>
				)}
				{/* date_of_birth end  */}

				{/* place_of_birth start  */}
				{place_of_birth && (
					<div className="personal__sub-header">
						<div className="content">
							<b>Place Of Birth: </b> &nbsp;{place_of_birth}
						</div>
					</div>
				)}
				{/* place_of_birth end  */}
			</div>
		</div>
	) : (
		""
	);

	// web_links
	const web_links = !isArrayEmpty(web_link_items) ? (
		<div className="section">
			<div className="title">Follow Me</div>

			<div className="web__links_wrapper">
				{!isArrayEmpty(web_link_items) &&
					removeEmptyObjects(web_link_items, ["link_label", "link_url"]).map(
						(label, index) => {
							return (
								<div key={index} className="link__wrapper">
									<img
										src={`/assets/icons/${generateIconBlack(label.link_url)}`}
										alt="icon"
										className="link__img"
									/>

									<a
										href={label.link_url}
										target="_blank"
										rel="noopener noreferrer"
										className="link__url"
									>
										{label.link_label}
									</a>
								</div>
							);
						}
					)}
			</div>
		</div>
	) : (
		""
	);

	// skills
	const skills = !isArrayEmpty(skills_items) ? (
		<div className="section">
			<div className="title">{skills_title}</div>

			<div className="skill-wrapper">
				{skills_items.map((value, index) => {
					return (
						<SkillsLevel
							title_class="skill_title"
							level_class="skill_level"
							bg_color="#1A1A1A"
							skill={value.skill}
							skill_level={value.skill_level}
							key={index}
							displaying_level={displaying_level}
							end_bullet={true}
						/>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// languages
	const languages = !isArrayEmpty(languages_items) ? (
		<div className="section">
			<div className="title">{languages_title}</div>

			<div className="skill-wrapper">
				{languages_items.map((value, index) => {
					return (
						<SkillsLevel
							title_class="skill_title"
							level_class="skill_level"
							bg_color="#1A1A1A"
							skill={value.languages_name}
							skill_level={value.languages_level}
							key={index}
							end_bullet={true}
						/>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	//  hobbies
	const hobbies = hobbies_description ? (
		<div className="section">
			<div className="title">{hobbies_title}</div>

			{hobbies_description.split(",").map((value, index) => {
				return (
					<div key={index} className="hobbies-description">
						<div className="dash"> - </div>

						{value}
					</div>
				);
			})}
		</div>
	) : (
		""
	);

	//  disclaimer
	const disclaimer = disclaimer_description ? (
		<div className="section">
			<div className="title">{disclaimer_title}</div>

			<div className="item__sub_header mt-5">{disclaimer_description}</div>
		</div>
	) : (
		""
	);

	// for displaying additional section left start
	const additionalSectionLeft = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Courses") {
			storeAdditionalSection.push(courses);
		} else if (value.key === "Internship") {
			storeAdditionalSection.push(internship);
		}

		return storeAdditionalSection;
	});
	// for displaying additional section left end

	// for displaying additional section Right start
	const additionalSectionRight = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Extra-curricular Activities") {
			storeAdditionalSection.push(activities);
		} else if (value.key === "Custom") {
			customArr.map(
				(item) =>
					item.cus_section_id === value._id &&
					storeAdditionalSection.push(custom(item))
			);
		}

		return storeAdditionalSection;
	});
	// for displaying additional section Right end

	// left-section array
	const leftSection = [
		education,
		skills,
		languages,
		...additionalSectionLeft,
		hobbies,
		web_links
	];

	// right-section array
	const rightSection = [
		professional_summary,
		employment_history,
		projects,
		...additionalSectionRight,
		references,
		disclaimer
	];

	return (
		<>
			<style>{MarketingSpecialistStyle}</style>

			<div className="container">
				{/* name-job_title start  */}
				<div className="name-job-title-wrapper">
					{checkVariables(first_name, last_name) && (
						<div className="name">
							{first_name} &nbsp; {last_name}
						</div>
					)}

					{job_title && <div className="job-title">{job_title}</div>}
				</div>
				{/* name-job_title end */}

				{/* profile-contact start  */}
				<div className="profile-contact-wrapper">
					<div className="profile-container section__left">
						<div className="profile-wrapper">
							<img
								src={`/assets/profile-img/${
									cropped_profile_img
										? cropped_profile_img
										: "default-profile.png"
								}`}
								alt="profile-img"
								className="profile-img"
							/>
						</div>
					</div>

					<div className="contact-wrapper section__right">
						{personal_details}
					</div>
				</div>
				{/* profile-contact end */}

				<div className="body">
					{/* body__left start  */}
					<div className="section__left">
						{leftSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div key={index}>{value}</div>
							))}
					</div>
					{/* body__left end  */}

					{/* body__right start  */}
					<div className="section__right">
						{rightSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div key={index}>{value}</div>
							))}
					</div>
					{/* body__right end  */}
				</div>
			</div>
		</>
	);
};

export default MarketingSpecialist;
