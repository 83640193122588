// external components
import { memo, useEffect, useState } from "react";

// internal components
import LanguageLevel from "./LanguageLevel/LanguageLevel";
import { DeleteTooltip, FirstCol } from "../../common";

const LanguagesItem = ({
	itemValues,
	handleSetActive,
	contents,
	setDeleteItem,
	updateResumeInfo,
	delay
}) => {
	// languagesItem contents
	const { language, label, not_specific, deleteText } = contents.editor;

	// assign
	const _id = itemValues._id;

	// getting & updating links all values start
	const [inputValues, setInputValues] = useState(itemValues);

	const [latestUpdate, setLatestUpdate] = useState({});

	useEffect(() => {
		if (Object.keys(latestUpdate)[0]) {
			const timeoutId = setTimeout(() => {
				updateResumeInfo({ ...latestUpdate, _id });
			}, delay);

			return () => {
				clearTimeout(timeoutId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestUpdate]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setInputValues((prevValues) => ({
			...prevValues,
			[name]: value
		}));

		setLatestUpdate({
			[name]: value || null
		});
	};
	// getting & updating links all values end

	// collapse toggle
	const handleActivate = () => {
		handleSetActive(_id);
	};

	return (
		<>
			<div className="specific-item-container">
				<div className="specific-item-wrapper">
					<div className="content-title-wrapper" onClick={handleActivate}>
						<div className="content-title">
							<h4>
								{inputValues.languages_name
									? inputValues.languages_name
									: not_specific}{" "}
							</h4>
							{inputValues.languages_level && (
								<span>{inputValues.languages_level}</span>
							)}
						</div>

						<i className="fa-solid fa-chevron-up" id="up"></i>

						<i className="fa-solid fa-chevron-down" id="down"></i>
					</div>

					{/* for delete that item */}
					<DeleteTooltip
						content={deleteText}
						setDeleteItem={setDeleteItem}
						_id={_id}
					/>
				</div>

				<div className="row-container-wrapper">
					{/* Language & Level start  */}
					<div className="row-container">
						<FirstCol
							content={language}
							name={"languages_name"}
							value={inputValues.languages_name}
							onChange={handleInputChange}
							haveIm={true}
						/>

						<div className="second-col">
							<div className="sub-label">
								<label htmlFor="Level">{label}</label>
							</div>

							<div className="input-field">
								<LanguageLevel
									languages_level={inputValues.languages_level}
									setInputValues={setInputValues}
									updateResumeInfo={updateResumeInfo}
									_id={_id}
								/>
							</div>
						</div>
					</div>
					{/* Language & Level end  */}
				</div>
			</div>
		</>
	);
};

export default memo(LanguagesItem);
