// external components
import { NavLink } from "react-router-dom";

// internal components
import "./_SubFooter.scss";

const SubFooter = ({ contents }) => {
	// contents
	const { privacy_policy, contact_us } = contents.sub_footer;
	const { copy_right_text } = contents.footer;

	return (
		<>
			<div className="sub-footer-container">
				<div className="container">
					<div className="sub-footer-wrapper">
						<div className="sub-footer-left">
							<NavLink to={"/privacy-policy"} target="__blank">
								{privacy_policy}
							</NavLink>
							<span>I</span>
							<NavLink to={"/contact-us"} target="__blank">
								{contact_us}
							</NavLink>
						</div>

						<div className="sub-footer-right">
							<i
								className="fa-regular fa-copyright fa-spin"
								id="copy-right-icon"
							></i>
							&nbsp;
							<p dangerouslySetInnerHTML={{ __html: copy_right_text }}></p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SubFooter;
