// external components
import { useEffect, useRef, useState } from "react";
import sortArray from "sort-array";

// internal components
import "./CategoriesDropdown.css";

const CategoriesDropdown = ({
	setIsActiveCategory,
	getCategory,
	setCategory,
	categories,
	forWhat
}) => {
	const [categoryDrop, setCategoryDrop] = useState("");

	// for close dropdown when outside clicked start
	const myRef = useRef();

	const handleClickOutside = (e) => {
		if (!myRef.current?.contains(e.target) && categoryDrop) {
			setCategoryDrop(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryDrop]);
	// for close dropdown when outside clicked end

	return (
		<>
			<div
				className={
					categoryDrop && forWhat !== "delete"
						? "category-dropdown-container active"
						: "category-dropdown-container"
				}
				onClick={() => setCategoryDrop(!categoryDrop)}
				ref={myRef}
			>
				<input
					type="text"
					id="Level"
					placeholder="Select Category"
					onFocus={() => setIsActiveCategory(true)}
					onBlur={() => {
						setIsActiveCategory(false);
					}}
					value={getCategory}
					readOnly
				/>

				<div className="option" ref={myRef}>
					{categories?.length > 0 &&
						sortArray(categories, {
							by: "category"
						}).map((value, index) => {
							return (
								<div onClick={() => setCategory(value.category)} key={index}>
									<span>{value.category}</span>
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};

export default CategoriesDropdown;
