// external components

// internal components
import "./CoverLettersContainer.css";

const CoverLettersContainer = ({ updatingCounter, getCounter }) => {
	return (
		<>
			<div className="cover-letters-con">
				<div className="when-not-cover">
					<div className="when-not-cover-wrapper">
						<div className="for-img"></div>

						<h3>A cover letter to win hearts and minds</h3>
						<p>
							The perfect companion to your resume. Do what other candidates are
							missing – speak directly to the employer!
						</p>

						<button
							onClick={() =>
								updatingCounter({
									selectedTab: "Cover Letter",
									count: getCounter.cover_letter + 1
								})
							}
						>
							<i className="fa-solid fa-plus"></i> New Cover Letter
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default CoverLettersContainer;
