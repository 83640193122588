// external components
import { CircularProgress } from "@mui/material";
import {
	createContext,
	memo,
	useContext,
	useEffect,
	useRef,
	useState
} from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// internal components
const rootContext = createContext(null);

const EditorContextHandler = ({
	counter,
	selectedRTemplate,
	control,
	dispatchControl,
	children
}) => {
	const Navigate = useNavigate();

	// updating resume's delay
	const delay = 200;

	// getting current resume's data start
	const [getResume, setResume] = useState("");

	// userId
	const userId = useRef();

	// get current-resume
	const gettingCurrentResume = async () => {
		try {
			dispatchControl({ type: "start-loading" });

			const response = await fetch(
				`/resume?counter=${counter}&template=${selectedRTemplate}`
			);

			const result = await response.json();

			if (response.status === 200) {
				setResume(result);
				userId.current = result.user;
				dispatchControl({ type: "stop-loading" });
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "dark"
				});
				dispatchControl({ type: "stop-loading" });
				Navigate("/user-dashboard");
			}
		} catch (error) {
			dispatchControl({ type: "stop-loading" });
			Navigate("/user-dashboard");
		}
	};

	useEffect(() => {
		gettingCurrentResume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// getting current resume's data end

	// for updating resume's values start
	const updateResumeInfo = async (obj) => {
		try {
			dispatchControl({ type: "saving-start" });

			const response = await fetch(
				`/resume/update?user=${userId.current}&counter=${counter}`,
				{
					method: "POST",
					body: JSON.stringify(obj),
					headers: {
						"Content-Type": "application/json"
					}
				}
			);

			const result = await response.json();

			if (response.status === 200) {
				if (
					Object.keys(obj)[0] === "add_additional_section" &&
					result?.getAdditionalSection
				) {
					return result?.getAdditionalSection;
				} else {
					locallyUpdateResume(obj);
				}

				dispatchControl({ type: "saving-end" });
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				dispatchControl({ type: "saving-end" });
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
			dispatchControl({ type: "saving-end" });
		}
	};
	// for updating resume's values end

	// updateResume
	const locallyUpdateResume = (obj) => {
		const key = Object.keys(obj)[0];

		if (key === "do_not_ask_again") {
			setResume((prev) => ({ ...prev, do_not_ask_again: true }));
		}
		// assign personal_details
		else if (
			[
				"personal_details_title",
				"job_title",
				"first_name",
				"last_name",
				"email",
				"phone",
				"country",
				"nationality",
				"present_address",
				"present_city",
				"present_area",
				"present_zip",
				"same_address",
				"permanent_address",
				"permanent_city",
				"permanent_area",
				"permanent_zip",
				"driving_license",
				"nid",
				"place_of_birth",
				"date_of_birth"
			].includes(key)
		) {
			setResume((prev) => ({
				...prev,
				personal_details: [
					{
						...prev.personal_details[0],
						[key]: obj[key]
					}
				]
			}));
		}
		// assign professional_summary
		else if (
			["professional_summary_title", "rich_text_content"].includes(key)
		) {
			setResume((prev) => ({
				...prev,
				professional_summary: [
					{
						...prev.professional_summary[0],
						[key]: obj[key]
					}
				]
			}));
		}
		// assign employment-history
		else if (key === "employment_history_title") {
			setResume((prev) => ({
				...prev,
				employment_history: {
					...prev.employment_history,
					employment_history_title: obj[key]
				}
			}));
		} else if (
			[
				"employment_job_title",
				"employer",
				"start_date",
				"end_date",
				"employment_city",
				"employment_rich_text_content"
			].includes(key)
		) {
			setResume((prev) => ({
				...prev,
				employment_history: {
					...prev.employment_history,
					employment_history_items:
						prev.employment_history.employment_history_items.map((item) => {
							if (item._id === obj._id) {
								return {
									...item,
									[key]: obj[key]
								};
							}
							return item;
						})
				}
			}));
		}
		// assign education
		else if (key === "education_title") {
			setResume((prev) => ({
				...prev,
				education: {
					...prev.education,
					education_title: obj[key]
				}
			}));
		} else if (
			[
				"school",
				"degree",
				"edu_start_date",
				"edu_end_date",
				"edu_city",
				"edu_rich_text_content"
			].includes(key)
		) {
			setResume((prev) => ({
				...prev,
				education: {
					...prev.education,
					education_items: prev.education.education_items.map((item) => {
						if (item._id === obj._id) {
							return {
								...item,
								[key]: obj[key]
							};
						}
						return item;
					})
				}
			}));
		}
		// assign projects
		else if (key === "project_title") {
			setResume((prev) => ({
				...prev,
				projects: {
					...prev.projects,
					project_title: obj[key]
				}
			}));
		} else if (
			[
				"project_name",
				"project_duration",
				"technologies",
				"pro_description"
			].includes(key)
		) {
			setResume((prev) => ({
				...prev,
				projects: {
					...prev.projects,
					project_items: prev.projects.project_items.map((item) => {
						if (item._id === obj._id) {
							return {
								...item,
								[key]: obj[key]
							};
						}
						return item;
					})
				}
			}));
		} else if (["label", "link"].includes(key)) {
			setResume((prev) => ({
				...prev,
				projects: {
					...prev.projects,
					project_items: prev.projects.project_items.map((item) => {
						if (item._id === obj.parentId) {
							const updatedProjectLinks = item.project_links.map((link) => {
								if (link._id === obj._id) {
									return {
										...link,
										[key]: obj[key]
									};
								}
								return link;
							});

							return {
								...item,
								project_links: updatedProjectLinks
							};
						}
						return item;
					})
				}
			}));
		}
		// assign web_link
		else if (key === "web_link_title") {
			setResume((prev) => ({
				...prev,
				web_link: {
					...prev.web_link,
					web_link_title: obj[key]
				}
			}));
		} else if (["link_label", "link_url"].includes(key)) {
			setResume((prev) => ({
				...prev,
				web_link: {
					...prev.web_link,
					web_link_items: prev.web_link.web_link_items.map((item) => {
						if (item._id === obj._id) {
							return {
								...item,
								[key]: obj[key]
							};
						}
						return item;
					})
				}
			}));
		}

		// assign skill
		else if (["skills_title", "displaying_level"].includes(key)) {
			setResume((prev) => ({
				...prev,
				skills: {
					...prev.skills,
					[key]: obj[key]
				}
			}));
		} else if (["skill", "skill_level"].includes(key)) {
			setResume((prev) => ({
				...prev,
				skills: {
					...prev.skills,
					skills_items: prev.skills.skills_items.map((item) => {
						if (item._id === obj._id) {
							return {
								...item,
								[key]: obj[key]
							};
						}
						return item;
					})
				}
			}));
		}

		// assign hobbies
		else if (["hobbies_title", "hobbies_description"].includes(key)) {
			setResume((prev) => ({
				...prev,
				hobbies: [
					{
						...prev.hobbies[0],
						[key]: obj[key]
					}
				]
			}));
		}

		// assign courses
		else if (key === "courses_title") {
			setResume((prev) => ({
				...prev,
				courses: {
					...prev.courses,
					courses_title: obj[key]
				}
			}));
		} else if (
			[
				"course_name",
				"course_institution",
				"course_start_date",
				"course_end_date",
				"course_rich_text_content"
			].includes(key)
		) {
			setResume((prev) => ({
				...prev,
				courses: {
					...prev.courses,
					courses_items: prev.courses.courses_items.map((item) => {
						if (item._id === obj._id) {
							return {
								...item,
								[key]: obj[key]
							};
						}
						return item;
					})
				}
			}));
		}

		// assign activity
		else if (key === "activity_title") {
			setResume((prev) => ({
				...prev,
				activity: {
					...prev.activity,
					activity_title: obj[key]
				}
			}));
		} else if (
			[
				"activity_function",
				"activity_employer",
				"activity_start_date",
				"activity_end_date",
				"activity_city",
				"activity_rich_text_content"
			].includes(key)
		) {
			setResume((prev) => ({
				...prev,
				activity: {
					...prev.activity,
					activity_items: prev.activity.activity_items.map((item) => {
						if (item._id === obj._id) {
							return {
								...item,
								[key]: obj[key]
							};
						}
						return item;
					})
				}
			}));
		}

		// assign internship
		else if (key === "internship_title") {
			setResume((prev) => ({
				...prev,
				internship: {
					...prev.internship,
					internship_title: obj[key]
				}
			}));
		} else if (
			[
				"intern_job",
				"intern_employer",
				"intern_start_date",
				"intern_end_date",
				"intern_city",
				"intern_rich_text_content"
			].includes(key)
		) {
			setResume((prev) => ({
				...prev,
				internship: {
					...prev.internship,
					internship_items: prev.internship.internship_items.map((item) => {
						if (item._id === obj._id) {
							return {
								...item,
								[key]: obj[key]
							};
						}
						return item;
					})
				}
			}));
		}

		// assign language
		else if (key === "languages_title") {
			setResume((prev) => ({
				...prev,
				languages: {
					...prev.languages,
					languages_title: obj[key]
				}
			}));
		} else if (["languages_name", "languages_level"].includes(key)) {
			setResume((prev) => ({
				...prev,
				languages: {
					...prev.languages,
					languages_items: prev.languages.languages_items.map((item) => {
						if (item._id === obj._id) {
							return {
								...item,
								[key]: obj[key]
							};
						}
						return item;
					})
				}
			}));
		}

		// assign references
		else if (["references_title", "references_hide"].includes(key)) {
			setResume((prev) => ({
				...prev,
				references: {
					...prev.references,
					[key]: obj[key]
				}
			}));
		} else if (
			[
				"references_name",
				"references_company",
				"references_phone",
				"references_email"
			].includes(key)
		) {
			setResume((prev) => ({
				...prev,
				references: {
					...prev.references,
					references_items: prev.references.references_items.map((item) => {
						if (item._id === obj._id) {
							return {
								...item,
								[key]: obj[key]
							};
						}
						return item;
					})
				}
			}));
		}

		// assign custom
		else if (key === "custom_title") {
			setResume((prev) => {
				const updatedCustom = prev.custom.map((customItem) => {
					if (customItem.cus_section_id === obj._id) {
						return {
							...customItem,
							custom_title: obj[key]
						};
					}
					return customItem;
				});

				return {
					...prev,
					custom: updatedCustom
				};
			});
		} else if (
			[
				"custom_activity",
				"custom_city",
				"custom_start_date",
				"custom_end_date",
				"custom_rich_text_content"
			].includes(key)
		) {
			setResume((prev) => {
				const updatedCustom = prev.custom.map((customItem) => {
					if (customItem.cus_section_id === obj.cus_section_id) {
						const updatedCustomItems = customItem.custom_items.map(
							(customItemInner) => {
								if (customItemInner._id === obj._id) {
									return {
										...customItemInner,
										[key]: obj[key]
									};
								}
								return customItemInner;
							}
						);

						return {
							...customItem,
							custom_items: updatedCustomItems
						};
					}
					return customItem;
				});

				return {
					...prev,
					custom: updatedCustom
				};
			});
		}

		// assign disclaimer
		else if (["disclaimer_title", "disclaimer_description"].includes(key)) {
			setResume((prev) => ({
				...prev,
				disclaimer: [
					{
						...prev.disclaimer[0],
						[key]: obj[key]
					}
				]
			}));
		}
	};

	return (
		<>
			{control ? (
				<div className="loader-container" id="full-viewport">
					<CircularProgress />
				</div>
			) : (
				getResume && (
					<rootContext.Provider
						value={{
							getResume,
							userId: userId.current,
							setResume,
							gettingCurrentResume,
							updateResumeInfo,
							delay
						}}
					>
						{children}
					</rootContext.Provider>
				)
			)}
		</>
	);
};

export const EditorContextApi = () => {
	return useContext(rootContext);
};

export default memo(EditorContextHandler);
