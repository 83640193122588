// external components
import { createContext, useContext, useEffect, useState } from "react";

// internal components
import { english } from "../contents/english";
const rootContext = createContext(null);

const ContextHandler = ({ children }) => {
	// for getting current-user
	const [currentUser, setCurrentUser] = useState("");

	// for selected resume-templates
	const [selectedRTemplate, setSelectedRTemplate] = useState("");

	// language base project's contents
	const [contents, setContents] = useState(english);

	// define user's country
	const [userCountry, setUserCountry] = useState("");

	useEffect(() => {
		const fetchCountry = async () => {
			try {
				const response = await fetch("https://ipinfo.io?token=51262b3d41e3ec");
				const data = await response.json();

				setUserCountry(data.country.toUpperCase());
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchCountry();
	}, []);

	const freeTemplateName = process.env.REACT_APP_FREE_TEMPLATE.replace(
		/_/g,
		" "
	);

	return (
		<>
			{userCountry && (
				<rootContext.Provider
					value={{
						currentUser,
						setCurrentUser,
						selectedRTemplate,
						setSelectedRTemplate,
						contents,
						setContents,
						userCountry,
						freeTemplateName
					}}
				>
					{children}
				</rootContext.Provider>
			)}
		</>
	);
};

export const GetContextApi = () => {
	return useContext(rootContext);
};

export default ContextHandler;
