// external components
import { Avatar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// internal components
import "./_style.scss";

const OnImgPopupOption = ({ contents, currentUser }) => {
	// for hidden option when click outside start
	const [optionT, setOptionT] = useState(false);

	const offHandleOption = () => {
		setOptionT(false);
	};

	const myOption = useRef();

	const handleClickOutside = (e) => {
		if (!myOption.current?.contains(e.target) && optionT) {
			setOptionT(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionT]);
	// for hidden option when click outside end

	const path = useLocation().pathname.substring(1);

	// contents
	const {
		dashboard,
		dashboard_subtitle,

		account_settings,
		faq,
		sign_out
	} = contents.popup_option;

	return (
		<>
			<div
				className="img-options-container"
				id={path.includes("resume") ? "" : "for-navbar"}
				ref={myOption}
			>
				<Avatar
					src={
						currentUser.profile_img
							? currentUser.profile_img
							: "/assets/profile-img/default-profile.png"
					}
					className="img"
					alt="profile-img"
					onClick={() => setOptionT(true)}
				/>

				{optionT && (
					<div className="only-options-con" data-aos="fade-down">
						<ul>
							<Link to={"/user-dashboard"}>
								<div
									className="for-profile-dashboard"
									onClick={offHandleOption}
								>
									<i className="bi bi-house-door"></i>
									<div className="text-container">
										<span>{dashboard}</span>
										<span>{dashboard_subtitle}</span>
									</div>
								</div>
							</Link>

							<li onClick={offHandleOption} id="first-item">
								<Link to={"/user-profile"}>{"My Profile"}</Link>
							</li>

							<li onClick={offHandleOption}>
								<Link to={"/account-settings"}>{account_settings}</Link>
							</li>

							<li onClick={offHandleOption}>
								<Link to={"/faq"}>{faq}</Link>
							</li>

							<li onClick={offHandleOption}>
								<Link to={"/log-out"}>{sign_out}</Link>
							</li>
						</ul>
					</div>
				)}
			</div>
		</>
	);
};

export default OnImgPopupOption;
