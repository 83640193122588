// external components
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

// internal components
import TableSkeleton from "../AdminDashboardContents/TableSkeleton";
import "./_AdminMessages.scss";

const AdminMessages = () => {
	// for loading until fetching not complete
	const [isLoading, setIsLoading] = useState(false);

	// for getting all messages start
	const [allMessages, setAllMessages] = useState([]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const response = await fetch(`/refund/all-refund-request`);

				const result = await response.json();

				if (response.status === 200) {
					setAllMessages(result);

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		})();
	}, []);
	// for getting all package end

	// modal toggle start
	const [open, setOpen] = useState({
		create: false,
		update: false,
		delete: false
	});

	const handleClickOpen = (whichOne) => {
		setOpen({ ...open, [whichOne]: true });
	};

	const handleClose = () => {
		setOpen({
			create: false,
			update: false,
			delete: false
		});
	};
	// modal toggle end

	return (
		<>
			<Box
				sx={{
					padding: "10px 40px 0"
				}}
			>
				<Paper elevation={24} sx={{ borderRadius: "16px", paddingTop: "30px" }}>
					{/* header start  */}
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							paddingTop: "15px"
						}}
					>
						<Typography
							variant="h6"
							sx={{
								wordSpacing: "5px",
								paddingLeft: "20px",
								borderLeft: "5px solid #f30a5c",
								alignSelf: "center",
								opacity: 0.9,
								lineHeight: 1
							}}
						>
							Refund Requests
						</Typography>
					</Box>
					{/* header end  */}

					{/* table start  */}
					{isLoading ? (
						<TableSkeleton />
					) : (
						<div className="package-table-container">
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col" style={{ width: "5%" }}>
											#
										</th>

										<th scope="col">User Email</th>

										<th scope="col">Trans Id</th>

										<th scope="col">Purchase Date</th>

										<th scope="col">Submit Date</th>

										<th scope="col">Action</th>
									</tr>
								</thead>

								<tbody>
									{allMessages.length > 0 &&
										allMessages
											.map((value, index) => {
												return (
													<tr key={index}>
														<td id="id">
															<span>{index + 1}</span>
														</td>

														<td>
															<input readOnly value={value.user_email} />
														</td>

														<td>
															<input readOnly value={value.trans_id} />
														</td>

														<td>
															<input
																readOnly
																value={moment(
																	new Date(value.purchase_date)
																).format("DD-MM-YYYY")}
															/>
														</td>

														<td>
															<input
																readOnly
																value={moment(
																	new Date(value.submit_date)
																).format("DD-MM-YYYY")}
															/>
														</td>

														<td>
															<Box>
																<Tooltip title="Edit" aria-label="edit">
																	<IconButton
																		onClick={() => {
																			handleClickOpen("update");
																		}}
																	>
																		<EditNoteIcon />
																	</IconButton>
																</Tooltip>

																<Tooltip title="Delete" aria-label="delete">
																	<IconButton
																		onClick={() => {
																			handleClickOpen("delete");
																		}}
																	>
																		<DeleteIcon />
																	</IconButton>
																</Tooltip>
															</Box>
														</td>
													</tr>
												);
											})
											.reverse()}
								</tbody>
							</table>
						</div>
					)}
					{/* table end */}
				</Paper>
			</Box>
		</>
	);
};

export default AdminMessages;
