/* eslint-disable import/no-webpack-loader-syntax */
// internal components
import ProductDesignerStyle from "!!raw-loader!./UxDesigner.css";
import {
	RichTextParser,
	SectionTitle,
	SkillsLevelRound,
	Time
} from "../Components";
import {
	checkVariables,
	divideAndAddBrTag,
	fetchingResumeData,
	isArrayEmpty,
	objHasAnyValue,
	removeEmptyObjects
} from "../Components/Utils";

const UxDesigner = ({ getResume }) => {
	// fetching resume's all data
	const {
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		professional_summary_title,
		rich_text_content,
		employment_history_title,
		employment_history_items,
		education_title,
		education_items,
		project_title,
		project_items,
		web_link_items,
		skills_title,
		skills_items,
		displaying_level,
		languages_title,
		languages_items,
		hobbies_title,
		hobbies_description,
		activity_title,
		activity_items,
		internship_title,
		internship_items,
		courses_title,
		courses_items,
		references_title,
		references_hide,
		references_items,
		customArr,
		disclaimer_title,
		disclaimer_description
	} = fetchingResumeData(getResume);

	//  professional_summary
	const professional_summary = rich_text_content ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img src="/assets/icons/user.png" alt="icon" className="icon" />
				</div>

				<SectionTitle
					className="title"
					sectionName={professional_summary_title}
					equalTo="Professional Summary"
					initialName="Profile"
				/>
			</div>

			{rich_text_content && (
				<RichTextParser
					className={"rich_editor__container add-margin-t10"}
					rich_text={rich_text_content}
				/>
			)}
		</div>
	) : (
		""
	);

	//  employment_history
	const employment_history = !isArrayEmpty(employment_history_items) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img src="/assets/icons/suitcase.png" alt="icon" className="icon" />
				</div>

				<SectionTitle
					className="title"
					sectionName={employment_history_title}
					equalTo="Employment History"
					initialName="WORK EXPERIENCE"
				/>
			</div>

			{employment_history_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">
								<div className="item__header_left">
									{item.employment_job_title}
								</div>

								<div className="item__header_right">
									{item.employer}
									{item.employment_city && `, ${item.employment_city}`}
								</div>
							</div>

							<div className="sub__header">
								<div className="sub__header_left">
									{checkVariables(item.start_date, item.end_date) && (
										<Time start={item.start_date} end={item.end_date} />
									)}
								</div>

								<div className="sub__header_right">
									{item.employment_rich_text_content && (
										<RichTextParser
											className={"rich_editor__container"}
											rich_text={item.employment_rich_text_content}
										/>
									)}
								</div>
							</div>
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// projects
	const projects = !isArrayEmpty(project_items) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/project-icon.png"
						alt="icon"
						className="icon"
					/>
				</div>

				<SectionTitle
					className="title"
					sectionName={project_title}
					equalTo="Projects/Portfolio"
					initialName="Projects"
				/>
			</div>

			{project_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">
								<div className="item__header_left">{item.project_name}</div>

								<div className="item__header_right project_links">
									{!isArrayEmpty(item.project_links) &&
										removeEmptyObjects(item.project_links, [
											"label",
											"link"
										]).map((label, index) => {
											return (
												<div
													key={index}
													className={
														index === 0 ? "label label__first" : "label"
													}
												>
													<a
														href={label.link}
														target="_blank"
														rel="noopener noreferrer"
													>
														{label.label}
													</a>
												</div>
											);
										})}
								</div>
							</div>

							<div className="sub__header">
								<div className="sub__header_left">{item.project_duration}</div>

								<div className="sub__header_right">
									{item.technologies && (
										<div>
											<strong>Technologies&nbsp;:&nbsp;</strong>
											{item.technologies}
										</div>
									)}

									{item.pro_description && (
										<RichTextParser
											className={
												item.technologies
													? "rich_editor__container add-margin-t10"
													: "rich_editor__container"
											}
											rich_text={item.pro_description}
										/>
									)}
								</div>
							</div>
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// education
	const education = !isArrayEmpty(education_items) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img src="/assets/icons/graduate.png" alt="icon" className="icon" />
				</div>

				<div className="title">{education_title}</div>
			</div>

			{education_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">
								<div className="item__header_left">{item.degree}</div>

								<div className="item__header_right">
									{item.school}
									{item.edu_city && `, ${item.edu_city}`}
								</div>
							</div>

							<div className="sub__header">
								<div className="sub__header_left">
									{checkVariables(item.edu_start_date, item.edu_end_date) && (
										<Time start={item.edu_start_date} end={item.edu_end_date} />
									)}
								</div>

								<div className="sub__header_right">
									{item.edu_rich_text_content && (
										<RichTextParser
											className={"rich_editor__container"}
											rich_text={item.edu_rich_text_content}
										/>
									)}
								</div>
							</div>
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// internship
	const internship = !isArrayEmpty(internship_items) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img src="/assets/icons/internship.png" alt="icon" className="icon" />
				</div>

				<div className="title">{internship_title}</div>
			</div>

			{internship_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.intern_start_date,
						item.intern_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header-left">{item.intern_job}</div>

							{item.intern_employer && (
								<div className="sub__header-left">{item.intern_employer}</div>
							)}

							{checkVariables(item.intern_city, checkTime) && (
								<div className="item__sub_header_italic">
									{item.intern_city && `${item.intern_city}, `}

									{checkTime && (
										<Time
											start={item.intern_start_date}
											end={item.intern_end_date}
											fromWhere={"education"}
										/>
									)}
								</div>
							)}

							{item.intern_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container add-margin-t5"}
									rich_text={item.intern_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// courses
	const courses = !isArrayEmpty(courses_items) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/courses-black.png"
						alt="icon"
						className="icon"
					/>
				</div>

				<div className="title">{courses_title}</div>
			</div>

			{courses_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.course_start_date,
						item.course_end_date
					);

					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header-left">{item.course_name}</div>

							{item.course_institution && (
								<div className="sub__header-left">
									{item.course_institution}
								</div>
							)}

							{checkTime && (
								<div className="item__sub_header_italic">
									<Time
										start={item.course_start_date}
										end={item.course_end_date}
									/>
								</div>
							)}

							{item.course_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container add-margin-t5"}
									rich_text={item.course_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// extra curricular activities
	const activities = !isArrayEmpty(activity_items) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/activities-black.png"
						alt="icon"
						className="icon"
					/>
				</div>

				<div className="title">{activity_title}</div>
			</div>

			{activity_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="item__header">
								<div className="item__header_left">
									{item.activity_function}
								</div>

								<div className="item__header_right">
									{item.activity_employer}
									{item.activity_city && `, ${item.activity_city}`}
								</div>
							</div>

							<div className="sub__header">
								<div className="sub__header_left">
									{checkVariables(
										item.activity_start_date,
										item.activity_end_date
									) && (
										<Time
											start={item.activity_start_date}
											end={item.activity_end_date}
										/>
									)}
								</div>

								<div className="sub__header_right">
									{item.activity_rich_text_content && (
										<RichTextParser
											className={"rich_editor__container"}
											rich_text={item.activity_rich_text_content}
										/>
									)}
								</div>
							</div>
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// references
	const references = !isArrayEmpty(references_items) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/reference-black.png"
						alt="icon"
						className="icon"
					/>
				</div>

				<div className="title">{references_title}</div>
			</div>

			{references_hide && (
				<div className="add-margin-t10">
					<div className="item__header">Reference available upon request</div>
				</div>
			)}

			{!references_hide && (
				<div className="references-wrapper">
					{references_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div
									className={
										index === 0 || index % 2 === 0 ? "item item__first" : "item"
									}
									id="reference-item"
									key={index}
								>
									<div className="item__header">{item.references_name}</div>

									<div style={{ fontSize: "9.5px" }}>
										{item.references_company}
									</div>

									{checkVariables(
										item.references_phone,
										item.references_email
									) && (
										<div
											style={{ fontSize: "9.5px" }}
											className="refer-contact"
										>
											{item.references_phone}
											{item.references_phone && <br />}
											{item.references_email}
										</div>
									)}
								</div>
							);
						})}
				</div>
			)}
		</div>
	) : (
		""
	);

	// custom
	const custom = (specificCustom) =>
		!isArrayEmpty(specificCustom.custom_items) ? (
			<div className="section">
				<div className="title-wrapper">
					<div className="icon-wrapper">
						<img
							src="/assets/icons/custom-black.png"
							alt="icon"
							className="icon"
						/>
					</div>

					<div className="title">{specificCustom.custom_title}</div>
				</div>

				{specificCustom.custom_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item__header">
									<div className="item__header_left">
										{item.custom_activity}
									</div>

									<div className="item__header_right">{item.custom_city}</div>
								</div>

								<div className="sub__header">
									<div className="sub__header_left">
										{checkVariables(
											item.custom_start_date,
											item.custom_end_date
										) && (
											<Time
												start={item.custom_start_date}
												end={item.custom_end_date}
											/>
										)}
									</div>

									<div className="sub__header_right">
										{item.custom_rich_text_content && (
											<RichTextParser
												className={"rich_editor__container"}
												rich_text={item.custom_rich_text_content}
											/>
										)}
									</div>
								</div>
							</div>
						);
					})}
			</div>
		) : (
			""
		);

	//  personal_details
	const personal_details = checkVariables(
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth
	) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/contact-black.png"
						alt="icon"
						className="icon"
					/>
				</div>

				<div className="title">Contact</div>
			</div>

			<div className="personal-details-wrapper">
				{/* present_address start */}
				{checkVariables(
					present_address,
					present_city,
					present_area,
					present_zip
				) && (
					<div className="personal__sub-header">
						<div className="header">Present Address:</div>

						<div className="content">
							{present_address}

							{present_city && `, ${present_city}`}

							{present_area && `, ${present_area}`}

							{present_zip && `, ${present_zip}`}

							{!checkVariables(
								permanent_address,
								permanent_city,
								permanent_area,
								permanent_zip
							) &&
								country &&
								`, ${country}`}
						</div>
					</div>
				)}
				{/* present_address end */}

				{/* permanent_address start */}
				{checkVariables(
					permanent_address,
					permanent_city,
					permanent_area,
					permanent_zip,
					same_address
				) && (
					<div className="personal__sub-header">
						<div className="header">Permanent Address:</div>

						{same_address ? (
							<div className="content">Same as above</div>
						) : (
							<div className="content">
								{permanent_address}
								{permanent_city && `, ${permanent_city}`}

								{permanent_area && `, ${permanent_area}`}

								{permanent_zip && `, ${permanent_zip}`}

								{country && `, ${country}`}
							</div>
						)}
					</div>
				)}
				{/* permanent_address end */}

				{/* phone start  */}
				{phone && (
					<div className="personal__sub-header">
						<div className="header">Phone:</div>
						<div className="content">
							<a
								href={`tel:${phone}`}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "#000" }}
							>
								{phone}
							</a>
						</div>
					</div>
				)}
				{/* phone end  */}

				{/* email start  */}
				{email && (
					<div className="personal__sub-header">
						<div className="header">Email:</div>
						<div className="content">
							<a
								href={`mailto:${email}`}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: "#000" }}
								dangerouslySetInnerHTML={{ __html: divideAndAddBrTag(email) }}
							></a>
						</div>
					</div>
				)}
				{/* email end  */}

				{/* other start  */}
				{checkVariables(
					nationality,
					nid,
					place_of_birth,
					date_of_birth,
					driving_license
				) && (
					<div className="personal__sub-header">
						<div className="header">Others</div>
					</div>
				)}
				{/* other end */}

				{/* nationality start  */}
				{nationality && (
					<div className="personal__sub-header">
						<div className="content">Nationality : {nationality}</div>
					</div>
				)}
				{/* nationality end  */}

				{/* nid start  */}
				{nid && (
					<div className="personal__sub-header">
						<div className="content">
							{isNaN(nid) ? "Passport" : "NID"} : {nid}
						</div>
					</div>
				)}
				{/* nid end  */}

				{/* place_of_birth start  */}
				{place_of_birth && (
					<div className="personal__sub-header">
						<div className="content">Place Of Birth : {place_of_birth}</div>
					</div>
				)}
				{/* place_of_birth end  */}

				{date_of_birth && (
					<div className="personal__sub-header">
						<div className="content">Date Of Birth : {date_of_birth}</div>
					</div>
				)}

				{driving_license && (
					<div className="personal__sub-header">
						<div className="content">Driving License : {driving_license}</div>
					</div>
				)}
			</div>
		</div>
	) : (
		""
	);

	// web_links
	const web_links = !isArrayEmpty(web_link_items) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/reference-black.png"
						alt="icon"
						className="icon"
					/>
				</div>

				<div className="title">Follow Me</div>
			</div>

			<div className="web__links_wrapper">
				{!isArrayEmpty(web_link_items) &&
					removeEmptyObjects(web_link_items, ["link_label", "link_url"]).map(
						(label, index) => {
							return (
								<div key={index} className="link__wrapper">
									<img
										src={`/assets/icons/${
											(label.link_url.includes("youtube") &&
												"account-youtube.png") ||
											(label.link_url.includes("twitter") &&
												"account-twitter.png") ||
											(label.link_url.includes("linkedin") &&
												"account-linkedin.png") ||
											(label.link_url.includes("instagram") &&
												"account-instagram.png") ||
											(label.link_url.includes("facebook") &&
												"account-facebook.png") ||
											"internet.png"
										}`}
										alt="icon"
										className="link__img"
									/>

									<a
										href={label.link_url}
										target="_blank"
										rel="noopener noreferrer"
										className="link__url"
									>
										{label.link_label}
									</a>
								</div>
							);
						}
					)}
			</div>
		</div>
	) : (
		""
	);

	// skills
	const skills = !isArrayEmpty(skills_items) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img src="/assets/icons/skills.png" alt="icon" className="icon" />
				</div>

				<div className="title">{skills_title}</div>
			</div>

			<div className="skills-container">
				{skills_items.map((value, index) => {
					return (
						<div className="skill-wrapper" key={index}>
							<SkillsLevelRound
								title_class="skill_title"
								level_class="skill_level"
								bg_color="#1A1919"
								skill={value.skill}
								skill_level={value.skill_level}
								displaying_level={displaying_level}
							/>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// languages
	const languages = !isArrayEmpty(languages_items) ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img src="/assets/icons/languages.png" alt="icon" className="icon" />
				</div>

				<div className="title">{languages_title}</div>
			</div>

			<div className="skills-container">
				{languages_items.map((value, index) => {
					return (
						<div className="skill-wrapper" key={index}>
							<SkillsLevelRound
								title_class="skill_title"
								level_class="skill_level"
								bg_color="#1A1919"
								skill={value.languages_name}
								skill_level={value.languages_level}
							/>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	//  hobbies
	const hobbies = hobbies_description ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img src="/assets/icons/hobbies.png" alt="icon" className="icon" />
				</div>

				<div className="title">{hobbies_title}</div>
			</div>

			<div className="hobbies-description">
				{hobbies_description.split(",").map((value, index) => {
					return (
						<div
							key={index}
							className={
								index === 0 ? "hobby-item hobby-item__first" : "hobby-item"
							}
						>
							<div className="dash"> - </div>

							{value}
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	//  disclaimer
	const disclaimer = disclaimer_description ? (
		<div className="section">
			<div className="title-wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/disclaimer-black.png"
						alt="icon"
						className="icon"
					/>
				</div>

				<div className="title">{disclaimer_title}</div>
			</div>

			<div className="when-disclaimer">{disclaimer_description}</div>
		</div>
	) : (
		""
	);

	// for displaying additional section left start
	const additionalSectionLeft = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Courses") {
			storeAdditionalSection.push(courses);
		} else if (value.key === "Internship") {
			storeAdditionalSection.push(internship);
		}

		return storeAdditionalSection;
	});
	// for displaying additional section left end

	// for displaying additional section right start
	const additionalSectionRight = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Extra-curricular Activities") {
			storeAdditionalSection.push(activities);
		} else if (value.key === "Custom") {
			customArr.map(
				(item) =>
					item.cus_section_id === value._id &&
					storeAdditionalSection.push(custom(item))
			);
		}

		return storeAdditionalSection;
	});
	// for displaying additional section right end

	// left-section array
	const leftSection = [
		personal_details,
		skills,
		languages,
		...additionalSectionLeft,
		hobbies,
		web_links
	];

	// right-section array
	const rightSection = [
		professional_summary,
		employment_history,
		education,
		projects,
		...additionalSectionRight,
		references,
		disclaimer
	];

	return (
		<>
			<style>{ProductDesignerStyle}</style>

			<div className="container">
				{/* top_header start  */}
				<div className="top_header">
					<div className="top_header__left">
						{checkVariables(first_name, last_name) && (
							<div className="name">
								{first_name && first_name} &nbsp; {last_name && last_name}
							</div>
						)}
					</div>

					<div className="top_header__right">
						<div className="img-container">
							<img
								src={`/assets/profile-img/${
									cropped_profile_img
										? cropped_profile_img
										: "default-profile.png"
								}`}
								alt="profile-img"
								className="profile-img"
							/>
						</div>
					</div>
				</div>
				{/* top_header end  */}

				{job_title && (
					<div className="job-title-wrapper">
						<div className="job-title">{job_title}</div>
					</div>
				)}

				<div className="body">
					{/* body__left start  */}
					<div className="body__left">
						{leftSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div key={index}>{value}</div>
							))}
					</div>
					{/* body__left end  */}

					{/* body__right start  */}
					<div className="body__right">
						{rightSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div key={index}>{value}</div>
							))}
					</div>
					{/* body__right end  */}
				</div>
			</div>
		</>
	);
};

export default UxDesigner;
