// external components
import { useState } from "react";
import { toast } from "react-hot-toast";
import sortArray from "sort-array";

// internal components
import TableSkeleton from "../TableSkeleton";
import "./Category.css";

const Category = ({ isLoading, categories, setIsUpdateCategory }) => {
	// for add category toggle
	const [addNewT, setAddNewT] = useState(false);

	// for getting new category's value
	const [newCategoryValue, setNewCategoryValue] = useState("");

	// for active new category's input-fields toggle
	const [isActive, setIsActive] = useState(false);

	// for adding new category start
	const addNewCategory = async () => {
		try {
			const response = await fetch(`/category/add-new`, {
				method: "POST",
				body: JSON.stringify({ category: newCategoryValue }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				setIsUpdateCategory(Date.now());
				setNewCategoryValue("");
				setAddNewT(false);
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for adding new category end

	// for updating specific category start
	const [updateCategory, setUpdateCategory] = useState("");

	const updateSpecificCategory = async () => {
		try {
			const response = await fetch(`/category/update-specific-category`, {
				method: "PUT",
				body: JSON.stringify({
					category: updateCategory.category,
					_id: updateCategory._id
				}),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				setIsUpdateCategory(Date.now());
				setUpdateCategory("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for updating specific category end

	// for deleting specific category start
	const [deleteCategory, setDeleteCategory] = useState("");

	const deleteCategoryHandler = async () => {
		try {
			const response = await fetch(`/category/delete-category`, {
				method: "DELETE",
				body: JSON.stringify({ _id: deleteCategory._id }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				setIsUpdateCategory(Date.now());
				setDeleteCategory("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for deleting specific category end

	return (
		<>
			<div className="category-container">
				{/* categories list start  */}
				<div className="categories-list">
					<div className="header">
						<h5>All Categories</h5>

						<button
							type="button"
							className="btn"
							onClick={() => setAddNewT(true)}
						>
							<i className="fa-solid fa-plus"></i> Add New Category
						</button>
					</div>
					{isLoading ? (
						<TableSkeleton />
					) : (
						<div className="table-container">
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col">#</th>

										<th scope="col">Category</th>

										<th scope="col">Action</th>
									</tr>
								</thead>
								<tbody>
									{categories.length > 0 &&
										sortArray(categories, {
											by: "category"
										}).map((value, index) => {
											return (
												<tr key={index}>
													<td id="id">
														<span>{index + 1}</span>
													</td>

													<td>
														<input readOnly value={value?.category} />
													</td>

													<td>
														<div className="btn-container">
															<button
																className="btn"
																onClick={() => {
																	setUpdateCategory({
																		category: value.category,
																		_id: value._id
																	});
																}}
															>
																<i className="fa-solid fa-pencil"></i> Edit
															</button>

															<button
																className="btn"
																onClick={() =>
																	setDeleteCategory({
																		category: value.category,
																		_id: value._id
																	})
																}
															>
																<i className="fa-solid fa-trash-can"></i> Delete
															</button>
														</div>
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					)}
				</div>
				{/* categories list end  */}

				{/* for add new category start  */}
				{addNewT && !updateCategory && !deleteCategory && (
					<div className="add-new-category-container">
						<div className="add-new-category-wrapper" data-aos="fade-down">
							<div className="top-section">
								<h3>Add New Category</h3>
							</div>

							<div className="input-field" id={isActive ? "active" : ""}>
								<input
									onFocus={() => setIsActive(true)}
									onBlur={() => {
										setIsActive(false);
									}}
									onChange={(e) => setNewCategoryValue(e.target.value)}
									value={newCategoryValue}
									placeholder="Category  . . ."
								/>
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setAddNewT(false);
										setNewCategoryValue("");
									}}
								>
									Cancel
								</button>
								<button onClick={() => newCategoryValue && addNewCategory()}>
									Submit
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for add new category  end */}

				{/* for update specific category start  */}
				{updateCategory && (
					<div className="add-new-category-container">
						<div className="add-new-category-wrapper" data-aos="fade-down">
							<div className="top-section">
								<h3>Update A Category</h3>
							</div>

							<div className="input-field" id={isActive ? "active" : ""}>
								<input
									onFocus={() => setIsActive(true)}
									onBlur={() => {
										setIsActive(false);
									}}
									onChange={(e) =>
										setUpdateCategory({
											category: e.target.value,
											_id: updateCategory._id
										})
									}
									value={updateCategory.category}
									placeholder="Category  . . ."
								/>
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setUpdateCategory("");
									}}
								>
									Cancel
								</button>
								<button
									onClick={() =>
										updateCategory.category && updateSpecificCategory()
									}
								>
									Update
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for update specific category end */}

				{/* for deleting specific category start  */}
				{deleteCategory && (
					<div className="add-new-category-container">
						<div className="add-new-category-wrapper" data-aos="fade-down">
							<div className="top-section">
								<h3>Delete A Category</h3>
							</div>

							<div className="input-field">
								<input value={deleteCategory.category} readOnly />
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setDeleteCategory("");
									}}
								>
									Cancel
								</button>
								<button
									onClick={() =>
										deleteCategory.category && deleteCategoryHandler()
									}
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for deleting specific category end */}
			</div>
		</>
	);
};

export default Category;
