// external components

// internal components

const InputField = ({
	name,
	value,
	onChange,
	labelName,
	type,
	placeholder,
	haveIm
}) => {
	return (
		<div className="input-field">
			<label htmlFor={name} className="label">
				{labelName ? labelName : name.replace(/_/g, " ")}
				{haveIm && <span id="important">*</span>}
			</label>

			<input
				type={type}
				id={name}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder ? placeholder : ""}
				className="input"
			/>
		</div>
	);
};

export default InputField;
