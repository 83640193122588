// external components
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

// internal components
import OnImgPopupOption from "../../common/OnImgPopupOptions";
import { bangla } from "../../contents/bangla";
import { english } from "../../contents/english";
import { GetContextApi } from "../../storage/ContextApi";
import "./Navbar.css";
import { Viewport } from "./Viewport";

const Navbar = ({ fromWhere }) => {
	// for getting currentUser & contents & userCountry
	const { currentUser, contents, setContents, userCountry } = GetContextApi();

	// for checking mobile view-port achieve or not
	const isMobile = Viewport("(max-width:576px)", true);

	// for hidden navbar
	const [isHiddenNav, setIsHiddenNav] = useState(false);

	// for hidden navbar when click outside start
	const myRef = useRef();

	const handleClickOutside = (e) => {
		if (!myRef.current?.contains(e.target) && isHiddenNav) {
			setIsHiddenNav(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for hidden navbar when click outside end

	// navbar contents
	const {
		home,
		resume,
		about_us,
		contact_us,
		sign_in,
		sign_up,
		my_account,
		upgrade_now
	} = contents.navbar;
	const { blog } = contents.footer;

	return (
		<>
			{/* language start  */}
			<div
				className={
					userCountry === "BD"
						? "nav-language-con d-block"
						: "nav-language-con d-none"
				}
			>
				<div className="container">
					<div className="row">
						<div className="col-11 col-sm-12 language-wrapper">
							<h6
								onClick={() => setContents(english)}
								id={contents.title === "english" ? "active" : ""}
							>
								English
							</h6>
							<h6>|</h6>
							<h6
								onClick={() => setContents(bangla)}
								id={contents.title === "bangla" ? "active" : ""}
							>
								বাংলা
							</h6>
						</div>
					</div>
				</div>
			</div>
			{/* language end  */}

			<div className="main-navbar-container">
				<div className="container">
					<div className="row navbar-container">
						<div className="col-11 col-sm-12 navbar-wrapper" ref={myRef}>
							{/* left-side start  */}
							<div
								className="project-logo"
								id={isMobile && isHiddenNav ? "not-display" : ""}
								onClick={() => {
									setIsHiddenNav(false);
								}}
							>
								<NavLink to={"/"}>
									<img
										src="/assets/images/main-logo.svg"
										alt="main-logo"
										className="img-fluid main-logo"
									/>
								</NavLink>
							</div>
							{/* left-side end */}

							{/* middle-side start  */}
							<div
								className={isHiddenNav ? "nav-links nav-active" : "nav-links"}
							>
								{/* desktop ul start  */}
								<ul className="ul-desktop">
									<li>
										<NavLink className="navlink" to={"/"}>
											{home}
										</NavLink>
									</li>

									<li>
										<NavLink className="navlink" to={"/resume-templates"}>
											{resume}
										</NavLink>
									</li>

									{/* <li>
								<span id="dropdown" onClick={() => setIsActiveItem("")}> when cover-letter
									COVER LETTER
								</span>
							</li> */}

									<li>
										<NavLink className="navlink" to={"/about-us"}>
											{about_us}
										</NavLink>
									</li>

									<li>
										<NavLink className="navlink" to={"/contact-us"}>
											{contact_us}
										</NavLink>
									</li>

									{/* <li>
										<a href="https://blog.cvwave.com" className="navlink">
											{blog}
										</a>
									</li> */}
								</ul>
								{/* desktop ul end  */}

								{/* responsive ul start  */}
								<ul>
									<li className={isHiddenNav ? "ani-link-1" : null}>
										<NavLink
											onClick={() => {
												setIsHiddenNav(!isHiddenNav);
											}}
											className="navlink"
											to={"/"}
										>
											{home}
										</NavLink>
									</li>

									<li className={isHiddenNav ? "ani-link-2" : null}>
										<NavLink
											onClick={() => {
												setIsHiddenNav(!isHiddenNav);
											}}
											className="navlink"
											to={"/resume-templates"}
										>
											{resume}
										</NavLink>
									</li>

									<li className={isHiddenNav ? "ani-link-3" : null}>
										<NavLink
											onClick={() => {
												setIsHiddenNav(!isHiddenNav);
											}}
											className="navlink"
											to={"/about-us"}
										>
											{about_us}
										</NavLink>
									</li>

									<li className={isHiddenNav ? "ani-link-4" : null}>
										<NavLink
											onClick={() => {
												setIsHiddenNav(!isHiddenNav);
											}}
											className="navlink"
											to={"/contact-us"}
										>
											{contact_us}
										</NavLink>
									</li>

									{/* <li className={isHiddenNav ? "ani-link-5" : null}>
										<a href="https://blog.cvwave.com" className="navlink">
											{blog}
										</a>
									</li> */}

									{(!currentUser ||
										["log-in", "sign-up"].includes(fromWhere)) && (
										<li className={isHiddenNav ? "ani-link-6" : null}>
											<NavLink
												onClick={() => {
													setIsHiddenNav(!isHiddenNav);
												}}
												className="navlink"
												to={"/log-in"}
											>
												{sign_in}
											</NavLink>
										</li>
									)}

									{(!currentUser ||
										["log-in", "sign-up"].includes(fromWhere)) && (
										<li className={isHiddenNav ? "ani-link-7" : null}>
											<NavLink
												onClick={() => {
													setIsHiddenNav(!isHiddenNav);
												}}
												className="navlink"
												to={"/sign-up"}
											>
												{sign_up}
											</NavLink>
										</li>
									)}
								</ul>
								{/* responsive ul end  */}
							</div>
							{/* middle-side end */}

							{/* right-side start  */}
							<div
								className="authentication"
								id={isMobile && isHiddenNav ? "" : "active"}
							>
								{currentUser?.password &&
								![
									"user-dashboard",
									"account-settings",
									"user-profile",
									"log-in",
									"sign-up"
								].includes(fromWhere) ? (
									<div
										className="my-account-btn"
										id={isMobile && isHiddenNav ? "when-mobile" : ""}
										onClick={() => {
											setIsHiddenNav(false);
										}}
									>
										{contents && (
											<NavLink to={"/user-dashboard"}>
												<button className="primary-btn-styles">
													{my_account}
												</button>
											</NavLink>
										)}
									</div>
								) : currentUser &&
								  [
										"user-dashboard",
										"account-settings",
										"user-profile"
								  ].includes(fromWhere) ? (
									<div
										className="upgrade"
										id={isMobile && isHiddenNav ? "when-mobile" : ""}
									>
										{contents && (
											<NavLink to={"../subscription"}>
												<button className="primary-btn-styles">
													<i className="fa-solid fa-crown"></i> {upgrade_now}
												</button>
											</NavLink>
										)}

										<OnImgPopupOption
											contents={contents}
											currentUser={currentUser}
										/>
									</div>
								) : (
									<div className="authentication-wrapper">
										<NavLink to={"/log-in"} className="auth-link sign-in">
											<i className="fa-regular fa-user" id="sign-in-icon"></i>
											{sign_in}
										</NavLink>

										<NavLink
											to={"/sign-up"}
											className="auth-link primary-btn-styles"
										>
											{sign_up}
										</NavLink>
									</div>
								)}
							</div>
							{/* right-side end */}

							{/* for menu-bar icon start  */}
							<div
								className={
									(isMobile && isHiddenNav && !currentUser) ||
									(isMobile &&
										isHiddenNav &&
										["log-in", "sign-up"].includes(fromWhere))
										? "burger display-logo"
										: "burger"
								}
								id={isMobile && isHiddenNav ? "when-mobile" : ""}
								onClick={() => {
									setIsHiddenNav(!isHiddenNav);
								}}
							>
								{((isMobile && isHiddenNav && !currentUser) ||
									(isMobile &&
										isHiddenNav &&
										["log-in", "sign-up"].includes(fromWhere))) && (
									<NavLink to={"/"}>
										<img
											src="/assets/images/footer-project-logo.svg"
											alt="main-logo"
											className="img-fluid main-logo"
										/>
									</NavLink>
								)}

								<div className="line-container">
									<div className={isHiddenNav ? "line-1" : null}></div>
									<div className={isHiddenNav ? "line-2" : null}></div>
									<div className={isHiddenNav ? "line-3" : null}></div>
								</div>
							</div>
							{/* for menu-bar icon end */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Navbar;
