// external components

// internal components
import { GetContextApi } from "../../storage/ContextApi";
import "./Features.css";

const Features = () => {
	// features contents
	const { contents } = GetContextApi();

	const {
		title,
		feature_1_heading,
		feature_1_description,
		feature_2_heading,
		feature_2_description,
		feature_3_heading,
		feature_3_description,
		feature_4_heading,
		feature_4_description
	} = contents.features;

	return (
		<>
			<div className="features-main-container">
				<div className="container">
					<div className="features-container row">
						<div className="col-11 col-sm-12 features-wrapper">
							<div className="features-header" data-aos="fade-up">
								<h3>{title}</h3>
							</div>

							{/* card-container start  */}
							<div className="card-container">
								{/* .card-1 start  */}
								<div className="card-box">
									<img src="/assets/icons/feature-1.png" alt="feature-icon" />

									<h5>{feature_1_heading}</h5>

									<p>{feature_1_description}</p>
								</div>
								{/* .card-1 end  */}

								{/* .card-2 start  */}
								<div className="card-box">
									<img src="/assets/icons/feature-2.png" alt="feature-icon" />

									<h5>{feature_2_heading}</h5>

									<p>{feature_2_description}</p>
								</div>
								{/* .card-2 end  */}

								{/* .card-3 start  */}
								<div className="card-box">
									<img src="/assets/icons/feature-3.png" alt="feature-icon" />

									<h5>{feature_3_heading}</h5>

									<p>{feature_3_description}</p>
								</div>
								{/* .card-3 end  */}

								{/* .card-4 start  */}
								<div className="card-box">
									<img src="/assets/icons/feature-4.png" alt="feature-icon" />

									<h5>{feature_4_heading}</h5>

									<p>{feature_4_description}</p>
								</div>
								{/* .card-4 end  */}
							</div>
							{/* card-container end  */}
						</div>

						{/* animation shape start  */}
						<div className="section-shapes">
							<div className="shape">
								<svg
									className="circle-1 svg-item inline shape-zoom"
									id="Layer_3"
									data-name="Layer 3"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 62 62"
								>
									<defs></defs>
									<path
										style={{
											fill: "none",
											stroke: "#e7e8fc",
											strokeMiterlimit: "10",
											strokeWidth: "10px",
											fillRule: "evenodd"
										}}
										d="M332,620a26,26,0,1,1-26,26A26,26,0,0,1,332,620Z"
										transform="translate(-301 -615)"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="circle-2 svg-item inline shape-zoom"
									id="Layer_3"
									data-name="Layer 3"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 62 62"
								>
									<defs></defs>
									<path
										style={{
											fill: "none",
											stroke: "#e7e8fc",
											strokeMiterlimit: "10",
											strokeWidth: "10px",
											fillRule: "evenodd"
										}}
										d="M332,620a26,26,0,1,1-26,26A26,26,0,0,1,332,620Z"
										transform="translate(-301 -615)"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="circle-3 svg-item inline shape-zoom"
									id="Layer_3"
									data-name="Layer 3"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 62 62"
								>
									<defs></defs>
									<path
										style={{
											fill: "none",
											stroke: "#e7e8fc",
											strokeMiterlimit: "10",
											strokeWidth: "10px",
											fillRule: "evenodd"
										}}
										d="M332,620a26,26,0,1,1-26,26A26,26,0,0,1,332,620Z"
										transform="translate(-301 -615)"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="triangle-2 svg-item inline shape-rotate"
									id="Layer_4"
									data-name="Layer 4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 33.83 33.83"
								>
									<defs></defs>
									<title></title>
									<path
										style={{
											fill: "none",
											stroke: "#d3e1fd",
											strokeMiterlimit: "10",
											strokeWidth: "4px",
											fillRule: "evenodd"
										}}
										d="M895,191v27H868Z"
										transform="translate(-863.17 -186.17)"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="rectangle svg-item inline shape-rotate"
									id="Layer_5"
									data-name="Layer 5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 56.3 56.3"
								>
									<defs></defs>
									<title></title>
									<path
										style={{
											fill: "none",
											stroke: "#ffd3d8",
											strokeMiterlimit: "10",
											strokeWidth: "5px",
											fillRule: "evenodd"
										}}
										d="M766.87,597.9l22.23-27,27,22.23-22.23,27Z"
										transform="translate(-763.35 -567.35)"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item square-1 inline shape-rotate"
									version="1.1"
									id="Layer_38"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 36.65 36.65"
									style={{ enableBackground: "new 0 0 36.65 36.65" }}
								>
									<rect
										x="2.5"
										y="2.5"
										style={{
											fill: "transparent",
											stroke: "#eaedfe",
											strokeWidth: "5px",
											strokeLinecap: "round",
											strokeLinejoin: "round",
											strokeMiterlimit: 10
										}}
										width="31"
										height="31"
									></rect>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item half-circle inline shape-rotate"
									version="1.1"
									id="Layer_34"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 36.68 20.91"
									style={{ enableBackground: "new 0 0 36.68 20.91" }}
								>
									<path
										style={{
											fill: "none",
											stroke: "#f7e9f3",
											strokeWidth: "5",
											strokeLinecap: "round",
											strokeLinejoin: "round",
											strokeMiterlimit: 10
										}}
										d="M2.5,18.41C2.5,9.62,9.59,2.5,18.34,2.5c8.75,0,15.84,7.12,15.84,15.91"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item cross-1 inline shape-rotate"
									version="1.1"
									id="Layer_43"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 20.25 20.25"
									style={{ enableBackground: "new 0 0 20.25 20.25" }}
								>
									<g>
										<line
											style={{
												fill: "#EBF0FD",
												stroke: "#e6ebfd",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											x1="17.75"
											y1="17.28"
											x2="2.5"
											y2="2.97"
										></line>
										<line
											style={{
												fill: "#EBF0FD",
												stroke: "#e6ebfd",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											x1="2.97"
											y1="17.75"
											x2="17.28"
											y2="2.5"
										></line>
									</g>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item cross-2 inline shape-rotate"
									version="1.1"
									id="Layer_43"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 20.25 20.25"
									style={{ enableBackground: "new 0 0 20.25 20.25" }}
								>
									<g>
										<line
											style={{
												fill: "#EBF0FD",
												stroke: "#e6ebfd",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											x1="17.75"
											y1="17.28"
											x2="2.5"
											y2="2.97"
										></line>
										<line
											style={{
												fill: "#EBF0FD",
												stroke: "#e6ebfd",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											x1="2.97"
											y1="17.75"
											x2="17.28"
											y2="2.5"
										></line>
									</g>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item snake-1 inline shape-fade"
									version="1.1"
									id="Layer_41"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 63.14 17.25"
									style={{ enableBackground: "new 0 0 63.14 17.25" }}
								>
									<g>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M17.04,14.75C10.13,14.75,11.32,2.5,2.5,2.5"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M46.11,14.75c-6.91,0-5.72-12.25-14.54-12.25"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M17.04,14.75c6.91,0,5.72-12.25,14.54-12.25"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M60.64,2.5"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M46.11,14.75c6.91,0,5.72-12.25,14.54-12.25"
										></path>
									</g>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item snake-2 inline shape-fade"
									version="1.1"
									id="Layer_41"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 63.14 17.25"
									style={{ enableBackground: "new 0 0 63.14 17.25" }}
								>
									<g>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M17.04,14.75C10.13,14.75,11.32,2.5,2.5,2.5"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M46.11,14.75c-6.91,0-5.72-12.25-14.54-12.25"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M17.04,14.75c6.91,0,5.72-12.25,14.54-12.25"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M60.64,2.5"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M46.11,14.75c6.91,0,5.72-12.25,14.54-12.25"
										></path>
									</g>
								</svg>
							</div>
						</div>
						{/* animation shape end */}
					</div>
				</div>
			</div>
		</>
	);
};

export default Features;
