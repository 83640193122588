// external components

// internal components
import PageHeader from "../../common/PageHeader";
import { GetContextApi } from "./../../storage/ContextApi";
import "./TermsConditions.css";

const TermsConditions = () => {
	// contents
	const { contents } = GetContextApi();

	const {
		title,
		time,
		welcome,
		reference,
		services,
		client_refer,
		access_txt,
		warning_txt,
		section_1_title,
		section_1_des,

		section_2_title,
		section_2_des,

		section_3_title,
		section_3_des,

		section_4_title,
		section_4_des,

		section_5_title,
		section_5_des,

		section_6_title,
		section_6_des_1,
		section_6_des_2,
		section_6_des_li,

		section_7_title,
		section_7_des,

		section_8_title,
		section_8_des,

		section_9_title,
		section_9_des_1,
		section_9_des_li,
		section_9_des_2,

		section_10_title,

		section_101_title,
		section_101_des,

		section_101_a_title,
		section_101_a_des,

		section_102_title,
		section_102_des,

		section_103_title,
		section_103_des_li,

		section_11_title,
		section_11_des,

		section_12_title,
		section_12_des,

		section_13_title,
		section_13_des,

		section_14_title,

		section_141_title,
		section_141_des,

		section_142_title,
		section_142_1_des,
		section_142_li,
		section_142_2_des,

		section_143_title,
		section_143_des,
		section_143_li,

		section_144_title,
		section_144_des,

		section_15_title,
		section_15_des,

		section_16_title,

		section_16_1_title,
		section_16_1_des,

		section_16_2_title,
		section_16_2_des,

		section_16_3_title,
		section_16_3_des,

		section_17_title,
		section_17_des,
		section_17_des_li
	} = contents.terms_condition;

	return (
		<>
			<PageHeader
				pageName={
					contents.title === "bangla"
						? "নিয়ম ও শর্তাবলী"
						: "TERMS AND CONDITION"
				}
				link={
					contents.title === "bangla"
						? "নিয়ম ও শর্তাবলী"
						: "Terms-And-Conditions"
				}
				whichLanguage={contents.title}
			/>

			{/* contents-section start  */}
			<div className="container">
				<div className="terms-conditions-container row">
					<div className="terms-conditions-wrapper col-11 col-sm-12">
						{/* title  */}
						<h4 id="top-title">{title}</h4>

						{/* welcome message start  */}
						<div className="content margin-top-20">
							{/* time  */}
							<div>{time}</div>
							<div dangerouslySetInnerHTML={{ __html: welcome }}></div>
						</div>

						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: reference }}
						></div>

						<div
							className="content"
							dangerouslySetInnerHTML={{ __html: services }}
						></div>

						<div className="content">{client_refer}</div>

						<div className="content">{access_txt}</div>
						{/* welcome message end  */}

						{/* warning message start  */}
						<h5 id="warning">{warning_txt}</h5>
						{/* warning message end  */}

						{/* lists of terms & conditions start  */}
						<ol
							style={{
								listStyleType:
									contents.title === "bangla" ? "bengali" : "decimal"
							}}
						>
							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_1_title}</h5>

								<div
									className="content"
									dangerouslySetInnerHTML={{ __html: section_1_des }}
								></div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_2_title}</h5>

								<div
									className="content"
									dangerouslySetInnerHTML={{ __html: section_2_des }}
								></div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_3_title}</h5>

								<div
									className="content"
									dangerouslySetInnerHTML={{ __html: section_3_des }}
								></div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_4_title}</h5>

								{section_4_des.map((value, index) => (
									<div
										className="content"
										key={index}
										dangerouslySetInnerHTML={{ __html: value }}
									></div>
								))}
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_5_title}</h5>

								<div
									className="content"
									dangerouslySetInnerHTML={{ __html: section_5_des }}
								></div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_6_title}</h5>

								<div
									className="content"
									dangerouslySetInnerHTML={{ __html: section_6_des_1 }}
								></div>

								<div className="content">
									{section_6_des_2}
									<ul>
										{section_6_des_li.map((value, index) => (
											<li
												key={index}
												dangerouslySetInnerHTML={{ __html: value }}
											></li>
										))}
									</ul>
								</div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_7_title}</h5>

								<div
									className="content"
									dangerouslySetInnerHTML={{ __html: section_7_des }}
								></div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_8_title}</h5>

								<div
									className="content"
									dangerouslySetInnerHTML={{ __html: section_8_des }}
								></div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_9_title}</h5>

								<div className="content">
									{section_9_des_1}
									<ul>
										{section_9_des_li.map((value, index) => (
											<li
												key={index}
												dangerouslySetInnerHTML={{ __html: value }}
											></li>
										))}
									</ul>
								</div>

								<div className="content">{section_9_des_2}</div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_10_title}</h5>

								<ul>
									<li className="mt-1">
										<h6>{section_101_title}</h6>

										<div
											className="content"
											dangerouslySetInnerHTML={{ __html: section_101_des }}
										></div>
									</li>

									<li className="margin-top-20">
										<h6>{section_101_a_title}</h6>

										<div
											className="content"
											dangerouslySetInnerHTML={{ __html: section_101_a_des }}
										></div>
									</li>

									<li className="margin-top-20">
										<h6>{section_102_title}</h6>

										<div
											className="content"
											dangerouslySetInnerHTML={{ __html: section_102_des }}
										></div>
									</li>

									<li className="margin-top-20">
										<h6>{section_103_title}</h6>

										{section_103_des_li.map((value, index) => (
											<div
												className="content"
												key={index}
												dangerouslySetInnerHTML={{ __html: value }}
											></div>
										))}
									</li>
								</ul>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_11_title}</h5>

								{section_11_des.map((value, index) => (
									<div
										className="content"
										key={index}
										dangerouslySetInnerHTML={{ __html: value }}
									></div>
								))}
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_12_title}</h5>

								<div
									className="content"
									dangerouslySetInnerHTML={{ __html: section_12_des }}
								></div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_13_title}</h5>

								<div className="content">{section_13_des}</div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_14_title}</h5>

								<ul>
									<li className="mt-1">
										<h6>{section_141_title}</h6>

										<div className="content">{section_141_des}</div>
									</li>

									<li className="margin-top-20">
										<h6>{section_142_title}</h6>

										<div
											className="content"
											dangerouslySetInnerHTML={{ __html: section_142_1_des }}
										></div>

										<div className="content" style={{ marginTop: "-10px" }}>
											<ul>
												{section_142_li.map((value, index) => (
													<li
														key={index}
														dangerouslySetInnerHTML={{ __html: value }}
													></li>
												))}
											</ul>
										</div>

										<div className="content" style={{ marginTop: "-10px" }}>
											{section_142_2_des}
										</div>
									</li>

									<li className="margin-top-20">
										<h6>{section_143_title}</h6>

										<div className="content">
											{section_143_des}
											<ul>
												{section_143_li.map((value, index) => (
													<li
														key={index}
														dangerouslySetInnerHTML={{ __html: value }}
													></li>
												))}
											</ul>
										</div>
									</li>

									<li className="margin-top-20">
										<h6>{section_144_title}</h6>

										{section_144_des.map((value, index) => (
											<div
												className="content"
												key={index}
												dangerouslySetInnerHTML={{ __html: value }}
											></div>
										))}
									</li>
								</ul>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_15_title}</h5>

								{section_15_des.map((value, index) => (
									<div
										className="content"
										key={index}
										dangerouslySetInnerHTML={{ __html: value }}
									></div>
								))}
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_16_title}</h5>

								<ul>
									<li className="mt-1">
										<h6>{section_16_1_title}</h6>

										{section_16_1_des.map((value, index) => (
											<div
												className="content"
												key={index}
												dangerouslySetInnerHTML={{ __html: value }}
											></div>
										))}
									</li>

									<li className="margin-top-20">
										<h6>{section_16_2_title}</h6>

										{section_16_2_des.map((value, index) => (
											<div
												className="content"
												key={index}
												dangerouslySetInnerHTML={{ __html: value }}
											></div>
										))}
									</li>

									<li className="margin-top-20">
										<h6>{section_16_3_title}</h6>

										<div
											className="content"
											dangerouslySetInnerHTML={{ __html: section_16_3_des }}
										></div>
									</li>
								</ul>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">{section_17_title}</h5>

								<div
									className="content"
									dangerouslySetInnerHTML={{ __html: section_17_des }}
								></div>

								<div className="content" style={{ marginTop: "-10px" }}>
									<ul>
										{section_17_des_li.map((value, index) => (
											<li
												key={index}
												dangerouslySetInnerHTML={{ __html: value }}
											></li>
										))}
									</ul>
								</div>
							</li>

							{/* <li className="margin-top-20">
								<h5 className="d-inline-block">CHANGES TO THESE TERMS</h5>

								<div className="content">
									CVWave reserves the right to modify this Agreement and our
									Privacy Policy at any time, with or without notice to you,
									either by publishing a new version or giving you a notice of
									the change to your registered email address. It is your duty
									to read and understand this Agreement and the Privacy Policy
									on a regular basis.
								</div>

								<div className="content">
									We may modify, add to, suspend, or delete these Terms of Use
									or other agreements, in whole or in part, in our sole
									discretion at any time, with such modifications, additions or
									deletions being immediately effective upon their posting to
									the Site. Your use of the Site or the Services after
									modification, addition or deletion of these Terms of Use shall
									be deemed to constitute acceptance by you of the modification,
									addition, or deletion.
								</div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">
									THIRD PARTY WEBSITES AND SERVICES
								</h5>

								<div className="content">
									By using our Services, you may be linked to other websites on
									the Internet. These sites may contain information that some
									people may find offensive. CVWave does not control these
									sites, and you acknowledge that CVWave is not responsible for
									the content, legality, decency or any other aspect of these
									websites. The inclusion of these links on our Services does
									not imply an endorsement or association of any kind with
									CVWave. CVWave does not make any representations or warranties
									regarding the security of any information that these websites
									may request, and you irrevocably waive any claim against
									CVWave regarding these websites. CVWave encourages you to use
									your best judgment when providing sensitive information to any
									third party and to take whatever steps you deem necessary
									before providing any information to these websites.
								</div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">PUBLIC DISCOURSE AND FORUMS</h5>

								<div className="content">
									The Service may include various bulletin boards, chat rooms,
									community pages, comments or chat features, or other forums
									(“Forums”) where you can post User Content, including, but not
									limited to, your observations and comments on designated
									topics and on others’ User Content. The Provider cannot
									guarantee that other Users will not use the ideas and
									information that you share. Therefore, if you have an idea or
									information that you would like to keep confidential and/or do
									not want others to use, do not post it publicly on the
									Service. When you disclose information or rely on any
									information in the Forums, you do so at your own risk. The
									Provider reserves the right, but has no obligation, to monitor
									the Forums, or any postings or other materials that you or
									other users transmit or post on the Forums, to alter or remove
									any such materials, and to disclose such materials and the
									circumstances surrounding their transmission to any third
									party in order to operate the Service properly or to comply
									with legal obligations or governmental requests. Furthermore,
									you acknowledge that a large volume of information is
									available on these Forums and that the people participating in
									such Forums may occasionally post messages or make statements,
									whether intentionally or unintentionally, that are inaccurate,
									misleading, deceptive, abusive or even unlawful.
								</div>

								<div className="content">
									The Provider neither endorses nor is responsible for such
									messages or statements, or for any opinion, advice,
									information or other utterance made or displayed in the Forums
									by you or the other users.{" "}
									<b>
										The opinions expressed in the Forums reflect solely the
										opinions of you and/or the other users and may not reflect
										the opinions of the Provider.
									</b>
								</div>

								<div className="content">
									The Provider is not responsible for any errors or omissions in
									postings, for hyperlinks embedded in messages or for any
									results obtained from the use of the information contained in
									the Forums.{" "}
									<b>
										Under no circumstances will the Provider be liable for any
										loss or damage caused by your reliance on the information in
										the Forums or posted User Content or your use of the Forums
										or User Content.
									</b>{" "}
									ANY USE OF THE FORUMS WILL BE AT YOUR OWN RISK AND WILL BE
									SUBJECT TO THE DISCLAIMERS AND LIMITATIONS ON LIABILITY SET
									OUT IN THESE TERMS.
								</div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">
									COMMENTS, USER REVIEWS, SUBMISSIONS, TESTIMONIALS, & OTHER
								</h5>

								<div className="content">
									As a Registered User, you may upload, post, share, reframe,
									transmit or otherwise make available (“Make Available”) your
									User Content through the Service. User Content means, without
									limitation, your Account information, CV, career history,
									educational history, reviews, ratings, rankings, videos,
									photos, pictures, audio, profile entries, posts, questions,
									career materials, testimonials, submissions, photos, pictures
									and/or any other information you provide on or through the
									Service or that we may create for you. When you create an
									Account or provide career information on or through the
									Service (including via forms available on the Site or by
									uploading a CV), you agree that you are solely responsible for
									the accuracy of your User Content. You agree that the Provider
									may offer information that is of most interest to you.
								</div>

								<div className="content">
									You may choose to make some of your User Content public on the
									Service. For some of our features, other members may be able
									to request email notifications of your new public User Content
									or publish their own comments to your comments. We may use the
									public User Content to improve our service, make connections
									with potential employers, personalism site views, market
									services or identify or feature popular members.
								</div>

								<div className="content">
									To avoid any doubt, you retain ownership of your User Content
									at all times. However, by posting, uploading and/or making
									available any User Content within the Service, and/or by
									providing any communication or material to the Provider, you
									automatically:
									<ul>
										<li className="mt-2" id="remove-list-type">
											<h6>21.1</h6>

											<div className="content">
												Grant the Provider a worldwide, perpetual, irrevocable,
												non-exclusive, royalty-free, sublicensable through
												multiple tiers, and transferable, license to use, copy,
												reproduce, process, adapt, modify, publish, transmit,
												distribute, prepare derivative works of, display, and
												perform the User Content in connection with the Service
												and Provider's (and its successors' and affiliates')
												business, including without limitation for promoting and
												redistributing part or all of the Service (and
												derivative works thereof) in any media formats and
												through any media channels (known now or developed
												later). We may modify or adapt your User Content in
												order to transmit, display or distribute it over
												computer networks and in various media, and/or make
												changes to your User Content as are necessary to conform
												and adapt that Content to any requirements or
												limitations within the Service, or of any networks,
												devices, services or media; including, but not limited,
												to structured data markups for content ratings. You also
												hereby grant each user of the Service a non-exclusive
												license to access your public User Content, and to use,
												reproduce, distribute, display and perform such User
												Content as permitted through the functionality of the
												Service and under these Terms. To the extent permitted
												by applicable law,{" "}
												<ul>
													<li>
														The above licenses granted by you in User Content
														you submit to the Service will survive even if you
														remove or delete your User Content from the Service
														and,
													</li>

													<li>
														The above licenses granted by you in forum comments,
														testimonials or submissions you submit are perpetual
														and irrevocable. Except with respect to your User
														Content, you agree that you have no right or title
														in or to any other content or materials that appears
														on or in the Service, including, but not limited to,
														any information associated with another Account or
														user profile or stored on or in the Service.
													</li>
												</ul>
											</div>
										</li>

										<li className="margin-top-20" id="remove-list-type">
											<h6>21.2</h6>

											<div className="content">
												To the extent permitted by applicable law, waive all
												moral rights in the User Content that may be available
												to you in any part of the world and confirm that no such
												rights have been asserted.
											</div>
										</li>

										<li className="margin-top-20" id="remove-list-type">
											<h6>21.3</h6>

											<div className="content">
												Appoint the Provider as your agent with full power to
												enter into any document and/or do any act that the
												Provider may consider appropriate to confirm the grant
												and assignment, consent and waiver set out above.
											</div>
										</li>

										<li className="margin-top-20" id="remove-list-type">
											<h6>21.4</h6>

											<div className="content">
												Warrant that you are the owner of the User Content and
												entitled to enter into these Terms and that the User
												Content does not infringe the proprietary or privacy
												rights of any third party.
											</div>
										</li>

										<li className="margin-top-20" id="remove-list-type">
											<h6>21.5</h6>

											<div className="content">
												Confirm that no such User Content will be subject to any
												obligation, of confidence or otherwise, to you or any
												other person and that the Provider shall not be liable
												for any use or disclosure of such User Content.
											</div>
										</li>

										<li className="margin-top-20" id="remove-list-type">
											<h6>21.6</h6>

											<div className="content">
												Agree and understand that your User Content is subject
												at all times to our Acceptable Use Policy.
											</div>
										</li>
									</ul>
								</div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">GOVERNING LAW</h5>

								<div className="content">
									This Agreement will be governed by and interpreted in
									compliance with Bangladesh law, without regard to its conflict
									of laws principles. The appropriate venue for any judicial
									proceedings arising out of, relating to, or in accordance with
									this Agreement would be the state and country courts located
									in Dhaka, Bangladesh (“Supreme Court of Bangladesh”), subject
									to and without waiving the arbitration agreement. You and
									CVWave agree to apply to extraterritorial service of process
									and waive all objections to the personal jurisdiction and
									location of such courts.
								</div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">GENERAL TERMS</h5>

								<div className="content">
									These Terms constitute the entire agreement between the
									Parties as to the matters in these Terms and supplant any
									prior agreements. These Terms do not create any agency,
									partnership, employer, or joint venture relationship. CVWave
									is entitled to transfer its rights and obligations under the
									Agreement, as well as any data it processes, in the event of a
									sale, merger, or acquisition of CVWave. Use of the Services,
									including creation and use of an account, constitutes your
									consent to receiving communications from us, including emails
									with marketing offers and information about your account. The
									parties shall not be liable for any event beyond that party’s
									reasonable control, such as a war, pandemic, natural disaster,
									government order or regulation, explosion, fire, strike, act
									of God, or other force majeure event. If any provision of
									these Terms is ruled to be invalid or unenforceable, the
									remainder of the Terms shall continue to be valid and
									enforceable, and to this end these Terms are severable.
								</div>
							</li>

							<li className="margin-top-20">
								<h5 className="d-inline-block">Contact Us</h5>

								<div className="content">
									If there are any questions or issues regarding these terms and
									conditions, feel free to bring this to our attention at{" "}
									<a href="mailto:info@cvwave.com">info@cvwave.com</a>.
								</div>
							</li> */}
						</ol>
						{/* lists of terms & conditions end */}
					</div>
				</div>
			</div>
			{/* contents-section end  */}
		</>
	);
};

export default TermsConditions;
