// external components
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { GetContextApi } from "../storage/ContextApi";

const Logout = () => {
	// For Redirect "/" home page
	const Navigate = useNavigate();

	// for updating currentUser
	const { setCurrentUser } = GetContextApi();

	useEffect(() => {
		(async () => {
			try {
				const response = await fetch("/user/logout");
				const result = await response.json();

				if (response.status === 200) {
					setCurrentUser("");
					Navigate("/");
				} else {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 3000
					});
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					theme: "colored",
					autoClose: 3000
				});
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return;
};

export default Logout;
