// external components
import { useEffect } from "react";

// internal components
import "./_PasswordRequired.scss";

const PasswordRequired = ({
	getPassword,
	setPasswordRequired,
	passwordRequired,
	contents
}) => {
	// contents
	const {
		minimum_required,
		following_required,
		uppercase,
		lowercase,
		number,
		special
	} = contents.password_required;

	// check upperCase
	const containsUppercase = (password) => {
		if (!password) return false;
		return /[A-Z]/.test(password);
	};

	// check lowerCase
	const containsLowercase = (password) => {
		if (!password) return false;
		return /[a-z]/.test(password);
	};

	// check number
	const containsNumber = (password) => {
		if (!password) return false;
		return /\d/.test(password);
	};

	// check special-character
	const containsSpecialChar = (password) => {
		if (!password) return false;
		return /[-+_!@#$%^&*.,?()~]/.test(password);
	};

	useEffect(() => {
		if (getPassword) {
			// checking at least 3 following required are filled
			if (
				containsUppercase(getPassword) &&
				containsLowercase(getPassword) &&
				containsNumber(getPassword)
			) {
				setPasswordRequired(true);
			} else if (
				containsUppercase(getPassword) &&
				containsLowercase(getPassword) &&
				containsSpecialChar(getPassword)
			) {
				setPasswordRequired(true);
			} else if (
				containsUppercase(getPassword) &&
				containsNumber(getPassword) &&
				containsSpecialChar(getPassword)
			) {
				setPasswordRequired(true);
			} else if (
				containsSpecialChar(getPassword) &&
				containsLowercase(getPassword) &&
				containsNumber(getPassword)
			) {
				setPasswordRequired(true);
			} else if (
				containsUppercase(getPassword) &&
				containsLowercase(getPassword) &&
				containsNumber(getPassword) &&
				containsSpecialChar(getPassword)
			) {
				setPasswordRequired(true);
			} else {
				setPasswordRequired(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getPassword]);

	return (
		<>
			<div
				className="required-container"
				id={
					getPassword && !(getPassword.length >= 8 && passwordRequired)
						? "active"
						: ""
				}
			>
				<div className="required">
					<div className="item">
						<div id="icon-wrapper">
							{getPassword?.length >= 8 ? (
								<i className="fa-solid fa-circle-check"></i>
							) : (
								<i className="fa-solid fa-circle"></i>
							)}
						</div>

						<p id="label">{minimum_required}</p>
					</div>
				</div>

				<div className="required">
					<div className="item">
						<div id="icon-wrapper">
							{passwordRequired ? (
								<i className="fa-solid fa-circle-check"></i>
							) : (
								<i className="fa-solid fa-circle"></i>
							)}
						</div>

						<p id="label">{following_required}</p>
					</div>

					<div className="sub-items-container">
						<div className="item">
							<div id="icon-wrapper">
								{containsUppercase(getPassword) ? (
									<i className="fa-solid fa-circle-check"></i>
								) : (
									<i className="fa-solid fa-circle"></i>
								)}
							</div>

							<p id="label">{uppercase}</p>
						</div>

						<div className="item">
							<div id="icon-wrapper">
								{containsLowercase(getPassword) ? (
									<i className="fa-solid fa-circle-check"></i>
								) : (
									<i className="fa-solid fa-circle"></i>
								)}
							</div>

							<p id="label">{lowercase}</p>
						</div>

						<div className="item">
							<div id="icon-wrapper">
								{containsNumber(getPassword) ? (
									<i className="fa-solid fa-circle-check"></i>
								) : (
									<i className="fa-solid fa-circle"></i>
								)}
							</div>

							<p id="label">{number}</p>
						</div>

						<div className="item">
							<div id="icon-wrapper">
								{containsSpecialChar(getPassword) ? (
									<i className="fa-solid fa-circle-check"></i>
								) : (
									<i className="fa-solid fa-circle"></i>
								)}
							</div>
							<p id="label">{special}</p>
						</div>
					</div>
				</div>
			</div>

			<div
				className="required-container"
				id={getPassword?.length >= 8 && passwordRequired ? "active" : ""}
			>
				<div className="required">
					<div className="item">
						<div id="icon-wrapper">
							<i className="fa-solid fa-circle-check"></i>
						</div>

						<p id="label">Require Achieved.</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default PasswordRequired;
