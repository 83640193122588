// external components
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import TextareaAutosize from "react-textarea-autosize";

// internal components
import PageHeader from "../../common/PageHeader";
import { isValidateEmail } from "../../common/function";
import { GetContextApi } from "../../storage/ContextApi";
import "./ContactUs.css";
import TabsDropdown from "./TabsDropdown/TabsDropdown";

const ContactUs = ({ getQuery }) => {
	// for getting currentUser & project's contents
	const { currentUser, contents } = GetContextApi();

	// contactUs contents
	const {
		title,

		thanks_feedback,

		option_title,
		feedback,
		billing,
		pricing,
		page_errors,
		cancel_subscription,
		other,

		your_name,
		your_email,
		your_phone,
		your_message,

		your_name_placeholder,
		your_email_placeholder,
		your_phone_placeholder,
		message_placeholder,

		email_error_msg,

		button_text,
		when_sending,

		address,
		address_details,

		phone_number,
		phone_number_details,

		email_address,
		email_address_details
	} = contents.contact_us;

	// for getting input-field values
	const [name, setName] = useState(currentUser?.name ? currentUser.name : "");
	const [email, setEmail] = useState(
		currentUser?.email ? currentUser.email : ""
	);
	const [phone, setPhone] = useState("");
	const [msg, setMsg] = useState("");

	// for button clicked
	const [btnClicked, setBtnClicked] = useState(false);

	// for getting selected tab
	const [selectedTab, setSelectedTab] = useState(
		contents.title === "bangla" ? "প্রতিক্রিয়া" : "Feedback"
	);

	// for displaying successful msg toggle
	const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);

	// for loading until feedback not sending
	const [isLoading, setIsLoading] = useState(false);

	// for sending feedback start
	const sendingFeedback = async () => {
		if (isValidateEmail(email)) {
			try {
				setIsLoading(true);
				const response = await fetch(`/user/send-feedback`, {
					method: "POST",
					body: JSON.stringify({ selectedTab, email, name, msg, phone }),
					headers: {
						"Content-Type": "application/json"
					}
				});

				const result = await response.json();

				if (response.status === 200) {
					setDisplaySuccessMsg(true);
					setIsLoading(false);
					setBtnClicked(false);
					setMsg("");
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});
					setIsLoading(false);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				setIsLoading(false);
			}
		}
	};
	// for sending feedback end

	// if displaying success-message again back to feedback start
	useEffect(() => {
		if (displaySuccessMsg) {
			setTimeout(() => {
				setDisplaySuccessMsg(false);
			}, 2500);
		}
	}, [displaySuccessMsg]);
	// if displaying success-message again back to feedback end

	// updating query-tab base on query start
	useEffect(() => {
		if (getQuery === "cancel-subscription") {
			setSelectedTab("Cancel Subscription");
		} else {
			setSelectedTab("Feedback");
		}
	}, [getQuery]);
	// updating query-tab base on query start

	return (
		<>
			<PageHeader
				pageName={contents.title === "bangla" ? "যোগাযোগ করুন" : "CONTACT US"}
				link={contents.title === "bangla" ? "যোগাযোগ করুন" : "Contact-Us"}
				whichLanguage={contents.title}
			/>

			{/* form-contents start  */}
			<div className="container">
				<div className="row contact-us-container">
					<div className="col-11 col-sm-12 contact-us-wrapper">
						<div
							className={displaySuccessMsg ? "title d-none" : "title d-block"}
						>
							{title}
						</div>

						{/* contact-form-container start  */}
						<div className="contact-form-container">
							{/* feedback msg start  */}
							<div
								className="contact__form-success"
								id={displaySuccessMsg ? "active" : ""}
							>
								<div className="contact__form-success-image">
									<img src="/assets/images/mail-send.png" alt="feedback-img" />
								</div>
								<div className="contact__form-success-text">
									{thanks_feedback}
								</div>
							</div>
							{/* feedback msg end  */}

							{/* contact-form-wrapper start  */}
							<div
								className="contact-form-wrapper"
								id={displaySuccessMsg ? "inactive" : ""}
							>
								<div className="contact__form-title">{option_title}</div>

								{/* contact__form-tabs-container start  */}
								<div className="contact__form-tabs-container">
									<div
										className="tab-item"
										id={selectedTab === "Feedback" ? "active" : ""}
										onClick={() => setSelectedTab("Feedback")}
									>
										{feedback}
									</div>
									<div
										className="tab-item"
										id={selectedTab === "Billing" ? "active" : ""}
										onClick={() => setSelectedTab("Billing")}
									>
										{billing}
									</div>
									<div
										className="tab-item"
										id={selectedTab === "Pricing" ? "active" : ""}
										onClick={() => setSelectedTab("Pricing")}
									>
										{pricing}
									</div>
									<div
										className="tab-item"
										id={selectedTab === "Page Errors" ? "active" : ""}
										onClick={() => setSelectedTab("Page Errors")}
									>
										{page_errors}
									</div>

									<div
										className="tab-item"
										id={selectedTab === "Cancel Subscription" ? "active" : ""}
										onClick={() => setSelectedTab("Cancel Subscription")}
									>
										{cancel_subscription}
									</div>
									<div
										className="tab-item"
										id={selectedTab === "Other" ? "active" : ""}
										onClick={() => setSelectedTab("Other")}
									>
										{other}
									</div>
								</div>

								<div className="drop-down-container">
									<TabsDropdown
										selectedTab={selectedTab}
										setSelectedTab={setSelectedTab}
										contents={contents}
									/>
								</div>
								{/* contact__form-tabs-container start  */}

								{/* form-input-field-container start  */}

								<div className="form-input-field-container">
									<div className="top-section">
										<div className="input-field">
											<label htmlFor="name">
												{your_name} <span id="important">*</span>
											</label>

											<input
												id="name"
												placeholder={your_name_placeholder}
												onChange={(e) => setName(e.target.value)}
												value={name}
											/>

											<i className="fa-solid fa-user"></i>
										</div>

										<div className="input-field">
											<label htmlFor="email">
												{your_email} <span id="important">*</span>
											</label>

											{btnClicked && !isValidateEmail(email) && (
												<p id="error-msg">{email_error_msg}</p>
											)}

											<input
												type="email"
												id="email"
												placeholder={your_email_placeholder}
												onChange={(e) => setEmail(e.target.value)}
												value={email}
											/>

											<i className="fa-solid fa-envelope"></i>
										</div>

										<div className="input-field">
											<label htmlFor="phone">{your_phone}</label>

											<input
												type="number"
												id="phone"
												placeholder={your_phone_placeholder}
												onChange={(e) => setPhone(e.target.value)}
												value={phone}
											/>
											<i className="fa-solid fa-phone"></i>
										</div>
									</div>

									<div className="bottom-section">
										<div className="input-field">
											<label htmlFor="message">
												{your_message} <span id="important">*</span>
											</label>

											<TextareaAutosize
												id="message"
												onChange={(e) => setMsg(e.target.value)}
												value={msg}
												minRows={5}
												placeholder={`      ${message_placeholder}`}
											></TextareaAutosize>

											{!msg && <i className="fa-solid fa-pen"></i>}
										</div>
									</div>
								</div>

								{/* form-input-field-container start  */}

								{/* contact__form-buttons style start  */}

								<div className="contact__form-buttons">
									<button
										id={email && name && msg ? "" : "inactive"}
										onClick={() => {
											email && name && msg && sendingFeedback();
											email && name && msg && setBtnClicked(true);
										}}
									>
										{isLoading ? when_sending : button_text}
									</button>
								</div>

								{/* contact__form-buttons style end  */}
							</div>
							{/* contact-form-wrapper end  */}
						</div>
						{/* contact-form-container end */}
					</div>
				</div>
			</div>
			{/* form-contents end  */}

			{/* address-section start  */}
			<div className="contact-address-container">
				<div className="container">
					<div className="row card-wrapper">
						<div className="col-11 col-sm-12">
							<div className="card-items-container">
								{/* one start  */}
								<div className="card-item">
									<div className="icon-wrapper">
										<i className="fa-solid fa-location-arrow"></i>
									</div>
									<div className="address-title">{address}</div>

									<div id="line"></div>
									<p
										dangerouslySetInnerHTML={{
											__html: address_details
										}}
									></p>
								</div>
								{/* one end  */}

								{/* two start  */}
								<div className="card-item">
									<div className="icon-wrapper">
										<i className="fa-solid fa-phone"></i>
									</div>
									<div className="address-title">{phone_number}</div>

									<div id="line"></div>
									<p
										dangerouslySetInnerHTML={{
											__html: phone_number_details
										}}
									></p>
								</div>
								{/* two end  */}

								{/* three start  */}
								<div className="card-item">
									<div className="icon-wrapper">
										<i className="fa-solid fa-envelope"></i>
									</div>
									<div className="address-title">{email_address}</div>

									<div id="line"></div>
									<p
										dangerouslySetInnerHTML={{
											__html: email_address_details
										}}
									></p>
								</div>
								{/* three end  */}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* address-section end */}

			{/* add google-map start  */}
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.4843097704934!2d90.41156957501524!3d23.765762278659942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7aee45787ed%3A0x5f541820728efaca!2sBigWave%20Limited!5e0!3m2!1sen!2sbd!4v1685883771313!5m2!1sen!2sbd"
				width="100%"
				height="500"
				style={{ border: "0" }}
				allowFullScreen={true}
				loading="lazy"
				referrerPolicy="no-referrer-when-downgrade"
				title="office-location"
			></iframe>
			{/* add google-map end */}
		</>
	);
};

export default ContactUs;
