// external components
import { arrayMoveImmutable } from "array-move";
import { memo, useEffect, useReducer, useState } from "react";

// internal components
import { EditorContextApi } from "../../../../../pages/ResumeEditor/EditorContextApi";
import { CustomSectionItem } from "../SectionItems";
import {
	AddMore,
	ControlCollapse,
	DeleteDialog,
	SectionTitle
} from "../common";
import {
	SortableContainer,
	SortableItem,
	updateBeforeSortStart
} from "../common/drag&drop";
import { updateItems } from "../common/function";

const CustomSection = ({ contents, sectionId, setRemoveSection }) => {
	// customSection contents
	const { add_item, add_more_item, rename, revert, deleteText } =
		contents.editor;

	// editor contextApi
	const { updateResumeInfo, getResume, setResume, delay, userId } =
		EditorContextApi();

	// assign
	const custom = getResume.custom.filter(
		(item) => item.cus_section_id === sectionId
	)[0];
	const counter = getResume.counter;

	// for delete specific items
	const [deleteItem, setDeleteItem] = useState("");

	// for title and collapse controls start
	const initialController = {
		border: false,
		editTitle: false,
		collapse: true
	};

	const controlHandler = (state, action) => {
		switch (action.type) {
			case "open-border":
				return {
					...state,
					border: true
				};

			case "open-editor":
				return {
					...state,
					editTitle: true
				};

			case "close-border-editor":
				return {
					...state,
					border: false,
					editTitle: false
				};

			case "open-collapse":
				return {
					...state,
					collapse: true
				};

			case "close-collapse":
				return {
					...state,
					collapse: false
				};

			default:
				return state;
		}
	};

	const [control, dispatchControl] = useReducer(
		controlHandler,
		initialController
	);
	// for title and collapse controls end

	// assign custom's all values start
	const [getTitle, setTitle] = useState({
		custom_title: custom?.custom_title
	});

	const [customItems, setCustomItems] = useState([]);
	useEffect(() => {
		if (custom?.custom_items?.length > 0) {
			let getCusItemArray = [];

			custom?.custom_items?.map((value) =>
				getCusItemArray.push(
					<CustomSectionItem
						itemValues={value}
						handleSetActive={handleSetActive}
						contents={contents}
						setDeleteItem={setDeleteItem}
						updateResumeInfo={updateResumeInfo}
						delay={delay}
						cus_section_id={sectionId}
					/>
				)
			);

			setCustomItems(getCusItemArray);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// assign courses all values end

	// handle activate id start
	const [activeId, setActiveId] = useState(-1);

	const handleSetActive = (id) => {
		setActiveId((prevActiveId) => (prevActiveId === id ? -1 : id));
	};
	// handle activate id end

	// handle when dragEnd
	const onSortEnd = async ({ oldIndex, newIndex }) => {
		setCustomItems(arrayMoveImmutable(customItems, oldIndex, newIndex));

		const arr = arrayMoveImmutable(
			getResume.custom.filter((item) => item.cus_section_id === sectionId)[0]
				.custom_items,
			oldIndex,
			newIndex
		);

		setResume((prev) => {
			const updatedCustom = prev.custom.map((customItem) => {
				if (customItem.cus_section_id === sectionId) {
					return {
						...customItem,
						custom_items: arr
					};
				}
				return customItem;
			});

			return {
				...prev,
				custom: updatedCustom
			};
		});

		updateItems(arr, userId, counter, "custom", sectionId);
	};

	// delete item handler
	useEffect(() => {
		if (deleteItem._id && (deleteItem?.conform || getResume.do_not_ask_again)) {
			setCustomItems((prev) =>
				prev.filter((item) => item.props.itemValues._id !== deleteItem._id)
			);

			const arr = getResume.custom
				.filter((obj) => obj.cus_section_id === sectionId)[0]
				.custom_items.filter((item) => item._id !== deleteItem._id);

			setResume((prev) => {
				const updatedCustom = prev.custom.map((customItem) => {
					if (customItem.cus_section_id === sectionId) {
						return {
							...customItem,
							custom_items: arr
						};
					}
					return customItem;
				});

				return {
					...prev,
					custom: updatedCustom
				};
			});

			updateItems(arr, userId, counter, "custom", sectionId);

			setDeleteItem("");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteItem?._id, deleteItem?.conform]);

	return (
		<div className="section-container">
			{/* label start  */}
			<div className="label" id={control.collapse ? "active" : ""}>
				<SectionTitle
					title="Untitled"
					name="custom_title"
					control={control}
					dispatchControl={dispatchControl}
					_id={sectionId}
					value={getTitle.custom_title}
					setInputValues={setTitle}
					updateResumeInfo={updateResumeInfo}
					rename={rename}
					revert={revert}
					drag={true}
					whenAdditionalSection={{
						deleteText,
						sectionId,
						setRemoveSection,
						key: "Custom"
					}}
				/>

				<ControlCollapse
					collapse={control.collapse}
					dispatchControl={dispatchControl}
				/>
			</div>
			{/* label end  */}

			{/* content start  */}
			<div
				className="content-container remove-margin"
				id={control.collapse ? "active" : ""}
			>
				{/* for displaying customItem's array start  */}
				{customItems.length > 0 && (
					<SortableContainer
						onSortEnd={onSortEnd}
						useDragHandle
						lockAxis="y"
						lockToContainerEdges={true}
						updateBeforeSortStart={() => updateBeforeSortStart(setActiveId)}
					>
						{customItems.map((item, index) => (
							<SortableItem
								key={item.props.itemValues._id}
								index={index}
								value={item}
								className="display-section-item"
								id={activeId === item.props.itemValues._id ? "active" : ""}
							/>
						))}
					</SortableContainer>
				)}
				{/* for displaying customItem's array end */}

				{/* add-more  */}
				<AddMore
					userId={userId}
					counter={counter}
					section={"custom"}
					item={sectionId}
					newArr={{
						custom_activity: "",
						custom_city: "",
						custom_start_date: "",
						custom_end_date: "",
						custom_rich_text_content: ""
					}}
					arrCom={customItems}
					setArrCom={setCustomItems}
					itemCom={{
						itemComponent: CustomSectionItem,
						contents,
						updateResumeInfo,
						setDeleteItem,
						delay
					}}
					setResume={setResume}
					controlActiveId={{ activeId, setActiveId, handleSetActive }}
					initialContent={add_item}
					moreContent={add_more_item}
				/>
			</div>
			{/* content end */}

			{/* delete modal  */}
			{!getResume.do_not_ask_again && deleteItem && (
				<DeleteDialog
					contents={contents}
					deleteItem={deleteItem}
					setDeleteItem={setDeleteItem}
				/>
			)}
		</div>
	);
};

export default memo(CustomSection);
