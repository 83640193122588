// external components
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

// internal components
import RestrictionDialog from "../../common/RestrictionDialog";
import { getResumeCounter } from "../../common/function";
import CoverLettersContainer from "../../components/UserDashboardContents/CoverLettersContainer/CoverLettersContainer";
import ResumesContainer from "../../components/UserDashboardContents/ResumesContainer/ResumesContainer";
import { GetContextApi } from "../../storage/ContextApi";
import "./UserDashboard.css";

const UserDashboard = ({ freeResumeTemplateId }) => {
	// for redirect "/" home page
	const Navigate = useNavigate();

	// for getting currentUser & checking & contents start
	const { currentUser, setCurrentUser, contents } = GetContextApi();

	useEffect(() => {
		if (currentUser) {
		} else {
			Navigate("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for getting currentUser & checking end

	// user-dashboard contents
	const { title, button_text } = contents.user_dashboard;

	// restriction contents
	const {
		multiple_free_template,
		additional_resume_restriction,
		verify_with_photo_identity
	} = contents.package_limit;
	const { upgrade_now } = contents.navbar;

	// for fetching currentUser's all resumes from db start
	const [getResumes, setResumes] = useState([]);

	// for loading until fetching isn't complete
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				const response = await fetch("/resume/all-resumes");

				const result = await response.json();

				if (result.error || response.status !== 200) {
					toast.error(result.error, {
						position: "top-right",
						theme: "colored",
						autoClose: 2500
					});

					setResumes([]);
					setIsLoading(false);
				} else if (response.status === 200) {
					setResumes(result);
					setIsLoading(false);
				}
			} catch (error) {
				setResumes([]);
				setIsLoading(false);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for fetching currentUser's all resumes from db end

	// for updating counter number into server start
	const updatingCounter = async (obj) => {
		try {
			const response = await fetch(`/counter/update`, {
				method: "POST",
				body: JSON.stringify(obj),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				obj.selectedTab === "Resume"
					? Navigate(`/resume/${obj.count}/create-resume`)
					: Navigate(`/cover-letter/${obj.count}/editor`);
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				Navigate("/");
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
			Navigate("/");
		}
	};
	// for updating counter number into server end

	// for getting selected tab
	const [selectedTab, setSelectedTab] = useState("Resumes");

	// user restriction start
	const [restrictionObj, setRestrictionObj] = useState("");

	const restrictionHandler = (resumes) => {
		if (resumes.length !== 0) {
			if (currentUser.maximum_resumes > getResumes.length) {
				if (selectedTab === "Resumes") {
					(async () => {
						const result = await getResumeCounter();

						if (result) {
							await updatingCounter({
								selectedTab: "Resume",
								count: result.resume + 1
							});
						}
					})();

					setCurrentUser((prev) => ({
						...prev,
						used_templates: [...prev.used_templates, freeResumeTemplateId]
					}));
				}
				//  else {
				// 	updatingCounter({
				// 		selectedTab: "Cover Letter",
				// 		count: getCounter.cover_letter + 1
				// 	});
				// }
			} else {
				if (currentUser.package["Package Name"] === "Free Plan") {
					setRestrictionObj(multiple_free_template);
				} else {
					setRestrictionObj(additional_resume_restriction);
				}
			}
		} else {
			if (selectedTab === "Resumes") {
				(async () => {
					const result = await getResumeCounter();

					if (result) {
						await updatingCounter({
							selectedTab: "Resume",
							count: result.resume + 1
						});
					}
				})();

				setCurrentUser((prev) => ({
					...prev,
					used_templates: [...prev.used_templates, freeResumeTemplateId]
				}));
			}
			// else {
			// 	updatingCounter({
			// 		selectedTab: "Cover Letter",
			// 		count: getCounter.cover_letter + 1
			// 	});
			// }
		}
	};
	// user restriction end

	// isVerifyWithPhotoIdentity start
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const myParam = queryParams.get("isVerifyWithPhotoIdentity");

		if (JSON.parse(myParam)) {
			setRestrictionObj(verify_with_photo_identity);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// isVerifyWithPhotoIdentity end

	return (
		<>
			<div className="container">
				<div className="user-dashboard-container row">
					<div className="user-dashboard-wrapper col-11 col-sm-12">
						{/* header-section start  */}
						<div className="header-section">
							<div className="title">
								{title}
								{/* & Cover Letters   when cover-letters */}
							</div>
							<div className="tab-container" style={{ display: "none" }}>
								{/* when cover-letters  */}
								<button
									id={selectedTab === "Resumes" ? "active" : ""}
									onClick={() => setSelectedTab("Resumes")}
								>
									Resumes
								</button>
								<button
									id={selectedTab === "Cover Letters" ? "active" : ""}
									onClick={() => setSelectedTab("Cover Letters")}
								>
									Cover Letters
								</button>

								<div
									className="for-border"
									id={
										selectedTab === "Resumes"
											? "for-resumes"
											: "for-cover-letters"
									}
								></div>
							</div>

							<button
								id="create-new"
								className="primary-btn-styles"
								onClick={() => restrictionHandler(getResumes)}
							>
								<i className="fa-solid fa-plus"></i> {button_text}
							</button>
						</div>
						{/* header-section end  */}

						{/* displaying user previous works start  */}
						<div className="previous-works">
							{selectedTab === "Resumes" ? (
								isLoading ? (
									<div className="loader-container">
										<CircularProgress />
									</div>
								) : (
									<ResumesContainer
										contents={contents}
										getResumes={getResumes}
										setResumes={setResumes}
										setRestrictionObj={setRestrictionObj}
										restrictionHandler={restrictionHandler}
									/>
								)
							) : (
								<CoverLettersContainer
									updatingCounter={updatingCounter}
									// getCounter={getCounter}
								/>
							)}
						</div>
						{/* displaying user previous works end */}

						{/* for restriction dialog box */}
						{restrictionObj && (
							<RestrictionDialog
								restrictionObj={restrictionObj}
								setRestrictionObj={setRestrictionObj}
								btn_text={location.search ? "" : upgrade_now}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default UserDashboard;
