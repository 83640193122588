// external components

// internal components
import { GetContextApi } from "../../storage/ContextApi";
import "./FAQ.css";

const FAQ = () => {
	// faq contents
	const { contents } = GetContextApi();

	return (
		<>
			<div className="container">
				<div className="faq-container row">
					<div className="col-11 col-sm-12 faq-wrapper">
						<h4 className="title">
							{contents.title === "bangla"
								? "সচরাচর জিজ্ঞাস্য"
								: "Frequently Asked Questions"}
						</h4>

						{/* faq-section start  */}
						<div className="faq-section-container">
							<div className="accordion" id="faq-components">
								{contents.faq.map((item, index) => (
									<div className="accordion-item" key={index}>
										<h2 className="accordion-header" id={`heading-${index}`}>
											<button
												className={
													index === 0
														? "accordion-button"
														: "accordion-button collapsed"
												}
												type="button"
												data-bs-toggle="collapse"
												data-bs-target={`#collapse-${index}`}
												aria-expanded={index === 0 ? "true" : "false"}
												aria-controls={`collapse-${index}`}
											>
												{item.title}
											</button>
										</h2>
										<div
											id={`collapse-${index}`}
											className={
												index === 0
													? "accordion-collapse collapse show"
													: "accordion-collapse collapse"
											}
											aria-labelledby={`heading-${index}`}
											data-bs-parent="#faq-components"
										>
											<div className="accordion-body">{item.description}</div>
										</div>
									</div>
								))}
							</div>
						</div>
						{/* faq-section end */}
					</div>
				</div>
			</div>
		</>
	);
};

export default FAQ;
