// external components
import { toBengaliNumber } from "bengali-number";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// internal components
import {
	PaymentMethods,
	RestrictionDialog,
	ShapeAnimation,
	Stairs
} from "../../common";
import { GetContextApi } from "../../storage/ContextApi";
import "./Payment.css";

const Payment = ({ allPackages }) => {
	// for getting currentUser & checking & contents start
	const { currentUser, setCurrentUser, contents, userCountry } =
		GetContextApi();

	// for redirect "/" home page
	const Navigate = useNavigate();

	// for getting query values
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (currentUser) {
			if (
				searchParams.get("plan") === "Student" &&
				currentUser.type === "general"
			) {
				Navigate("/subscription");
			}
		} else {
			Navigate("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// for getting currentUser & checking end

	// payment contents
	const {
		list_1,
		list_2,
		list_3,
		list_4,
		cancel_subscription_header,
		cancel_subscription_des,
		money_back_header,
		money_back_des,
		due_today,
		coupon_placeholder,
		apply,
		actual_price,
		discount_price,
		total_price,
		pay_button,
		pay_address,
		phone_placeholder
	} = contents.payment;

	// restriction contents
	const { invalid_coupon } = contents.package_limit;
	const { term_condition, privacy_policy } = contents.footer;

	// getting specific package
	const [selectedPackage, setSelectedPackage] = useState("");
	const [price, setPrice] = useState("");

	useEffect(() => {
		if (allPackages.length > 0 && searchParams.get("plan")) {
			const filterPackage = allPackages.filter(
				(pack) =>
					pack["Package Name"].toLowerCase() ===
					searchParams.get("plan").toLowerCase()
			);

			setSelectedPackage(filterPackage[0]);
			setPrice(filterPackage[0]["Discounted Price"]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allPackages.length > 0]);

	// for getting input-fields value
	const [getCoupon, setCoupon] = useState("");

	// for applying coupon start
	const [getCouponDoc, setCouponDoc] = useState("");

	// getting phone-number
	const [getPhone, setPhone] = useState(null);

	const [restrictionObj, setRestrictionObj] = useState("");

	const applyingCoupon = async () => {
		try {
			const response = await fetch(`/coupon/apply`, {
				method: "POST",
				body: JSON.stringify({
					coupon: getCoupon.toUpperCase()
				}),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				if (result.method === "Fixed") {
					const actualPrice =
						selectedPackage["Discounted Price"] - result.amount;

					if (actualPrice < 0) {
						setCoupon("");
						setRestrictionObj(invalid_coupon);
					} else {
						setPrice(actualPrice);
						setCouponDoc(result);
					}
				} else if (result.method === "Percentage (%)") {
					const actualPrice = Math.round(
						selectedPackage["Discounted Price"] -
							selectedPackage["Discounted Price"] * (result.amount / 100)
					);

					if (actualPrice < 0) {
						setCoupon("");
						setRestrictionObj(invalid_coupon);
					} else {
						setPrice(actualPrice);
						setCouponDoc(result);
					}
				}
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for applying coupon end

	// payment handler for ssl start
	const makeId = (length) => {
		let result = "";
		const characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	};

	const submitHandler = async () => {
		try {
			const obj = {
				package_name: selectedPackage["Package Name"],
				price,
				phone: getPhone
			};

			const response = await fetch(
				price === 0
					? `/sslPayment/success/${currentUser._id}/${
							obj.package_name
					  }/${`${new Date()
							.toISOString()
							.slice(0, 10)
							.replace(/-/g, "")}-${makeId(7)}`}/${price}`
					: "/sslPayment/init",
				{
					method: "POST",
					body: JSON.stringify(obj),
					headers: {
						"Content-Type": "application/json"
					}
				}
			);

			const result = await response.json();

			if (response.status === 200) {
				if (Number(price) === 0) {
					setCurrentUser(result);
					Navigate("/thank-you");
				} else {
					window.location.replace(result);
				}
			} else {
				toast.error(result.error, {
					position: "top-right",
					theme: "colored",
					autoClose: 2500
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				theme: "colored",
				autoClose: 3000
			});
		}
	};
	// payment handler for ssl end

	return (
		<>
			<Stairs
				from_where={"payment"}
				contents={contents}
				currentUser={currentUser}
			/>

			{/* payment-container start  */}
			<div className="main-payment-wrapper">
				<div className="container">
					<div className="payment-container row">
						<div className="payment-wrapper col-11 col-sm-12">
							{/* pay-container start  */}
							<div className="pay-container">
								<div className="pay-main-wrapper">
									{/* left-section start  */}
									<div className="left-section">
										{/* pay-features start  */}
										<div className="pay-features">
											<div className="pay-feature-item">
												<i className="fa-solid fa-check"></i>
												{list_1}
											</div>
											<div className="pay-feature-item">
												<i className="fa-solid fa-check"></i>
												{list_2}
											</div>
											<div className="pay-feature-item">
												{" "}
												<i className="fa-solid fa-check"></i> {list_3}
											</div>
											<div className="pay-feature-item">
												{" "}
												<i className="fa-solid fa-check"></i> {list_4}
											</div>
										</div>
										{/* pay-features end  */}

										{/* pay-question start  */}
										<div className="pay-question">
											<div className="pay-question-title">
												{cancel_subscription_header}
											</div>
											<div className="pay-question-text">
												{cancel_subscription_des}
											</div>
										</div>
										<div className="pay-question">
											<div className="pay-question-title">
												{money_back_header}
											</div>
											<div className="pay-question-text">{money_back_des}</div>
										</div>
										{/* pay-question end  */}
									</div>
									{/* left-section end  */}

									{/* right-section start  */}
									<div className="right-section">
										<div className="pay-form">
											<div className="pay-form-content">
												{!getCouponDoc && (
													<div className="pay-form-billed">
														<div>{due_today}</div>

														{userCountry === "BD" ? (
															<div id="amount">{`${
																contents.title === "bangla" ? "৳." : "Tk."
															}${
																contents.title === "bangla"
																	? toBengaliNumber(
																			selectedPackage["Discounted Price"]
																	  )
																	: selectedPackage["Discounted Price"]
															}`}</div>
														) : (
															<div id="amount">
																{`$${
																	selectedPackage["Discounted Price"] / 100 + 5
																}`}
															</div>
														)}
													</div>
												)}

												{/* for calculation start */}
												{getCouponDoc && (
													<div className="calculation-container">
														<div className="item">
															<span>{actual_price}</span>{" "}
															<span>{`${
																contents.title === "bangla" ? "৳" : "Tk."
															}${
																contents.title === "bangla"
																	? toBengaliNumber(
																			selectedPackage["Discounted Price"]
																	  )
																	: selectedPackage["Discounted Price"]
															}`}</span>
														</div>
														<div className="item">
															<span>{discount_price}</span>{" "}
															<span>{`-${
																contents.title === "bangla" ? "৳" : "Tk."
															}${
																getCouponDoc.method === "Fixed"
																	? contents.title === "bangla"
																		? toBengaliNumber(getCouponDoc.amount)
																		: getCouponDoc.amount
																	: contents.title === "bangla"
																	? toBengaliNumber(
																			selectedPackage["Discounted Price"] *
																				(getCouponDoc.amount / 100)
																	  )
																	: selectedPackage["Discounted Price"] *
																	  (getCouponDoc.amount / 100)
															}`}</span>
														</div>

														<div className="item">
															<span>{total_price}</span>{" "}
															<span>
																{`${contents.title === "bangla" ? "৳" : "Tk."}${
																	contents.title === "bangla"
																		? toBengaliNumber(price)
																		: price
																}`}
															</span>
														</div>
													</div>
												)}
												{/* for calculation end */}

												{/* for coupon code start */}
												<div className="coupon-code">
													<div className="input-field">
														{!getCouponDoc && (
															<>
																<input
																	placeholder={coupon_placeholder}
																	onChange={(e) => setCoupon(e.target.value)}
																	value={getCoupon}
																	type="text"
																/>

																<button
																	id={getCoupon ? "" : "inactive"}
																	onClick={() => getCoupon && applyingCoupon()}
																>
																	{apply}
																</button>
															</>
														)}

														{price !== 0 && (
															<input
																type="tel"
																placeholder={phone_placeholder}
																onChange={(e) =>
																	setPhone(
																		e.target.value.replace(/[^0-9+]/g, "")
																	)
																}
																value={getPhone}
																id="phone-number"
															/>
														)}
													</div>
												</div>

												{/* for coupon code end */}

												<button
													onClick={submitHandler}
													id={price === 0 ? "" : getPhone ? "" : "disable"}
												>
													{price === 0 ? "Processed" : pay_button}
												</button>

												<div className="privacy-policy-content">
													<div id="address">{pay_address}</div>

													<div id="link">
														<Link
															to={"../terms-and-conditions"}
															rel="noreferrer"
															target={"_blank"}
														>
															{term_condition}
														</Link>
														<span>|</span>
														<Link
															to={"../privacy-policy"}
															rel="noreferrer"
															target={"_blank"}
														>
															{privacy_policy}
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* right-section end  */}
								</div>
							</div>
							{/* pay-container end  */}
						</div>
					</div>
				</div>

				{/* for restriction dialog box */}
				{restrictionObj && (
					<RestrictionDialog
						restrictionObj={restrictionObj}
						setRestrictionObj={setRestrictionObj}
					/>
				)}

				<PaymentMethods />

				{/* shapes animation component   */}
				<ShapeAnimation />
			</div>
			{/* payment-container end  */}
		</>
	);
};

export default Payment;
