// external components
import { useState } from "react";

// internal components
import { GetContextApi } from "../../storage/ContextApi";
import "./ClientFeedback.css";

const ClientFeedback = () => {
	// for getting selected client's state
	const [whichClient, setWhichClient] = useState("one");

	// client_feedback contents
	const { contents } = GetContextApi();

	const {
		title,

		client_1_name,
		client_1_position,
		client_1_message,

		client_2_name,
		client_2_position,
		client_2_message,

		client_3_name,
		client_3_position,
		client_3_message,

		client_4_name,
		client_4_position,
		client_4_message,

		client_5_name,
		client_5_position,
		client_5_message
	} = contents.client_feedback;

	return (
		<>
			<div className="feedback-main-container">
				<div className="container">
					<div className="row feedback-container">
						<div className="col-11 col-sm-12 feedback-wrapper">
							<div className="feedback-header" data-aos="fade-up">
								<h3>{title}</h3>
							</div>

							{/* testimonial section start  */}
							<div className="testimonial-container row">
								{/* testimonial-left start  */}
								<div className="testimonial-left col-md-6 col-12">
									<div className="testimonial-left-bg">
										<img
											src="/assets/images/testimonial-bg.svg"
											alt="testimonial-bg"
											className="img-fluid"
										/>
									</div>

									<ul className="client-images">
										<li>
											<div
												className={
													whichClient === "one"
														? "client-img active"
														: "client-img"
												}
												id="one"
												onClick={() => setWhichClient("one")}
											>
												<img
													src="/assets/images/client-img-5.jpg"
													alt="testimonial-img"
													className="img-fluid"
												/>
											</div>
										</li>

										<li>
											<div
												className={
													whichClient === "two"
														? "client-img active"
														: "client-img"
												}
												id="two"
												onClick={() => setWhichClient("two")}
											>
												<img
													src="/assets/images/client-img-2.jpg"
													alt="testimonial-img"
													className="img-fluid"
												/>
											</div>
										</li>

										<li>
											<div
												className={
													whichClient === "three"
														? "client-img active"
														: "client-img"
												}
												id="three"
												onClick={() => setWhichClient("three")}
											>
												<img
													src="/assets/images/client-img-3.jpg"
													alt="testimonial-img"
													className="img-fluid"
												/>
											</div>
										</li>

										<li>
											<div
												className={
													whichClient === "four"
														? "client-img active"
														: "client-img"
												}
												id="four"
												onClick={() => setWhichClient("four")}
											>
												<img
													src="/assets/images/client-img-4.jpg"
													alt="testimonial-img"
													className="img-fluid"
												/>
											</div>
										</li>

										<li>
											<div
												className={
													whichClient === "five"
														? "client-img active"
														: "client-img"
												}
												id="five"
												onClick={() => setWhichClient("five")}
											>
												<img
													src="/assets/images/client-img-1.jpg"
													alt="testimonial-img"
													className="img-fluid"
												/>
											</div>
										</li>
									</ul>
								</div>
								{/* testimonial-left end  */}

								{/* testimonial-right start  */}
								<div className="testimonial-right col-md-6 col-12">
									{/* client-details-group start  */}
									<div className="client-details-group">
										{/* one start  */}
										<div
											className={
												whichClient === "one"
													? "client-detail active"
													: "client-detail"
											}
										>
											<img
												src="/assets/images/quote-img.png"
												alt="quote-img"
												className="img-fluid"
											/>
											<p className="client-msg">{client_1_message}</p>
											<p className="client-name">
												{client_1_name}&nbsp;&nbsp;-&nbsp;&nbsp;
												<span>{client_1_position}</span>
											</p>
										</div>
										{/* one end  */}

										{/* two start  */}
										<div
											className={
												whichClient === "two"
													? "client-detail active"
													: "client-detail"
											}
										>
											<img
												src="/assets/images/quote-img.png"
												alt="quote-img"
												className="img-fluid"
											/>
											<p className="client-msg">{client_2_message}</p>
											<p className="client-name">
												{client_2_name}&nbsp;&nbsp;-&nbsp;&nbsp;
												<span>{client_2_position}</span>
											</p>
										</div>
										{/* two end  */}

										{/* three start  */}
										<div
											className={
												whichClient === "three"
													? "client-detail active"
													: "client-detail"
											}
										>
											<img
												src="/assets/images/quote-img.png"
												alt="quote-img"
												className="img-fluid"
											/>
											<p className="client-msg">{client_3_message}</p>
											<p className="client-name">
												{client_3_name}&nbsp;&nbsp;-&nbsp;&nbsp;
												<span>{client_3_position}</span>
											</p>
										</div>
										{/* three end  */}

										{/* four start  */}
										<div
											className={
												whichClient === "four"
													? "client-detail active"
													: "client-detail"
											}
										>
											<img
												src="/assets/images/quote-img.png"
												alt="quote-img"
												className="img-fluid"
											/>
											<p className="client-msg">{client_4_message}</p>
											<p className="client-name">
												{client_4_name}&nbsp;&nbsp;-&nbsp;&nbsp;
												<span>{client_4_position}</span>
											</p>
										</div>
										{/* four end  */}

										{/* five start  */}
										<div
											className={
												whichClient === "five"
													? "client-detail active"
													: "client-detail"
											}
										>
											<img
												src="/assets/images/quote-img.png"
												alt="quote-img"
												className="img-fluid"
											/>
											<p className="client-msg">{client_5_message}</p>
											<p className="client-name">
												{client_5_name}&nbsp;&nbsp;-&nbsp;&nbsp;
												<span>{client_5_position}</span>
											</p>
										</div>
										{/* five end  */}
									</div>
									{/* client-details-group end  */}

									<div className="dots-container">
										<i
											className="fa-solid fa-circle"
											id={whichClient === "one" ? "active" : ""}
											onClick={() => setWhichClient("one")}
										></i>

										<i
											className="fa-solid fa-circle"
											id={whichClient === "two" ? "active" : ""}
											onClick={() => setWhichClient("two")}
										></i>

										<i
											className="fa-solid fa-circle"
											id={whichClient === "three" ? "active" : ""}
											onClick={() => setWhichClient("three")}
										></i>

										<i
											className="fa-solid fa-circle"
											id={whichClient === "four" ? "active" : ""}
											onClick={() => setWhichClient("four")}
										></i>

										<i
											className="fa-solid fa-circle"
											id={whichClient === "five" ? "active" : ""}
											onClick={() => setWhichClient("five")}
										></i>
									</div>
								</div>
								{/* testimonial-right start  */}
							</div>
							{/* testimonial section end */}
						</div>

						{/* animation shape start  */}
						<div className="section-shapes">
							<div className="shape">
								<svg
									className="circle-1 svg-item inline shape-zoom"
									id="Layer_3"
									data-name="Layer 3"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 62 62"
								>
									<defs></defs>
									<path
										style={{
											fill: "none",
											stroke: "#e7e8fc",
											strokeMiterlimit: "10",
											strokeWidth: "10px",
											fillRule: "evenodd"
										}}
										d="M332,620a26,26,0,1,1-26,26A26,26,0,0,1,332,620Z"
										transform="translate(-301 -615)"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="circle-2 svg-item inline shape-zoom"
									id="Layer_3"
									data-name="Layer 3"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 62 62"
								>
									<defs></defs>
									<path
										style={{
											fill: "none",
											stroke: "#e7e8fc",
											strokeMiterlimit: "10",
											strokeWidth: "10px",
											fillRule: "evenodd"
										}}
										d="M332,620a26,26,0,1,1-26,26A26,26,0,0,1,332,620Z"
										transform="translate(-301 -615)"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="circle-3 svg-item inline shape-zoom"
									id="Layer_3"
									data-name="Layer 3"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 62 62"
								>
									<defs></defs>
									<path
										style={{
											fill: "none",
											stroke: "#e7e8fc",
											strokeMiterlimit: "10",
											strokeWidth: "10px",
											fillRule: "evenodd"
										}}
										d="M332,620a26,26,0,1,1-26,26A26,26,0,0,1,332,620Z"
										transform="translate(-301 -615)"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="triangle-1 svg-item inline shape-rotate"
									id="Layer_4"
									data-name="Layer 4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 33.83 33.83"
								>
									<defs></defs>
									<title></title>
									<path
										style={{
											fill: "none",
											stroke: "#d3e1fd",
											strokeMiterlimit: "10",
											strokeWidth: "4px",
											fillRule: "evenodd"
										}}
										d="M895,191v27H868Z"
										transform="translate(-863.17 -186.17)"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="rectangle svg-item inline shape-rotate"
									id="Layer_5"
									data-name="Layer 5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 56.3 56.3"
								>
									<defs></defs>
									<title></title>
									<path
										style={{
											fill: "none",
											stroke: "#ffd3d8",
											strokeMiterlimit: "10",
											strokeWidth: "5px",
											fillRule: "evenodd"
										}}
										d="M766.87,597.9l22.23-27,27,22.23-22.23,27Z"
										transform="translate(-763.35 -567.35)"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item square-1 inline shape-rotate"
									version="1.1"
									id="Layer_38"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 36.65 36.65"
									style={{ enableBackground: "new 0 0 36.65 36.65" }}
								>
									<rect
										x="2.5"
										y="2.5"
										style={{
											fill: "transparent",
											stroke: "#eaedfe",
											strokeWidth: "5px",
											strokeLinecap: "round",
											strokeLinejoin: "round",
											strokeMiterlimit: 10
										}}
										width="31"
										height="31"
									></rect>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item square-3 inline shape-rotate"
									version="1.1"
									id="Layer_38"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 36.65 36.65"
									style={{ enableBackground: "new 0 0 36.65 36.65" }}
								>
									<rect
										x="2.5"
										y="2.5"
										style={{
											fill: "transparent",
											stroke: "#eaedfe",
											strokeWidth: "5px",
											strokeLinecap: "round",
											strokeLinejoin: "round",
											strokeMiterlimit: 10
										}}
										width="31"
										height="31"
									></rect>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item half-circle inline shape-rotate"
									version="1.1"
									id="Layer_34"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 36.68 20.91"
									style={{ enableBackground: "new 0 0 36.68 20.91" }}
								>
									<path
										style={{
											fill: "none",
											stroke: "#f7e9f3",
											strokeWidth: "5",
											strokeLinecap: "round",
											strokeLinejoin: "round",
											strokeMiterlimit: 10
										}}
										d="M2.5,18.41C2.5,9.62,9.59,2.5,18.34,2.5c8.75,0,15.84,7.12,15.84,15.91"
									></path>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item cross-1 inline shape-rotate"
									version="1.1"
									id="Layer_43"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 20.25 20.25"
									style={{ enableBackground: "new 0 0 20.25 20.25" }}
								>
									<g>
										<line
											style={{
												fill: "#EBF0FD",
												stroke: "#e6ebfd",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											x1="17.75"
											y1="17.28"
											x2="2.5"
											y2="2.97"
										></line>
										<line
											style={{
												fill: "#EBF0FD",
												stroke: "#e6ebfd",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											x1="2.97"
											y1="17.75"
											x2="17.28"
											y2="2.5"
										></line>
									</g>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item cross-2 inline shape-rotate"
									version="1.1"
									id="Layer_43"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 20.25 20.25"
									style={{ enableBackground: "new 0 0 20.25 20.25" }}
								>
									<g>
										<line
											style={{
												fill: "#EBF0FD",
												stroke: "#e6ebfd",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											x1="17.75"
											y1="17.28"
											x2="2.5"
											y2="2.97"
										></line>
										<line
											style={{
												fill: "#EBF0FD",
												stroke: "#e6ebfd",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											x1="2.97"
											y1="17.75"
											x2="17.28"
											y2="2.5"
										></line>
									</g>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item snake-1 inline shape-fade"
									version="1.1"
									id="Layer_41"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 63.14 17.25"
									style={{ enableBackground: "new 0 0 63.14 17.25" }}
								>
									<g>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M17.04,14.75C10.13,14.75,11.32,2.5,2.5,2.5"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M46.11,14.75c-6.91,0-5.72-12.25-14.54-12.25"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M17.04,14.75c6.91,0,5.72-12.25,14.54-12.25"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M60.64,2.5"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M46.11,14.75c6.91,0,5.72-12.25,14.54-12.25"
										></path>
									</g>
								</svg>
							</div>

							<div className="shape">
								<svg
									className="svg-item snake-3 inline shape-fade"
									version="1.1"
									id="Layer_41"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
									y="0px"
									viewBox="0 0 63.14 17.25"
									style={{ enableBackground: "new 0 0 63.14 17.25" }}
								>
									<g>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M17.04,14.75C10.13,14.75,11.32,2.5,2.5,2.5"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M46.11,14.75c-6.91,0-5.72-12.25-14.54-12.25"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M17.04,14.75c6.91,0,5.72-12.25,14.54-12.25"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M60.64,2.5"
										></path>
										<path
											style={{
												fill: "none",
												stroke: "#FEF2F4",
												strokeWidth: "5",
												strokeLinecap: "round",
												strokeLinejoin: "round",
												strokeMiterlimit: 10
											}}
											d="M46.11,14.75c6.91,0,5.72-12.25,14.54-12.25"
										></path>
									</g>
								</svg>
							</div>
						</div>
						{/* animation shape end */}
					</div>
				</div>
			</div>
		</>
	);
};

export default ClientFeedback;
