const SectionTitle = ({ className, sectionName, equalTo, initialName }) => {
	return (
		<div className={className}>
			{sectionName.toLowerCase().trim().replace(/\s+/g, "") ===
			equalTo.toLowerCase().trim().replace(/\s+/g, "")
				? initialName
				: sectionName}
		</div>
	);
};

export default SectionTitle;
