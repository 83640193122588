// external components
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { memo, useEffect, useState } from "react";

// internal components
import { FirstCol, SecondCol } from "../../common";

const AddLink = ({
	value,
	updateResumeInfo,
	parentId,
	setDeleteLink,
	contents,
	delay
}) => {
	// addLink contents
	const { label, link } = contents.editor;

	// assign
	const _id = value._id;

	// getting & updating links all values start
	const [inputValues, setInputValues] = useState(value);

	const [latestUpdate, setLatestUpdate] = useState({});

	useEffect(() => {
		if (Object.keys(latestUpdate)[0]) {
			const timeoutId = setTimeout(() => {
				updateResumeInfo({ ...latestUpdate, _id, parentId });
			}, delay);

			return () => {
				clearTimeout(timeoutId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestUpdate]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setInputValues((prevValues) => ({
			...prevValues,
			[name]: value
		}));

		setLatestUpdate({
			[name]: value || null
		});
	};
	// getting & updating links all values end

	return (
		<>
			{/* label and link start  */}
			<div
				className="row-container project-links"
				style={{
					padding: "4px 20px 0px",
					marginBottom: "10px",
					marginTop: "2px",
					alignItems: "center"
				}}
			>
				<FirstCol
					content={label}
					name={"label"}
					value={inputValues.label}
					onChange={handleInputChange}
					haveIm={true}
				/>

				<SecondCol
					content={link}
					name={"link"}
					value={inputValues.link}
					onChange={handleInputChange}
					haveIm={true}
				/>

				<IconButton
					aria-label="delete"
					className="delete-icon"
					onClick={() =>
						setDeleteLink({
							_id,
							parentId
						})
					}
				>
					<DeleteIcon />
				</IconButton>
			</div>
			{/* label and link end  */}
		</>
	);
};

export default memo(AddLink);
