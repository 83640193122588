// external components
import { memo } from "react";

const ControlCollapse = ({ collapse, dispatchControl }) => {
	return (
		<>
			{collapse ? (
				<i
					className="fa-solid fa-chevron-up"
					onClick={() => dispatchControl({ type: "close-collapse" })}
				></i>
			) : (
				<i
					className="fa-solid fa-chevron-down"
					onClick={() => dispatchControl({ type: "open-collapse" })}
				></i>
			)}
		</>
	);
};

export default memo(ControlCollapse);
