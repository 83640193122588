const english = {
	title: "english",

	about_us: {
		title: "KNOW MORE ABOUT US!",
		description: [
			`At <b>CvWave</b>, we change the way people write resumes. We have
      created a suitable and user-friendly system that helps you create
      a resume in minutes. We are very excited to help job seekers to
      find their jobs, Job seeking is not an easy task, but with us it
      is much easier.`,
			`Over the years, we have reviewed a huge number of resumes and have
      noticed that people do not build their resume correctly - not in
      terms of design, structure or content. We realized that before we
      can help companies find the right people for their company, we
      need to invest our resources into helping job seekers create a
      resume that makes a lasting impression on recruiters. Each day, we
      would give tips on how to write a resume correctly. This is a
      vital component in any job search as employers receive hundreds of
      resumes for each open position, which causes them to filter
      resumes quickly (about 5 seconds on average). As a result, most
      candidates do not pass the initial screening.`,
			`For this reason, we have launched
      <a href="/" target="_blank">CvWave</a>. Our mission statement is to
      make your job search easier. With years of research, proven
      experience in human resources and state-of-the-art technology, we
      have created a platform that will make you stand out in today’s
      competitive job market.`,
			`With our help, many job seekers have found their next career opportunity amongst the best employers.`
		]
	},

	auth_left: {
		title: "Create a Resume with <br /> impressive features",

		section_1:
			"Experience easy-to-use Interface <br /> with Customizable professional templates",
		section_2:
			"Optimize resume by suggested keywords <br /> and step-by-step guidance with live preview",
		section_3:
			"Showcase skills or accomplishments <br /> and complete your stunning resume <br /> with less time and effort"
	},

	client_feedback: {
		title: "What Our Clients Say",

		client_1_name: "Shegufta Mahjabin",
		client_1_position: "Director",
		client_1_message:
			"I've used a few resume builders before, but this one is the finest so far. There are numerous templates and customization choices available.",

		client_2_name: "Tanvir Ahmed",
		client_2_position: "Lead Data Engineer",
		client_2_message:
			"Although I lack technical knowledge, this app was simple to use and explore. It simplified and speed up the resume-writing process.",

		client_3_name: "Mahbub Alam",
		client_3_position: "Banker",
		client_3_message:
			"The customer service team responded quickly and assisted me in resolving a problem I was having downloading my resume. Great response!",

		client_4_name: "Atiquzzahan Remon",
		client_4_position: "Sales Manager",
		client_4_message:
			"I appreciated that this app automatically saved my progress so that I wouldn't have to stress about losing my work. Despite being a minor element, it had a significant impact on me.",

		client_5_name: "Sumaiya Ahmed",
		client_5_position: "Principal Consultant",
		client_5_message:
			"Every time I've used this app to make resumes for various job applications, I've had a great experience. I heartily endorse it."
	},

	contact_us: {
		title:
			"Feel free to share any comments, questions, or feedback you have. Our team is eager to hear from you. Reach out via a call or by submitting a message using the form below.",

		thanks_feedback: "Thank you for your feedback! We will contact you soon.",

		option_title: "Select a subject",
		feedback: "FEEDBACK",
		billing: "BILLING",
		pricing: "PRICING",
		page_errors: "PAGE ERRORS",
		cancel_subscription: "CANCEL SUBSCRIPTION",
		other: "OTHER",

		your_name: "YOUR NAME",
		your_email: "YOUR EMAIL",
		your_phone: "YOUR PHONE NUMBER",
		your_message: "YOUR MESSAGE",
		message_placeholder: "Write your message here...",
		your_name_placeholder: "Enter your name here...",
		your_email_placeholder: "Enter your email here...",
		your_phone_placeholder: "Enter your phone here...",

		email_error_msg: "Please enter valid e-mail address *",

		button_text: "Send Message",
		when_sending: "Sending . . .",

		address: "Address",
		address_details: `Mohanagar Project, West&nbsp;Rampura <br />
      Dhaka 1219. Bangladesh`,

		phone_number: "Phone Number",
		phone_number_details: `Support: +88 017 0168 0168 <br />
      Office: +88 016 1709 0302`,

		email_address: "Email Address",
		email_address_details: `info@cvwave.com <br />
      support@cvwave.com`
	},

	create_account: {
		social_title: "Link your social media profile",
		social_subtitle:
			"Automatically populate your account with information from your social profile.",

		add_name_title: "Add your name",
		add_name_subtitle:
			"You've chosen an excellent template! Let's now incorporate your name into it.",

		contact_title: "Provide your contact details",
		contact_subtitle:
			"Please enter your email address below. Ensuring employers have <br /> a way to reach you is essential.",

		back_btn: "BACK",
		continue_btn: "Continue",
		skip_btn: "SKIP",

		submit: "SUBMIT",
		submitting: "SUBMITTING . . .",

		creating: "CREATING",

		first_name: "First Name",
		first_name_placeholder: "Enter your first name ...",

		last_name: "Last Name",
		last_name_placeholder: "Enter your last name ...",

		emailAddress: "Email ID*",
		email_placeholder: "Enter your email here...",
		email_error_msg: "Please enter valid e-mail address *",

		passwordTxt: "Password*",
		enter_pass_placeholder: "Enter your password here...",

		social_agree: `By signing up via your social profile you agree with our <br/>
		<a href="../terms-and-conditions" target="_blank"
		rel="noreferrer">Terms of Conditions</a>  and <a href="../privacy-policy" target="_blank"
		rel="noreferrer">Privacy policy</a>.`
	},

	editor: {
		// header
		import_text: "Import Demo Data",

		dummy_title: "Do you want to import demo data?",
		dummy_subtitle:
			"If you import demo data, your existing data will be removed.",

		// progress-bar
		percentage_text: "PROFILE COMPLETED",
		percentage_text_short: "COMPLETED",
		per_personal: "Add personal details",
		per_professional: "Add professional summary",
		per_employment: "Add employment history",
		per_education: "Add education",
		per_links: "Add website & social links",
		per_skills: "Add skills",

		// common
		city: "CITY",
		description: "DESCRIPTION",
		present: "Present",
		employer: "EMPLOYER",
		date: "START & END DATE",
		not_specific: "(Not Specific)",
		recruiter_tip: "Recruiter tip",
		write: "write",
		words_maximum: "words maximum",
		words_left: "words left",
		add_link: "Add link",
		add_more_link: "Add one more link",
		label: "LABEL",
		link: "LINK",
		add_item: "Add item",
		add_more_item: "Add one more item",

		// personal_details
		personal_details_subtitle:
			"FILUP THE MANDATORY FIELDS*. INCLUDES SECTIONS ONLY IF IT IS RELEVANT REQUIREMENT FOR YOUR PROFESSION OR JOB POSITION. IN MOST CASES, LEAVE THESE BLANK.",
		job_title: "WANTED JOB TITLE",
		upload_photo: "UPLOAD PHOTO",
		edit_photo: "Edit Photo",
		delete_photo: "Delete",
		first_name: "FIRST NAME",
		last_name: "LAST NAME",
		email: "EMAIL ADDRESS",
		phone: "PHONE NUMBER",
		country: "COUNTRY",
		nationality: "NATIONALITY",
		present_address: "PRESENT ADDRESS",
		personal_city: "CITY / DIVISION",
		personal_area: "STATE / AREA",
		personal_zip: "ZIP CODE",
		same_address: "SAME AS ABOVE",
		permanent_address: "PERMANENT ADDRESS",
		driving_license: "DRIVING LICENSE",
		nid: "NID / PASSPORT",
		place_of_birth: "PLACE OF BIRTH",
		date_of_birth: "DATE OF BIRTH",
		edit_additional_details: "Edit additional details",
		hidden_additional_details: "Hide additional details",

		// professional_summary
		professional_summary_subtitle:
			"THE STANDARD WORD LIMIT FOR A PROFESSIONAL SUMMARY IN A RESUME IS TYPICALLY AROUND 2-3 SENTENCES OR 50-100 WORDS. IT SHOULD BE CONCISE AND IMPACTFUL, SUMMARIZING YOUR KEY QUALIFICATIONS, SKILLS, AND EXPERIENCE RELEVANT TO THE POSITION YOU ARE APPLYING FOR.",

		// employment_history
		employment_history_subtitle:
			"LIST YOUR WORK EXPERIENCES IN REVERSE CHRONOLOGICAL ORDER, STARTING WITH YOUR MOST RECENT JOB AND GOING BACKWARD IN TIME. THIS FORMAT ALLOWS EMPLOYERS TO SEE YOUR MOST RECENT AND RELEVANT EXPERIENCES FIRST WITH KEY RESPONSIBILITIES AND ACHIEVEMENTS.",
		employment_job_title: "JOB TITLE",
		add_employment: "Add employment",
		add_more_employment: "Add one more employment",

		// education
		education_subtitle:
			"EDUCATION PROVIDES A FOUNDATION OF KNOWLEDGE AND SKILLS THAT CAN BE VALUABLE AND RELEVANCE TO THE POSITION. IN A COMPETITIVE JOB MARKET, HAVING RELEVANT EDUCATIONAL QUALIFICATIONS CAN GIVE YOU AN ADVANTAGE OVER OTHER CANDIDATES.",
		school: "SCHOOL / COLLEGE / UNIVERSITY",
		degree: "DEGREE",
		add_education: "Add education",
		add_more_education: "Add one more education",

		// PROJECTS/PORTFOLIO
		project_subtitle:
			"INCORPORATING PROJECTS OR A PORTFOLIO IN YOUR PROFESSIONAL RESUME CAN BE IMPACTFUL. IF APPLICABLE, PROVIDE VISUAL AIDS LIKE LINKS TO LIVE PROJECTS OR A PORTFOLIO WEBSITE. FOCUS ON RECENT AND RELEVANT PROJECTS, AS THEY HIGHLIGHT YOUR CURRENT SKILLS AND KNOWLEDGE.",
		project_name: "PROJECT NAME",
		project_duration: "DURATION",
		technologies: "TECHNOLOGIES",
		add_project: "Add project",
		add_more_project: "Add one more project",

		// web_links
		web_link_subtitle:
			"A LINK TO YOUR PORTFOLIO, LINKEDIN PROFILE, OR PERSONAL WEBSITE IN A RESUME ARE CRUCIAL AS THEY ENHANCE A CANDIDATE'S PROFILE. THEY PROVIDE HIRING MANAGERS WITH A MORE HOLISTIC VIEW OF THE APPLICANT'S SKILLS, WORK, AND ONLINE PRESENCE. ENSURE ALL LINKS ARE CURRENT, FUNCTIONAL, AND LEAD TO THE INTENDED CONTENT.",

		// skills
		skills_subtitle:
			"CHOOSE 5 OF THE MOST IMPORTANT & RELEVANT SKILLS TO SHOW YOUR TALENTS! MAKE SURE THEY PRESENT YOUR SKILLS IN A COMPELLING MANNER, INCREASING YOUR CHANCES OF CATCHING THE ATTENTION OF HIRING MANAGERS AND SECURING OPPORTUNITIES THAT ALIGN WITH YOUR EXPERTISE AND CAREER GOALS.",
		is_show_skill_level: "Don't show experience level",
		skill: "SKILL",
		level: "LEVEL",
		novice: "NOVICE",
		beginner: "BEGINNER",
		skillful: "SKILLFUL",
		experienced: "EXPERIENCED",
		expert: "EXPERT",
		add_skill: "Add skill",
		add_more_skill: "Add one more skill",

		// hobbies
		hobbies_subtitle:
			"HOBBIES IN A RESUME IS AN OPTIONAL PRACTICE, AND WHETHER YOU SHOULD INCLUDE THEM OR NOT DEPENDS ON YOUR SPECIFIC CIRCUMSTANCES LIKE RELEVANCE TO THE JOB OR CULTURAL FIT. IT'S ESSENTIAL TO AVOID MENTIONING HOBBIES THAT MIGHT BE CONTROVERSIAL OR POLARIZING.",

		// custom
		custom_activity: "ACTIVITY NAME, JOB TITLE, BOOK TITLE ETC.",

		// course
		course_subtitle:
			"BY INCLUDING RELEVANT COURSES ON YOUR RESUME, YOU CAN DEMONSTRATE YOUR COMMITMENT TO CONTINUOUS LEARNING AND SHOWCASE SPECIFIC SKILLS THAT ALIGN WITH THE JOB REQUIREMENTS, MAKING YOU A MORE COMPETITIVE CANDIDATE IN THE HIRING PROCESS.",
		course: "COURSE",
		institution: "INSTITUTION",
		add_course: "Add course",
		add_more_course: "Add one more course",

		// EXTRA-CURRICULAR ACTIVITIES
		activities_subtitle:
			"EXTRA CURRICULAR ACTIVITIES IN A RESUME CAN BE BENEFICIAL, ESPECIALLY FOR RECENT GRADUATES OR CANDIDATES WITH LIMITED WORK EXPERIENCE. FOCUS ON EXTRACURRICULAR ACTIVITIES THAT ARE RELEVANT TO THE JOB YOU'RE APPLYING FOR OR CANDIDATES WITH LIMITED WORK EXPERIENCE. BE CONCISE, SHOW PASSION AND PERSONALITY.",
		activity_function: "FUNCTION TITLE",
		add_activity: "Add activity",
		add_more_activity: "Add one more activity",

		// internship
		internship_job_title: "JOB TITLE",
		add_internship: "Add internship",
		add_more_internship: "Add one more internship",

		// reference
		is_hide_refer:
			"I'd like to hide references and make them available only upon request",
		refer_name: "REFERENT'S FULL NAME",
		refer_company: "COMPANY",
		add_refer: "Add reference",
		add_more_refer: "Add one more reference",

		// languages
		language_subtitle:
			"MENTIONING LANGUAGE PROFICIENCY IN A RESUME IS IMPORTANT, ESPECIALLY IF THE POSITION YOU'RE APPLYING FOR REQUIRES MULTILINGUAL SKILLS OR IF IT'S RELEVANT TO THE JOB IN ANY WAY. LANGUAGE SKILLS CAN MAKE YOU STAND OUT AS A CANDIDATE, ESPECIALLY IN INTERNATIONAL OR MULTICULTURAL WORK ENVIRONMENTS. AVOID EXAGGERATING OR MISREPRESENTING YOUR ABILITIES, AS IT MAY BE TESTED DURING THE HIRING PROCESS.",
		language: "LANGUAGE",
		add_language: "Add language",
		add_more_language: "Add one more language",

		// info tooltip
		rename: "Rename",
		revert: "Revert Section Name",
		drag: "Click and drag to move",
		deleteText: "Delete",
		demo_tooltip:
			"Import pre-defined content to generate the resume, so that you can edit it easily.",
		job_title_tooltip:
			"Add a title like ‘Senior Marketer’ or ‘Sales Executive’ that quickly describes your overall experience or the type of role you're applying to",
		nid_tooltip:
			"Include your nationality only if it is relevant to your position. In most cases, leave this blank.",
		driving_license_tooltip:
			"Include this section if your profession requires a certain type of license. If not, leave it blank.",
		date_of_birth_tooltip:
			"Add your date of birth only if it is a relevant requirement for your position. In most cases, leave this blank.",
		date_tooltip: `If you don’t want to show month you can type only year. Leave it blank if you don’t want to display dates for this entry." <br />  Example: ‘Feb 2023’, or blank`,

		// delete_entry
		delete_entry_header: "Delete Entry",
		delete_entry_sub_header: "Are you sure you want to delete this entry?",
		delete_entry_dont_ask: "Don't ask me again",
		delete_entry_cancel: "Cancel"
	},

	preview: {
		saved: "SAVED",
		saving: "SAVING . . .",
		choose_template: "CHOOSE TEMPLATE",
		download_pdf: "DOWNLOAD PDF"
	},

	faq: [
		{
			title: "What is a resume builder web application?",
			description:
				"A resume builder web application is an online tool that helps you create professional resumes and CVs easily. It provides pre-designed templates, formatting options, and guidance to help you highlight your skills and qualifications effectively."
		},
		{
			title: "How does the resume builder web application or CvWave work?",
			description:
				"The resume builder web application typically works in a step-by-step process. You start by selecting a template or design, then enter your personal information, work experience, education, skills, and other relevant details. The application formats and organizes this information into a visually attractive resume."
		},
		{
			title: "Is this web application free to use?",
			description:
				"CvWave is a resume builder web applications that offer both free and paid options. Basic features and free templates are available to use for free, but advanced features and premium templates require a subscription."
		},
		{
			title: "Can I customize the resume templates?",
			description:
				"Yes, CvWave allows you to customize the resume to fit your needs. You can change the section headings, and text to create a unique and personalized resume."
		},
		{
			title: "Can I import my existing resume into the web application?",
			description:
				"No, currently CVWAVE doesn’t allow you to import your existing resume in this web application. But we are happy to inform you that our awesome team is working on it."
		},
		{
			title: "Can I download my resume in PDF format?",
			description:
				"Yes, CvWave provide options to download your resume in PDF format and this allows you to easily share your resume as needed."
		},
		{
			title: "Is my personal information secure?",
			description:
				"CvWave take privacy and security seriously. We use encryption and secure data storage methods to protect your personal information. However, recommend to review our privacy policy and terms of service to ensure your data is handled securely."
		},
		{
			title: "Can I update my resume later?",
			description:
				"Yes, this web applications auto save your resume and allow you to make updates at any time. You can log in to your account and access your resume to make changes, add new experiences, or update your contact information."
		},
		{
			title: "Can CvWave help with writing content?",
			description:
				"CvWave is a resume builder application that provides templates and formatting options, it's essential to write the content of your resume yourself. However, CVWAVE offer helpful tips, suggestions, and examples with Pre-Written Phrase to guide you in writing effective resume content."
		},
		{
			title: "Can I create multiple resumes for different job applications?",
			description:
				"Yes, CvWave allow you to create and manage multiple resumes. This feature is helpful if you're applying for different types of positions and need to tailor your resume to specific job requirements."
		}
	],

	features: {
		title: "Features designed to help you win your dream job",
		feature_1_heading: "Customize your resume",
		feature_1_description:
			"Tailor your resume to the job you are applying for by using keywords and phrases that match the job description. This will show the employer that you are a good match for the job.",
		feature_2_heading: "Highlight your achievements",
		feature_2_description:
			"Use specific examples to demonstrate your achievements and skills. This will help you stand out from other candidates and show the employer what you can bring to the role.",
		feature_3_heading: "Use a professional format",
		feature_3_description:
			"Use a clean, professional format that is easy to read. Avoid using gimmicks or flashy designs that could distract from your qualifications.",
		feature_4_heading: "Update regularly",
		feature_4_description:
			"Update your resume regularly to reflect any new skills or experiences you have gained. This will ensure that your resume is always up-to-date and ready to use."
	},

	footer: {
		description: `CvWave is an online tool that allows individuals to create a professional-looking resume or CV. These tools typically provide users with templates that they can customize to fit their personal information and work experience.`,

		site_map: "Site Map",
		home: "Home",
		resume: "Resume",
		about_us: "About Us",
		contact_us: "Contact Us",
		faq: "FAQ",
		blog: "BLOG",
		term_condition: "Terms and conditions",
		privacy_policy: "Privacy policy",
		refund_policy: "Refund policy",
		cancel_subscription: "Cancel subscription",
		pricing: "Pricing",

		address_title: "Contact with us",
		address: "Mohanagar Project, West Rampura",
		city: "Dhaka 1219. Bangladesh",
		phone_title: "Phone",
		phone_number: "+88 017 0168 0168",
		email_title: "Email",
		email_address: "info@cvwave.com",

		trade_license_text: "Trade License No. :",
		trade_license_num: "TRAD/DNCC/140552/2022",

		copy_right_text: `<span id="highlight">CvWave</span> all rights reserved. Powered by <span id="highlight"><a
		href="https://bigwavebd.com"
		target="_blank"
		rel="noreferrer"
	>BigWave</a></span>`
	},

	landing: {
		title_1: "ONLINE RESUME CREATOR",
		title_2_1: "Professional",
		title_2_2: "Resumes for",
		title_3: "Successful Job Interviews",
		description:
			"A professional resume is an essential tool for any job seeker. It is a document that summarizes your work experience, skills, and education, and it is the first thing that potential employers will see when considering you for a job.",
		button_text: "Create Resume"
	},

	log_in: {
		loginBox_title: "Sign In into your account",

		recover_password: "Recover your password",
		setup_account: "Setup your account",
		setup_account_password: "Setup your account password",

		forgetPassDes: "Enter your Email and instructions will be sent to you!",

		emailAddress: "Email ID*",
		email_placeholder: "Enter your email here...",
		email_error_msg: "Please enter valid e-mail address *",

		passwordTxt: "Password*",

		new_pass_placeholder: "New Password . . .",
		enter_pass_placeholder: "Enter your password here...",

		keepMeSign: "Keep me signed in",
		forgetPass: "Forgot Password?",

		loginBtn: "Sign In",
		loggingBtn: "Signing In",

		submit: "Submit",
		submitting: "Submitting . . .",

		loginSocialNet: "Login using Social Network",

		dontHaveAct: "Don’t have an account?",
		dontHaveActLabel: "Sign Up Now"
	},

	sign_up: {
		c_gen_account: "Create an account",
		c_stu_account: "Create an student account",

		setup_account: "Setup your account",
		setup_account_password: "Setup your account password",

		user_name: "User Name*",
		user_name_placeholder: "Enter your name here...",

		emailAddress: "Email ID*",
		sutEmailAddress: "Student Email ID*",
		email_placeholder: "Enter your email here...",
		email_error_msg: "Please enter valid e-mail address *",

		stu_card_checkbox: `If you do not have a student email(*.edu) then
		upload your student Id picture for verification.`,

		passwordTxt: "Password*",
		enter_pass_placeholder: "Enter your password here...",

		signUpBtn: "Sign Up",
		signingUpBtn: "Creating . . .",

		submit: "Submit",
		submitting: "Submitting . . .",

		signUpSocialNet: "Sign-up using Social Network",

		alreadyAcc: "I’m already member?",
		loginLabel: "Sign In",

		ifStudent: "If you are student,",
		ifNotStudent: "If you aren't student,",
		signUPLabel: "Sign Up",
		here: "here"
	},

	auth_info_msg: {
		heading_ac_email:
			"Please verify the activation link that has been sent to your email.",
		description_ac_email: `An email containing an activation link has been sent to
		your provided email address. To activate your account,
		kindly click on the provided link. If the link remains
		unclicked, your account will remain inactive, and
		subsequent emails will not be delivered. <br /> <br />
		<span>In case you do not receive the email within a few minutes,
		please ensure to check your spam folder as well.</span>`,

		heading_re_pass:
			"Please check the link we sent to your email for resetting your password.",
		description_re_pass: `An email has been sent to your email address containing an confirmation link. Please click on the link to confirm your account. If you do not click the link you can't reset your password and login. <br /> <br />
		<span>If you do not receive the email within few minutes, please check your spam folder.</span>`,

		heading_unAuth_email: "Activation Required",
		description_unAuth_email:
			"To continue further you need to activate your account via the activation link we have sent you earlier.",

		or_unAuth_email: "Didn't get that activation link?",

		or: "or,",

		resend_btn: "Resend Link",
		sending_btn: "Sending . . ."
	},

	navbar: {
		home: "HOME",
		resume: "RESUME",
		about_us: "ABOUT US",
		contact_us: "CONTACT US",
		sign_in: "SIGN IN",
		sign_up: "SIGN UP",
		my_account: "My Account",
		upgrade_now: "Upgrade Now"
	},

	payment: {
		list_1: "Payment through a trusted payment partner",
		list_2: "SSL secure checkout",
		list_3: "7-day money back guarantee",
		list_4: "Your data will be shared with BigWave for product fulfillment",

		cancel_subscription_header: "How can I cancel subscription?",
		cancel_subscription_des:
			'Cancel your subscription easily by contacting with our support team through email or telephone, or you have the option to initiate the cancellation by yourself on the "Account Settings" page.',

		money_back_header: "Money-Back Guarantee!",
		money_back_des:
			"If you find yourself not completely satisfied and it's within 7 days of the initial payment, just inform us, and we'll be glad to promptly initiate a refund for you.",

		due_today: "TOTAL DUE TODAY:",

		coupon_placeholder: "COUPON CODE",

		apply: "APPLY",

		phone_placeholder: "Enter your phone number*",

		actual_price: "Actual Price:",

		discount_price: "Discount Amount:",

		total_price: "Total :",

		pay_button: "PAY NOW",

		pay_address:
			"Your data will be shared with BigWave for product fulfillment. House # D100, Road # 4, Block # D, Mohanagar Project, West Rampura, Dhaka 1219. Bangladesh"
	},

	popup_option: {
		dashboard: "Dashboard",
		dashboard_subtitle: "Your resumes",

		account_settings: "Account Settings",
		faq: "FAQ",
		sign_out: "Sign Out"
	},

	procedure: {
		title: "Make Excellent Resumes For The Trendy Job Market",

		procedure_1_heading: "Your First Steps",
		procedure_1_description:
			"We made sure that registration in our resume-building services even more simple than usual. Choose one of the social media platforms (LinkedIn, Facebook, or your Google account), or just enter your name and email address without doing anything else. We firmly maintain the privacy of your data.",

		procedure_2_heading: "Achieve Beauty With Ease",
		procedure_2_description:
			"Select one of our elegant, professionally designed formats for a resume or cover letter. Add your personal details, then pick and change the required fields. You can alter the design and graphics however much or as little as you wish. We offer a ton of pre-written content that is open to customization to suit your demands and creative vision.",

		procedure_3_heading: "Now It’s Yours!",
		procedure_3_description:
			"You can export your updated resume or cover letter in PDF format for the best and most reliable visual formatting, or you can upload the document to an online application system. You can also update about your resume and share as much as you can.",

		button_text: "GET STARTED"
	},

	resume_templates: {
		title: "Amazing Resume Templates",
		sub_title:
			"Begin your resume journey confidently with our diverse collection of 25+ field-tested templates. Each template is thoughtfully designed to help you stand out and seize the attention of potential employers. Start creating your impactful resume today!",
		button_text: "Create My Resume",
		all_templates: "All templates",
		creative: "Creative",
		simple: "Simple",
		professional: "Professional",
		modern: "Modern",
		choose_button_text: "Use This Template"
	},

	select_template: {
		title: "Beautiful Ready-to-use Resume Templates",
		button_text: "Choose This Template"
	},

	stairs: {
		create_resume: "Create resume",
		choose_plan: "Choose plan",
		payment_details: "Payment details",
		download_resume: "Download resume",

		choose_template: "Choose template",
		enter_your_details: "Enter your details"
	},

	subscription: {
		title: "Upgrade now for premium benefits <br /> and enhanced features!",
		subtitle:
			"Ready to level up? Elevate your resume with access to premium templates, unlimited PDF downloads, and more. Upgrade today for a standout profile!",

		features: "Features",
		regular_price_text: "Regular Price",
		subs_period: "Subscription Period",
		limits: "Templates Use Limits",
		customization: "Resume Customization",
		download: "Resume Download",
		spelling_check: "Spelling Check",
		pre_written_phrases: "Pre-Written Phrases",
		exclusive_templates: "Exclusive Templates",
		expert_advice: "Expert Advice",
		value_added_service: "Value Added Service",

		btn_text: "Upgrade & Download",

		basic: "Basic",
		student: "Student",
		standard: "Standard",
		premium: "Premium",

		month: "Month",
		year: "Year",

		months: "Months",
		years: "Years",

		unlimited: "Unlimited",

		money_back_des:
			"Upon placing an order, you forfeit withdrawal rights and agree to instant service delivery, including digital products. Within 7 days of the initial payment, the money-back guarantee is applicable.",

		we_accept: "We accept"
	},

	user_dashboard: {
		title: "Resumes",
		button_text: "Create New",

		empty_resume_header: "Your impressive and polished professional look",
		empty_resume_des:
			"Personalized exceptional resumes. Elevate your job search with ease in just a few clicks!",

		resume_title: "Untitled",
		updated: "Updated",
		edit: "Edit",
		share: "Share a link",
		download: "Download PDF",
		copy: "Make a copy",
		delete_txt: "Delete",
		delete_a_resume: "Delete A Resume",
		duplicate_a_resume: "Duplicate A Resume",
		deleteBtn: "Delete",
		duplicate: "Duplicate",
		cancel: "Cancel"
	},

	share_link_modal: {
		header: "Share link of your resume",
		sub_header:
			"You can either share this link on your social media platforms, copy and paste the URL to send your resume via text or email, or use it to display your resume on your personal website.",
		url_title: "Copy This URL",
		open_link: "Open Link",
		copy_link: "Copy Link",
		copied: "Copied"
	},

	account_setting: {
		page_title: "Account Settings",

		your_plan: "YOUR PLAN",
		upgrade_text: "Upgrade",

		free_plan: {
			plan: "Free Plan",
			description:
				"You are on the free plan. You can save your data, search for jobs and share your resume link with free template. Upgrade for More templates with PDF downloads & premium features."
		},

		basic_plan: {
			plan: "Basic Plan",
			description: `You are currently enrolled in our Basic Plan, which allows you to save your data, and share your resume link with up to 5 premium templates for one month. Enhance your experience by upgrading to access additional templates, extend your subscription, and unlock a host of premium features.`
		},

		student_plan: {
			plan: "Student Plan",
			description: `You are enrolled in our Student Plan, which grants you the ability to save your data, search for jobs, share your resume link, or download a PDF version with access to up to 10 premium templates for an entire year, along with a substantial discount.`
		},

		standard_plan: {
			plan: "Standard Plan",
			description: `You are currently subscribed to our Standard Plan. Save data, enjoy unlimited customization, share resume links with PDF downloads, and access up to 10 premium templates for a full year.`
		},

		account: "ACCOUNT",
		first_name: "First Name",
		last_name: "Last Name",
		email: "Email",
		email_des: `Use this email to log in to your <span id="highlight">cvwave.com</span> account and receive notifications.`,
		current_password: "Current Password",
		new_password: "New Password",
		info_text:
			"Input current-password then click save button in order to update changes.",
		save_btn: "Save",
		saved_btn: "Saved",

		f_name_error: "First name is required *",
		l_name_error: "Last name is required *",

		student_verification: {
			header: "Student Verification",
			change_to_stu: "Change or verify your account as a student account",
			stu_verified: "You are verified as a student",
			pending_btn: "Pending for verification",
			verify_as_stu: "Verify as student",

			verify_with_id: "Verify with a student email id",
			verify_with_photo: "Verify with a photo identify card",

			stu_email: "Student Email Id:",
			submit: "Submit"
		},

		social_profile: "SOCIAL PROFILE",
		connect: "Connect",
		disconnect: "Disconnect",

		email_notification: "EMAIL NOTIFICATION",

		update_offers: "Updates and Offers",
		update_offers_des: "Discounts, special offers, new features and more",

		resume_analytics: "Resume Analytics",
		resume_analytics_des:
			"Views, downloads and monthly statistics for each resume",

		newsletter: "Resume and Job Tips Newsletter",
		newsletter_des:
			"Useful resume and job tips! Straight to your inbox every 2 weeks",

		danger_zone: "DANGER ZONE",
		danger_zone_warning:
			"Once you delete your account, it cannot be undone. This is permanent.",
		delete_account_btn: "Delete Account",

		delete_acc_header: "Do you want to delete your account?",
		delete_acc_sub_header:
			"If you delete your account, your whole information will be deleted, including resumes",
		agree: "Agree",
		disagree: "Disagree",

		help_text:
			"Need help? Have questions or feedback? Our team would love to hear from you —",
		contact_us_link: "contact our support",

		billing_details: {
			header: "Billing Details",
			sub_header: "Your Subscription",
			your_package: "Your Package:",
			subscription_start_date: "Subscription Start Date:",
			subscription_period: "Subscription Period:",
			subscription_price: "Subscription Price:",
			coupon: "Coupon:",
			total_paid: "Total Paid:"
		},

		cancel_subscription: {
			description: "Do you want to cancel your subscription?",
			btn_text: "Cancel Subscription"
		},

		refund_request: {
			description: "Ask for refund",
			btn_text: "Refund"
		}
	},

	agree_checkbox: {
		agreeText: `I agree to <a href="../terms-and-conditions" target="_blank"
		rel="noreferrer">Terms of Conditions</a>  and <a href="../privacy-policy" target="_blank"
		rel="noreferrer">Privacy policy</a>`,

		isSendUpdateTxt: "Send me resume examples and updates"
	},

	password_required: {
		minimum_required: "8 characters minimum required",
		following_required: "3 of the following required :",
		uppercase: "1 uppercase letter",
		lowercase: "1 lowercase letter",
		number: "1 number (0-9)",
		special: "1 special character"
	},

	sub_footer: {
		privacy_policy: "Privacy Policy",
		contact_us: "Contact Us"
	},

	terms_condition: {
		title: "USER AGREEMENT/TERMS AND CONDITIONS",
		time: "Last Revised: 1st of September, 2023",

		welcome: `Hello and welcome to <b>CVWave</b>. Please read these Terms and Conditions carefully before using our website <a href="/" target="__blank"><b>www.cvwave.com</b></a>. This agreement explains the rules that apply to buying or providing services from CVWave.
		`,

		reference: `“We”, “us”, “our” refers to <b>CVWave</b>. “Site” refers to <a href="/" target="__blank"><b>www.cvwave.com</b></a>.`,

		services: `“Services” refers to <b>CVWave</b> selling “Basic”, “Student”, “Standard” and/or “Premium” packages that involve writing resumes, cover letters and all such things related.`,

		client_refer: `“Buyer”, “client”, “You” refers to a customer who is paying for our services.`,

		access_txt: `Your access to and use of our services is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all users who access or use our Services. By accessing or using the Service you agree to be bound by these Terms.`,

		warning_txt: `IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS & CONDITIONS, PLEASE DO NOT REGISTER OR USE THIS WEBSITE.`,

		section_1_title: `SERVICE DESCRIPTION`,
		section_1_des: `The word “curriculum vitae,” or “CV,” comes from Latin and means “life path.” <b>CVWave</b> offers resources and application for creating CVs (the “Services”). You can use the Services to develop, edit, export, and download resume and cover letter using templates, or you can hire one of our experts to help you create your own CV. You will use the Services as long as you agree to these Terms and follow them. Both users of the Web and Facilities, including guests registered users and paid subscriber, are bound by these Terms.`,

		section_2_title: `ACCEPTANCE OF THESE TERMS`,
		section_2_des: `In consideration of your use of our services, you agree to provide true, accurate, and complete information about yourself when prompted by the Site and ordering process. You must be age 14 or older to use any services offered by <b>CVWave</b>. When using our services, you affirm that you are ages 14 or older and fully competent to make online transactions. <b>CVWave</b> has the right to reject your order if your information is inaccurate, untrue, incomplete, or hinders <b>CvWave’s</b> ability to fulfill its services in any meaningful way.`,

		section_3_title: `CONTRACTUAL RELATIONSHIP`,
		section_3_des: `You do not have the right to transmit under any contractual or other relationship (e.g., inside information, proprietary or confidential information received in the context of an employment or a non-disclosure agreement). If you are not legally able to enter into contracts, you cannot use the Service at any time or in any manner, or submit any information to the Provider or the Service.`,

		section_4_title: `REGISTRATION & ACCOUNTS`,
		section_4_des: [
			`To access certain Services or view areas of the Site, you will need to register with <b>CVWave</b> and create an account (your “Account”). Once you create an Account, your resumes, cover letters, and other data will be stored and accessible through your Account. You represent and warrant that all user information you provide in connection with your Account is current, complete, and accurate, and that you will update that information as necessary to keep it accurate. You further represent and warrant that you are not impersonating any person or entity through your Account, or misleading other users as to your affiliation with any person or entity.`,
			`The Account is personal and can only be used by the owner of the email address or Social Profile to which the Account is linked. You are responsible for maintaining the confidentiality of your Account credentials, including your username and password. It is expressly prohibited to give third parties access to your Account. You are responsible for anything that happens through your Account unless you notify <b>CVWave</b> of unauthorized use.`,
			`If we suspect, in our sole discretion, that there has been a fissure of your Account security, we reserve the right to refuse access to the Services, terminate your Account, suspend or terminate your right to use the Services, or take such other action as we deem necessary, in our sole discretion.`
		],

		section_5_title: `COLLECTION AND USE OF YOUR INFORMATION`,
		section_5_des: `“Your Information” includes any information you provide to us, including, without limitation, any information you provide during registration, in any public message area or public forum, or through any email feature.
		You are solely responsible for Your Information. We have no obligation to monitor the Website. We may, however, take any action we deem necessary and appropriate in our sole discretion if we believe Your Information creates a risk of liability to <b>CVWave</b>. <b>CVWave</b> may, at its sole discretion, remove or disable access to material on the website that infringes on the rights of third parties or is otherwise harmful to <b>CVWave</b>. <b>CVWave</b> may also, at its sole discretion, terminate your access to the website at any time for any or no reason, with or without notice.
		`,

		section_6_title: `TERMINATION`,
		section_6_des_1: `Either you or <b>CVWave</b> may terminate this Agreement at any time upon written notice to the other party. If you terminate this Agreement, you may no longer access your Account or use the Services. If, after termination, you access the Services or create a new Account, such action will constitute your consent to this Agreement and the Privacy Policy, and the prior termination will be deemed null and void.`,
		section_6_des_2: `We reserve the right to terminate your right to use any of our services even if you have paid for the service for any of the following reasons:`,
		section_6_des_li: [
			`The information you provided is misleading or false;`,
			`You are not cooperating with the execution of your order;`,
			`We have reason to believe you are involved with fraudulent activity;`,
			`We have reason to believe you are causing harm to our servers or attempting to do so.`
		],

		section_7_title: `PRIVACY POLICY`,
		section_7_des: `Your personal data will be collected and processed by <b>CVWave</b> in accordance with its privacy policy. You hereby agree to these terms in our privacy policy by your agreement to the terms and conditions of this Agreement. Please visit our  <a href="/privacy-policy" target="__blank"><b>Privacy Policy</b></a> to get more details about how we manage your information, which is incorporated herein by reference.`,

		section_8_title: `DISPUTE RESOLUTION`,
		section_8_des: `If a dispute were to arise between you and one or more users, you release <b>CVWave</b> and its affiliates from any claims, demands, liabilities, costs or expenses and damages, actual and consequential of every kind and nature, arising out of or in any way connected with those disputes. By entering into this release, you are expressly waiving any protections to the extent permitted by law, that would otherwise limit the coverage of this release to include only claims which you know or suspect exist at the time of your agreement to this release.`,

		section_9_title: `PROHIBITED USAGE & CONDUCT`,
		section_9_des_1: `In addition to the other restrictions on use set forth herein, you agree and acknowledge that you shall not use the Site and/or the Services:`,
		section_9_des_li: [
			`For any unlawful purpose;`,
			`To solicit Users to perform or participate in any unlawful acts or to engage in acts that are unrelated to the purpose(s) of the Site or the Services;`,
			`To violate any international, governmental, federal, provincial or state regulations, rules, laws, or local ordinances;`,
			`To infringe upon or violate our intellectual property rights or the intellectual property rights of others;`,
			`To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;`,
			`To submit false or misleading information; `,
			`To upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Site or the Services;`,
			`To collect or track the personal information of others;`,
			`To spam, phish, pharm, pretext, spider, crawl, or scrape;`,
			`For any obscene or immoral purpose; or, `,
			`To interfere with or circumvent the security features of the Site or the Services.`
		],
		section_9_des_2: `We reserve the right to terminate your use of the Site and/or the Services for violating any of the prohibited uses or for any other reason in our sole and exclusive decision.`,

		section_10_title: `SUBSCRIPTION, PAYMENTS AND PRICING`,

		section_101_title: `10.1  Subscriptions`,
		section_101_des: `<b>CVWave’s</b> Services are offered on a subscription basis. If you enroll in a subscription plan (your “Subscription”), you will be billed automatically according to the terms of the Subscription as provided to you at the time of your enrollment (“Subscription Terms”).`,

		section_101_a_title: `10.1(a)  Timing of Subscription Payments`,
		section_101_a_des: `At the time you enroll in your Subscription, you will be required to provide your payment information. You understand and agree that your payment information on file will be charged for additional Subscription periods (e.g., once per month or year) without notice and without obtaining further permission from you. Your Subscription renews automatically unless cancelled in advance of the next payment period. Please pay attention to the pricing, payment terms, and disclosures provided when signing up for your Subscription.`,

		section_102_title: `10.2  Payments`,
		section_102_des: `By using the Services, you agree to pay <b>CVWave</b> all associated fees (the “Fees”), as indicated to you at the time you agree to such fees (e.g. when you register for your Account or during the checkout process). <b>CVWave’s</b> payment services are provided by trusted Third Party Payment Processors (SSLCOMMERZ). As such, you may be redirected to a third-party website and/or required to agree to separate third party terms in order to complete your payment transaction. In case of late payment, <b>CVWave</b> will send you a reminder to make your payment.`,

		section_103_title: `10.3  Pricing`,
		section_103_des_li: [
			`Costs for the Services vary based on the packages <b>CVWave</b> offer. Additional information about our pricing can be found <a href="/subscription" target="__blank">
			<b>here</b></a>.`,
			`<b>CVWave</b> may find it necessary to change the price of our Services and reserves the right to do so in its sole discretion. <b>CVWave</b> will provide users with thirty (30) days’ notice of any price changes. If you do not agree with the change, you have the right to cancel your Account or Subscription. Your use of the Services after the changes are in effect constitute your acceptance.`,
			`Your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates).`
		],

		section_11_title: `CHANGES AND CANCELLATION`,
		section_11_des: [
			`Your Account or Subscription may be paused or canceled at any time by logging into your account or visiting <a href="/contact-us?selectedTab=cancel-subscription" target="__blank"><b>www.cvwave.com/cancel-subscription</b></a>.`,
			`Changes and/or cancellations to your Subscription must be made at least three (3) days before you are charged for the next Subscription payment in order to avoid said payment.`,
			`Upon cancellation of paid subscription, your Content will be stored by CVWave for twelve (12) months. After twelve (12) months, your Content will be deleted. We may notify you via email prior to the deletion of your Content, but we are not obligated to do so. Until the moment of deletion, you have the option to use the data by re-entering into the Agreement.`
		],

		section_12_title: `NO REFUNDS; WAIVER OF RIGHT TO WITHDRAW`,
		section_12_des: `Due to the nature of the Services, <b>ALL SALES ARE FINAL AND THERE ARE NO REFUNDS</b>, whether in whole or in part. Because performance of the Services occurs upon submission of your initial payment, you understand, acknowledge and agree that you will lose your right of withdrawal upon submission of your initial payment.`,

		section_13_title: `TAXES`,
		section_13_des: `All orders and subscription plans are subject to taxes applicable in the state/country where you reside.`,

		section_14_title: `PROCEDURE FOR CLAIMS OF COPYRIGHT INFRINGEMENT AND NOTICES`,

		section_141_title: `14.1 In General`,
		section_141_des: `We respect the intellectual property rights of others and encourage you to do the same. Accordingly, we have a policy of removing content that violates the intellectual property rights of others, suspending access to the Service (or any portion thereof) to any user who uses the Service in violation of someone else’s intellectual property rights, and/or terminating in appropriate circumstances the Account of any user who uses the Service in violation of someone else’s intellectual property rights.`,

		section_142_title: `14.2 Submitting a Notification for Removing Infringing Content`,
		section_142_1_des: `We have implemented procedures for receiving written notification of claimed copyright infringement and for processing such claims in accordance with applicable law. If you believe your copyright or other intellectual property right is being infringed by a user of this Site, please provide a written notice to our agent for notice of claims of infringement at Email: <a href="mailto:legal@cvwave.com">legal@cvwave.com</a>. <br/>
		To be sure the matter is handled immediately, your written notice must:
		`,
		section_142_li: [
			`Contain your physical or electronic signature;`,
			`Identify the copyrighted work or other intellectual property alleged to have been infringed;`,
			`Identify the allegedly infringing material in a sufficiently precise manner to allow us to locate that material;`,
			`Contain adequate information by which we can contact you (including postal address, telephone number, and e-mail address);`,
			`Contain a statement that you have a good faith belief that use of the copyrighted material or other intellectual property is not authorized by the owner, the owner’s agent or the law;`,
			`Contain a statement that the information in the written notice is accurate; and`,
			`Contain statement, under penalty of perjury, that you are authorized to act on behalf of the copyright or other intellectual property right owner.`
		],
		section_142_2_des: `Unless the notice pertains to copyright or other intellectual property infringement, the agent will be unable to address the listed concern.`,

		section_143_title: `14.3 Submitting a Counter-Notification`,
		section_143_des: `We will notify you that we have removed or disabled access to copyright-protected material that you provided if such removal is pursuant to a validly received LEGAL take-down notice. In response, if you believe the material was removed or disabled by mistake or because of a misidentification of the material, you may provide our agent with a written counter-notification that includes the following information:`,
		section_143_li: [
			`Your physical or electronic signature;`,
			`Identification of the material that has been removed or to which access has been disabled, and the location at which the material appeared before it was removed or access to it was disabled;`,
			`A statement from you under the penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; and`,
			`Your name, physical address and telephone number, and a statement that you consent to the jurisdiction of a court for the judicial district in which your physical address is located, or if your physical address is outside of the Bangladesh, for any judicial district in which we may be located, and that you will accept service of process from the person who provided notification of allegedly infringing material or an agent of such person.`
		],

		section_144_title: `14.4 Termination of Repeat Infringers`,
		section_144_des: [
			`We reserve the right, in our sole discretion, to terminate the account or access of any user of this Site or Service who is the subject of repeated LEGAL or other infringement notifications.`,
			`CvWave seeks to preserve any and all exemptions from liability that may be available under the copyright law, but does not necessarily stipulate that it is a service provider as defined in (LAW NUMBER) or elsewhere.
		`
		],

		section_15_title: `INTELLECTUAL PROPERTY, TRADEMARKS, COPYRIGHT, OWNERSHIP, & LICENSE`,
		section_15_des: [
			`<b>CvWave</b> is a project of <b>BigWave</b> and <b>CvWave</b> is powered by <b>BigWave</b> The Services provided on this website, including all materials and content incorporated into it, as well as anything made available to download, are protected by copyright, patents, trade secrets and other proprietary rights (“Copyrights”). The trademarks, names, logos and other images are also protected as registered and unregistered trademarks, trade names and service marks owned by <b>BigWave (“Trademarks”)</b>. We respect the intellectual property right of others and ask our users to do so as well. The use or misuse of our Copyrights or Trademarks is prohibited and nothing in the Services, explicit or implied, grants you a license or right to the Copyrights, Trademarks, or other intellectual property rights of <b>CvWave</b> or any other third party.`,
			`From time to time <b>CvWave</b> may use or refer to trademarks and/or trade names that are property of third parties. This use is not meant to represent <b>CvWave</b> affiliation with these third parties and appear for identification purposes only.`
		],

		section_16_title: `DISCLAIMER, LIMIT OF LIABILITY, AND ASSUMPTION OF RISK`,

		section_16_1_title: `16.1 Disclaimer`,
		section_16_1_des: [
			`<b>CvWave</b> does not endorse any User Content submitted to the Service by any user or other licensor, or any opinion, recommendation, or advice expressed therein, and <b>CvWave</b> expressly disclaims any and all liability in connection with User Content.`,
			`<b>CvWave</b> does not permit copyright infringing activities and infringement of intellectual property rights on the Service, and <b>CvWave</b> will remove User Content if properly notified that such User Content infringes on another’s intellectual property rights (please refer to our Copyright and Intellectual Property Infringement Notification Section). <b>CvWave</b> reserves the right to remove User Content without prior notice.`,
			`We do our best to describe every product or service offered on the Service as accurately as possible. However, we are human, and therefore we do not warrant that product specifications, pricing, or other content on the online services is complete, accurate, reliable, current, or error-free. In the event of any errors relating to the pricing or specifications, <b>CvWave</b> shall have the right to refuse or cancel any orders in its sole discretion. Additional terms may apply.`
		],

		section_16_2_title: `16.2  Limit of Liability`,
		section_16_2_des: [
			`To the best extent allowed by law, <b>CvWave</b> and its affiliated companies will not be held responsible for any claims that may arise from this agreement or your use of the services. This includes but is not limited to any special, unexpected, or consequential damages, such as lost profits, missed business opportunities, loss of data or confidential information, loss of privacy, or costs associated with finding alternative goods or services. This limitation applies even if we didn't foresee these damages happening and even if it's related to actions like not acting in good faith, negligence, or other legal reasons.`,
			`In any case, the total amount of liability that <b>CvWave</b> and its affiliates might have towards you under this agreement will not exceed the total fees you've paid or need to pay for two months of services during the term of this agreement, if any. This limit on liability applies whether the claim is based on the contract, negligence, strict liability, or any other reason. We both agree that this limit is a fair way to distribute the risks involved, and it's a crucial part of our agreement. Without these limitations, we might not be able to offer our services. These restrictions on liability will be applied as broadly as the law allows in your jurisdiction.`
		],

		section_16_3_title: `16.3  Assumption of Risk`,
		section_16_3_des: `When you use the Services, you willingly and honestly assume all risk. You freely consent to release, waive, discharge, hold harmless, protect, and indemnify <b>CvWave</b> and its owners, officers, directors, staff, agents, associates, consultants, representatives, sublicensee, successors, and assigns from any and all claims, acts, or losses for bodily injury, property damage, wrongful death, and other claims, actions, or losses on behalf of yourself, your personal representatives, and heirs.`,

		section_17_title: `CONFIDENTIALITY`,
		section_17_des: `Through the website, you may obtain access to certain confidential information of <b>CvWave</b> and its suppliers, including without limitation technical, contractual, product, program, pricing, marketing and other valuable information that should reasonably be understood as confidential (“Confidential Information”). You must hold Confidential Information in strict confidence. The Title to Confidential Information remains with <b>CvWave</b> and its suppliers. <br/>
		The Terms impose no obligation upon you with respect to Confidential Information that you can establish by legally sufficient evidence:
		`,
		section_17_des_li: [
			`You possessed prior to your receipt from <b>CvWave</b>, without an obligation to maintain its confidentiality;`,
			`Is or becomes generally known to the public through no act or omission by you, or otherwise without violation of the Terms;`,
			`You obtained from a third party who had the right to disclose it, without an obligation to keep such information confidential; or,`,
			`You independently developed without the use of Confidential Information and without the participation of individuals who have had access to it as can be evidenced by written records.`
		],

		section_18_title: `CHANGES TO THESE TERMS`,
		section_18_des: [
			`CVWave reserves the right to modify this Agreement and our Privacy Policy at any time, with or without notice to you, either by publishing a new version or giving you a notice of the change to your registered email address. It is your duty to read and understand this Agreement and the Privacy Policy on a regular basis.`,
			`We may modify, add to, suspend, or delete these Terms of Use or other agreements, in whole or in part, in our sole discretion at any time, with such modifications, additions or deletions being immediately effective upon their posting to the Site. Your use of the Site or the Services after modification, addition or deletion of these Terms of Use shall be deemed to constitute acceptance by you of the modification, addition, or deletion.`
		]
	},

	refund_policy: {
		issuance_of_refunds: "Issuance of Refunds",
		issuance_of_refunds_1:
			"1. The processing time of your refund depends on the type of refund and the payment method you used.",
		issuance_of_refunds_2:
			"2. The refund period / process starts when <b>CvWave</b> has processed your refund according to your refund request.",
		issuance_of_refunds_3:
			"3. The refund amount depends on the subscription plan and the payment method you used.",

		refund_type: "Refund Types",
		refund_type_heading:
			"<b>CvWave</b> will process your refund according to the following refund types",

		refund_type_1_heading: `1. Refunds for technical error - In the event that you encounter a situation where payment has been deducted from your account, card, or mobile wallet, but the <b>CvWave</b> application indicates that it remains unpaid due to a technical error, we kindly request that you initiate a new payment to secure your subscription. Simultaneously, please reach out to your card issuer bank to report the discrepancy and inform <b>CvWave</b> of the issue. <br/> <br/>In the event that <b>CvWave</b> receives double payment, rest assured that you will be refunded the excess amount through a payment reversal process. Your prompt action in this matter will help ensure the swift resolution of the situation.`,

		refund_type_2_heading: `2. Refunds from Money-Back Guarantee - Upon placing an order, you forfeit withdrawal rights and agree to instant service delivery, including digital products. <b>CvWave</b> offers a money-back guarantee within 7 days of the initial payment. If you submit a money-back request within this 7-day period, your subscription will be considered as <b>"Basic Plan"</b> and we will initiate the refund process based on the following calculation:`,

		refund_type_2_li_1: "(A) Subscription Price <b>1,000</b>",
		refund_type_2_li_2: "(B) Discount Coupon -",
		refund_type_2_li_3: "(C) Your Payment (A-B) <b>1,000</b>",
		refund_type_2_li_4:
			"(D) 7 days Fee (Basic Subscription Price-<b>500/30</b> Days*<b>7</b> Days) <b>117</b>",
		refund_type_2_li_5: "(E) Gateway Fee (Your Payment*<b>2.5%</b>) <b>25</b>",
		refund_type_2_li_6: "(F) Value Added Tax <b>48</b>",
		refund_type_2_li_7: "(G) Total Deduction (D+E+F) <b>189</b>",
		refund_type_2_li_8: "(H) Refund Amount (C-G) <b>811</b>",

		refund_type_2_note:
			"<b>Note: 2.5%</b> charges will not be reimbursed to account holder in bKash refund or reverse cases.",

		refund_type_3_heading:
			"3. Refunds from Coupon - Please note that coupons are non-refundable. While you can apply a coupon during the subscription process to receive a discount or benefit, it's important to understand that the value of the coupon itself will not be refunded in the event of a refund request.",

		method_th_1: "Payment Method",
		method_th_2: "Refund Option",
		method_th_3: "Refund Time",

		method_td_1_method: "Debit or Credit Card",
		method_td_1_option: "Debit or Credit Card Payment Reversal",
		method_td_1_time: "<b>7-10</b> working days",

		method_td_2_method: "Rocket",
		method_td_2_option: "Mobile Wallet Reversal",
		method_td_2_time: "<b>5-7</b> working days",

		method_td_3_method: "Nagad",
		method_td_3_option: "Mobile Wallet Reversal",
		method_td_3_time: "<b>5-7</b> working days",

		method_td_4_method: "BKash",
		method_td_4_option: "Mobile Wallet Reversal",
		method_td_4_time: "<b>5-7</b> working days",

		method_table_note:
			"<b>Note:</b> Maximum refund timeline excludes weekends and public holidays.",

		model_th_1: "Modes of Refund",
		model_th_2: "Description",

		model_td_1_title: "Debit Card or Credit Card",
		model_td_1_description:
			"If the refunded amount does not appear on your card statement after the refund process has been completed, and you have received a notification from CvWave confirming the refund, we kindly request that you reach out to your personal bank for further assistance.",

		model_td_2_title: "BKash / Rocket / Nagad Mobile Wallet",
		model_td_2_description:
			"The refunded amount will be credited back to the same mobile account details that you provided at the time of payment. This ensures that the refund is returned to the original source, making the process more convenient for you."
	},

	privacy_policy: {
		title: "Privacy Statement",

		intro: "Introduction",
		intro_contents: [
			`Welcome! This Privacy Statement (“Policy”) explains the online data practices, your data options and the disclosure of the information you Submit (“Information”) to <b>CvWave</b> (“<b>CvWave</b>”, “we”, or “us”) in connection with our Services. To the extent permissible by law, by visiting <b>CvWave</b> you agree to the processing of your information data as explained in this Policy, herein incorporated by reference. By visiting of our Website, you are agreeing to the terms of this Privacy Policy and the accompanying Terms of Service, both of which govern your use of the Website. By providing us information offline, you are also agreeing to the terms of this Privacy Policy.`,
			`If you need any support or questions, you can reach out our exceptional customer service team through our Contact Us page at <a href="/contact-us" target="__blank"><b>CvWave.com/contact-us</b></a> .`
		],

		what_info_collect: "What information do We collect?",
		what_info_collect_contents: [
			`The only information we collect about you is the information you provide to us yourself through our standard forms, other correspondence we may have with you and any technical information gathered automatically when you use our websites, for example details of your Internet browser type.`,
			`This data can include but is not limited to; your full name, email address, residential address, phone number, fax number, credit/debit card number, social security or other identifying personal number, user name and password, security questions and answers, educational and employment history, GPS coordinates and location-tracking information, and usernames and passwords for online accounts, all other data entered by you in the resume & cover letter.`
		],

		used_data: "How can we USE your data?",
		used_data_contents: [
			`<b>CvWave</b> is powered by BigWave and your data will be shared with BigWave for product fulfillment. All your personal information is visible, accessible and editable from your “Account” page.`,
			`Additional information such as drafts and final revisions are located on the “Order” page.`,
			`In the case that you wish to remove or delete your account completely from our platform you will need to contact us directly. This can be done via our contact us page form, email, telephone or live chat.`,
			`A copy of your data for the sake of portability can be acquired by reaching out to us via the contact us page form, email, telephone or live chat.`,
			`If you wish to remove yourself from our promotional material you can do so via the Unsubscribe link found on each email as well as via the Unsubscribe from Newsletter button found on your “Account” page, this will remove you from all promotional lists, please note you will continue to receive transactional emails as these are integral to the operation of your order and the service we provide.`
		],

		how_access_data: "How Can You Access Your Data?",
		how_access_data_contents: `You can download all your resumes & cover letters after signing in to your account. Please contact US at <a href="mailto:support@CvWave.com"><b>support@CvWave.com</b></a> if you need any assistance.`,

		payment: "Payment",
		payment_contents: `When you pay for our premium services, our third-party payment processor may store data such as your name and email address to send you a payment receipt or other notifications. As stated above, this third party are bound by confidentiality and privacy obligations consistent with this Policy and have limited access to your information, meant only to perform these tasks on our behalf and are committed to not disclose or use it for any other purposes.`,

		refunds: "Refunds",
		refunds_contents: `Due to the nature of our offering, we are unable to provide full refunds to users who purchase for the paid subscription. The reason is that <b>CvWave</b> is offering Free service, and you can “inspect the service” and all the site’s features in a limited capacity for free, before becoming a paid member. Our free layer is extremely generous, and we encourage you to use all features so you know exactly what’s in the paid version. To protect ourselves from abuse (i.e. people buying the service, using it to the fullest, and requesting a refund when they are done using it), we can’t offer full refund rather we are offering partial refund to our users through money-back guarantee. Since you were/are able to inspect what you buy before buying, we can’t offer full refunds. We appeal any chargebacks aggressively, which means you may have to pay a fine for purchases you made where you used the service but still decided to request a refund. In case of any transaction failure due to any technical or electrical issues in your area, submission of proper documents relating to the issues if our concern team will identify and found the reason is accurate then we can refund the transaction amount after deducting regular transaction fees.`,

		used_cookies: "Use of Cookies",
		used_cookies_contents: [
			`<b>CvWave</b> web application may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.`,
			`One of the primary purposes of cookies is to provide a convenience feature to save your time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize <b>CvWave</b> pages, or register with <b>CvWave</b> site or services, a cookie helps <b>CvWave</b> to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same <b>CvWave</b> website, the information you previously provided can be retrieved, so you can easily use the <b>CvWave</b> features that you customized.`,
			`You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the <b>CvWave</b> services or websites you visit.`
		],

		third_party_disclosure: "Third-party disclosure",
		third_party_disclosure_contents: [
			`We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.`,
			`However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.`
		],

		for_children: "Privacy Protection for Children Using the Internet",
		for_children_contents:
			"Protecting children’s privacy is important to us. For that reason, we do not collect or maintain information of those persons we actually know are under the age of thirteen (13) nor is any part of the Site or Services targeted to attract anyone under the age of thirteen (13) without express parental or guardian consent. We request that all users of the Site and Services who are under the age of thirteen (13) not disclose or provide any personally-identifiable information. If we discover that a child under thirteen (13) has provided us with personally-identifiable information, we will delete that child’s personally-identifiable information from our records.",

		data_security: "Data Security",
		data_security_contents: [
			`We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.`,
			`Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a closed lock icon at the bottom of your web browser, or looking for “https” at the beginning of the address of the web page.`,
			`While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.`
		],

		termination: "Termination of your Account",
		termination_contents: `You agree that <b>CvWave</b> may terminate your access to the site and/or services without notice if: (a) <b>CvWave</b> determines that you have violated these Terms of Service, or (b) <b>CvWave</b> is requested or required to do so by any court component jurisdiction or government authority in any country. You agree that the site and/or services are not intended to act as an indefinite information repository and, therefore, <b>CvWave</b> may, upon such termination, deactivate or delete your account and any related data, information, and files, and bar any further access to such data, information, and files. Such action may include, among other things, accessing you content or data and/or discontinuing your use of the Site and/or Services without refund or compensation.`,

		update_privacy: "Changes to this Privacy Statement",
		update_privacy_contents: `We may update this privacy policy from time to time. The updated version will be showed by an updated “Revised” date and the updated version will be effective as soon as it is available. If we make substantial changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.`,

		contact_us: "How can you Contact us about this Policy?",
		contact_us_contents: `We always want to hear from our customers. If you have any queries, suggestions or observations about this policy, you may contact us, by sending an email via <a href="mailto:support@CvWave.com"><b>support@CvWave.com</b></a>.`
	},

	package_limit: {
		// When a free user selects a premium template.
		fu_premium_template: {
			header: "Unlock Premium Templates",
			description: `You are currently on the <b>free plan</b>, which grants you access to free templates. To access premium templates, we recommend upgrading your subscription.`
		},

		// When a free user has already selected one free template but wishes to create another resume using a different free template.
		multiple_free_template: {
			header: "Upgrade Your Plan <br/> for Unlimited Resume Creation",
			description: `The <b>free plan</b> allows for the creation of a single resume. To create an unlimited number of resumes, we recommend upgrading your plan.`
		},

		// When a free user navigates to the template selection window within the editor and opts to choose premium templates for creating a resume. (choose template)
		fu_choose_premium_template: {
			header: "Enhance Your Experience: Upgrade for Premium Template Access",
			description: `You are currently on the <b>free plan</b>, which grants you access to free templates. To access premium templates, we recommend upgrading your subscription.`
		},

		// When a free user wishes to download their resume in PDF format.
		download_restriction: {
			header: "Upgrade Your Subscription for Premium Features",
			description:
				"To enable the PDF download feature, please consider upgrading your subscription. As a <b>free user</b>, you have the option to share your resume through a generated link, but downloading it in PDF format is a premium feature."
		},

		// When a free user who has already created a resume but desires to either create another new resume or make a copy of the existing one.
		create_new_or_copy_restriction: {
			header: "Upgrade for Limitless Resume Creation",
			description:
				"You have access to create only one resume on the <b>free plan</b>. To create an unlimited number of resumes, we recommend upgrading your plan."
		},

		// When a paid user or subscriber wishes to select additional templates beyond their allotted limit.
		additional_template_restriction: {
			header: "Consider Upgrading Your Package for More Choices",
			description: `You have exceeded the template limit for your current package, which allows the use of <span id="maxNoTemplate"></span> templates. To access additional templates, please consider upgrading your package.`
		},

		// When a paid user or subscriber wishes to create additional resumes beyond their allocated limit.
		additional_resume_restriction: {
			header: "Unlock More Resume Opportunities",
			description: `You have exceeded the resume limit. Your current package allows for the creation of up to <span id="maxNoTemplate"></span> resumes at a time. To create more resumes, we recommend upgrading your package.`
		},

		// for invalid coupon
		invalid_coupon: {
			header: "Invalid Coupon",
			description:
				"The value of your coupon exceeds the cost of your subscription. To make use of the coupon, please consider subscribing to a plan with a higher value than the coupon amount."
		},

		// student package restriction
		subscription_restriction: {
			header: "We Apologies",
			description: `It seems you don't meet the eligibility criteria for a student package. In order to access our student subscription, it's essential to verify your student status before making the purchase. You can easily verify your student status at any time within the <a href="../account-settings"
			rel="noreferrer">Account Setting</a> page. Thank you for your understanding.`
		},

		// isVerifyWithPhotoIdentity
		verify_with_photo_identity: {
			header: "Thank You",
			description: `You have successfully applied for student verification. It would take 3-4 days for us to verify your request. Once we verified your request we will update your account.`
		},

		// cancel_subscription
		act_cancel_subscription: {
			header: "Cancel Subscription",
			description:
				"Are you sure you want to cancel your subscription? This action can't be undone."
		},

		// refund request
		act_refund_request: {
			header: "Refund Request",
			description: `If you ask for refund, then your subscription will be change to free plan. Your refund will be sent to you within 7-10 days depending on your payment method. You can learn more about our <a href="../refund-policy" target="_blank"
				rel="noreferrer" id="refund-anchor">refund policy</a>  here.`
		},

		// student-verification
		stu_verification_alert: {
			header: "Warning",
			description: `Once you verified your account with student email id, you no longer able to use your old email id for login.`
		},

		yes: "Yes",
		no: "No"
	},

	thank_you_page: {
		success: {
			header: "Thank You !",
			description:
				"<b>Thank you for your payment.</b> Your transaction has been successfully processed. You will receive a confirmation email shortly. If you have any questions or need further assistance, please don't hesitate to contact our support team.",

			btn_text: "Back Your Dashboard"
		},

		failed: {
			header: "Payment Unsuccessful",
			description:
				"<b>We apologize for the inconvenience.</b> Please double-check your payment details and consider contacting your bank if needed. For further assistance, reach out to our support team. We appreciate your patience & understanding.",

			btn_text: "Back"
		}
	}
};

export { english };
