const convertToBanglaDate = (englishDate) => {
	const dateArray = englishDate.split(" ");
	const day = parseInt(dateArray[0], 10);
	const month = dateArray[1];

	const monthMapping = {
		January: "জানুয়ারী",
		February: "ফেব্রুয়ারী",
		March: "মার্চ",
		April: "এপ্রিল",
		May: "মে",
		June: "জুন",
		July: "জুলাই",
		August: "আগস্ট",
		September: "সেপ্টেম্বর",
		October: "অক্টোবর",
		November: "নভেম্বর",
		December: "ডিসেম্বর"
	};

	const monthInBangla = monthMapping[month];
	return `${day} ${monthInBangla}`;
};

export { convertToBanglaDate };
