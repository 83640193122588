// external components
import { Tooltip, Zoom } from "@mui/material";
import { memo } from "react";
import AutosizeInput from "react-input-autosize";

// internal components
import DragIcon from "./DragIcon";

const SectionTitle = ({
	title,
	name,
	control,
	dispatchControl,
	_id,
	setInputValues,
	value,
	updateResumeInfo,
	rename,
	revert,
	drag,
	whenAdditionalSection
}) => {
	return (
		<div
			className="input-field fill-underline"
			id={control.border ? "active" : ""}
		>
			<AutosizeInput
				id={name === "custom_title" ? `name-${_id}` : name}
				name={name}
				placeholder={title}
				onFocus={() =>
					control.editTitle && dispatchControl({ type: "open-border" })
				}
				onBlur={() => {
					dispatchControl({ type: "close-border-editor" });
					setInputValues((prevValues) => ({
						...prevValues,
						[name]: value ? value : title
					}));

					updateResumeInfo({
						[name]: value ? value : title,
						_id
					});
				}}
				onChange={(e) =>
					setInputValues((prevValues) => ({
						...prevValues,
						[name]: e.target.value
					}))
				}
				value={value}
				readOnly={control.editTitle ? false : true}
				maxLength="40"
			/>

			{/* rename, revert and additional_section_delete  */}
			<div
				className="edit-icon"
				id={whenAdditionalSection ? "when-3-icons" : ""}
			>
				<Tooltip
					title={rename}
					placement="top"
					arrow
					TransitionComponent={Zoom}
					componentsProps={{
						tooltip: {
							sx: {
								bgcolor: "#040026",
								"& .MuiTooltip-arrow": {
									color: "#040026"
								},
								fontSize: "13px",
								fontWeight: "400",
								textAlign: "center"
							}
						}
					}}
				>
					<label htmlFor={name === "custom_title" ? `name-${_id}` : name}>
						<i
							className="fa-solid fa-pencil"
							onClick={() => {
								dispatchControl({ type: "open-editor" });
							}}
						></i>
					</label>
				</Tooltip>

				{revert && value !== title && (
					<Tooltip
						title={revert}
						placement="top"
						arrow
						TransitionComponent={Zoom}
						componentsProps={{
							tooltip: {
								sx: {
									bgcolor: "#040026",
									"& .MuiTooltip-arrow": {
										color: "#040026"
									},
									fontSize: "13px",
									fontWeight: "400",
									textAlign: "center"
								}
							}
						}}
					>
						<i
							className="fa-solid fa-rotate-left"
							onClick={() => {
								setInputValues((prevValues) => ({
									...prevValues,
									[name]: title
								}));

								updateResumeInfo({
									[name]: title,
									_id
								});
							}}
						></i>
					</Tooltip>
				)}

				{/* additional-section delete  */}
				{whenAdditionalSection && (
					<Tooltip
						title={whenAdditionalSection.deleteText}
						placement="top"
						arrow
						TransitionComponent={Zoom}
						componentsProps={{
							tooltip: {
								sx: {
									bgcolor: "#040026",
									"& .MuiTooltip-arrow": {
										color: "#040026"
									},
									fontSize: "13px",
									fontWeight: "400",
									textAlign: "center"
								}
							}
						}}
						onClick={() =>
							whenAdditionalSection.setRemoveSection({
								sectionId: whenAdditionalSection.sectionId,
								key: whenAdditionalSection.key
							})
						}
					>
						<i className="fa-solid fa-trash-can" id="delete-icon"></i>
					</Tooltip>
				)}
			</div>

			{/* drag  */}
			{drag && <DragIcon className="for-section-drag" />}
		</div>
	);
};

export default memo(SectionTitle);
