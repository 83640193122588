// external components
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import AutosizeInput from "react-input-autosize";
import { Link, useNavigate } from "react-router-dom";
import {
	FacebookIcon,
	FacebookMessengerIcon,
	FacebookMessengerShareButton,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	WhatsappIcon,
	WhatsappShareButton
} from "react-share";
import sortArray from "sort-array";

// internal components
import { GetContextApi } from "../../../storage/ContextApi";
import { convertToBanglaDate } from "../../../utility";
import Template from "../../for_resume_editor/Preview/Template";
import "./ResumesContainer.css";

const ResumesContainer = ({
	getResumes,
	contents,
	setResumes,
	setRestrictionObj,
	restrictionHandler
}) => {
	// resumesContainer contents
	const {
		button_text,
		empty_resume_header,
		empty_resume_des,

		resume_title,
		updated,
		edit,
		share,
		download,
		copy,
		delete_txt,
		delete_a_resume,
		duplicate_a_resume,
		deleteBtn,
		duplicate,
		cancel
	} = contents.user_dashboard;

	// restriction contents
	const {
		download_restriction,
		create_new_or_copy_restriction,
		additional_resume_restriction
	} = contents.package_limit;

	// define contents for share-link
	const { header, sub_header, url_title, open_link, copy_link, copied } =
		contents.share_link_modal;

	// for getting current-user
	const { currentUser } = GetContextApi();

	// for redirect specific resume editor
	const Navigate = useNavigate();

	// for input-border animation
	const [isActiveTitle, setIsActiveTitle] = useState(false);

	// for share link toggle
	const [shareLinkT, setShareLinkT] = useState("");

	// for getting url
	const [getUrl, setUrl] = useState("");

	// for update specific-resume title
	const [updateTitle, setUpdateTitle] = useState("");

	// for updating specific resume's title on db start
	const updatingSpecificResumeTitle = async () => {
		try {
			const response = await fetch(`/resume/update-title`, {
				method: "PUT",
				body: JSON.stringify({
					_id: updateTitle._id,
					title: updateTitle.title
				}),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				setResumes(result);
				setUpdateTitle("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for updating specific resume's title on db end

	// for deleting specific resume from db start
	const [getDeleteResume, setDeleteResume] = useState("");

	const deletingSpecificResume = async () => {
		try {
			const response = await fetch(`/resume/delete-specific-resume`, {
				method: "DELETE",
				body: JSON.stringify({
					_id: getDeleteResume._id
				}),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				setResumes(result);
				setDeleteResume("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for deleting specific resume from db end

	// for duplicate specific resume on db start
	const [getDuplicateResume, setDuplicateResume] = useState("");
	const [isActiveDupResume, setIsActiveDupResume] = useState(false);

	const duplicateSpecificResume = async () => {
		try {
			const response = await fetch(`/resume/duplicate-specific-resume`, {
				method: "POST",
				body: JSON.stringify({
					_id: getDuplicateResume._id,
					title: getDuplicateResume.title
				}),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				setResumes(result?.allResumes.length > 0 ? result.allResumes : []);
				result?.counter && Navigate(`/resume/${result.counter}/create-resume`);

				setDuplicateResume("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	};
	// for copy specific resume on db end

	// social-share
	const title = "CVWAVE - Online Resume Builder | Free Resume Maker";
	const shareUrl = `${process.env.REACT_APP_DOMAIN}/resume/share/${shareLinkT}`;

	return (
		<>
			<div className="resumes-container">
				{getResumes.length === 0 ? (
					<div className="when-not-resume">
						{/* when-not-resume start  */}
						<div className="when-not-resume-wrapper">
							<div className="for-img"></div>

							<h3>{empty_resume_header}</h3>
							<p>{empty_resume_des}</p>

							<button
								onClick={() => restrictionHandler(getResumes)}
								className="primary-btn-styles"
							>
								<i className="fa-solid fa-plus"></i> {button_text}
							</button>
						</div>
						{/* when-not-resume end  */}
					</div>
				) : (
					<div className="when-have-resumes-container">
						{sortArray(getResumes, {
							by: "createdAt",
							order: "desc"
						}).map((value, index) => {
							return (
								<div
									className="section"
									key={index}
									id={
										currentUser.package["Package Name"] === "Free Plan" &&
										index !== 0
											? "disable-section"
											: ""
									}
								>
									{/* img-container start  */}
									<div
										className="img-container"
										onClick={() =>
											Navigate(`/resume/${value.counter}/create-resume`)
										}
									>
										<img
											src={`/assets/templates/${value.template.img}`}
											alt="template"
										/>
									</div>
									{/* img-container end  */}

									{/* content-wrapper start  */}
									<div className="content-wrapper">
										<div className="header">
											{/* title-start  */}
											<div className="title">
												<div
													className="input-field"
													id={
														updateTitle &&
														updateTitle._id === value._id &&
														isActiveTitle
															? "active"
															: ""
													}
												>
													<AutosizeInput
														placeholder="Untitled"
														onBlur={() => {
															setIsActiveTitle(false);
															updateTitle &&
															updateTitle.title === value.resume_title
																? setUpdateTitle("")
																: updateTitle._id === value._id &&
																  updatingSpecificResumeTitle();
														}}
														onChange={(e) =>
															setUpdateTitle({
																_id: value._id,
																title: e.target.value
															})
														}
														value={
															updateTitle && updateTitle._id === value._id
																? updateTitle.title
																: value.resume_title === "Untitled"
																? resume_title
																: value.resume_title
														}
														readOnly={
															updateTitle && updateTitle._id === value._id
																? false
																: true
														}
														maxLength="25"
													/>
												</div>

												<div className="edit-icon">
													<label htmlFor="set-title">
														<i
															className="fa-solid fa-pencil"
															onClick={() => {
																setUpdateTitle({
																	_id: value._id,
																	title: value.resume_title
																});
																setIsActiveTitle(true);
															}}
														></i>

														<h6 className="title-popup">Rename</h6>
													</label>
												</div>
											</div>
											{/* title-end  */}

											{/* update-time start  */}
											<div className="updated-at">{`${updated}  ${
												contents.title === "bangla"
													? convertToBanglaDate(
															moment(value.updatedAt).format("DD MMMM")
													  )
													: moment(value.updatedAt).format("DD MMMM")
											} ${moment(value.updatedAt).format("hh:mm A")}`}</div>
											{/* update-time end */}
										</div>

										{/* option-section-wrapper start  */}
										<div className="option-section-wrapper">
											<div
												className="option"
												onClick={() =>
													Navigate(`/resume/${value.counter}/create-resume`)
												}
											>
												<i className="fa-solid fa-pencil"></i>
												<span>{edit}</span>
											</div>

											<div
												className="option"
												onClick={() => setShareLinkT(value._id)}
											>
												<i className="fa-solid fa-share-from-square"></i>{" "}
												<span>{share}</span>
											</div>

											{currentUser.package["Package Name"] === "Free Plan" ? (
												<div
													className="option"
													onClick={() =>
														setRestrictionObj(download_restriction)
													}
												>
													<i className="fa-solid fa-download"></i>
													<span>{download}</span>
												</div>
											) : (
												<PDFDownloadLink
													document={<Template getResume={value} />}
													fileName={`${currentUser.name.replace(
														/\s/g,
														"-"
													)}-cvwave.pdf`}
													className="text-decoration-none"
												>
													{({ blob, url, loading, error }) => (
														<div className="option">
															<i className="fa-solid fa-download"></i>
															<span>{download}</span>
														</div>
													)}
												</PDFDownloadLink>
											)}

											<div
												className="option"
												onClick={() =>
													currentUser.maximum_resumes > getResumes.length
														? setDuplicateResume({
																_id: value._id,
																title: value.resume_title
														  })
														: currentUser.package["Package Name"] ===
														  "Free Plan"
														? setRestrictionObj(create_new_or_copy_restriction)
														: setRestrictionObj(additional_resume_restriction)
												}
											>
												<i className="fa-solid fa-copy"></i>
												<span>{copy}</span>
											</div>

											<div
												className="option"
												onClick={() =>
													setDeleteResume({
														_id: value._id,
														title: value.resume_title
													})
												}
											>
												<i className="fa-solid fa-trash-can"></i>
												<span>{delete_txt}</span>
											</div>
										</div>
										{/* option-section-wrapper end  */}
									</div>
									{/* content-wrapper end  */}
								</div>
							);
						})}
					</div>
				)}

				{/* for deleting specific resume start  */}
				{getDeleteResume && (
					<div className="delete-resume-container">
						<div className="delete-resume-wrapper" data-aos="fade-down">
							<div className="top-section">
								<h3>{delete_a_resume}</h3>
							</div>

							<div className="input-field">
								<input value={getDeleteResume.title} readOnly />
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setDeleteResume("");
									}}
									className="primary-btn-styles"
								>
									{cancel}
								</button>
								<button
									onClick={() => deletingSpecificResume()}
									className="primary-btn-styles"
								>
									{deleteBtn}
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for deleting specific resume end */}

				{/* for coping specific resume start  */}
				{getDuplicateResume && (
					<div className="delete-resume-container">
						<div className="delete-resume-wrapper" data-aos="fade-down">
							<div className="top-section">
								<h3>{duplicate_a_resume}</h3>
							</div>

							<div
								className="input-field"
								id={isActiveDupResume ? "active" : ""}
							>
								<input
									onFocus={() => setIsActiveDupResume(true)}
									onBlur={() => {
										setIsActiveDupResume(false);
									}}
									onChange={(e) =>
										setDuplicateResume({
											...getDuplicateResume,
											title: e.target.value
										})
									}
									value={getDuplicateResume.title}
								/>
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setDuplicateResume("");
									}}
								>
									{cancel}
								</button>
								<button onClick={() => duplicateSpecificResume()}>
									{duplicate}
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for coping specific resume end */}

				{/* for share link start  */}
				{shareLinkT && (
					<div className="share-link-container">
						<div className="share-link-wrapper" data-aos="fade-down">
							<div className="share-top-section">
								<h3>{header}</h3>
							</div>

							<div className="share-description">{sub_header}</div>

							<div className="share-input-field">
								<label htmlFor="url">{url_title}</label>
								<input
									className={getUrl ? "active" : ""}
									value={shareUrl}
									readOnly
									id="url"
								/>
							</div>

							<div className="share-btn-container">
								<div className="share-left-btns">
									<FacebookShareButton
										url={shareUrl}
										quote={title}
										hashtag={"#freeresumetemplate"}
										className="share-btn"
									>
										<FacebookIcon className="icon" />
									</FacebookShareButton>

									<FacebookMessengerShareButton
										url={shareUrl}
										appId={process.env.REACT_APP_APPID}
										quote={title}
										className="share-btn"
									>
										<FacebookMessengerIcon className="icon" />
									</FacebookMessengerShareButton>

									<WhatsappShareButton
										url={shareUrl}
										quote={title}
										hashtag={"#freeresumetemplate"}
										className="share-btn"
									>
										<WhatsappIcon className="icon" />
									</WhatsappShareButton>

									<LinkedinShareButton
										url={shareUrl}
										title={title}
										className="share-btn"
									>
										<LinkedinIcon className="icon" />
									</LinkedinShareButton>
								</div>

								<div className="share-right-btns">
									<button
										onClick={() => {
											setShareLinkT("");
										}}
									>
										<Link
											to={`../resume/share/${shareLinkT}`}
											target="_blank"
											rel="noreferrer"
										>
											{open_link}
										</Link>
									</button>

									<button
										onClick={() => {
											setUrl(shareUrl);
											navigator.clipboard.writeText(shareUrl);
										}}
									>
										{getUrl ? copied : copy_link}
									</button>
								</div>
							</div>

							<div
								className="share-close-btn"
								onClick={() => {
									setShareLinkT("");
									setUrl("");
								}}
							>
								<i className="fa-solid fa-xmark"></i>
							</div>
						</div>
					</div>
				)}
				{/* for share link end  */}
			</div>
		</>
	);
};

export default ResumesContainer;
