// external components

// internal components
import "./_AgreeCheckbox.scss";

const AgreeCheckbox = ({
	isCheckTerm,
	setIsCheckTerm,
	btnClicked,
	isSendUpdate,
	setIsSendUpdate,
	contents
}) => {
	// contents
	const { agreeText, isSendUpdateTxt } = contents.agree_checkbox;

	return (
		<>
			<div className="checkbox-item">
				<input
					type="checkbox"
					id="checked1"
					value="true"
					onClick={() => setIsCheckTerm(!isCheckTerm)}
					readOnly
					className="checkbox"
				/>

				<label
					htmlFor="checked1"
					className={isCheckTerm ? "checked label" : "label"}
					id={btnClicked && !isCheckTerm ? "not-checked" : ""}
				>
					<p dangerouslySetInnerHTML={{ __html: agreeText }}></p>
				</label>
			</div>

			<div className="checkbox-item" id="second">
				<input
					type="checkbox"
					id="checked2"
					value="true"
					onClick={() => setIsSendUpdate(!isSendUpdate)}
					readOnly
					className="checkbox"
				/>

				<label
					htmlFor="checked2"
					className={isSendUpdate ? "checked label" : "label"}
				>
					<p>{isSendUpdateTxt}</p>
				</label>
			</div>
		</>
	);
};

export default AgreeCheckbox;
