// external components
import moment from "moment";
import { useEffect, useRef, useState } from "react";

// internal components
import TableSkeleton from "../AdminDashboardContents/TableSkeleton";

const AllUsersList = ({ isLoading, allUsers, allResumes, setSelectedTab }) => {
	// for option toggle
	const [optionT, setOptionT] = useState("");

	// for hidden navbar when click outside start
	const myOption = useRef();

	const handleClickOutsideOp = (e) => {
		if (!myOption.current?.contains(e.target) && optionT) {
			setOptionT("");
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideOp);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideOp);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionT]);
	// for hidden navbar when click outside end

	return (
		<>
			{/* statistic start  */}
			<div className="statistic">
				<div className="statistic-section" id="registered">
					<span className="title">Registered &nbsp;Users</span>
					<div className="icon-number-wrapper">
						<span className="number">{allUsers.length}</span>
						<span className="icon" id="registered-icon">
							<i className="fa-solid fa-list-check"></i>
						</span>
					</div>
				</div>

				<div className="statistic-section" id="resume">
					<span className="title">Created &nbsp;Resumes</span>

					<div className="icon-number-wrapper">
						<span className="number">{allResumes.length}</span>

						<span className="icon" id="resume-icon">
							<i className="fa-solid fa-circle-check"></i>
						</span>
					</div>
				</div>

				<div className="statistic-section" id="cover-letter">
					<div className="title">Created &nbsp;Cover &nbsp;Letters</div>

					<div className="icon-number-wrapper">
						<span className="number">{0}</span>

						<span className="icon" id="cover-letter-icon">
							<i className="fa-solid fa-circle-check"></i>
						</span>
					</div>
				</div>

				<div className="statistic-section" id="subscription">
					<span className="title">Paid &nbsp;Subscription</span>

					<div className="icon-number-wrapper">
						<span className="number">{}</span>

						<span className="icon" id="subscription-icon">
							<i className="fa-solid fa-dollar-sign"></i>
						</span>
					</div>
				</div>
			</div>
			{/* statistic end  */}

			{/* all-users-list start  */}
			<div className="all-users-list">
				<div className="header">
					<h5>All Users</h5>

					<button type="button" className="btn">
						<i className="fa-regular fa-envelope"></i> Bulk Emails
					</button>
				</div>
				{isLoading ? (
					<TableSkeleton />
				) : (
					<div className="table-container">
						<table className="table table-hover">
							<thead>
								<tr>
									<th scope="col">#</th>

									<th scope="col">Profile</th>

									<th scope="col" style={{ width: "20%" }}>
										Name
									</th>

									<th scope="col" style={{ width: "30%" }}>
										Email
									</th>

									<th scope="col">Type</th>

									<th scope="col">Package</th>

									<th scope="col" style={{ width: "13%" }}>
										Registered Date
									</th>

									<th scope="col">Action</th>
								</tr>
							</thead>
							<tbody>
								{allUsers.length > 0 &&
									allUsers
										.map((value, index) => {
											return (
												<tr key={index}>
													<td id="id" onClick={() => setSelectedTab(value)}>
														<span>{index + 1}</span>
													</td>

													<td onClick={() => setSelectedTab(value)}>
														<div className="img-container">
															<img
																src={`/assets/profile-img/${
																	value.profile_img
																		? value.profile_img
																		: "default-profile.png"
																}`}
																alt=""
															/>
														</div>
													</td>

													<td onClick={() => setSelectedTab(value)}>
														<input readOnly value={value?.name} />
													</td>

													<td onClick={() => setSelectedTab(value)}>
														<input readOnly value={value?.email} />
													</td>

													<td onClick={() => setSelectedTab(value)}>
														<input
															readOnly
															value={
																value?.type === "general" && value.stu_card
																	? "Student Request"
																	: value?.type
															}
														/>
													</td>

													<td onClick={() => setSelectedTab(value)}>
														<input
															readOnly
															value={value?.package["Package Name"]}
														/>
													</td>

													<td onClick={() => setSelectedTab(value)}>
														<input
															readOnly
															value={moment(value?.createdAt).format(
																"MMMM DD, YYYY"
															)}
														/>
													</td>

													<td>
														<div className="btn-container">
															<button className="btn">
																<a
																	href={`mailto:${value?.email}`}
																	id="send-email"
																>
																	<i className="fa-regular fa-envelope"></i>
																</a>
															</button>

															{/* <button
																className="btn"
																onClick={() => setOptionT(value)}
															>
																<i className="fa-regular fa-pen-to-square"></i>

																{optionT && optionT._id === value._id && (
																	<div
																		className="option-container"
																		data-aos="fade-down"
																		ref={myOption}
																	>
																		<ul>
																			<li
																				onClick={() => {
																					setSuspenseUser(optionT);
																					setOptionT("");
																				}}
																			>
																				<i className="bi bi-clock-history"></i>
																				Pending
																			</li>

																			<li
																				onClick={() => {
																					setDeleteUser(optionT);
																					setOptionT("");
																				}}
																			>
																				<i className="fa-solid fa-trash-can"></i>{" "}
																				Delete
																			</li>
																		</ul>
																	</div>
																)}
															</button> */}
														</div>
													</td>
												</tr>
											);
										})
										.reverse()}
							</tbody>
						</table>
					</div>
				)}
			</div>
			{/* all-users-list end  */}
		</>
	);
};

export default AllUsersList;
