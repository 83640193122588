// external components
import { CircularProgress } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { NavLink, useParams } from "react-router-dom";

// for create and pdf renderer
import { BlobProvider } from "@react-pdf/renderer";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useAsync } from "react-use";

// internal components
import Template from "../../components/for_resume_editor/Preview/Template";
import "./Share.css";

// define pdf worker
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Share = () => {
	// for getting _id from route
	const { _id } = useParams();

	// for getting resume start
	const [getResume, setResume] = useState("");

	useEffect(() => {
		if (_id) {
			(async () => {
				try {
					const response = await fetch(`/resume/share?_id=${_id}`);

					const result = await response.json();

					if (response.status === 200) {
						setResume(result);
					} else if (result.error) {
						toast.error(result.error, {
							position: "top-right",
							autoClose: 2500,
							theme: "colored"
						});
					}
				} catch (error) {
					toast.error(error.message, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});
				}
			})();
		}
	}, [_id]);
	// for getting resume end

	// for getting pdf blob
	const [getBlob, setBlob] = useState("");

	// for getting pageNumber
	const [pageNumber, setPageNumber] = useState(1);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setPageNumber(numPages);
	};

	// get url
	const render = useAsync(async () => {
		if (!getBlob) return null;

		const url = URL.createObjectURL(getBlob);

		return url;
	}, [getBlob]);

	// for getting number of pages start
	const [getPages, setPages] = useState([]);

	useEffect(() => {
		if (pageNumber) {
			let pagesArr = [];

			for (let i = 1; i <= pageNumber; i++) {
				pagesArr.push(
					<Document
						renderMode="canvas"
						file={render.value}
						error={null}
						loading={null}
						noData={null}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page
							pageNumber={i}
							renderTextLayer={false}
							renderAnnotationLayer={false}
							loading={null}
							error={null}
							noData={null}
							className="main-canvas"
							scale={1.35}
							onContextMenu={handleContextMenu}
						></Page>
					</Document>
				);
			}

			setPages(pagesArr);
		}
	}, [pageNumber, render.value]);
	// for getting number of pages start

	// for disable contentMenu
	const handleContextMenu = (event) => {
		event.preventDefault();
	};

	return (
		<>
			<div className="share-container">
				<div className="topbar">
					<NavLink to={"/"}>
						<img src="/assets/images/main-logo.svg" alt="logo" />
					</NavLink>
				</div>

				{getResume && (
					<div className="share-wrapper">
						<BlobProvider document={<Template getResume={getResume} />}>
							{({ blob }) => {
								if (blob) {
									setBlob(blob);
								}
								return null;
							}}
						</BlobProvider>

						<div className="share-canvas">
							{render?.value ? (
								getPages.length > 0 ? (
									getPages.map((value, index) => {
										return <Fragment key={index}>{value}</Fragment>;
									})
								) : (
									<Document
										renderMode="canvas"
										file={render.value}
										error={null}
										loading={null}
										noData={null}
										onLoadSuccess={onDocumentLoadSuccess}
									>
										<Page
											pageNumber={1}
											renderTextLayer={false}
											renderAnnotationLayer={false}
											loading={null}
											error={null}
											noData={null}
											scale={1.35}
											onContextMenu={handleContextMenu}
										></Page>
									</Document>
								)
							) : (
								<div className="loader-container">
									<CircularProgress />
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Share;
