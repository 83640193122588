// external components
import { toast } from "react-hot-toast";

const updateItems = async (arr, userId, counter, section, item) => {
	try {
		const response = await fetch(
			`/resume/updated-items-array?user=${userId}&counter=${counter}&section=${section}&item=${item}`,
			{
				method: "PUT",
				body: JSON.stringify({ itemsArr: arr }),
				headers: {
					"Content-Type": "application/json"
				}
			}
		);

		if (response.status === 200) {
			return null;
		} else {
			toast.error("Try again", {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
	} catch (error) {
		toast.error(error.message, {
			position: "top-right",
			autoClose: 2500,
			theme: "colored"
		});
	}
};

export default updateItems;
