// external components
import { memo, useEffect, useRef, useState } from "react";

// internal components
import { EditorContextApi } from "../../../../../pages/ResumeEditor/EditorContextApi";

const DeleteDialog = ({ contents, deleteItem, setDeleteItem }) => {
	// contents
	const {
		delete_entry_header,
		delete_entry_sub_header,
		delete_entry_dont_ask,
		deleteText,
		delete_entry_cancel
	} = contents.editor;

	// get editor contextApi
	const { updateResumeInfo } = EditorContextApi();

	// for do_not_ask_again toggle
	const [dontAsk, setDontAsk] = useState(false);

	// for closing delete-popup start
	const deleteWrapper = useRef();

	const handleClickOutsideDelete = (e) => {
		if (!deleteWrapper.current?.contains(e.target) && deleteItem) {
			setDeleteItem("");
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutsideDelete);
		return () =>
			document.removeEventListener("mousedown", handleClickOutsideDelete);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteItem]);
	// for closing delete-popup end

	return (
		<>
			<div className="delete-specific-item-wrapper">
				<div
					className="delete-specific-item"
					data-aos="fade-down"
					ref={deleteWrapper}
				>
					<div className="delete-item-header-section">
						<h3>{delete_entry_header}</h3>
						<p>{delete_entry_sub_header}</p>
					</div>

					<div className="delete-item-bottom-section">
						<div className="checked">
							<input
								type="checkbox"
								id="checked"
								value="true"
								onClick={() => setDontAsk(!dontAsk)}
								readOnly
							/>
							<label htmlFor="checked" className={dontAsk ? "checkedBox" : ""}>
								<p>{delete_entry_dont_ask}</p>
							</label>
						</div>

						<div className="btn-container">
							<button
								onClick={() => {
									setDeleteItem((prev) => ({ ...prev, conform: true }));
									dontAsk &&
										updateResumeInfo({
											do_not_ask_again: true
										});
								}}
								className="primary-btn-styles"
							>
								{deleteText}
							</button>
							<button
								onClick={() => {
									setDeleteItem("");
								}}
								className="primary-btn-styles"
							>
								{delete_entry_cancel}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(DeleteDialog);
