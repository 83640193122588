// external components
import { useEffect, useRef, useState } from "react";

// internal components
import "./AmountMethodDropdown.css";

const AmountMethodDropdown = ({
	setIsActiveDropDown,
	setMethod,
	getMethod,
	forWhat
}) => {
	const [methodDrop, setMethodDrop] = useState("");

	// for close dropdown when outside clicked start
	const myRef = useRef();

	const handleClickOutside = (e) => {
		if (!myRef.current?.contains(e.target) && methodDrop) {
			setMethodDrop(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [methodDrop]);
	// for close dropdown when outside clicked end

	return (
		<>
			<div
				className={
					methodDrop && forWhat !== "delete"
						? "method-dropdown-container active"
						: "method-dropdown-container"
				}
				onClick={() => setMethodDrop(!methodDrop)}
				ref={myRef}
			>
				<input
					type="text"
					id="Level"
					placeholder="Select method"
					onFocus={() => setIsActiveDropDown(true)}
					onBlur={() => {
						setIsActiveDropDown(false);
					}}
					value={getMethod}
					readOnly
				/>

				<div className="option" ref={myRef}>
					<div onClick={() => setMethod("Percentage (%)")}>Percentage (%)</div>

					<div onClick={() => setMethod("Fixed")}>Fixed</div>
				</div>
			</div>
		</>
	);
};

export default AmountMethodDropdown;
