// external components
import { toBengaliNumber } from "bengali-number";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

// internal components
import { GetContextApi } from "../../storage/ContextApi";
import {
	FAQComponent,
	PaymentMethods,
	RestrictionDialog,
	ShapeAnimation,
	Stairs
} from "./../../common";
import "./Subscription.css";

const Subscription = ({ allPackages }) => {
	// subscription contents & userCountry
	const { currentUser, contents, userCountry } = GetContextApi();

	const {
		title,
		subtitle,

		features,
		regular_price_text,
		subs_period,
		limits,
		customization,
		download,
		spelling_check,
		pre_written_phrases,
		exclusive_templates,
		expert_advice,
		value_added_service,

		btn_text,

		basic,
		student,
		standard,

		month,
		year,
		months,
		years,

		unlimited,

		money_back_des,

		we_accept
	} = contents.subscription;

	// restriction contents
	const { subscription_restriction } = contents.package_limit;

	// for redirect pages
	const Navigate = useNavigate();

	// define sorting order
	const sortOrder = ["Basic", "Student", "Standard"];

	// student package restriction
	const [restrictionObj, setRestrictionObj] = useState("");

	// generating subscription period
	const generatingSubPeriod = (value) => {
		const getPeriod = parseInt(value.replace(/[^0-9.-]/g, ""));

		const convertYears = getPeriod / 12;

		if (Number.isInteger(convertYears)) {
			const number =
				contents.title === "bangla"
					? toBengaliNumber(convertYears)
					: convertYears;
			return convertYears === 1 ? `${number} ${year}` : `${number} ${years}`;
		} else {
			const number =
				contents.title === "bangla" ? toBengaliNumber(getPeriod) : getPeriod;

			return getPeriod === 1 ? `${number} ${month}` : `${number} ${months}`;
		}
	};

	// for package Component
	const PackageComponent = ({
		id,
		name,
		regular_price,
		discount_price,
		subscription_period,
		template_limit,
		customization,
		download,
		spelling_check,
		pre_written_phrases,
		exclusive_templates,
		expert_advice,
		service
	}) => {
		const packageName =
			(name === "Basic" && basic) ||
			(name === "Student" && student) ||
			(name === "Standard" && standard) ||
			name;

		const discountPrice =
			contents.title === "bangla"
				? toBengaliNumber(discount_price)
				: discount_price;

		const regularPrice =
			contents.title === "bangla"
				? toBengaliNumber(regular_price)
				: regular_price;

		const templateLimit =
			contents.title === "bangla"
				? toBengaliNumber(template_limit)
				: template_limit;

		const customizeLimit =
			contents.title === "bangla" && customization.toLowerCase() === "unlimited"
				? unlimited
				: customization;

		const downloadLimit =
			contents.title === "bangla" && download.toLowerCase() === "unlimited"
				? unlimited
				: download;

		return (
			<div className="package-wrapper-column">
				<h2 id={id}>
					<span>{packageName}</span>
					<span
						style={{ flexDirection: "column", padding: "23px 0" }}
						id={userCountry !== "BD" ? "when-foreigner" : ""}
					>
						{`${
							userCountry !== "BD"
								? "$"
								: contents.title === "bangla"
								? "৳. "
								: "Tk. "
						}${discountPrice} / ${subscription_period.split(" ").pop()}`}

						{userCountry === "BD" && regular_price && (
							<div className="regular-price-text">
								{regular_price_text}:{" "}
								<span className="regular-price-amount">{regularPrice}</span>
							</div>
						)}
					</span>
				</h2>

				<ul>
					<li>{subscription_period}</li>
					<li>{templateLimit}</li>
					<li>{customizeLimit}</li>
					<li>{downloadLimit}</li>
					<li className={spelling_check}>
						{spelling_check === "cd-checked" ? (
							<span>Yes</span>
						) : (
							<span>No</span>
						)}
					</li>
					<li className={pre_written_phrases}>
						{pre_written_phrases === "cd-checked" ? (
							<span>Yes</span>
						) : (
							<span>No</span>
						)}
					</li>
					<li className={exclusive_templates}>
						{exclusive_templates === "cd-checked" ? (
							<span>Yes</span>
						) : (
							<span>No</span>
						)}
					</li>
					<li className={expert_advice}>
						{expert_advice === "cd-checked" ? (
							<span>Yes</span>
						) : (
							<span>No</span>
						)}
					</li>
					<li className={service}>
						{service === "cd-checked" ? <span>Yes</span> : <span>No</span>}
					</li>

					<li>
						<button
							className="primary-btn-styles"
							onClick={() => {
								currentUser
									? name === "Student" && currentUser.type === "general"
										? setRestrictionObj(subscription_restriction)
										: Navigate(`../subscription/payment?plan=${name}`)
									: Navigate("/sign-up");
							}}
						>
							{btn_text}
						</button>
					</li>
				</ul>
			</div>
		);
	};

	return (
		<>
			<Stairs
				from_where={"subscription"}
				contents={contents}
				currentUser={currentUser}
			/>

			{/* subscription-content container start  */}
			<div className="main-subscription-container">
				<div className="container">
					<div className="row subscription-container">
						{/* subscription-price-wrapper start  */}
						<div className="col-11 col-sm-12 subscription-price-wrapper">
							<div
								className="price-title"
								dangerouslySetInnerHTML={{ __html: title }}
							></div>

							<div className="price-description">{subtitle}</div>

							{/* package-wrapper style start  */}
							<section id="package-wrapper">
								<header className="package-wrapper-column">
									<h2 id="description-header">{features}</h2>
									<ul id="feature">
										<li>{subs_period}</li>
										<li>{limits}</li>
										<li>{customization}</li>
										<li>{download}</li>
										<li>{spelling_check}</li>
										<li>{pre_written_phrases}</li>
										<li>{exclusive_templates}</li>
										<li>{expert_advice}</li>
										<li>{value_added_service}</li>
									</ul>
								</header>

								<div className="package-wrapper-container">
									<div className="package-wrapper-wrapper">
										{allPackages.length > 0 &&
											allPackages
												.sort((a, b) => {
													return (
														sortOrder.indexOf(a["Package Name"]) -
														sortOrder.indexOf(b["Package Name"])
													);
												})
												.map((value, index) => {
													return (
														<Fragment key={index}>
															<PackageComponent
																id={`item-${index}`}
																name={value["Package Name"]}
																regular_price={value["Regular Price"]}
																discount_price={`${
																	userCountry !== "BD"
																		? value["Discounted Price"] / 100 + 5
																		: value["Discounted Price"]
																}`}
																subscription_period={generatingSubPeriod(
																	value["Subscription Period"]
																)}
																template_limit={value["Templates Use Limits"]}
																customization={value["Resume Customization"]}
																download={value["Resume Download"]}
																spelling_check={
																	value["Spelling Check"]
																		? "cd-checked"
																		: "cd-unchecked"
																}
																pre_written_phrases={
																	value["Pre-Written Phrases"]
																		? "cd-checked"
																		: "cd-unchecked"
																}
																exclusive_templates={
																	value["Exclusive Templates"]
																		? "cd-checked"
																		: "cd-unchecked"
																}
																expert_advice={
																	value["Expert Advice"]
																		? "cd-checked"
																		: "cd-unchecked"
																}
																service={
																	value["Value Added Service"]
																		? "cd-checked"
																		: "cd-unchecked"
																}
															/>
														</Fragment>
													);
												})}
									</div>
								</div>

								<em className="cd-scroll-right"></em>
							</section>
							{/* package-wrapper style end  */}

							{/* subscription-methods-icons-wrapper start  */}
							<div
								className={
									userCountry === "BD"
										? "d-none"
										: "subscription-methods-icons-wrapper"
								}
							>
								<div className="subscription-methods-icons-label">
									{we_accept} :
								</div>
								<img src="/assets/icons/visa.svg" alt="payment-method-icon" />

								<img
									src="/assets/icons/master-card.svg"
									alt="payment-method-icon"
								/>

								<img
									src="/assets/icons/american-express.svg"
									alt="payment-method-icon"
								/>

								<img src="/assets/icons/paypal.svg" alt="payment-method-icon" />
							</div>
							{/* subscription-methods-icons-wrapper end */}

							{/* subscription-methods-label start  */}
							<div className="subscription-methods-label">{money_back_des}</div>
							{/* subscription-methods-label end  */}
						</div>
						{/* subscription-price-wrapper end */}

						<FAQComponent />
					</div>
				</div>

				{/* for restriction dialog box */}
				{restrictionObj && (
					<RestrictionDialog
						restrictionObj={restrictionObj}
						setRestrictionObj={setRestrictionObj}
					/>
				)}

				{/* shapes animation component   */}
				<ShapeAnimation />
			</div>
			<PaymentMethods />
			{/* subscription-content container end  */}
		</>
	);
};

export default Subscription;
