// external components
import dayjs from "dayjs";
import { convertFromHTML } from "draft-convert";
import { EditorState } from "draft-js";
import { memo, useEffect, useReducer, useState } from "react";

// internal components
import {
	DatePickerCom,
	DeleteTooltip,
	EditorTips,
	FirstCol,
	RichEditor,
	SecondCol
} from "../common";

const CustomSectionItem = ({
	itemValues,
	handleSetActive,
	contents,
	setDeleteItem,
	updateResumeInfo,
	delay,
	cus_section_id
}) => {
	// customSectionItem contents
	const {
		custom_activity,
		city,
		date,
		present,
		not_specific,
		deleteText,
		date_tooltip
	} = contents.editor;

	// define maximum words & counter
	const maxWords = 120;
	const [wordCount, setWordCount] = useState(0);

	// assign
	const _id = itemValues._id;

	// getting & updating educationItem all values start
	const [inputValues, setInputValues] = useState({
		...itemValues,
		custom_rich_text_content: itemValues.custom_rich_text_content
			? EditorState.createWithContent(
					convertFromHTML(itemValues.custom_rich_text_content)
			  )
			: EditorState.createEmpty()
	});

	const [latestUpdate, setLatestUpdate] = useState({});

	useEffect(() => {
		if (Object.keys(latestUpdate)[0]) {
			const timeoutId = setTimeout(() => {
				updateResumeInfo({ ...latestUpdate, _id, cus_section_id });
			}, delay);

			return () => {
				clearTimeout(timeoutId);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestUpdate]);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setInputValues((prevValues) => ({
			...prevValues,
			[name]: value
		}));

		setLatestUpdate({
			[name]: value || null
		});
	};
	// getting & updating educationItem all values end

	// useReducer for datepicker toggle start
	const initialState = {
		startDate: false,
		endDate: false,
		editor: false,
		dateTooltip: false,
		isPresent: false
	};

	const reducerFunction = (state, action) => {
		const { type } = action;

		if (type === "startDate") {
			return { ...state, startDate: true };
		} else if (type === "endDate") {
			return { ...state, endDate: true };
		} else if (type === "close") {
			return { ...state, startDate: false, endDate: false };
		} else if (type === "focused-rich-editor") {
			return {
				...state,
				editor: true
			};
		} else if (type === "blur-rich-editor") {
			return {
				...state,
				editor: false
			};
		} else if (type === "open-date-tooltip") {
			return {
				...state,
				dateTooltip: true
			};
		} else if (type === "close-date-tooltip") {
			return {
				...state,
				dateTooltip: false
			};
		} else if (type === "toggle-present") {
			return {
				...state,
				isPresent: !state.isPresent
			};
		} else {
			return state;
		}
	};

	const [controller, dispatch] = useReducer(reducerFunction, initialState);
	// useReducer for datepicker toggle end

	// collapse toggle
	const handleActivate = () => {
		handleSetActive(_id);
	};

	return (
		<>
			<div className="specific-item-container">
				<div className="specific-item-wrapper">
					<div className="content-title-wrapper" onClick={handleActivate}>
						<div className="content-title">
							<h4>
								{inputValues.custom_activity && inputValues.custom_city
									? `${inputValues.custom_activity}, ${inputValues.custom_city}`
									: inputValues.custom_activity || inputValues.custom_city
									? inputValues.custom_city || inputValues.custom_activity
									: not_specific}
							</h4>

							<span>
								{inputValues.custom_start_date &&
									dayjs(inputValues.custom_start_date).format("MMM YYYY")}{" "}
								{inputValues.custom_start_date &&
									inputValues.custom_end_date &&
									"-"}{" "}
								{inputValues.custom_end_date &&
								inputValues.custom_end_date !== "Present"
									? dayjs(inputValues.custom_end_date).format("MMM YYYY")
									: inputValues.custom_end_date === "Present"
									? "Present"
									: ""}
							</span>
						</div>

						<i className="fa-solid fa-chevron-up" id="up"></i>

						<i className="fa-solid fa-chevron-down" id="down"></i>
					</div>

					{/* for delete that item */}
					<DeleteTooltip
						content={deleteText}
						setDeleteItem={setDeleteItem}
						_id={_id}
					/>
				</div>

				<div className="row-container-wrapper">
					{/* activity name & city start  */}
					<div className="row-container">
						<FirstCol
							content={custom_activity}
							name={"custom_activity"}
							value={inputValues.custom_activity}
							onChange={handleInputChange}
						/>

						<SecondCol
							content={city}
							name={"custom_city"}
							value={inputValues.custom_city}
							onChange={handleInputChange}
						/>
					</div>
					{/* activity and city start  */}

					{/* start & end date  start  */}
					<div className="row-container">
						<DatePickerCom
							startDateName={"custom_start_date"}
							endDateName={"custom_end_date"}
							inputValues={inputValues}
							setInputValues={setInputValues}
							setLatestUpdate={setLatestUpdate}
							contents={{
								date,
								present,
								date_tooltip
							}}
							controller={controller}
							dispatch={dispatch}
						/>
					</div>
					{/* start & end date end */}

					{/* rich-editor start  */}
					<div className="row-container">
						<div className="first-col" id="when-description">
							<div className="input-field rich-editor">
								<RichEditor
									name="custom_rich_text_content"
									editorState={inputValues.custom_rich_text_content}
									setInputValues={setInputValues}
									updateResumeInfo={updateResumeInfo}
									_id={_id}
									cus_section_id={cus_section_id}
									maxWords={maxWords}
									wordCount={wordCount}
									setWordCount={setWordCount}
									placeholder=""
									control={controller.editor}
									dispatchControl={dispatch}
									delay={delay}
								/>
							</div>
							{/* rich-editor end */}

							{/* tips   */}
							<EditorTips
								contents={contents}
								maxWords={maxWords}
								wordCount={wordCount}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(CustomSectionItem);
