// external components
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

// datepicker
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";

// internal components
import TableSkeleton from "../AdminDashboardContents/TableSkeleton";
import "./AdminCouponContents.css";
import AmountMethodDropdown from "./AmountMethodDropdown/AmountMethodDropdown";

const AdminCouponContents = () => {
	// for new coupon toggle
	const [newCouponT, setNewCouponT] = useState(false);

	// for loading until fetching not complete
	const [isLoading, setIsLoading] = useState(false);

	// for checking is updated coupon or not
	const [isUpdateCoupon, setIsUpdateCoupon] = useState("");

	// for getting all coupons start
	const [allCoupons, setAllCoupons] = useState([]);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const response = await fetch(`/coupon/all-coupons`);

				const result = await response.json();

				if (response.status === 200) {
					setAllCoupons(result);

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				} else if (result.error) {
					toast.error(result.error, {
						position: "top-right",
						autoClose: 2500,
						theme: "colored"
					});

					setTimeout(() => {
						setIsLoading(false);
					}, 500);
				}
			} catch (error) {
				toast.error(error.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				setTimeout(() => {
					setIsLoading(false);
				}, 500);
			}
		})();
	}, [isUpdateCoupon]);
	// for getting all coupons start

	// for border animation
	const [isActiveCode, setIsActiveCode] = useState(false);
	const [isActiveDropDown, setIsActiveDropDown] = useState(false);
	const [isActiveAmount, setIsActiveAmount] = useState(false);
	const [isActiveStartDate, setIsActiveStartDate] = useState(false);
	const [isActiveEndDate, setIsActiveEndDate] = useState(false);

	// for getting input-fields value
	const [getCode, setCode] = useState("");
	const [getAmount, setAmount] = useState("");
	const [getMethod, setMethod] = useState("");
	const [getStartDate, setStartDate] = useState(null);
	const [getEndDate, setEndDate] = useState(null);

	// for generate new coupon start

	// for only get integer value
	function handleInputChange(event) {
		const inputValue = event.target.value;
		if (/^\d*$/.test(inputValue)) {
			// check if inputValue is a valid integer
			setAmount(inputValue);
		}
	}

	const generateNewCoupon = async () => {
		try {
			setIsLoading(true);
			const response = await fetch(`/coupon/add-new`, {
				method: "POST",
				body: JSON.stringify({
					code: getCode.toUpperCase() + getAmount,
					amount: getAmount,
					method: getMethod,
					start_date: getStartDate.getTime(),
					end_date: getEndDate.getTime()
				}),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				setIsUpdateCoupon(Date.now());
				setNewCouponT(false);
				setCode("");
				setAmount("");
				setMethod("");
				setStartDate("");
				setEndDate("");
				setIsLoading(false);
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				setIsLoading(false);
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
			setIsLoading(false);
		}
	};
	// for generate new coupon end

	// for updating specific coupon start
	const [updateCouponId, setUpdateCouponId] = useState("");

	const updateSpecificCoupon = async () => {
		try {
			setIsLoading(true);

			const response = await fetch(`/coupon/update-specific-coupon`, {
				method: "PUT",
				body: JSON.stringify({
					_id: updateCouponId,
					code: getCode.toUpperCase() + getAmount,
					amount: getAmount,
					method: getMethod,
					start_date: getStartDate.getTime(),
					end_date: getEndDate.getTime()
				}),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				setIsUpdateCoupon(Date.now());
				setCode("");
				setAmount("");
				setMethod("");
				setStartDate("");
				setEndDate("");
				setIsLoading(false);
				setUpdateCouponId("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				setIsLoading(false);
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
			setIsLoading(false);
		}
	};
	// for updating specific coupon end

	// for deleting specific coupon start
	const [deleteCouponId, setDeleteCouponId] = useState("");

	const deleteCouponHandler = async () => {
		try {
			setIsLoading(true);

			const response = await fetch(`/coupon/delete-specific-coupon`, {
				method: "DELETE",
				body: JSON.stringify({ _id: deleteCouponId }),
				headers: {
					"Content-Type": "application/json"
				}
			});

			const result = await response.json();

			if (response.status === 200) {
				toast.success(result.message, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});

				setIsUpdateCoupon(Date.now());
				setCode("");
				setAmount("");
				setMethod("");
				setStartDate("");
				setEndDate("");
				setIsLoading(false);
				setDeleteCouponId("");
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
				setIsLoading(false);
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
			setIsLoading(false);
		}
	};
	// for deleting specific coupon end

	return (
		<>
			<div className="admin-coupon-container">
				<div className="admin-coupon-wrapper">
					<div className="header">
						<h5>All Coupons</h5>

						<button
							type="button"
							className="btn"
							onClick={() => setNewCouponT(true)}
						>
							<i className="fa-solid fa-plus"></i> Add Coupon
						</button>
					</div>
					{isLoading ? (
						<TableSkeleton />
					) : (
						<div className="table-container">
							<table className="table table-hover">
								<thead>
									<tr>
										<th scope="col" style={{ width: "5%" }}>
											#
										</th>

										<th scope="col" style={{ width: "20%" }}>
											Code
										</th>

										<th scope="col" style={{ width: "15%" }}>
											Method
										</th>

										<th scope="col" style={{ width: "15%" }}>
											Amount
										</th>

										<th scope="col" style={{ width: "13%" }}>
											Start Date
										</th>

										<th scope="col" style={{ width: "13%" }}>
											End Date
										</th>

										<th scope="col" style={{ width: "10%" }}>
											Action
										</th>
									</tr>
								</thead>

								<tbody>
									{allCoupons.length > 0 &&
										allCoupons
											.map((value, index) => {
												return (
													<tr key={index}>
														<td id="id">
															<span>{index + 1}</span>
														</td>

														<td>
															<input readOnly value={value?.code} />
														</td>

														<td>
															<input readOnly value={value?.method} />
														</td>

														<td>
															<input readOnly value={value?.amount} />
														</td>

														<td>
															<input
																readOnly
																value={moment(value?.start_date).format(
																	"MMMM DD, YYYY"
																)}
															/>
														</td>

														<td>
															<input
																readOnly
																value={moment(value?.end_date).format(
																	"MMMM DD, YYYY"
																)}
															/>
														</td>

														<td>
															<div className="btn-container">
																<button
																	className="btn"
																	onClick={() => {
																		setCode(
																			value.code.replace(value.amount, "")
																		);
																		setAmount(value.amount);
																		setMethod(value.method);
																		setStartDate(new Date(value.start_date));
																		setEndDate(new Date(value.end_date));
																		setUpdateCouponId(value._id);
																	}}
																>
																	<i className="fa-regular fa-pen-to-square"></i>
																</button>

																<button
																	className="btn for-delete"
																	onClick={() => {
																		setCode(
																			value.code.replace(value.amount, "")
																		);
																		setAmount(value.amount);
																		setMethod(value.method);
																		setStartDate(new Date(value.start_date));
																		setEndDate(new Date(value.end_date));
																		setDeleteCouponId(value._id);
																	}}
																>
																	<i className="fa-solid fa-trash-can"></i>
																</button>
															</div>
														</td>
													</tr>
												);
											})
											.reverse()}
								</tbody>
							</table>
						</div>
					)}
				</div>

				{/* for new coupon start  */}
				{newCouponT && (
					<div className="add-new-coupon-container">
						<div className="add-new-coupon-wrapper" data-aos="fade-down">
							<div className="top-section">
								<h3>Add New Coupon</h3>
							</div>

							<div className="input-field" id={isActiveCode ? "active" : ""}>
								<span>Code</span>
								<input
									placeholder="Code"
									onFocus={() => setIsActiveCode(true)}
									onBlur={() => {
										setIsActiveCode(false);
									}}
									onChange={(e) => setCode(e.target.value)}
									value={getCode.toUpperCase()}
								/>
							</div>

							<div
								className="input-field"
								id={isActiveDropDown ? "active" : ""}
							>
								<span>Discount Method</span>
								<AmountMethodDropdown
									setIsActiveDropDown={setIsActiveDropDown}
									setMethod={setMethod}
									getMethod={getMethod}
									forWhat="create"
								/>
							</div>

							<div className="input-field" id={isActiveAmount ? "active" : ""}>
								<span>Amount</span>
								<input
									type="number"
									placeholder="Amount"
									onFocus={() => setIsActiveAmount(true)}
									onBlur={() => {
										setIsActiveAmount(false);
									}}
									onChange={handleInputChange}
									value={getAmount}
								/>
							</div>

							<div
								className="input-field"
								id={isActiveStartDate ? "active" : ""}
							>
								<span>Start Date</span>
								<DatePicker
									type="date"
									placeholder="Pick start date"
									onFocus={() => setIsActiveStartDate(true)}
									onBlur={() => {
										setIsActiveStartDate(false);
									}}
									minDate={new Date()}
									onChange={setStartDate}
									value={getStartDate}
									format="dd-MM-y"
								/>
							</div>

							<div
								className="input-field last-field"
								id={isActiveEndDate ? "active" : ""}
							>
								<span>End Date</span>

								<DatePicker
									type="date"
									placeholder="Pick end date"
									onFocus={() => setIsActiveEndDate(true)}
									onBlur={() => {
										setIsActiveEndDate(false);
									}}
									minDate={getStartDate ? getStartDate : new Date()}
									onChange={setEndDate}
									value={getEndDate}
									format="dd-MM-y"
								/>
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setNewCouponT(false);
										setCode("");
										setAmount("");
										setMethod("");
										setStartDate("");
										setEndDate("");
									}}
								>
									Cancel
								</button>
								<button
									onClick={() =>
										getCode &&
										getMethod &&
										getAmount &&
										getStartDate &&
										getEndDate &&
										generateNewCoupon()
									}
									id={
										getCode &&
										getMethod &&
										getAmount &&
										getStartDate &&
										getEndDate
											? ""
											: "inactive"
									}
								>
									{isLoading ? "Generating" : "Generate"}
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for new coupon  end */}

				{/* for coupon update start  */}
				{updateCouponId && (
					<div className="add-new-coupon-container">
						<div className="add-new-coupon-wrapper" data-aos="fade-down">
							<div className="top-section">
								<h3>Update Specific Coupon</h3>
							</div>

							<div className="input-field" id={isActiveCode ? "active" : ""}>
								<span>Code</span>
								<input
									placeholder="Code"
									onFocus={() => setIsActiveCode(true)}
									onBlur={() => {
										setIsActiveCode(false);
									}}
									onChange={(e) => setCode(e.target.value)}
									value={getCode.toUpperCase()}
								/>
							</div>

							<div
								className="input-field"
								id={isActiveDropDown ? "active" : ""}
							>
								<span>Discount Method</span>
								<AmountMethodDropdown
									setIsActiveDropDown={setIsActiveDropDown}
									setMethod={setMethod}
									getMethod={getMethod}
									forWhat="update"
								/>
							</div>

							<div className="input-field" id={isActiveAmount ? "active" : ""}>
								<span>Amount</span>
								<input
									type="number"
									placeholder="Amount"
									onFocus={() => setIsActiveAmount(true)}
									onBlur={() => {
										setIsActiveAmount(false);
									}}
									onChange={handleInputChange}
									value={getAmount}
								/>
							</div>

							<div
								className="input-field"
								id={isActiveStartDate ? "active" : ""}
							>
								<span>Start Date</span>
								<DatePicker
									type="date"
									placeholder="Pick start date"
									onFocus={() => setIsActiveStartDate(true)}
									onBlur={() => {
										setIsActiveStartDate(false);
									}}
									minDate={new Date()}
									onChange={setStartDate}
									value={getStartDate}
									format="dd-MM-y"
								/>
							</div>

							<div
								className="input-field last-field"
								id={isActiveEndDate ? "active" : ""}
							>
								<span>End Date</span>

								<DatePicker
									type="date"
									placeholder="Pick end date"
									onFocus={() => setIsActiveEndDate(true)}
									onBlur={() => {
										setIsActiveEndDate(false);
									}}
									minDate={getStartDate ? getStartDate : new Date()}
									onChange={setEndDate}
									value={getEndDate}
									format="dd-MM-y"
								/>
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setUpdateCouponId("");
										setCode("");
										setAmount("");
										setMethod("");
										setStartDate("");
										setEndDate("");
									}}
								>
									Cancel
								</button>
								<button
									onClick={() =>
										getCode &&
										getMethod &&
										getAmount &&
										getStartDate &&
										getEndDate &&
										updateSpecificCoupon()
									}
									id={
										getCode &&
										getMethod &&
										getAmount &&
										getStartDate &&
										getEndDate
											? ""
											: "inactive"
									}
								>
									{isLoading ? "Updating" : "Update"}
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for coupon update  end */}

				{/* for coupon delete start  */}
				{deleteCouponId && (
					<div className="add-new-coupon-container">
						<div className="add-new-coupon-wrapper" data-aos="fade-down">
							<div className="top-section">
								<h3>Delete Specific Coupon</h3>
							</div>

							<div className="input-field">
								<span>Code</span>
								<input
									placeholder="Code"
									readOnly
									value={getCode.toUpperCase()}
								/>
							</div>

							<div className="input-field">
								<span>Discount Method</span>
								<AmountMethodDropdown
									setIsActiveDropDown={setIsActiveDropDown}
									setMethod={setMethod}
									getMethod={getMethod}
									forWhat="delete"
								/>
							</div>

							<div className="input-field">
								<span>Amount</span>
								<input
									type="number"
									placeholder="Amount"
									readOnly
									value={getAmount}
								/>
							</div>

							<div className="input-field">
								<span>Start Date</span>
								<DatePicker
									type="date"
									placeholder="Pick start date"
									minDate={new Date()}
									readOnly
									value={getStartDate}
									format="dd-MM-y"
									disabled
								/>
							</div>

							<div className="input-field last-field">
								<span>End Date</span>

								<DatePicker
									type="date"
									placeholder="Pick end date"
									onFocus={() => setIsActiveEndDate(true)}
									onBlur={() => {
										setIsActiveEndDate(false);
									}}
									minDate={getStartDate ? getStartDate : new Date()}
									readOnly
									value={getEndDate}
									format="dd-MM-y"
									disabled
								/>
							</div>

							<div className="btn-container">
								<button
									onClick={() => {
										setDeleteCouponId("");
										setCode("");
										setAmount("");
										setMethod("");
										setStartDate("");
										setEndDate("");
									}}
								>
									Cancel
								</button>
								<button onClick={() => deleteCouponHandler()}>
									{isLoading ? "Deleting" : "Delete"}
								</button>
							</div>
						</div>
					</div>
				)}
				{/* for coupon delete  end */}
			</div>
		</>
	);
};

export default AdminCouponContents;
