// external components
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";

// for datepicker
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// internal components
import "./_UserProfile.scss";
import { AccordionCom } from "./common";
import { PersonalDetails } from "./components/personal";

const UserProfile = () => {
	// getting selected tabs
	const [selectedTabs, setSelectedTabs] = useState("Personal");

	const selectedTabOnClick = (tabName) => {
		setSelectedTabs(tabName);

		if (tabName === "Personal") {
			setExpanded("Personal Details");
		} else if (tabName === "Education/Training") {
			setExpanded("Academic Summary");
		} else if (tabName === "Employment") {
			setExpanded("Employment History");
		} else if (tabName === "Other Information") {
			setExpanded("Specialization");
		}
	};

	// define tabs
	const tabNames = [
		{ name: "Personal", icon: "fa-solid fa-user" },
		{ name: "Education/Training", icon: "fa-solid fa-graduation-cap" },
		{ name: "Employment", icon: "fa-solid fa-briefcase" },
		{ name: "Other Information", icon: "fa-solid fa-bars-staggered" },
		{ name: "Photograph", icon: "fa-solid fa-camera" }
	];

	// accordion handler start
	const [expanded, setExpanded] = useState(false);

	const generateAccordion = () => {
		if (selectedTabs === "Personal") {
			return [
				{
					title: "Personal Details",
					contents: (
						<PersonalDetails
							userProfile={userProfile}
							setUserProfile={setUserProfile}
							handleOnChange={handleOnChange}
						/>
					)
				},
				{ title: "Address Details", contents: "contents" },
				{ title: "Career And Application Information", contents: "contents" },
				{ title: "Preferred Areas", contents: "contents" },
				{ title: "Other Relevant Information", contents: "contents" },
				{ title: "Disability Information (If Any)", contents: "contents" }
			];
		} else if (selectedTabs === "Education/Training") {
			return [
				{ title: "Academic Summary", contents: "contents" },
				{ title: "Training Summary", contents: "contents" },
				{ title: "Professional Certification Summary", contents: "contents" }
			];
		} else if (selectedTabs === "Employment") {
			return [{ title: "Employment History", contents: "contents" }];
		} else if (selectedTabs === "Other Information") {
			return [
				{ title: "Specialization", contents: "contents" },
				{ title: "Language Proficiency", contents: "contents" },
				{ title: "Link Accounts", contents: "contents" },
				{ title: "References", contents: "contents" }
			];
		} else {
			return [];
		}
	};
	// accordion handler end

	// fetching handler start
	const [userProfile, setUserProfile] = useState("");

	const handleOnChange = (event) => {
		const { name, value } = event.target;

		if (["phone", "secondary_phone", "emergency_contact"].includes(name)) {
			setUserProfile((prevValues) => ({
				...prevValues,
				[name]: value.replace(/[^0-9+]/g, "")
			}));
		} else {
			setUserProfile((prevValues) => ({
				...prevValues,
				[name]: value
			}));
		}
	};

	const getUserProfileDoc = async () => {
		try {
			const response = await fetch("/user-profile");

			const result = await response.json();

			if (response.ok) {
				setUserProfile(result);
			} else {
				setUserProfile("");
			}
		} catch (error) {
			return null;
		}
	};

	useEffect(() => {
		getUserProfileDoc();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// fetching handler end

	return (
		<div className="container">
			<div className="row user-profile-container">
				<div className="user-profile-wrapper col-11 col-sm-12">
					{!userProfile ? (
						<div className="loader-container">
							<CircularProgress />
						</div>
					) : (
						<>
							{/* displaying-tabs start  */}
							<div className="displaying-tabs">
								{tabNames.map((tabObj, index) => (
									<div
										key={index}
										className="tab-wrapper"
										id={tabObj.name === selectedTabs ? "active" : ""}
										onClick={() => selectedTabOnClick(tabObj.name)}
									>
										<i className={`${tabObj.icon} tab-icon`}></i>
										<span className="tab-name">{tabObj.name}</span>
									</div>
								))}
							</div>
							{/* displaying-tabs end  */}

							{/* accordion-wrapper start  */}
							<div className="accordion-wrapper">
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									{generateAccordion().map((item, index) => (
										<AccordionCom
											title={item.title}
											contents={item.contents}
											expanded={expanded}
											setExpanded={setExpanded}
											key={index}
										/>
									))}
								</LocalizationProvider>
							</div>
							{/* accordion-wrapper end  */}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default UserProfile;
