/* eslint-disable import/no-webpack-loader-syntax */
// internal components
import AccountManagerStyle from "!!raw-loader!./AccountManager.css";
import { RichTextParser, SectionTitle, SkillsLevel, Time } from "../Components";
import {
	checkVariables,
	divideAndAddBrTag,
	fetchingResumeData,
	isArrayEmpty,
	objHasAnyValue,
	removeEmptyObjects
} from "../Components/Utils";

const AccountManager = ({ getResume }) => {
	// fetching resume's all data
	const {
		personal_details_title,
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		professional_summary_title,
		rich_text_content,
		employment_history_title,
		employment_history_items,
		education_title,
		education_items,
		project_title,
		project_items,
		web_link_items,
		skills_title,
		skills_items,
		displaying_level,
		languages_title,
		languages_items,
		hobbies_title,
		hobbies_description,
		activity_title,
		activity_items,
		internship_title,
		internship_items,
		courses_title,
		courses_items,
		references_title,
		references_hide,
		references_items,
		customArr,
		disclaimer_title,
		disclaimer_description
	} = fetchingResumeData(getResume);

	// web_links
	const web_links = checkVariables(web_link_items, phone, email) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper when_left">
					<img
						src="/assets/icons/account-chat.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">Contact</div>
			</div>

			<div className="web__links_wrapper">
				{!isArrayEmpty(web_link_items) &&
					removeEmptyObjects(web_link_items, ["link_label", "link_url"]).map(
						(label, index) => {
							return (
								<div key={index} className="link__wrapper">
									<img
										src={`/assets/icons/${
											(label.link_url.includes("youtube") &&
												"account-youtube.png") ||
											(label.link_url.includes("twitter") &&
												"account-twitter.png") ||
											(label.link_url.includes("linkedin") &&
												"account-linkedin.png") ||
											(label.link_url.includes("instagram") &&
												"account-instagram.png") ||
											(label.link_url.includes("facebook") &&
												"account-facebook.png") ||
											"internet.png"
										}`}
										alt="icon"
										className="link__img"
									/>

									<a
										href={label.link_url}
										target="_blank"
										rel="noopener noreferrer"
										className="link__url"
									>
										{label.link_label}
									</a>
								</div>
							);
						}
					)}

				{phone && (
					<div className="link__wrapper">
						<img
							src={"/assets/icons/account-phone.png"}
							alt="icon"
							className="link__img"
						/>

						<a
							href={`tel:${phone}`}
							target="_blank"
							rel="noopener noreferrer"
							className="link__url"
						>
							{phone}
						</a>
					</div>
				)}

				{email && (
					<div className="link__wrapper">
						<img
							src={"/assets/icons/account-email.png"}
							alt="icon"
							className="link__img"
						/>

						<a
							href={`mailto:${email}`}
							target="_blank"
							rel="noopener noreferrer"
							className="link__url"
							dangerouslySetInnerHTML={{ __html: divideAndAddBrTag(email) }}
						></a>
					</div>
				)}
			</div>
		</div>
	) : (
		""
	);

	//  professional_summary
	const professional_summary = rich_text_content ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/professional-white.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<SectionTitle
					className="title"
					sectionName={professional_summary_title}
					equalTo="Professional Summary"
					initialName="Profile"
				/>
			</div>

			<div className="main-contents-wrapper">
				<RichTextParser
					className={"hobbies-description"}
					rich_text={rich_text_content}
				/>
			</div>
		</div>
	) : (
		""
	);

	//  employment_history
	const employment_history = !isArrayEmpty(employment_history_items) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/white-work-experience.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<SectionTitle
					className="title"
					sectionName={employment_history_title}
					equalTo="EmploymenT History"
					initialName="Work Experience"
				/>
			</div>

			<div className="main-contents-wrapper items-container">
				{employment_history_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						const checkTime = checkVariables(item.start_date, item.end_date);

						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item-dot"></div>

								<div className="item-content">
									<div className="item__header">
										{item.employment_job_title}

										{checkTime && " ( "}
										{checkTime && (
											<Time start={item.start_date} end={item.end_date} />
										)}
										{checkTime && " ) "}
									</div>

									<div className="item__sub_header">
										{item.employer}

										{item.employment_city && `, ${item.employment_city}`}
									</div>

									{item.employment_rich_text_content && (
										<RichTextParser
											className={"item__rich_editor"}
											rich_text={item.employment_rich_text_content}
										/>
									)}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// projects
	const projects = !isArrayEmpty(project_items) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/white-project.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<SectionTitle
					className="title"
					sectionName={project_title}
					equalTo="Projects/Portfolio"
					initialName="Projects"
				/>
			</div>

			<div className="main-contents-wrapper items-container">
				{project_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item-dot"></div>

								<div className="item-content">
									<div className="item__header project-header">
										<div>
											{item.project_name}

											{item.project_duration && ` ( ${item.project_duration} )`}
										</div>

										<div className="project_links">
											{!isArrayEmpty(item.project_links) &&
												removeEmptyObjects(item.project_links, [
													"label",
													"link"
												]).map((label, index) => {
													return (
														<div
															key={index}
															className={
																index === 0 ? "label label__first" : "label"
															}
														>
															<a
																href={label.link}
																target="_blank"
																rel="noopener noreferrer"
															>
																{label.label}
															</a>
														</div>
													);
												})}
										</div>
									</div>

									{checkVariables(item.technologies) && (
										<div className="item__sub_header add-margin-5">
											<strong>Technologies&nbsp;:&nbsp;</strong>
											{item.technologies}
										</div>
									)}

									{item.pro_description && (
										<RichTextParser
											className={"item__rich_editor"}
											rich_text={item.pro_description}
										/>
									)}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// education
	const education = !isArrayEmpty(education_items) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/white-education.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">{education_title}</div>
			</div>

			<div className="main-contents-wrapper items-container">
				{education_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item-dot"></div>

								<div className="item-content">
									<div className="item__header">{item.degree}</div>

									<div className="item__sub_header">
										{item.school}

										{item.edu_city && ` | ${item.edu_city}`}
									</div>

									{checkVariables(item.edu_start_date, item.edu_end_date) && (
										<div className="item__time">
											<Time
												start={item.edu_start_date}
												end={item.edu_end_date}
												fromWhere={"education"}
											/>
										</div>
									)}

									{item.edu_rich_text_content && (
										<RichTextParser
											className={"item__rich_editor"}
											rich_text={item.edu_rich_text_content}
										/>
									)}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// internship
	const internship = !isArrayEmpty(internship_items) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/white-internship.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">{internship_title}</div>
			</div>

			<div className="main-contents-wrapper items-container">
				{internship_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						const checkTime = checkVariables(
							item.intern_start_date,
							item.intern_end_date
						);

						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item-dot"></div>

								<div className="item-content">
									<div className="item__header">
										{item.intern_job}

										{checkTime && " ( "}
										{checkTime && (
											<Time
												start={item.intern_start_date}
												end={item.intern_end_date}
											/>
										)}
										{checkTime && " ) "}
									</div>

									<div className="item__sub_header">
										{item.intern_employer}

										{item.intern_city && `, ${item.intern_city}`}
									</div>

									{item.intern_rich_text_content && (
										<RichTextParser
											className={"item__rich_editor"}
											rich_text={item.intern_rich_text_content}
										/>
									)}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// courses
	const courses = !isArrayEmpty(courses_items) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/white-courses.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">{courses_title}</div>
			</div>

			<div className="main-contents-wrapper items-container">
				{courses_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						const checkTime = checkVariables(
							item.course_start_date,
							item.course_end_date
						);

						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item-dot"></div>

								<div className="item-content">
									<div className="item__header">
										{item.course_name}

										{checkTime && " ( "}
										{checkTime && (
											<Time
												start={item.course_start_date}
												end={item.course_end_date}
											/>
										)}
										{checkTime && " ) "}
									</div>

									<div className="item__sub_header">
										{item.course_institution}
									</div>

									{item.course_rich_text_content && (
										<RichTextParser
											className={"item__rich_editor"}
											rich_text={item.course_rich_text_content}
										/>
									)}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// extra curricular activities
	const activities = !isArrayEmpty(activity_items) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/white-activity.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">{activity_title}</div>
			</div>

			<div className="main-contents-wrapper items-container">
				{activity_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						const checkTime = checkVariables(
							item.activity_start_date,
							item.activity_end_date
						);

						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="item-dot"></div>

								<div className="item-content">
									<div className="item__header">
										{item.activity_function}

										{checkTime && " ( "}
										{checkTime && (
											<Time
												start={item.activity_start_date}
												end={item.activity_end_date}
											/>
										)}
										{checkTime && " ) "}
									</div>

									<div className="item__sub_header">
										{item.activity_employer}

										{item.activity_city && `, ${item.activity_city}`}
									</div>

									{item.activity_rich_text_content && (
										<RichTextParser
											className={"item__rich_editor"}
											rich_text={item.activity_rich_text_content}
										/>
									)}
								</div>
							</div>
						);
					})}
			</div>
		</div>
	) : (
		""
	);

	// references
	const references = !isArrayEmpty(references_items) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/references.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">{references_title}</div>
			</div>

			{references_hide && (
				<div className="main-contents-wrapper items-container reference-container">
					<div className="item-content">
						<div className="item__header">Reference available upon request</div>
					</div>
				</div>
			)}

			{!references_hide && (
				<div className="main-contents-wrapper items-container reference-container">
					{references_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							return (
								<div className="item reference-item" key={index}>
									{(index === 0 || index % 2 === 0) && (
										<div className="item-dot"></div>
									)}

									<div className="item-content">
										<div className="item__header">
											{item.references_name}
											{item.references_company &&
												`, ${item.references_company}`}
										</div>

										{checkVariables(
											item.references_phone,
											item.references_email
										) && (
											<div style={{ fontSize: "10px" }}>
												{item.references_phone}
												{item.references_phone && <br />}
												{item.references_email}
											</div>
										)}
									</div>
								</div>
							);
						})}
				</div>
			)}
		</div>
	) : (
		""
	);

	// custom
	const custom = (specificCustom) =>
		!isArrayEmpty(specificCustom.custom_items) ? (
			<div className="section">
				<div className="section_title__wrapper">
					<div className="icon-wrapper">
						<img
							src="/assets/icons/custom.png"
							alt="icon"
							className="icon left-icon"
						/>
					</div>

					<div className="title">{specificCustom.custom_title}</div>
				</div>

				<div className="main-contents-wrapper items-container">
					{specificCustom.custom_items
						.filter((obj) => objHasAnyValue(obj))
						.map((item, index) => {
							const checkTime = checkVariables(
								item.custom_start_date,
								item.custom_end_date
							);

							return (
								<div
									className={index === 0 ? "item item__first" : "item"}
									key={index}
								>
									<div className="item-dot"></div>

									<div className="item-content">
										<div className="item__header">
											{item.custom_activity}

											{checkTime && " ( "}
											{checkTime && (
												<Time
													start={item.custom_start_date}
													end={item.custom_end_date}
												/>
											)}
											{checkTime && " ) "}
										</div>

										<div className="item__sub_header">{item.custom_city}</div>

										{item.custom_rich_text_content && (
											<RichTextParser
												className={"item__rich_editor"}
												rich_text={item.custom_rich_text_content}
											/>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
		) : (
			""
		);

	//  personal_details
	const personal_details = checkVariables(
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth
	) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper when_left">
					<img
						src="/assets/icons/account-personal.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">{personal_details_title}</div>
			</div>

			<div className="main-contents-wrapper">
				{/* present_address start */}
				{checkVariables(
					present_address,
					present_city,
					present_area,
					present_zip
				) && (
					<div className="personal__sub-header">
						<div className="header">Present Address</div>

						<div className="content">
							{present_address}

							{present_city && `, ${present_city}`}

							{present_area && `, ${present_area}`}

							{present_zip && `, ${present_zip}`}

							{!checkVariables(
								permanent_address,
								permanent_city,
								permanent_area,
								permanent_zip
							) &&
								country &&
								`, ${country}`}
						</div>
					</div>
				)}
				{/* present_address end */}

				{/* permanent_address start */}
				{checkVariables(
					permanent_address,
					permanent_city,
					permanent_area,
					permanent_zip,
					same_address
				) && (
					<div className="personal__sub-header">
						<div className="header">Permanent Address</div>

						{same_address ? (
							<div className="content">Same as above</div>
						) : (
							<div className="content">
								{permanent_address}
								{permanent_city && `, ${permanent_city}`}

								{permanent_area && `, ${permanent_area}`}

								{permanent_zip && `, ${permanent_zip}`}

								{country && `, ${country}`}
							</div>
						)}
					</div>
				)}
				{/* permanent_address end */}

				{nationality && (
					<div className="personal__sub-header">
						<div className="header">Nationality</div>

						<div className="content">{nationality}</div>
					</div>
				)}

				{nid && (
					<div className="personal__sub-header">
						<div className="header">{isNaN(nid) ? "Passport" : "NID"}</div>

						<div className="content">{nid}</div>
					</div>
				)}

				{place_of_birth && (
					<div className="personal__sub-header">
						<div className="header">Place Of Birth</div>

						<div className="content">{place_of_birth}</div>
					</div>
				)}

				{date_of_birth && (
					<div className="personal__sub-header">
						<div className="header">Date Of Birth</div>

						<div className="content">{date_of_birth}</div>
					</div>
				)}

				{driving_license && (
					<div className="personal__sub-header">
						<div className="header">Driving License</div>

						<div className="content">{driving_license}</div>
					</div>
				)}
			</div>
		</div>
	) : (
		""
	);

	// skills
	const skills = !isArrayEmpty(skills_items) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper when_left">
					<img
						src="/assets/icons/account-skill.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">{skills_title}</div>
			</div>

			<div className="main-contents-wrapper skill-wrapper">
				{skills_items.map((value, index) => {
					return (
						<SkillsLevel
							title_class="skill_title"
							level_class="skill_level"
							bg_color="#0a2640"
							skill={value.skill}
							skill_level={value.skill_level}
							key={index}
							displaying_level={displaying_level}
						/>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// languages
	const languages = !isArrayEmpty(languages_items) ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper when_left">
					<img
						src="/assets/icons/account-language.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">{languages_title}</div>
			</div>

			<div className="main-contents-wrapper skill-wrapper">
				{languages_items.map((value, index) => {
					return (
						<SkillsLevel
							title_class="skill_title"
							level_class="skill_level"
							bg_color="#0a2640"
							skill={value.languages_name}
							skill_level={value.languages_level}
							key={index}
						/>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	//  hobbies
	const hobbies = hobbies_description ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/white-hobby.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">{hobbies_title}</div>
			</div>

			<div className="main-contents-wrapper">
				<pre className="hobbies-description">{hobbies_description}</pre>
			</div>
		</div>
	) : (
		""
	);

	//  disclaimer
	const disclaimer = disclaimer_description ? (
		<div className="section">
			<div className="section_title__wrapper">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/disclaimer-white.png"
						alt="icon"
						className="icon left-icon"
					/>
				</div>

				<div className="title">{disclaimer_title}</div>
			</div>

			<div className="main-contents-wrapper">
				<div className="hobbies-description">{disclaimer_description}</div>
			</div>
		</div>
	) : (
		""
	);

	// for displaying additional section start
	const additionalSection = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Courses") {
			storeAdditionalSection.push(courses);
		} else if (value.key === "Extra-curricular Activities") {
			storeAdditionalSection.push(activities);
		} else if (value.key === "Internship") {
			storeAdditionalSection.push(internship);
		} else if (value.key === "Custom") {
			customArr.map(
				(item) =>
					item.cus_section_id === value._id &&
					storeAdditionalSection.push(custom(item))
			);
		}

		return storeAdditionalSection;
	});
	// for displaying additional section end

	// left-section array
	const leftSection = [web_links, personal_details, skills, languages];

	// right-section array
	const rightSection = [
		professional_summary,
		employment_history,
		projects,
		education,
		...additionalSection,
		hobbies,
		references,
		disclaimer
	];

	return (
		<>
			<style>{AccountManagerStyle}</style>

			<div className="container">
				{/* top-header  */}
				<div className="top_header"></div>

				<div className="body">
					{/* body__left start  */}
					<div className="body__left">
						<div className="body__left_bg"></div>

						<div className="img-container">
							<img
								src={`/assets/profile-img/${
									cropped_profile_img
										? cropped_profile_img
										: "default-profile.png"
								}`}
								alt="profile-img"
								className="profile-img"
							/>
						</div>

						{leftSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div key={index}>{value}</div>
							))}
					</div>
					{/* body__left end  */}

					{/* body__right start  */}
					<div className="body__right">
						{/* name, job-title  start */}
						<div className="identity">
							{checkVariables(first_name, last_name) && (
								<div className="name">
									{first_name && first_name} &nbsp; {last_name && last_name}
								</div>
							)}

							{job_title && <div className="job-title">{job_title}</div>}
						</div>
						{/* name, job-title  end */}

						{rightSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div key={index}>{value}</div>
							))}
					</div>
					{/* body__right end  */}
				</div>
			</div>
		</>
	);
};

export default AccountManager;
