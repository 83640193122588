// external components
import { Link } from "react-router-dom";

// internal components
import { GetContextApi } from "../../storage/ContextApi";

const PaymentFailed = () => {
	// contents
	const { contents } = GetContextApi();
	const { failed } = contents.thank_you_page;

	return (
		<>
			<div
				className="d-flex justify-content-center align-items-center"
				style={{
					minHeight: "calc(var(--vh, 1vh) * 100 - 100px)",
					padding: "0 30px"
				}}
			>
				<div className="col-md-4">
					<div className="border border-3 border-danger"></div>
					<div className="card  bg-white shadow p-5">
						<div className="mb-4 text-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="75"
								height="75"
								fill="currentColor"
								className="bi bi-x-circle text-danger"
								viewBox="0 0 16 16"
							>
								<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
								<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
							</svg>
						</div>
						<div className="text-center">
							<h1>{failed.header}</h1>
							<p dangerouslySetInnerHTML={{ __html: failed.description }}></p>
							<button className="primary-btn-styles">
								<Link
									to={"../subscription"}
									className="text-white text-decoration-none"
								>
									{failed.btn_text}
								</Link>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PaymentFailed;
