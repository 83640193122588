// external components
import { useState } from "react";
import toast from "react-hot-toast";

// internal components
import RestrictionDialog from "../RestrictionDialog";
import "./_StuModal.scss";

const StuModal = ({
	setVerifyStuModal,
	handleClickOpen,
	modalRef,
	getEmail,
	setEmail,
	currentPassword,
	setCurrentPassword,
	contents,
	userEmail,
	setCurrentUser
}) => {
	// contents
	const { email_error_msg } = contents.log_in;
	const { verify_with_photo_identity } = contents.package_limit;
	const { student_verification, current_password } = contents.account_setting;

	// getting radio option
	const [selectedOption, setSelectedOption] = useState("using-email");

	// for checking button clicked for submit or not
	const [btnClicked, setBtnClicked] = useState(false);

	const handleRadioChange = (event) => {
		setSelectedOption(event.target.value);
	};

	// student email validation
	const isValidEduEmail = (email) => {
		if (!email) return false;

		const emailParts = email.split("@");
		if (emailParts.length !== 2) return false;

		const domain = emailParts[1];
		const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		return (
			validEmailRegex.test(email) &&
			(domain.includes(".edu.") || domain.endsWith(".edu"))
		);
	};

	// upload student card start
	const [stuCard, setStuCard] = useState("");
	const [restrictionObj, setRestrictionObj] = useState("");

	const uploadStudentCard = async (userEmail) => {
		try {
			const formData = new FormData();

			const extension = stuCard.name.split(".").pop();

			formData.append("file", stuCard, `stu_card_${userEmail}.${extension}`);

			const response = await fetch(
				`/user/sign-up/student/with-password?userEmail=${userEmail}&currentPassword=${currentPassword}`,
				{
					method: "POST",
					body: formData
				}
			);

			const result = await response.json();

			if (response.status === 200) {
				setRestrictionObj(verify_with_photo_identity);
				setStuCard("");
				setCurrentUser((prev) => ({
					...prev,
					stu_card: `stu_card_${userEmail}.${extension}`
				}));
			} else if (result.error) {
				toast.error(result.error, {
					position: "top-right",
					autoClose: 2500,
					theme: "colored"
				});
			}
		} catch (error) {
			toast.error(error.message, {
				position: "top-right",
				autoClose: 2500,
				theme: "colored"
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};
	// upload student card end

	return (
		<>
			<div className="stu-modal-container">
				<div className="stu-modal-wrapper" data-aos="fade-down" ref={modalRef}>
					<div className="modal-head">
						<h3>{student_verification.header}</h3>
					</div>

					{/* radio btn container start  */}
					<div className="radio-btn-container">
						<div className="form-check form-check-inline radio-item">
							<input
								className="form-check-input"
								type="radio"
								name="inlineRadioOptions"
								id="using-email"
								value="using-email"
								checked={selectedOption === "using-email"}
								onChange={handleRadioChange}
							/>
							<label
								className="form-check-label modal__level"
								for="using-email"
							>
								{student_verification.verify_with_id}
								<div id="example">(e.g. yourname@versity.edu)</div>
							</label>
						</div>

						<div className="form-check form-check-inline radio-item">
							<input
								className="form-check-input"
								type="radio"
								name="inlineRadioOptions"
								id="using-stu-card"
								value="using-stu-card"
								checked={selectedOption === "using-stu-card"}
								onChange={handleRadioChange}
							/>
							<label
								className="form-check-label modal__level"
								for="using-stu-card"
							>
								{student_verification.verify_with_photo}
							</label>
						</div>
					</div>
					{/* radio btn container end  */}

					{/* .input-fields-container start  */}
					<div className="input-fields-container">
						<div className="input-fields">
							{selectedOption === "using-email" ? (
								<div className="field">
									{btnClicked && !isValidEduEmail(getEmail) && (
										<p id="error-msg">{email_error_msg}</p>
									)}

									<label htmlFor="email" className="input-label">
										{student_verification.stu_email}
									</label>

									<input
										type="email"
										id="email"
										className="input"
										placeholder="e.g. yourname@versity.edu"
										onChange={(e) => setEmail(e.target.value)}
										onFocus={() => setBtnClicked(true)}
										value={getEmail}
									/>
								</div>
							) : (
								<div className="stu-card-wrapper">
									<label
										className={
											btnClicked && !stuCard
												? "get-stu-card-wrapper inactive"
												: "get-stu-card-wrapper"
										}
										id="stu-card"
									>
										<i className="fa-solid fa-cloud-arrow-up" id="icon"></i>

										<span id="support">
											{stuCard ? stuCard?.name : "Supports : JPEG, JPG, PNG"}
										</span>

										<input
											style={{ display: "none" }}
											type="file"
											id="stu-card"
											onChange={(e) => setStuCard(e.target.files[0])}
										/>
									</label>
								</div>
							)}

							<div className="field">
								<label htmlFor="password" className="input-label">
									{current_password}
								</label>

								<input
									type="password"
									id="password"
									className="input"
									placeholder="current password . . ."
									onChange={(e) => setCurrentPassword(e.target.value)}
									value={currentPassword}
								/>
							</div>

							<div className="field btn-container">
								<button
									className="primary-btn-styles input-btn"
									id={
										(selectedOption === "using-email" &&
										!(isValidEduEmail(getEmail) && currentPassword)
											? "disable"
											: "") ||
										(selectedOption === "using-stu-card" &&
										!(stuCard && currentPassword)
											? "disable"
											: "")
									}
									onClick={() => {
										selectedOption === "using-email"
											? handleClickOpen("student-verification")
											: uploadStudentCard(userEmail);
									}}
								>
									{student_verification.submit}
								</button>
							</div>
						</div>
					</div>
					{/* .input-fields-container end  */}

					<div
						className="modal-close-btn"
						onClick={() => {
							setVerifyStuModal(false);
						}}
					>
						<i className="fa-solid fa-xmark" id="close-icon"></i>
					</div>
				</div>

				{/* for restriction dialog box */}
				{restrictionObj && (
					<RestrictionDialog
						restrictionObj={restrictionObj}
						setRestrictionObj={setRestrictionObj}
					/>
				)}
			</div>
		</>
	);
};

export default StuModal;
