/* eslint-disable import/no-webpack-loader-syntax */
// internal components
import CreativeDesignerStyle from "!!raw-loader!./CreativeDesigner.css";
import { RichTextParser, SectionTitle, SkillsLevel, Time } from "../Components";
import {
	checkVariables,
	divideAndAddBrTag,
	fetchingResumeData,
	generateIconBlack,
	isArrayEmpty,
	objHasAnyValue,
	removeEmptyObjects
} from "../Components/Utils";

const CreativeDesigner = ({ getResume }) => {
	// fetching resume's all data
	const {
		job_title,
		cropped_profile_img,
		first_name,
		last_name,
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth,
		professional_summary_title,
		rich_text_content,
		employment_history_title,
		employment_history_items,
		education_title,
		education_items,
		project_title,
		project_items,
		web_link_items,
		skills_title,
		skills_items,
		displaying_level,
		languages_title,
		languages_items,
		hobbies_title,
		hobbies_description,
		activity_title,
		activity_items,
		internship_title,
		internship_items,
		courses_title,
		courses_items,
		references_title,
		references_hide,
		references_items,
		customArr,
		disclaimer_title,
		disclaimer_description
	} = fetchingResumeData(getResume);

	//  professional_summary
	const professional_summary = rich_text_content ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img src="/assets/icons/user.png" alt="icon" className="icon" />
				</div>
				<SectionTitle
					className="title"
					sectionName={professional_summary_title}
					equalTo="Professional Summary"
					initialName="Profile"
				/>
			</div>

			<div className="item__first add-padding">
				<RichTextParser className={""} rich_text={rich_text_content} />
			</div>
		</div>
	) : (
		""
	);

	//  employment_history
	const employment_history = !isArrayEmpty(employment_history_items) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/professional-templates.png"
						alt="icon"
						className="icon"
					/>
				</div>
				<SectionTitle
					className="title"
					sectionName={employment_history_title}
					equalTo="Employment History"
					initialName="WORK EXPERIENCE"
				/>
			</div>

			{employment_history_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="bullet"></div>

							<div className="space-between">
								<div className="item-header">{item.employment_job_title}</div>

								<div className="item-header">
									<Time start={item.start_date} end={item.end_date} />
								</div>
							</div>

							{checkVariables(item.employer, item.employment_city) && (
								<div className="item-content">
									{item.employer}

									{item.employer && item.employment_city && " / "}

									{item.employment_city}
								</div>
							)}

							{item.employment_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.employment_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// projects
	const projects = !isArrayEmpty(project_items) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/project-icon.png"
						alt="icon"
						className="icon"
					/>
				</div>
				<SectionTitle
					className="title"
					sectionName={project_title}
					equalTo="Projects/Portfolio"
					initialName="Projects"
				/>
			</div>

			{project_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="bullet"></div>

							<div className="space-between">
								<div className="item-header">{item.project_name}</div>

								<div className="item-header">
									{!isArrayEmpty(item.project_links) &&
										removeEmptyObjects(item.project_links, [
											"label",
											"link"
										]).map((label, index) => {
											return (
												<span key={index}>
													<a
														href={label.link}
														target="_blank"
														rel="noopener noreferrer"
													>
														{label.label}
													</a>
													{index !== item.project_links.length - 1 && " | "}
												</span>
											);
										})}
								</div>
							</div>

							{item.project_duration && (
								<div className="item-content">{item.project_duration}</div>
							)}

							{item.technologies && (
								<div className="item-content">
									<b>Technologies:&nbsp;</b>
									{item.technologies}
								</div>
							)}

							{item.pro_description && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.pro_description}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// education
	const education = !isArrayEmpty(education_items) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img src="/assets/icons/graduate.png" alt="icon" className="icon" />
				</div>
				<div className="title">{education_title}</div>
			</div>

			{education_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.edu_start_date,
						item.edu_end_date
					);

					return (
						<div className="item" key={index}>
							<div className="item-header">{item.degree}</div>

							{item.school && <div className="item-content">{item.school}</div>}

							{checkVariables(item.edu_city, checkTime) && (
								<div className="item-content">
									{item.edu_city}

									{item.edu_city && checkTime && ", "}

									{checkTime && (
										<Time
											start={item.edu_start_date}
											end={item.edu_end_date}
											fromWhere={"education"}
										/>
									)}
								</div>
							)}

							{item.edu_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.edu_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// internship
	const internship = !isArrayEmpty(internship_items) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img src="/assets/icons/internship.png" alt="icon" className="icon" />
				</div>
				<div className="title">{internship_title}</div>
			</div>

			{internship_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.intern_start_date,
						item.intern_end_date
					);

					return (
						<div className="item" key={index}>
							<div className="item-header">{item.intern_job}</div>

							{item.intern_employer && (
								<div className="item-content">{item.intern_employer}</div>
							)}

							{checkVariables(item.intern_city, checkTime) && (
								<div className="item-content">
									{item.intern_city}

									{item.intern_city && checkTime && ", "}

									{checkTime && (
										<Time
											start={item.intern_start_date}
											end={item.intern_end_date}
										/>
									)}
								</div>
							)}

							{item.intern_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.intern_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// courses
	const courses = !isArrayEmpty(courses_items) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/courses-black.png"
						alt="icon"
						className="icon"
					/>
				</div>
				<div className="title">{courses_title}</div>
			</div>

			{courses_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					const checkTime = checkVariables(
						item.course_start_date,
						item.course_end_date
					);

					return (
						<div className="item" key={index}>
							<div className="item-header">{item.course_name}</div>

							{item.course_institution && (
								<div className="item-content">{item.course_institution}</div>
							)}

							{checkTime && (
								<div className="item-content">
									<Time
										start={item.course_start_date}
										end={item.course_end_date}
									/>
								</div>
							)}

							{item.course_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.course_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// extra curricular activities
	const activities = !isArrayEmpty(activity_items) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/activities-black-2.png"
						alt="icon"
						className="icon"
					/>
				</div>
				<div className="title">{activity_title}</div>
			</div>

			{activity_items
				.filter((obj) => objHasAnyValue(obj))
				.map((item, index) => {
					return (
						<div
							className={index === 0 ? "item item__first" : "item"}
							key={index}
						>
							<div className="bullet"></div>

							<div className="space-between">
								<div className="item-header">{item.activity_function}</div>

								<div className="item-header">
									<Time
										start={item.activity_start_date}
										end={item.activity_end_date}
									/>
								</div>
							</div>

							{checkVariables(item.activity_employer, item.activity_city) && (
								<div className="item-content">
									{item.activity_employer}

									{item.activity_employer && item.activity_city && " / "}

									{item.activity_city}
								</div>
							)}

							{item.activity_rich_text_content && (
								<RichTextParser
									className={"rich_editor__container"}
									rich_text={item.activity_rich_text_content}
								/>
							)}
						</div>
					);
				})}
		</div>
	) : (
		""
	);

	// references
	const references = !isArrayEmpty(references_items) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/reference-black.png"
						alt="icon"
						className="icon"
					/>
				</div>
				<div className="title">{references_title}</div>
			</div>

			{references_hide && (
				<div className="item">
					<div className="item-content remove-padding">
						Reference available upon request
					</div>
				</div>
			)}

			{!references_hide &&
				references_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div className="item" key={index}>
								<div className="item-header">{item.references_name}</div>

								{item.references_company && (
									<div className="item-content">{item.references_company}</div>
								)}

								{item.references_phone && (
									<div className="item-content">{item.references_phone}</div>
								)}

								{item.references_email && (
									<div className="item-content">{item.references_email}</div>
								)}
							</div>
						);
					})}
		</div>
	) : (
		""
	);

	// custom
	const custom = (specificCustom) =>
		!isArrayEmpty(specificCustom.custom_items) ? (
			<div className="section">
				<div className="section-title-wrapper align-center">
					<div className="icon-wrapper">
						<img
							src="/assets/icons/custom-black.png"
							alt="icon"
							className="icon"
						/>
					</div>
					<div className="title">{specificCustom.custom_title}</div>
				</div>

				{specificCustom.custom_items
					.filter((obj) => objHasAnyValue(obj))
					.map((item, index) => {
						return (
							<div
								className={index === 0 ? "item item__first" : "item"}
								key={index}
							>
								<div className="bullet"></div>

								<div className="space-between">
									<div className="item-header">{item.custom_activity}</div>

									<div className="item-header">
										<Time
											start={item.custom_start_date}
											end={item.custom_end_date}
										/>
									</div>
								</div>

								{item.custom_city && (
									<div className="item-content">{item.custom_city}</div>
								)}

								{item.custom_rich_text_content && (
									<RichTextParser
										className={"rich_editor__container"}
										rich_text={item.custom_rich_text_content}
									/>
								)}
							</div>
						);
					})}
			</div>
		) : (
			""
		);

	//  personal_details
	const personal_details = checkVariables(
		email,
		phone,
		country,
		nationality,
		present_address,
		present_city,
		present_area,
		present_zip,
		same_address,
		permanent_address,
		permanent_city,
		permanent_area,
		permanent_zip,
		driving_license,
		nid,
		place_of_birth,
		date_of_birth
	) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img src="/assets/icons/user.png" alt="icon" className="icon" />
				</div>
				<div className="title">Contact Me</div>
			</div>

			<div className="contents-wrapper">
				{/* present_address start */}
				{checkVariables(
					present_address,
					present_city,
					present_area,
					present_zip
				) && (
					<div className="align-top mt-5">
						<div className="item-icon-wrapper">
							<img
								src="/assets/icons/location-black.png"
								alt="icon"
								className="icon"
							/>
						</div>

						<div className="item-content personal-d-item">
							{present_address}

							{present_address && present_city && ", "}

							{present_city}

							{present_area && `, ${present_area}`}

							{present_zip && `, ${present_zip}`}

							{!checkVariables(
								permanent_address,
								permanent_city,
								permanent_area,
								permanent_zip
							) &&
								country &&
								`, ${country}`}
						</div>
					</div>
				)}
				{/* present_address end */}

				{/* permanent_address start */}
				{checkVariables(
					permanent_address,
					permanent_city,
					permanent_area,
					permanent_zip,
					same_address
				) && (
					<div className="align-top mt-5">
						<div className="item-icon-wrapper">
							<img
								src="/assets/icons/location-black.png"
								alt="icon"
								className="icon"
							/>
						</div>

						<div className="item-content personal-d-item">
							{same_address ? (
								<>Same as above</>
							) : (
								<>
									{permanent_address}

									{permanent_address && permanent_city && ", "}

									{permanent_city}

									{permanent_area && `, ${permanent_area}`}

									{permanent_zip && `, ${permanent_zip}`}

									{country && `, ${country}`}
								</>
							)}
						</div>
					</div>
				)}
				{/* permanent_address end */}

				{/* phone start  */}
				{phone && (
					<div className="align-top mt-5">
						<div className="item-icon-wrapper">
							<img
								src="/assets/icons/phone-black.png"
								alt="icon"
								className="icon"
							/>
						</div>

						<div className="item-content personal-d-item">
							<a
								href={`tel:${phone}`}
								target="_blank"
								rel="noopener noreferrer"
								className="anchor"
							>
								{phone}
							</a>
						</div>
					</div>
				)}
				{/* phone end  */}

				{/* email start  */}
				{email && (
					<div className="align-top mt-5">
						<div className="item-icon-wrapper">
							<img
								src="/assets/icons/email-fill-black.png"
								alt="icon"
								className="icon"
							/>
						</div>

						<div className="item-content personal-d-item">
							<a
								href={`mailto:${email}`}
								target="_blank"
								rel="noopener noreferrer"
								className="anchor"
								dangerouslySetInnerHTML={{
									__html: divideAndAddBrTag(email, 30)
								}}
							></a>
						</div>
					</div>
				)}
				{/* email end  */}

				{/* other start  */}
				{checkVariables(
					nationality,
					nid,
					place_of_birth,
					date_of_birth,
					driving_license
				) && (
					<div className="align-top mt-5">
						<div className="item-icon-wrapper">
							<img src="/assets/icons/other.png" alt="icon" className="icon" />
						</div>

						<div className="item-content when-others">
							{nationality && (
								<div className="mt-3"> Nationality:&nbsp; {nationality}</div>
							)}
							{nid && (
								<div className="mt-3">
									{isNaN(nid) ? "Passport" : "NID"}:&nbsp; {nid}
								</div>
							)}
							{driving_license && (
								<div className="mt-3">
									Driving License:&nbsp; {driving_license}
								</div>
							)}
							{place_of_birth && (
								<div className="mt-3">
									Place Of Birth:&nbsp; {place_of_birth}
								</div>
							)}
							{date_of_birth && (
								<div className="mt-3">Date Of Birth:&nbsp; {date_of_birth}</div>
							)}
						</div>
					</div>
				)}
				{/* other end */}
			</div>
		</div>
	) : (
		""
	);

	// web_links
	const web_links = !isArrayEmpty(web_link_items) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img src="/assets/icons/follow-me.png" alt="icon" className="icon" />
				</div>
				<div className="title">Follow Me</div>
			</div>

			<div className="contents-wrapper">
				{!isArrayEmpty(web_link_items) &&
					removeEmptyObjects(web_link_items, ["link_url", "link_label"]).map(
						(label, index) => {
							return (
								<div
									key={index}
									className="item-content remove-padding align-center mt-5"
								>
									<div className="item-icon-wrapper">
										<img
											src={`/assets/icons/${generateIconBlack(label.link_url)}`}
											alt="icon"
											className="icon"
										/>
									</div>

									<a
										href={label.link_url}
										target="_blank"
										rel="noopener noreferrer"
									>
										{label.link_label}
									</a>
								</div>
							);
						}
					)}
			</div>
		</div>
	) : (
		""
	);

	// skills
	const skills = !isArrayEmpty(skills_items) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img src="/assets/icons/skills.png" alt="icon" className="icon" />
				</div>
				<div className="title">{skills_title}</div>
			</div>

			<div className="skills-container add-padding">
				{skills_items.map((value, index) => {
					return (
						<div className="skills-wrapper" key={index}>
							<SkillsLevel
								title_class="skill_title"
								level_class="skill_level"
								bg_color="#f2ab02"
								skill={value.skill}
								skill_level={value.skill_level}
								displaying_level={displaying_level}
							/>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	// languages
	const languages = !isArrayEmpty(languages_items) ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img src="/assets/icons/languages.png" alt="icon" className="icon" />
				</div>
				<div className="title">{languages_title}</div>
			</div>

			<div className="skills-container add-padding">
				{languages_items.map((value, index) => {
					return (
						<div className="skills-wrapper" key={index}>
							<SkillsLevel
								title_class="skill_title"
								level_class="skill_level"
								bg_color="#f2ab02"
								skill={value.languages_name}
								skill_level={value.languages_level}
							/>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	//  hobbies
	const hobbies = hobbies_description ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img src="/assets/icons/hobbies.png" alt="icon" className="icon" />
				</div>
				<div className="title">{hobbies_title}</div>
			</div>

			<div className="contents-wrapper">
				{hobbies_description.split(",").map((value, index) => {
					return (
						<div
							key={index}
							className="item-content remove-padding align-center mt-5"
						>
							<div className="mr"> - </div>
							{value}
						</div>
					);
				})}
			</div>
		</div>
	) : (
		""
	);

	//  disclaimer
	const disclaimer = disclaimer_description ? (
		<div className="section">
			<div className="section-title-wrapper align-center">
				<div className="icon-wrapper">
					<img
						src="/assets/icons/disclaimer-black.png"
						alt="icon"
						className="icon"
					/>
				</div>
				<div className="title">{disclaimer_title}</div>
			</div>

			<div className="item__first add-padding">
				<div className="item-content remove-padding">
					{disclaimer_description}
				</div>
			</div>
		</div>
	) : (
		""
	);

	// for displaying additional section Left start
	const additionalSectionLeft = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Courses") {
			storeAdditionalSection.push(courses);
		} else if (value.key === "Internship") {
			storeAdditionalSection.push(internship);
		}

		return storeAdditionalSection;
	});
	// for displaying additional section Left end

	// for displaying additional section Right start
	const additionalSectionRight = getResume.additional_section.map((value) => {
		let storeAdditionalSection = [];
		if (value.key === "Extra-curricular Activities") {
			storeAdditionalSection.push(activities);
		} else if (value.key === "Custom") {
			customArr.map(
				(item) =>
					item.cus_section_id === value._id &&
					storeAdditionalSection.push(custom(item))
			);
		}

		return storeAdditionalSection;
	});
	// for displaying additional section Right end

	// left-section array
	const leftSection = [
		personal_details,
		education,
		...additionalSectionLeft,
		hobbies,
		web_links,
		references
	];

	// right-section array
	const rightSection = [
		professional_summary,
		employment_history,
		projects,
		...additionalSectionRight,
		skills,
		languages,
		disclaimer
	];

	return (
		<>
			<style>{CreativeDesignerStyle}</style>

			<div className="container">
				<div className="body d-flex">
					{/* body__left start  */}
					<div className="body__left">
						<div className="body__left_bg"></div>

						<div className="img-container">
							<img
								src={`/assets/profile-img/${
									cropped_profile_img
										? cropped_profile_img
										: "default-profile.png"
								}`}
								alt="profile-img"
								className="profile-img"
							/>
						</div>

						{leftSection
							.filter((item) => item !== "")
							.map((value, index) => (
								<div
									key={index}
									className={index === 0 ? "left-first-section" : ""}
								>
									{value}
								</div>
							))}
					</div>
					{/* body__left end  */}

					{/* body__right start  */}
					<div className="body__right">
						{/* name, job-title  start */}
						<div className="identity">
							{checkVariables(first_name, last_name) && (
								<div className="name">
									{first_name} &nbsp; {last_name}
								</div>
							)}

							{job_title && <div className="job-title">{job_title}</div>}
						</div>
						{/* name, job-title  end */}

						<div className="right-section-wrapper">
							{rightSection
								.filter((item) => item !== "")
								.map((value, index) => (
									<div key={index}>{value}</div>
								))}
						</div>
					</div>
					{/* body__right end  */}
				</div>
			</div>
		</>
	);
};

export default CreativeDesigner;
